import { Rede } from "../interfaces/Rede.interface";
import api from "../services/api/api";

class RedeAPI {

    listarPorId = async (redeId: string) => await api.get(`/Rede/id/${redeId}`);

    listarPorCondominio = async (condominioId: string) => await api.get(`/Rede/condominio/${condominioId}`);

    cadastrar = async (rede: Rede) => await api.post<Rede>(`/Rede`, rede);

    atualizar = async (rede: Rede) => await api.put(`/Rede`, rede);

    deletar = async (redeId: string) => await api.delete(`/Rede/${redeId}`);
}

export default new RedeAPI();