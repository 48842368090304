import React from 'react';
import {
  Box,
  Message,
  Content,
  Icon,
} from './NotFound.styles';
import { ReactComponent as IconEmpty } from '../../assets/icons/icon-empty.svg';

interface NotFoundProps {
  message: string;
}

const NotFound: React.FC<NotFoundProps> = ({ message }) => (
  <Box>
    <Icon><IconEmpty /></Icon>
    <Content>
      <Message>{message}</Message>
    </Content>
  </Box>
);

export default NotFound;
