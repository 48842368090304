import { lighten } from 'polished';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

export const Title = styled.div`
  margin: 4px 8px;
  /* background-color: ${(props) => lighten(0.43, props.theme.colors.primary)}; */
  background: rgba(0, 0, 0, 0.05);
  color: ${(props) => props.theme.colors.primary};
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 800;
  border-radius: 5px;
  .history {
    align-self: center;
    font-size: 13pt;
  }
  &:first-child {
    border-top: none;
  }
`;

export const Notificacao = styled.div`
  font-weight: 700;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  line-height: 1;
`;

export const TransitionDiv = styled.div`  
  .animation {
    // height: 60vh;
  }  
  .fade-enter .animation {
    opacity: 0;
    transform: translateX(0%);
  }
  .fade-enter-active .animation {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit .animation {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit-active .animation {
    opacity: 0;
    transform: translateX(10%);
  }
  .fade-enter-active .animation,
  .fade-exit-active .animation {
    transition: opacity 200ms, transform 200ms;
  }
`
export const BiometriaContainer = styled.form`
  .title {
    text-align: center;
    font-size: 12pt;
    font-weight: 550;
    color: rgba(0,0,0,0.8);
  }
`;

export const RelatorioContainer = styled.div`
  background: ${(props) => props.theme.colors.bgBox};
  width: 20vw;
  height: 100vh
`;

export const Sidebar = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.default};
  width: 400px;
  flex-grow: 1;
  padding: 10px 0;
`;

export const TitleRelatorio = styled.div`
  margin: 4px 8px;
  /* background-color: ${(props) => lighten(0.43, props.theme.colors.primary)}; */
  background: rgba(0, 0, 0, 0.05);
  color: ${(props) => props.theme.colors.primary};
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 800;
  border-radius: 5px;
  .history {
    align-self: center;
    font-size: 13pt;
  }
  &:first-child {
    border-top: none;
  }
`;
 
export const Content = styled(PerfectScrollbar)`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
  padding-left: 10px;
`;

export const Icon = styled.div`
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 20px;
  background-color: ${(props) => props.theme.colors.warning};
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
`;