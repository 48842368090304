import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import UnidadeAPI from "../api/UnidadeAPI";
import { Unidade } from "../interfaces/Unidade.interface";

class UnidadeStore {

    loading: boolean = false;
    unidades: Unidade[] = [];
    unidadesPorCondomino: Unidade[] = [];
    unidadesPorCondominoSelecionado: Unidade[] = [];
    selecionado: Unidade | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async listar(blocoId: string) {
        this.loading = true;
        try {
            const { data } = await UnidadeAPI.listarUnidadePorBlocoId(blocoId);
            runInAction(() => {
                if(data)
                {
                    this.unidades = data;
                }
                else
                {
                    this.unidades = [];
                }
                this.loading = false;
            });
        } catch(e) {
            
        }
    }

    async listarPorCondominioId() {
        this.loading = true;
        let condominioId = localStorage.getItem('condominio')
        try {
            if(condominioId) {
                const { data } = await UnidadeAPI.listarUnidadePorCondominioId(condominioId);
                runInAction(() => {
                    if(data)
                    {
                        this.unidadesPorCondomino = data;
                    }
                    else
                    {
                        this.unidadesPorCondomino = [];
                    }
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarPorCondominioSelecionado(condominioId: string) {
        try {
            if(condominioId) {
                const { data } = await UnidadeAPI.listarUnidadePorCondominioId(condominioId);
                runInAction(() => {
                    if(data)
                    {
                        this.unidadesPorCondominoSelecionado = data;
                    }
                    else
                    {
                        this.unidadesPorCondominoSelecionado = [];
                    }
                });
            }
        } catch(e) {
            
        }
    }

    definirSelecionado(unidadeId: string) {
        let selecionado = this.unidadesPorCondomino.find((uni) => uni.id === unidadeId);
        if(selecionado) {
            this.selecionado = selecionado;
            localStorage.setItem('@unidadeId', selecionado.id);
        }
        else
        {
            this.selecionado = null;
        }
    }

}

export default UnidadeStore;