import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { StoreContext } from "../../stores";
import { BiEdit } from "react-icons/bi";
import NotFound from "../utils/NotFound";
import { Button, IconButton, Modal, Tooltip, Whisper } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import { MdOutlineDelete } from "react-icons/md";
import {
  ButtonDelete,
  ButtonEdit,
  Container,
  ContainerAction,
  CreatedBy,
  DescriptionText,
  Header,
  ImageAviso,
  ImageDefault,
  ListAviso,
  TextConfirmed,
  TextCreatedIn,
  TextDelete,
  TextEdit,
  TextExpired,
  TextExpiredVoid,
  TextPendent,
  TextRejected,
  TextRejectedReason,
} from "./styles/Aviso.style";
import Loading from "./Loading";
import CadastrarAviso from "./CadastrarAviso";
import AtualizarAviso from "./AtualizarAviso";
import useAviso from "../../hooks/Aviso.hook";
import imageDefault from "../../assets/img/noData.png";
import { ETipoAviso } from "../../enums/ETipoAviso";

const formatarDataHora = (dataHora) => {
  const data = new Date(dataHora);
  const dia = data.getDate().toString().padStart(2, "0");
  const mes = (data.getMonth() + 1).toString().padStart(2, "0");
  const ano = data.getFullYear();
  const hora = data.getHours().toString().padStart(2, "0");
  const minutos = data.getMinutes().toString().padStart(2, "0");
  return `${dia}/${mes}/${ano} às ${hora}:${minutos}`;
};

const AvisoComponent = () => {
  const { avisoStore, condominioStore, themeStore, authStore } =
    useContext(StoreContext);
  const { deletar } = useAviso();
  const [modalCadastrarAviso, setModalCadastrarAviso] = useState(false);
  const [modalAtualizarAviso, setModalAtualizarAviso] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);

  const toggleCadastrarAviso = () =>
    setModalCadastrarAviso(!modalCadastrarAviso);
  const toggleAtualizarAviso = () =>
    setModalAtualizarAviso(!modalAtualizarAviso);
  const toggleConfirmDelete = () => setModalConfirmDelete(!modalConfirmDelete);

  useEffect(() => {
    avisoStore.listar();
  }, [condominioStore.selecionado]);

  const deletarAviso = () => {
    if (avisoStore.selecionado?.id) {
      setModalConfirmDelete(true);
    }
  };

  const confirmarDelecao = () => {
    if (avisoStore.selecionado?.id) {
      deletar(avisoStore.selecionado?.id);
      toggleConfirmDelete();
    }
  };

  const renderStatus = (status: number) => {
    switch (status) {
      case 0:
        return "#FEC107";
      case 1:
        return "#005247";
      case 2:
        return "#A62727";
      default:
        return "";
    }
  };

  const renderAvisos = () => {
    if (avisoStore.loading) {
      return <Loading />;
    }

    if (avisoStore.avisos?.length === 0) {
      return <NotFound message="Nenhum aviso cadastrado" />;
    }

    const avisosOrdenados = avisoStore.avisos
      .slice()
      .sort(
        (a, b) =>
          new Date(b.criadoEm).getTime() - new Date(a.criadoEm).getTime()
      );

    return (
      <div>
        <Table
          hover
          variant={themeStore.theme === "dark" ? "dark" : "none"}
          size="md"
        >
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Imagem</th>
              <th style={{ textAlign: "center" }}>Descrição</th>
              <th style={{ textAlign: "center" }}>Criado Por:</th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th style={{ textAlign: "center" }}>Criado em:</th>
              <th style={{ textAlign: "center" }}>Expira em:</th>
              <th style={{ textAlign: "center" }}>Dias disponíveis</th>
              <th style={{ textAlign: "center" }}>Motivo de Rejeição</th>
              <th style={{ textAlign: "center" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {avisosOrdenados.map((aviso, i) => (
              <tr
                key={i}
                onClick={() => avisoStore.definirSelecionado(aviso.id)}
              >
                <td>
                  {aviso.imagem ? (
                    <ImageAviso src={aviso.imagem} alt="Aviso" />
                  ) : (
                    <ImageDefault src={imageDefault} alt="Nenhuma imagem" />
                  )}
                </td>
                <td>
                  <DescriptionText>
                    {aviso.descricao
                      ? aviso.descricao.length > 15
                        ? `${aviso.descricao.substring(0, 55)}...`
                        : aviso.descricao
                      : "-"}
                  </DescriptionText>
                </td>
                <td>
                  <CreatedBy>{aviso.registradoPor}</CreatedBy>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    color: renderStatus(aviso.status),
                  }}
                >
                  <TextPendent>{ETipoAviso[aviso.status]}</TextPendent>
                </td>
                <td style={{ textAlign: "center" }}>
                  <TextCreatedIn>
                    {formatarDataHora(new Date(aviso.criadoEm))}
                  </TextCreatedIn>
                </td>
                <td style={{ textAlign: "center" }}>
                  {aviso.status === 1 ? (
                    <TextExpired>
                      {formatarDataHora(new Date(aviso.expiraEm))}
                    </TextExpired>
                  ) : (
                    <TextExpiredVoid>-</TextExpiredVoid>
                  )}
                </td>
                <td>
                  <CreatedBy>{aviso.validade} dias</CreatedBy>
                </td>
                <td style={{ textAlign: "center" }}>
                  <TextRejectedReason>
                    {aviso.motivoRejeicao ?? (
                      <TextExpiredVoid>-</TextExpiredVoid>
                    )}
                  </TextRejectedReason>
                </td>
                <td style={{ textAlign: "center" }}>
                  <ContainerAction>
                    {aviso.status === 1 || aviso.status === 2 ? (
                      <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            Só é possível atualizar avisos com status pendentes
                          </Tooltip>
                        }
                      >
                        <ButtonEdit
                          onClick={() => {
                            if (
                              authStore.usuario?.nome === aviso.registradoPor
                            ) {
                              toggleAtualizarAviso();
                            } else {
                              alert(
                                "Atualização só pode ser feita por quem cadastrou"
                              );
                            }
                          }}
                          disabled={aviso.status === 1 || aviso.status === 2}
                        >
                          <BiEdit size={22} color="#240750" />
                        </ButtonEdit>
                      </Whisper>
                    ) : (
                      <ButtonEdit
                        onClick={() => {
                          if (authStore.usuario?.nome === aviso.registradoPor) {
                            toggleAtualizarAviso();
                          } else {
                            alert(
                              "Atualização só pode ser feita por quem cadastrou"
                            );
                          }
                        }}
                        disabled={aviso.status === 1 || aviso.status === 2}
                      >
                        <BiEdit size={22} color="#240750" />
                      </ButtonEdit>
                    )}

                    <ButtonDelete onClick={deletarAviso}>
                      <MdOutlineDelete size={22} color="#FF204E" />
                    </ButtonDelete>
                  </ContainerAction>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <Container>
      <Header>
        <div className="d-flex justify-content-between">
          <div>
            <p className="title">Campo de cadastros de avisos</p>
          </div>
          <div>
            <IconButton
              appearance="primary"
              color="violet"
              icon={<PlusIcon />}
              onClick={toggleCadastrarAviso}
            >
              Adicionar novo aviso
            </IconButton>
          </div>
        </div>
        <ListAviso>{renderAvisos()}</ListAviso>
      </Header>
      <CadastrarAviso show={modalCadastrarAviso} hide={toggleCadastrarAviso} />
      <AtualizarAviso show={modalAtualizarAviso} hide={toggleAtualizarAviso} />
      <Modal open={modalConfirmDelete} onClose={toggleConfirmDelete}>
        <Modal.Header>
          <Modal.Title>Deletar aviso</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja deletar este aviso?</Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={confirmarDelecao}>
            <p>Confirmar</p>
          </Button>
          <Button appearance="default" onClick={toggleConfirmDelete}>
            <p>Cancelar</p>
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default observer(AvisoComponent);
