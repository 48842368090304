import api from "../services/api/api";
import apiDispositivo from "../services/dispositivo/api";
import { BiometriaDigital, BiometriaFacial, BiometriaRetornoFacial, BiometriaStatus, Controle, ControleRelatorio, EnviarBiometriaDigital, EnviarFoto, RelatorioBiometria, SincronizarCartaoOrigemDestino, SincronizarFacial, SincronizarFacialCondominio, ITag } from "../interfaces/Dispositivo.interface";
import { Sincronizar } from "../interfaces/Veiculo.interface";
import { IntelbrasConfigEvento } from "../interfaces/Equipamento.interface";


class DispositivosAPI {
  
  sincronizarControleGuarita = async (veiculo: Sincronizar) => await apiDispositivo.post(`/controle/sincronizarveiculo`, veiculo);
  sincronizarFacialMoradores = async (facial: SincronizarFacial) => await apiDispositivo.post(`/biometriafacial/sincronizar/morador`, facial);
  sincronizarFacialCondominio = async (facial: SincronizarFacialCondominio) => await apiDispositivo.post(`/biometriafacial/sincronizar/condominio`, facial);
  sincronizarCartaoDoGuaritaParaControlIdCondominio = async (controlIdDestino: string) => await api.post(`/Controlid/sincronizarCartoesPorGuarita/${controlIdDestino}`);
  sincronizarCartaoOrigemDestino = async (controlId: SincronizarCartaoOrigemDestino) => await api.post(`/Controlid/sincronizarNovoControlId/${controlId.controlIdOrigem}/${controlId.controlIdDestino}`);
  sincronizarGuaritaHikvision = async (guaritaId: string, hikvisionId: string) => await api.post(`/DispositivosSincronizar/importarCartaoGuaritaHikvision/${guaritaId}/${hikvisionId}`);
  reiniciarControlId = async (controlId: string) => await api.get(`/Controlid/reiniciar`, {
    params:
    {
      controlIDId: controlId
    }
  });
  reiniciarIntelbras= async (intelbrasId: string) => await api.get(`/Intelbras/reiniciar`, {
    params:
    {
      equipamentoId: intelbrasId
    }
  });

  cadastrarTag = async (tag: ITag) => await apiDispositivo.post(`/tag`, tag);
  cadastrarTagPassiva = async (tag: Controle) => await apiDispositivo.post(`/tagpassiva`, tag);
  enviarFoto = async (enviarFoto: EnviarFoto) => await api.post<RelatorioBiometria>(`/Intelbras/enviarFoto?equipamentoId=${enviarFoto.equipamentoId}&moradorId=${enviarFoto.moradorId}`, enviarFoto.imageBase64Byte);


  listarTagPorMorador = async (moradorId: string) => await apiDispositivo.get(`/tag/morador/${moradorId}`);
  listarTagPassivaPorVeiculoId = async (unidadeId: string) => await apiDispositivo.get<Controle>(`/tagpassiva/veiculo/${unidadeId}`);
  listarTagPorUnidade = async (unidadeId: string) => await apiDispositivo.get(`/tag/unidade/${unidadeId}`);


  listarEquipamentoPorCondominioId = async (condominioId: string) => await apiDispositivo.get(`/equipamento/${condominioId}`);


  coletarEenviarBiometriaDigital = async (biometria: BiometriaDigital) => await apiDispositivo.post<RelatorioBiometria>(`/biometriadigital`, biometria);
  coletarEenviarBiometriaFacial = async (biometria: BiometriaFacial) => await apiDispositivo.post<RelatorioBiometria>(`/biometriafacial`, biometria);
  listarBiometriaDigitalPorMoradorId = async (moradorId: string) => await apiDispositivo.get<BiometriaStatus[]>(`/biometriadigital/${moradorId}`);
  listarBiometriaFacialPorMoradorId = async (moradorId: string) => await apiDispositivo.get<BiometriaRetornoFacial>(`/biometriafacial/${moradorId}`);


  cadastrarControle = async (controle: Controle) => await apiDispositivo.post<RelatorioBiometria>(`/controle`, controle); 
  listarContorlePorVeiculo = async (veiculoId: string) => await apiDispositivo.get<ControleRelatorio[]>(`/controle/controles/veiculo/${veiculoId}`);
  listarControlesPorUnidadeId = async (unidadeId: string) => await apiDispositivo.get<ControleRelatorio[]>(`/controle/unidade/${unidadeId}`);

  deletarBiometriaDigital = async ( id: string, moradorId: string) => await apiDispositivo.delete<BiometriaStatus[]>(`/biometriaDigital/${moradorId}`);
  deletarBiometriaFacial = async (moradorId: string) => await apiDispositivo.delete<BiometriaStatus[]>(`/biometriaFacial/${moradorId}`);

  deletarTag = async (id: string ) => await apiDispositivo.delete<any>(`/tag/${id}`);
  deletarTagpAssiva = async (veiculoId: string, serial: string) => await apiDispositivo.delete<RelatorioBiometria>(`/tagpassiva/${veiculoId}/${serial}`);
  deletarControle = async (veiculoId: string, serial: string) => await apiDispositivo.delete<RelatorioBiometria>(`/controle/serial`, { params: { serial } });

  sincronizarTagMorador = async (moradorId: string) => await apiDispositivo.post(`/tag/sincronizar/morador`, {
    moradorId: moradorId
  });

  configurarServidorEventosIntelbras = async (config: IntelbrasConfigEvento) => await api.post(`/Intelbras/configurarServidorDeEventos`, config);

  sincronizarDataHoraIntelbras = async (equipamentoId: string) => await api.get(`/Intelbras/configurarDataHoraLocal`, {
    params: {
      equipamentoId
    }
  });
  
  sincronizarBiometriaDigitalMorador = async (template: EnviarBiometriaDigital) => await apiDispositivo.post(`/biometriadigital/sincronizar/morador`, template);

  sincronizarTagsCondominio = async (condominioId: string) => await apiDispositivo.post(`/tag/sincronizar/condominio`, {
    condominioId: condominioId
  }); 

  sincronizarBiometriasCondominio = async (condominioId: string) => await apiDispositivo.post(`/biometriadigital/sincronizar/condominio`, {
    condominioId: condominioId
  }); 

  sincronizarControlesCondominio = async (condominioId: string) => await apiDispositivo.post(`/controle/sincronizar/${condominioId}`); 
  
}
  
export default new DispositivosAPI();