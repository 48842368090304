import { useContext, useState } from "react";
import { toast } from "react-toastify";
import LayoutAPI from "../api/LayoutAPI";
import { StoreContext } from "../stores";
import { Layout } from "../interfaces/Layout.interface";


export const useLayout = () => {

    const { layoutStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [success, setSuccess] = useState(false);
    
    const cadastrar = async (layout: Layout, hide: () => void, clear: () => void) => {
        setSalvando(true);

        if(!layout.usuarioDGuard) {
            layout.usuarioDGuard = ' ';
        }

        try {
            const { data, status } = await LayoutAPI.cadastrarLayout(layout);
            if(status === 200) {
                layoutStore.layouts.push(data);
                setSalvando(false);
                hide();
                setSuccess(true);
                clear();
                toast.success('Layout cadastrado com sucesso!');
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar layout');
        }
    }

    const atualizar = async (layout: Layout, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await LayoutAPI.atualizarLayout(layout);
            if(status === 200) {
                layoutStore.layouts = layoutStore.layouts.map(lay => lay.id === layout.id ? layout : lay);
                setAtualizando(false);
                hide();
                setSuccess(true);
                toast.success('Layout atualizado com sucesso!');
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar layout');
        }
    }

    const deletar = async (layout: Layout) => {
        setDeletando(true);
        try {
            const { status } = await LayoutAPI.deletarLayout(layout.id);
            if(status === 200) {
                layoutStore.layouts = layoutStore.layouts.filter(lay => lay.id !== layout.id);
                setDeletando(false);
                toast.success('Layout deletado com sucesso!')
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar layout')
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
        success,
    }

}