import { makeAutoObservable, runInAction } from 'mobx';
import { IReportResponse, IReports } from '../interfaces/IReports';
import ReportAPI from '../api/ReportAPI';
import moment from 'moment';

class ReportStore {
  loading: boolean = false;
  reports: IReportResponse[] = [];
  selected: IReportResponse | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  clear() {
    runInAction(() => {
      this.reports = [];
      this.selected = null;
    });
  }

  async getReports(request: IReports) {
    this.loading = true;
    try {
      const { data } = await ReportAPI.getReport(request);
      runInAction(() => {
        if (data)
        {
          data.forEach((x) =>
          {
            x.usuarioMorador = `${x.usuarioNome ?? ''} ${x.moradorNome ?? ''}`;
            x.dataHoraEvento = moment(x.dataHoraEvento).add(3, 'hours').format('DD/MM/YYYY HH:mm:ss');
            x.unidadeIdentificacao = `${x.unidade ?? ''} - ${x.bloco ?? ''}`
          })
          this.reports = data.slice().reverse();
        }
        else
          this.reports = [];
      });
        this.loading = false;
      } catch (e) {
      this.loading = false;
    }
  }
}

export default ReportStore;
