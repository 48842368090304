import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import react, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { StoreContext } from '../../stores';
import { useBloco } from '../../hooks/Bloco.hook';
import { useVeiculo } from '../../hooks/Veiculo.hook';
import { Bloco, BlocoRequest } from '../../interfaces/Bloco.interface';
import { Veiculo, VeiculoRequest } from '../../interfaces/Veiculo.interface';
import { Button, Input, Modal, SelectPicker } from 'rsuite';

interface ModalProps {
    show: boolean;
    hide: () => void;
    unidadeId?: string;
}

const CadastrarVeiculo: FC<ModalProps> = ({ show, hide, unidadeId }) => {

    const { veiculoStore, condominioStore, acionamentoPerfilStore, unidadeStore } = useContext(StoreContext);
    const { cadastrar, salvando } = useVeiculo();
    const [form, setForm] = useState<VeiculoRequest>({});

    useEffect(() => {
        if (unidadeStore.selecionado) {
            setForm({
                ...form,
                unidadeId: unidadeStore.selecionado.id,
                tipoPortaria: 0,
                acessoPermitido: 'S'
            });

        }
        veiculoStore.listarCorMarca();
        acionamentoPerfilStore.listar();
    }, [unidadeStore.selecionado]);

    // useEffect(() => {
    //     if(condominioStore.selecionado && show)
    //     {
    //         acionamentoPerfilStore.listar();
    //     }
    // }, [condominioStore.selecionado, show]);

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        cadastrar(form as Veiculo, hide);
    }

    const FormVeiculo = () => {
        return (
            <Form onSubmit={(e) => salvar(e)}>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><span className='text-danger'>*</span>Perfíl do veículo</Form.Label>
                    <br />
                    <SelectPicker
                        block
                        placeholder={'Selecione o perfil'}
                        data={acionamentoPerfilStore.perfis.map((p) => ({ label: p.nome, value: p.id }))}
                        value={form.acionamentoPerfilId || ''}
                        onChange={(e) => { if (e) setForm({ ...form, acionamentoPerfilId: e }) }}
                    />
                    {/* <select
                        value={form.acionamentoPerfilId || ''}
                        onChange={(e) => setForm({ ...form, acionamentoPerfilId: e.target.value })}
                        className='form-control'
                        required
                    >
                        <option value={''}>Selecione o perfíl do veículo</option>
                        {
                            acionamentoPerfilStore.perfis.map((perfil, i) => (
                                <option key={i} value={perfil.id}>{perfil.nome}</option>
                            ))
                        }
                    </select> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><span className='text-danger'>*</span>Marca do veículo</Form.Label>
                    <br />
                    <SelectPicker
                        block
                        placeholder={'Selecione a marca'}
                        data={veiculoStore.marcas.map((v) => ({ label: v.descricao, value: v.id }))}
                        value={form.marcaId || ''}
                        onChange={(e) => { if (e) setForm({ ...form, marcaId: e }) }}
                    />
                    {/* <select
                        value={form.marcaId || ''}
                        onChange={(e) => setForm({ ...form, marcaId: e.target.value })}
                        className='form-control'
                        required
                    >
                        <option value={''}>Selecione a marca do veículo</option>
                        {
                            veiculoStore.marcas.map((marca, i) => (
                                <option key={i} value={marca.id}>{marca.descricao}</option>
                            ))
                        }
                    </select> */}
                </Form.Group>

                <div className='d-flex justify-content-between'>
                    <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Modelo doveículo" onChange={(e) => setForm({ ...form, modelo: e })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-5" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Placa</Form.Label>
                        <Input required type="text" maxLength={7} className='shadow-none' placeholder="ABC0000" onChange={(e) => setForm({ ...form, placa: e })} />
                    </Form.Group>
                </div>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><span className='text-danger'>*</span>Cor de veículo</Form.Label>
                    <SelectPicker
                        block
                        placeholder={'Selecione a cor'}
                        data={veiculoStore.cores.map((c) => ({ label: c.descricao, value: c.id }))}
                        value={form.corId || ''}
                        onChange={(e) => { if (e) setForm({ ...form, corId: e }) }}
                    />
                    {/* <select
                        value={form.corId || ''}
                        onChange={(e) => setForm({ ...form, corId: e.target.value })}
                        className='form-control'
                        required
                    >
                        <option value={''}>Selecione a marca do veículo</option>
                        {
                            veiculoStore.cores.map((cor, i) => (
                                <option key={i} value={cor.id}>{cor.descricao}</option>
                            ))
                        }
                    </select> */}
                </Form.Group>

                <div className='d-flex justify-content-end'>
                    <Button appearance='primary' color={'blue'} disabled={salvando} type="submit">
                        {salvando ? 'Salvando...' : 'Salvar'}
                    </Button>

                    <Button disabled={salvando} type="submit" style={{ marginLeft: 10 }} onClick={hide}>
                        Fechar
                    </Button>
                </div>
                {salvando ? <LinearProgress color="primary" style={{ height: 2, marginTop: 15 }} /> : ''}
            </Form>
        )
    }

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastro de veículo</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormVeiculo()}</Modal.Body>
        </Modal>
    )
}

export default CadastrarVeiculo;