import api from "../services/api/api";
import { Unidade } from "../interfaces/Unidade.interface";
import { MoverMoradores } from "../interfaces/Morador.interface";

class UnidadeAPI {

    listarUnidadePorCondominioId = async (condominioId: string) => await api.get<Unidade[]>(`/Unidade/condominio/${condominioId}`); 
    listarUnidadePorBlocoId = async (blocoId: string) => await api.get<Unidade[]>(`/Unidade/blocoId?blocoId=${blocoId}`); 
    cadastrar = async (unidade: Unidade) => await api.post<Unidade>(`/Unidade`, unidade); 
    cadastrarMultiplos = async (unidade: Unidade[]) => await api.post<Unidade[]>(`/Unidade/multiplos`, unidade); 
    atualizar = async (unidade: Unidade) => await api.put<Unidade>(`/Unidade`, unidade); 
    moverMoradorParaOutraUnidade = async (mover: MoverMoradores) => await api.put<Unidade>(`/Unidade/morador/trocarunidade`, mover); 
    deletar = async (unidadeId: string) => await api.delete<Unidade>(`/Unidade/${unidadeId}`); 

}

export default new UnidadeAPI();