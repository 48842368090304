import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { BsPhone } from 'react-icons/bs';
import { FaWpforms } from 'react-icons/fa'
import MoradorAPI from '../../api/MoradorAPI';
import { StoreContext } from '../../stores';
import { useBloco } from '../../hooks/Bloco.hook';
import { useMorador } from '../../hooks/Morador.hook';
import { Bloco, BlocoRequest } from '../../interfaces/Bloco.interface';
import { AssociarMorador, AssociarMoradorRequest, Morador, MoradorRequest } from '../../interfaces/Morador.interface';
import { cpfMask, maskCelular, maskTelefoneFixo } from '../utils/mask';
import { Container } from './styles/AssociarMoradorCard.style';
import { SelectPicker, Modal, Button, Loader } from 'rsuite';
import { BiParagraph } from 'react-icons/bi';
import { IPerfil } from '../../interfaces/AcionamentoPerfil.interface';

interface ModalProps {
    show: boolean;
    hide: () => void;
    moradorSelecionado?: Morador | null;
}

interface ITopoMorador {
    descricao: string
    id: string 
  }


const AssociarMoradorCard: FC<ModalProps> = ({ show, hide }) => {

    const { condominioStore, acionamentoPerfilStore, unidadeStore, moradorStore, cameraStore  } = useContext(StoreContext);
    const { associarUsuarioNovoCondominio, salvando } = useMorador();

    const [condominioSelecionado, setDefinirCondominio] = useState('');
    const [tipo, setTipo] = useState<ITopoMorador[]>([]);
    const [novoMorador, setNovoMorador] = useState<AssociarMoradorRequest>({});

    const condomninios = condominioStore.condominios.map(condominio => ({ label: condominio.nome, value: condominio.id }));
    const unidades = unidadeStore.unidadesPorCondominoSelecionado.map(unidade => ({ label: `${unidade.apartamento.toString()} - ${unidade.blocoNome}`, value: unidade.id }));
    const perfisAcionamentos = acionamentoPerfilStore.perfisCondominio.map(perfil => ({ label: perfil.nome, value: perfil.id }));
    const perfisCameras = cameraStore.camerasPerfisIdentificacao.map(perfil => ({ label: perfil.nome, value: perfil.id }));
    const tipoMorador = tipo.map(t => ({ label: t.descricao, value: t.id }));

    useEffect(() =>
    {
        if(tipo.length === 0 && show)
        {
            MoradorAPI
            .tipoMorador()
            .then((res) => setTipo(res.data));
        }
    }, [show])

    useEffect(() =>
    {
        if(condominioSelecionado !== null)
        {
            acionamentoPerfilStore.listarPorCondominio(condominioSelecionado);
            unidadeStore.listarPorCondominioSelecionado(condominioSelecionado);
            cameraStore.listarCamerasPerfisIdentificacaoPorCondominioId(condominioSelecionado);
        }   
    }, [condominioSelecionado]);
    
    const salvar = () => {
        if(moradorStore.selecionado) 
        {
            novoMorador.usuarioId = moradorStore.selecionado.usuarioId;
            associarUsuarioNovoCondominio(novoMorador as AssociarMorador, hide);
        } else
        {
            alert('morador sem usuário cadastrado');
        }
    }

    return(
        <Modal backdrop={'static'} keyboard={false} open={show} onClose={hide}>
        <Modal.Header>
          <Modal.Title>Cadastrar novo morador em um novo condomínio</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Container>
                <div>
                    <p>Selecione o condomínio que você deseja cadastrar</p>
                    <SelectPicker placeholder={'Selecione'} className='shadow-none mt-2' data={condomninios} block onChange={(e) => {if(e) setDefinirCondominio(e as any)}} />
                </div>

                {
                    condominioSelecionado
                    ?
                    <>
                    <div>
                        <p className='title'>Selecione a o perfíl de acionamentos</p>
                        <SelectPicker placeholder={'Selecione'} className='shadow-none mt-2' data={perfisAcionamentos} block onChange={(e) => {if(e) setNovoMorador({ ...novoMorador, acionamentoPerfilId: e })}} />
                    </div>
                    <div>
                        <p className='title'>Selecione o perfil de câmeras</p>
                        <SelectPicker placeholder={'Selecione'} className='shadow-none mt-2' data={perfisCameras} block onChange={(e) => {if(e) setNovoMorador({ ...novoMorador, cameraPerfilIdentificacaoId: e })}} />
                    </div>
                    <div>
                        <p className='title'>Selecione a unidade desse novo morador</p>
                        <SelectPicker placeholder={'Selecione'} className='shadow-none mt-2' data={unidades} block onChange={(e) => {if(e) setNovoMorador({ ...novoMorador, unidadeId: e })}} />
                    </div>
                    <div>
                        <p className='title'>Selecione o tipo</p>
                        <SelectPicker placeholder={'Selecione'} className='shadow-none mt-2' data={tipoMorador} block onChange={(e) => {if(e) setNovoMorador({ ...novoMorador, tipoId: e })}} />
                    </div>
                    </>
                    :
                    'Selecione primeiro o condomínio'
                }
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button
                appearance='primary'
                color={'violet'}
                disabled={novoMorador.usuarioId === null || novoMorador.acionamentoPerfilId === null || novoMorador.unidadeId === null || novoMorador.tipoId === null || salvando ? true : false}
                onClick={() => salvar()}
            >
                { salvando ? <Loader content="Salvando..." />: 'Salvar' }
            </Button>
            <Button onClick={hide}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default observer(AssociarMoradorCard);