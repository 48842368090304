import React, { useContext, useEffect, useState } from 'react';
import { BiDotsHorizontalRounded, BiEdit, BiX } from 'react-icons/bi';
import { StoreContext } from '../../stores';
import AtualizarAcionamento from './AtualizarAcionamento';
import CadastrarAcionamento from './CadastrarAcionamento';
import useAcionamento from '../../hooks/Acionamento.hook';
import { Container, Header, ListAcionamentos } from './styles/Acionamento.style';
import {arrayMoveImmutable} from 'array-move';
import { Acionamento } from '../../interfaces/Acionamento.interface';
import { BsArrowsExpand, BsDoorOpen, BsPower } from 'react-icons/bs';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { observer } from 'mobx-react-lite';
import { Backdrop, CircularProgress, Drawer, Menu } from '@mui/material';
import { MdQrCodeScanner } from 'react-icons/md';
import {QRCodeSVG} from 'qrcode.react';
import logo from '../../assets/img/logo.png';
import { Button, Dropdown, FlexboxGrid, Input, List, Loader, Modal, IconButton } from 'rsuite';
import { FaQrcode, FaUser } from 'react-icons/fa';
import RenderComandos from '../utils/RenderComandos';
import ComandoListagem from '../comandos/ComandoListagem';
import RenderTipoAcionamento from '../utils/RenderTipoAcionamento';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import AtualizarOrdem from './AtualizarOrdem';
import DragableIcon from '@rsuite/icons/Dragable';
import SearchIcon from '@rsuite/icons/Search';
import PlusIcon from '@rsuite/icons/Plus';
import SingleSourceIcon from '@rsuite/icons/SingleSource';
import QrcodeIcon from '@rsuite/icons/Qrcode';
import SkeletonList from '../utils/SkeletonList';
import { Table } from 'react-bootstrap';

interface Props {
    value: Acionamento;
    position: number;
    atualizar: () => void;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    toggleQrCode: () => void;
  }

interface ListProps {
    items: Acionamento[];
    onClick?: React.MouseEventHandler<HTMLInputElement> | undefined;
    onChange?: React.FormEventHandler<HTMLUListElement> | undefined
    alterOrdem: boolean;
    setAlterOrdem: React.Dispatch<React.SetStateAction<boolean>>
    atualizar: () => void;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    toggleQrCode: () => void;
  }

const SortableItem = SortableElement(({value, position, handleClick, toggleQrCode} : Props) => 
{
  const { deletar, deletando } = useAcionamento();
  const {
    acionamentoStore
  } = useContext(StoreContext);

  if(deletando) {
    return(
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return(
    <tbody>
        <tr onClick={() => acionamentoStore.definirSelecionado(value.id)}>
            <td>{value.nome}</td>
            <td>{value.ordem}</td>
            <td>{value.tipo}</td>
            <td>
              <IconButton style={{width: 35, height: 35}} onClick={toggleQrCode}>
                <MdQrCodeScanner className='icon'/>
              </IconButton>
            </td>
            <td>
              <IconButton style={{width: 35, height: 35}} onClick={handleClick}>
                <BiDotsHorizontalRounded className='icon'/>
              </IconButton>
            </td>
        </tr>
    </tbody>
  )
}
);

const SortableList = SortableContainer(({ items, alterOrdem, atualizar, setAlterOrdem, handleClick, toggleQrCode }: ListProps) => {
  
  const { acionamentoStore } = useContext(StoreContext);
  const { atualizarOrdemAcionamento } = useAcionamento();

  const atualizarOrdem = () => {
    acionamentoStore.acionamentos.map((_, i) => _.ordem = i);
    atualizarOrdemAcionamento(acionamentoStore.acionamentos);
    setAlterOrdem(!alterOrdem);
  }

    return (
      <ul className='m-0 p-0'>
        {/* <List sortable onSort={handleSortEnd}>
          {items.map((acionamento, index) => (
            <List.Item key={index} index={index}>
              {acionamento.nome}
            </List.Item>
          ))}
        </List> */}
        {/* <Table borderless>
          <thead>
              <tr>
              <th scope="col">Nome</th>
              <th scope="col">Ordem</th>
              <th scope="col">Tipo</th>
              <th scope="col">Gerar QR-CODE</th>
              <th scope="col">Ação</th>
              </tr>
          </thead>
              {items.map((morador, index) => {
            return(
              <SortableItem disabled={!alterOrdem} key={`${index}`} index={index} atualizar={atualizar} value={morador} position={index} handleClick={handleClick} toggleQrCode={toggleQrCode} />
            )
          })}
        </Table>
        <div className='d-flex justify-content-end'>
          <div>
            <button className={`btn ${!alterOrdem ? 'btn-outline-primary' : 'btn-primary'} shadow-none`} onClick={() => setAlterOrdem(!alterOrdem)}>
              {
                  alterOrdem
                  ?
                  <span>Atualizar ordem: ATIVADO <BsArrowsExpand/></span>
                  :
                  <span>Atualizar ordem <BsArrowsExpand/></span>
              }
            </button>

            <button className='btn btn-success shadow-none m-1' disabled={!alterOrdem} onClick={() => atualizarOrdem()}>
              Salvar
            </button>
          </div>
        </div> */}
       </ul>
    );
  });

const AcionamentoListagem = () => {

    const { acionamentoStore, condominioStore, themeStore } = useContext(StoreContext);
    const { deletar, deletando, atualizarOrdemAcionamento } = useAcionamento();
    const [modalCadastrarAcionamento, setModalCadastrarAcionamento] = useState(false);
    const [modalEditarAcionamento, setModalEditarAcionamento] = useState(false);
    const [alterOrdem, setAlterOrdem] = useState(false);
    const [openComando, setOpenComando] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openQrCode, setOpenQrCode] = useState(false);
    const [modalDeletar, setModalDeletar] = useState(false);
    const [busca, setBusca] = useState('');

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const toggleAlterOrdem = () => setAlterOrdem(!alterOrdem);
    const toggleCadastrarAcionamento = () => setModalCadastrarAcionamento(!modalCadastrarAcionamento);
    const toggleEditarAcionamento = () => setModalEditarAcionamento(!modalEditarAcionamento);
    const toggleComando = () => setOpenComando(!openComando);
    const toggleOpenQrCode = () => setOpenQrCode(!openQrCode);
    const toggleModalDeletar = () => setModalDeletar(!modalDeletar);

    const deletarAcionamento = () =>
    {
      if(acionamentoStore.selecionado) {
        deletar(acionamentoStore.selecionado);
      }
    }

    useEffect(() =>
    {
      acionamentoStore.listar();
    }, []);

    const filtrar = acionamentoStore.acionamentos.filter((acionamento) =>
      acionamento.nome?.toLocaleLowerCase().includes(busca.toLocaleLowerCase()) ||
      !busca
    );

    const renderListagem = () => 
    {

      if(deletando) {
          return <SkeletonList/>
      }

      if(acionamentoStore.loading) {
        return <SkeletonList/>
    }

      return(
          <div>
              <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                  <thead>
                      <tr>
                          <th>Nome</th>
                          <th>Visivel</th>
                          <th>Comandos</th>
                          <th>QR CODE</th>
                          <th>Ação</th>
                      </tr>
                  </thead>
                  <tbody>
                      {
                          filtrar.map((acionamento, i) =>
                          (
                              <tr key={i} onClick={() => acionamentoStore.definirSelecionado(acionamento.id)}>
                                  <td>{acionamento.nome}</td>
                                  <td>{RenderTipoAcionamento(acionamento.tipo)}</td>
                                  <td>
                                    <IconButton size={'xs'} appearance={'primary'} color={'violet'} icon={<SingleSourceIcon />} onClick={toggleComando}>
                                      Comandos
                                    </IconButton>
                                  </td>
                                  <td>
                                    <IconButton size={'xs'} appearance={'primary'} color={'violet'} icon={<QrcodeIcon />} onClick={toggleOpenQrCode}>
                                      QR CODE
                                    </IconButton>
                                  </td>
                                  <td>
                                      <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                          <Dropdown.Item onClick={toggleEditarAcionamento}>Atualizar</Dropdown.Item>
                                          <Dropdown.Item onClick={toggleModalDeletar}>Deletar</Dropdown.Item>
                                      </Dropdown>
                                  </td>
                              </tr>
                          ))
                      }
                  </tbody>
              </Table>
          </div>
      )
  }
    return(
        <Container>


          <Header>
            <div className='d-flex justify-content-between'>
                      <div>
                          <p className='title'>Campo de cadastros de acionamentos</p>
                          <p className='subtitle'>Cadastre primeiro os acionamentos para adicionar os comandos</p>
                      </div>
                      <div>
                          <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarAcionamento}>
                              Adicionar novo acionamento
                          </IconButton>
                      </div>
                  </div>

                  <div className='d-flex box'>
                      <div className='content d-flex'>
                          <BsDoorOpen className='content-icon' />
                          <div>
                              <p className='content-title'>Acionamentos cadastrados </p>
                              <p className='content-subtitle'>{acionamentoStore.acionamentos.length} cadastrado</p>
                          </div>
                      </div>

                      <div style={{marginLeft: 30}} className='align-self-center'>
                          <p className='info-title'>Atualizar ordem dos acionamentos</p>
                          <IconButton appearance="primary" color="violet" icon={<DragableIcon />} onClick={toggleAlterOrdem}>
                              Atualizar
                          </IconButton>
                      </div>
                  </div>
            </Header>

            <ListAcionamentos>
              {renderListagem()}
            </ListAcionamentos>
          {/* <Header className='d-flex justify-content-between'>
              <div className='d-flex'>
                <div>
                  <p className='title'>Lista dos acionamentos cadastrado no condomínio</p>
                  <p className='subtitle'>Cadastre primeiro os acionamentos para adicionar os comandos a eles</p>
                </div>
                <div className='align-self-center' style={{ marginLeft: 20 }}>
                  <Input placeholder='Busca' onChange={(e) => setBusca(e)} />
                </div>
                <div className='align-self-center' style={{ marginLeft: 20 }}>
                  <Button onClick={toggleAlterOrdem}>Atualizar ordem dos acionamentos</Button> 
                </div>
              </div>
              <Button onClick={() => toggleCadastrarAcionamento()}>Cadastrar novo acionamento</Button>
          </Header>

          <ListAcionamentos>
            <Table borderless>
                <thead>
                    <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Visível</th>
                        <th scope="col">Ordem</th>
                        <th scope="col">Comando</th>
                        <th scope="col">QR - Code</th>
                        <th scope="col">Ação</th>
                    </tr>
                </thead>
                {renderListagem()}
            </Table>
          </ListAcionamentos> */}

          <CadastrarAcionamento show={modalCadastrarAcionamento} hide={toggleCadastrarAcionamento} />
          <AtualizarAcionamento show={modalEditarAcionamento} hide={toggleEditarAcionamento} acionamentoSelecionado={acionamentoStore.selecionado} />
          <ComandoListagem show={openComando} hide={toggleComando}/>
          <AtualizarOrdem show={alterOrdem} hide={toggleAlterOrdem} />


          <Modal open={openQrCode} onClose={toggleOpenQrCode}>
            <Modal.Header closeButton />
            <div className={'d-flex justify-content-center'}>
              <p style={{margin: 10, fontWeight: 550, fontSize: 25}}>{condominioStore.selecionado?.nome}</p>
            </div>
            <div onClick={() => window.print()} className={'d-flex justify-content-center'}>
              <QRCodeSVG style={{height: 300, width: 300}} value={acionamentoStore.selecionado ? acionamentoStore.selecionado.id : ''} />
            </div>

            {/* <div className={'d-flex justify-content-center mt-4'}>
                <img src={logo} height={40} />
              </div> */}
              
            <div className={'d-flex justify-content-center'}>
              <p style={{margin: 10, fontWeight: 550, fontSize: 20}}>{acionamentoStore.selecionado?.nome}</p>
            </div>
            <div className={'d-flex justify-content-center'}>
            </div>
          </Modal>

          <ModalConfirmacao show={modalDeletar} hide={toggleModalDeletar} action={deletarAcionamento} content={'Você em certeza que deseja deletar esse acionamento?'} loading={deletando} />

        </Container>
    )
}

export default observer(AcionamentoListagem);