import styled from 'styled-components';
import PerfectScroll from 'react-perfect-scrollbar';

export const Container = styled.div`
    padding: 30px;
    .header
    {
        margin-top: -10px;
    }
    .title
    {
        margin: 0;
        font-size: 19pt;
        width: 30%;
        font-weight: 550;
    }
    .subtitle
    {
        margin: 0;
        font-size: 9pt;
        // margin-bottom: -2%;
    }

    .content-1
    {
        padding: 20px;
        background: ${(props) => props.theme.colors.bgBox};
        border-radius: 12px;
        margin-top: 20px;
        width: 100%;
    }
    .title-chart
    {
        width: 50%;
        font-weight: 550;
    }
`

export const Content = styled.div`
    .icon-chart
    {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        margin-right: 10px;
    }
    .title-chart
    {
        width: 100%;
        font-size: 12pt;
    }
`

export const BarLeft = styled.div`
    // width: 80%;
    // height: 100vh;
    // padding: 10px;
    margin-left: 20px;
    margin-top: 10px;
    .box
    {
        margin: 10px;
        padding: 20px;
        background: ${(props) => props.theme.colors.bgBox};
        width: 30%;
        height: 130px;
        border-radius: 8px;
    }
    .title
    {
        font-size: 11pt;
        font-weight: 550;
        margin: 0;
        // align-self: center;
    }
    .content
    {
        padding-top: 10px;
        margin: 0;
        align-self: center;
    }
    .icon
    {
        margin: 0;
        font-size: 15pt;
        align-self: center;
    }
    .qtd
    {
        margin: 0;
        font-size: 13pt;
        margin-left: 10px;
    }
    .subtitle
    {
        font-size: 9pt;
        margin: 0;
    }
    .header-e
    {
        padding: 10px;
        background: ${(props) => props.theme.colors.bgBox};
        border-radius: 8px;
    }
    .header-e .subtitle
    {
        font-size: 9pt;
        margin: 0;
    }
    .title-e
    {
        font-size: 11pt;
        font-weight: 500;
        margin: 0;
    }
    .content-eq
    {
        padding: 5px;
        padding-left: 20px;
        background: ${(props) => props.theme.colors.primary};
        border-radius: 8px;
        width: 10vw;
        color: #fff;
    }

    .content-eq-dvr
    {
        padding: 10px;
        // padding-left: 20px;
        background: ${(props) => props.theme.colors.primary};
        border-radius: 8px;
        // width: 10vw;
        color: #fff;
    }

    .icon-eq
    {
        font-size: 15pt;
        align-self: center;
        margin: 0;
    }
    .info-eq
    {
        font-size: 9pt;
        align-self: center;
        margin: 0;
        margin-left: 5px;
    }
`