import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Skeleton, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { BiServer } from 'react-icons/bi';
import { BsArrowRightShort, BsHddNetwork } from 'react-icons/bs';
import { Button, Dropdown, IconButton } from 'rsuite';
import { StoreContext } from '../../stores';
import ModalAtualizar from './ModalAtualizar';
import ModalCadastrar from './ModalCadastrar';
import { Container, Header } from './styles/Rede.style';
import { Table } from 'react-bootstrap';
import PlusIcon from '@rsuite/icons/Plus';
import SkeletonList from '../utils/SkeletonList';
import { useServidorDGuard } from '../../hooks/ServidorDGuard.hook';
import ModalConfirmacao from '../utils/ModalConfirmacao';

const ServidoresListagem = () => {

    const token = process.env.REACT_APP_BASE_TOKEN_SERVIDOR;
    const { layoutStore, themeStore } = useContext(StoreContext);
    const { deletar, deletando } = useServidorDGuard();
    const [cadastrar, setCadastrar] = useState(false);
    const [atualizar, setAtualizar] = useState(false);
    const [status, setStatus] = useState('');
    const [excluir, setExcluir] = useState(false);

    const toggleCadastrar = () => setCadastrar(!cadastrar);
    const toggleAtualizar = () => setAtualizar(!atualizar);
    const toggleExcluir = () => setExcluir(!excluir);

    const deletarComModal = () => {
        if (layoutStore.servidorSelecionado)
            deletar(layoutStore.servidorSelecionado, toggleExcluir);
    }

    useEffect(() => {
        layoutStore.listarServidoresDGuard();
    }, []);

    const renderList = () => {

        if (layoutStore.loading) {
            return (
                <div style={{ width: '97%' }}>
                    <SkeletonList />
                </div>
            )
        }

        return (
            <div className='p-1'>
                <Table striped={themeStore.theme === 'dark' ? false : true}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>Ip</th>
                            <th>Porta</th>
                            <th>Porta - CGI</th>
                            <th>Usuario DGuard</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            layoutStore.servidoresDGuard.map((s) =>
                            (
                                <tr className='' onClick={() => {
                                    layoutStore.definirServidorSelecionado(s.id)

                                }}>
                                    <td className='p-2'>{s.id.split('-')[4]}</td>
                                    <td className='p-2'>{s.nome}</td>
                                    <td className='p-2'>{s.ip}</td>
                                    <td className='p-2'>{s.porta}</td>
                                    <td className='p-2'>{s.portaCgi}</td>
                                    <td className='p-2'>{s.usuarioDGuard}</td>
                                    <td>
                                        <Dropdown appearance='primary' color={'violet'} title="Ação" size={'xs'}>
                                            <Dropdown.Item onClick={toggleAtualizar}>Atualizar</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleExcluir}>Deletar</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))

                        } 
                    </tbody>
                </Table>
            </div>
        )
    }



    return (
        <Container>

            <Header className='d-flex justify-content-between'>
                <div>
                    <p className='title'>Servidores DGUARD</p>
                    <p className='subtitle'>Listagem dos servidores cadastrados</p>
                </div>
                <div>
                    <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrar}>
                        Adicionar nova máquina
                    </IconButton>
                </div>
            </Header>
            {renderList()}

            <ModalCadastrar show={cadastrar} hide={toggleCadastrar} />
            <ModalAtualizar show={atualizar} hide={toggleAtualizar} />
            <ModalConfirmacao show={excluir} hide={toggleExcluir} action={deletarComModal} content='Você realmente deseja deletar esse servidor?' loading={deletando} />
        </Container>
    )
}

export default observer(ServidoresListagem);