import axios, { AxiosError } from 'axios';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import DispositivosAPI from '../api/DispositivoAPI';
import { BiometriaDigital, BiometriaFacial, BiometriaStatus, ITag, RelatorioBiometria, Controle, ControleRelatorio, EnviarBiometriaDigital, EnviarFoto, SincronizarFacial, SincronizarFacialCondominio, SincronizarCartaoOrigemDestino } from '../interfaces/Dispositivo.interface';
import { Sincronizar } from '../interfaces/Veiculo.interface';
import { StoreContext } from '../stores';
import { IntelbrasConfigEvento } from '../interfaces/Equipamento.interface';

export const useDispositivo = () => {

    const { dispositivosStore, moradorStore } = useContext(StoreContext);
    const [loading, setLoading] = useState(false);
    const [statusColeta, setStatusColeta] = useState('');
    const [coletando, setColetando] = useState(false);
    const [salvandoTag, setSalvandoTag] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [openRelatorio, setOpenRelatorio] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [sincronizando, setSincronizando] = useState(false);
    const [enviando, setEnviando] = useState(false);
    const [reiniciando, setReiniciando] = useState(false);

    const [controleRelatorio, setControleRelatorio] = useState<ControleRelatorio[]>([]);

    const sincronizarFotoMorador = async (facial: SincronizarFacial) => {
        setEnviando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarFacialMoradores(facial);
            dispositivosStore.selecionarRelatorio(data);
            dispositivosStore.listarBiometriaDigitalPorMorador(data.moradorId);
            moradorStore.definirSelecionado(moradorStore.selecionado?.id as string);
            toast.success('Sincronização realizado com sucesso');
            setOpenRelatorio(true);
            setEnviando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispositivosStore.selecionarRelatorio(e.response?.data);
                setOpenRelatorio(true);
                setMensagem(e.response?.data[0].mensagem);
                setColetando(false);
                setStatusColeta('error');
                setEnviando(false);
            }
            setEnviando(false);
            setStatusColeta('error');
        }
    }

    const sincronizarDataHoraIntelbras = async (equipamentoId: string) => {
        setEnviando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarDataHoraIntelbras(equipamentoId);
            toast.success('Sincronização realizado com sucesso');
            setEnviando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setEnviando(false);
                setMensagem(e.response?.data[0].mensagem);
            }
            setEnviando(false);
        }
    }

    const enviarFotoMorador = async (fotoMorador: EnviarFoto) => {
        setEnviando(true);
        try {
            const { data } = await DispositivosAPI.enviarFoto(fotoMorador);
            dispositivosStore.selecionarRelatorio(data);
            if (data.erros.length > 0) {
                toast.error("Erro ao enviar para algum equipamento");
                setEnviando(false);
                setOpenRelatorio(true);
            }
            else {
                toast.success('Foto enviado com sucesso');
                setOpenRelatorio(true);
                setEnviando(false);
            }
            setOpenRelatorio(true);
            setEnviando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSalvandoTag(false);
                setMensagem(e.response?.data[0].mensagem);
                setColetando(false);
                setStatusColeta('error');
            }
            setStatusColeta('error');
        }
    }

    const enviarTagMorador = async (moradorId: string) => {
        setEnviando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarTagMorador(moradorId);
            dispositivosStore.selecionarRelatorio(data);
            toast.success('Tag sincronizada com sucesso');
            setOpenRelatorio(true);
            setEnviando(false);
        } catch (e) {
            toast.error('Erro ao sincronzar tag');
            setEnviando(false);
        }
    }

    const enviarBiometriaDigitalMorador = async (tamplate: EnviarBiometriaDigital) => {
        setEnviando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarBiometriaDigitalMorador(tamplate);
            toast.success('Biometria sincronizada com sucesso');
            dispositivosStore.selecionarRelatorio(data);
            setOpenRelatorio(true);
            setEnviando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSalvandoTag(false);
                setMensagem(e.response?.data[0].mensagem);
                setColetando(false);
                setStatusColeta('error');
            }
        }
    }

    const cadastrarBiometriaDigital = async (biometria: BiometriaDigital, clear: () => void, drawer: () => void) => {
        setColetando(true);
        try {
            const { data } = await DispositivosAPI.coletarEenviarBiometriaDigital(biometria);
            dispositivosStore.selecionarRelatorio(data);
            dispositivosStore.listarBiometriaDigitalPorMorador(biometria.moradorId);
            setStatusColeta('success');
            setColetando(false);
            setOpenRelatorio(true);
            drawer();
            clear();
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSalvandoTag(false);
                dispositivosStore.selecionarRelatorio(e.response?.data);
                setColetando(false);
                setOpenRelatorio(true);
                setStatusColeta('error');
                drawer();
                toast.error(e.response?.data.erros[0].notificacao)
            }
            setColetando(false);
        }
        setColetando(false);
    }

    const deletarBiometriaDigital = async (biometria: BiometriaStatus) => {
        setDeletando(true);
        try {
            await DispositivosAPI.deletarBiometriaDigital(biometria.id, biometria.moradorId);
            dispositivosStore.biometriaCadastrada = dispositivosStore.biometriaCadastrada.filter(bio => bio.moradorId !== biometria.moradorId);
            setOpenRelatorio(true);
            setDeletando(false);
            toast.success('Biometria deletado com sucesso');
        } catch (e) {
            setDeletando(false);
            toast.error('Erro ao deletar biometria');
        }
    }

    const reiniciarControlId = async (controlId: string) => {
        setReiniciando(true);
        try {
            await DispositivosAPI.reiniciarControlId(controlId);
            setReiniciando(false);
            toast.success('ControlId Reiniciado com sucesso');
        } catch (e) {
            setReiniciando(false);
            toast.error('Erro ao reiniciar controlId');
        }
    }

    const configurarServidorEventosIntelbras = async (config: IntelbrasConfigEvento, hide: () => void) => {
        setLoading(true);
        try {
            await DispositivosAPI.configurarServidorEventosIntelbras(config);
            setLoading(false);
            toast.success('Configurado com sucesso');
            hide();
        } catch (e) {
            setLoading(false);
            toast.error('Erro ao configurar');
        }
    }

    const reiniciarIntelbras = async (intelbrasId: string) => {
        setReiniciando(true);
        try {
            await DispositivosAPI.reiniciarIntelbras(intelbrasId);
            setReiniciando(false);
            toast.success('Intelbras Reiniciado com sucesso');
        } catch (e) {
            setReiniciando(false);
            toast.error('Erro ao reiniciar intelbras');
        }
    }

    const cadastrarBiometriaFacial = async (biometria: BiometriaFacial, clear: () => void) => {
        setColetando(true);

        try {
            const { data } = await DispositivosAPI.coletarEenviarBiometriaFacial(biometria);
            setStatusColeta('success');
            dispositivosStore.selecionarRelatorio(data);
            dispositivosStore.listarBiometriaDigitalPorMorador(biometria.moradorId);
            moradorStore.definirSelecionado(moradorStore.selecionado?.id as string);
            setOpenRelatorio(true);
            setColetando(false);
            clear();
        } catch (e) {
            setStatusColeta('error');
            setColetando(false);
        }
    }

    const deletarBiometriaFacial = async (moradorId: string) => {
        setDeletando(true);
        try {
            const { data } = await DispositivosAPI.deletarBiometriaFacial(moradorId);
            dispositivosStore.listarBiometriaDigitalPorMorador(moradorId);
            moradorStore.definirSelecionado(moradorId);
            setOpenRelatorio(true);
            setDeletando(false);
            toast.success('Biometria deletado com sucesso');
        } catch (e) {
            setDeletando(false);
            toast.error('Erro ao deletar biometria');
        }// 3873388325
    }


    const cadastrarTag = async (tag: ITag, hide: () => void, drawer: () => void, clear: () => void) => {
        setSalvandoTag(true);

        try {
            const { data } = await DispositivosAPI.cadastrarTag(tag);
            dispositivosStore.listarTagPorMorador(tag.moradorId);
            dispositivosStore.selecionarRelatorio(data);
            // toast.success('Tag cadastrado com sucesso');
            drawer();
            setOpenRelatorio(true);
            setSalvandoTag(false);
            clear();
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSalvandoTag(false);
                toast.error(e.response?.data.erros[0].notificacao);
            }
            setSalvandoTag(false);
            // 
        }
    }

    const cadastrarTagPassiva = async (tag: Controle, hide: () => void) => {
        setSalvandoTag(true);

        try {
            const { data } = await DispositivosAPI.cadastrarTagPassiva(tag);
            // dispositivosStore.listarTagPorMorador(tag.moradorId);
            dispositivosStore.selecionarRelatorio(data);
            dispositivosStore.listarTagPassivaPorVeiculo(tag.veiculoId);
            toast.success('Tag cadastrado com sucesso');
            hide();
            setOpenRelatorio(true);
            setSalvandoTag(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSalvandoTag(false);
                dispositivosStore.selecionarRelatorio(e.response?.data);
                setOpenRelatorio(true);
                if (e.response?.data[0]) {
                    toast.error(e.response?.data[0].mensagem);
                    toast.error(e.response?.data.erros[0].notificacao);

                }
                dispositivosStore.listarTagPassivaPorVeiculo(tag.veiculoId);
            }
            setSalvandoTag(false);
        }
    }

    const deletarTag = async (tag: ITag, drawer: () => void) => {
        setDeletando(true);
        try {
            const { data } = await DispositivosAPI.deletarTag(tag.id)
            dispositivosStore.selecionarRelatorio(data);
            dispositivosStore.tags = dispositivosStore.tags.filter(tg => tg.id !== tag.id);
            setDeletando(false);
            setOpenRelatorio(true);
            drawer();
            toast.success('Tag deletado com sucesso');
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setDeletando(false);
                if (e.response?.data[0]) {
                    toast.error(e.response?.data[0].mensagem);
                    toast.error(e.response?.data.erros[0].notificacao);
                }
            }
            setDeletando(false);
        }
    }

    const deletarTagPassiva = async (tagPassiva: Controle) => {
        setDeletando(true);
        try {
            const { data } = await DispositivosAPI.deletarTagpAssiva(tagPassiva.veiculoId, tagPassiva.serial);
            dispositivosStore.selecionarRelatorio(data);
            dispositivosStore.listarTagPassivaPorVeiculo(tagPassiva.veiculoId);
            setDeletando(false);
            setOpenRelatorio(true);
            toast.success('Tag deletado com sucesso');
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setDeletando(false);
                dispositivosStore.selecionarRelatorio(e.response?.data);
                setOpenRelatorio(true);
                if (e.response?.data[0]) {
                    toast.error(e.response?.data[0].mensagem);
                    toast.error(e.response?.data.erros[0].notificacao);
                }
            }
            dispositivosStore.listarTagPassivaPorVeiculo(tagPassiva.veiculoId);
            setDeletando(false);
        }
    }

    const listarControlePorUnidade = async (unidadeId: string) => {
        setLoading(true);
        setControleRelatorio([]);
        try {
            const { data } = await DispositivosAPI.listarControlesPorUnidadeId(unidadeId);
            setLoading(false);
            setControleRelatorio(data);
        } catch (e) {
            setLoading(false);
        }
    }

    const cadastrarControle = async (controle: Controle, hide: () => void) => {
        setSalvandoTag(true);
        try {
            const { data } = await DispositivosAPI.cadastrarControle(controle);
            if (data.erros.length > 0) {
                setDeletando(false);
                dispositivosStore.selecionarRelatorio(data);
                toast.error("Erro ao deletar controle");
                dispositivosStore.listarControlePorVeiculo(controle.veiculoId);
            }
            else {
                dispositivosStore.selecionarRelatorio(data);
                toast.success('Controle cadastrado com sucesso');
                dispositivosStore.listarControlePorVeiculo(controle.veiculoId);
                setOpenRelatorio(true);
                setSalvandoTag(false);
                hide();
            }
            setSalvandoTag(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispositivosStore.selecionarRelatorio(e.response?.data);
                setOpenRelatorio(true);
                if (e.response?.data[0]) {

                    toast.error(e.response?.data[0].notificacao);
                    toast.error(e.response?.data.erros[0].notificacao);
                }
                setSalvandoTag(false);
            } else {
                toast.error('Erro ao adicionar controle');
                setSalvandoTag(false);
            }
            setDeletando(false);
            setSalvandoTag(false);
        }
    }

    const deletarControle = async (veiculoId: string, serial: string) => {
        setDeletando(true);
        try {
            const { data } = await DispositivosAPI.deletarControle(veiculoId, serial);

            dispositivosStore.selecionarRelatorio(data);
            dispositivosStore.listarControlePorVeiculo(veiculoId);
            toast.success('Controle deletado com sucesso');
            setOpenRelatorio(true);
            setDeletando(false);
        } catch (e) {
            setDeletando(true);
            if (axios.isAxiosError(e)) {
                dispositivosStore.selecionarRelatorio(e.response?.data[0]);
                setOpenRelatorio(true);
                toast.error('Erro ao adicionar controle');
                if (e.response?.data[0]) {

                    toast.error(e.response?.data[0].notificacao);
                }
            } else {
                toast.error('Erro ao adicionar controle');
            }
            setDeletando(false);
        }
    }

    const sincronizarControle = async (veiculo: Sincronizar) => {
        setSincronizando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarControleGuarita(veiculo);
            dispositivosStore.selecionarRelatorio(data);
            toast.success('Sincronização realizado com sucesso');
            setOpenRelatorio(true);
            setSincronizando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSincronizando(false);
                setDeletando(false);
                toast.error(e.response?.data[0].mensagem);
            } else {
                setSincronizando(false);
                toast.error('Erro ao sincronizar controle');
            }
            setSincronizando(false);
        }
    }

    const sincronizarTagsCondominio = async (condominioId: string) => {
        setSincronizando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarTagsCondominio(condominioId);
            toast.success('Sincronização realizado com sucesso');
            dispositivosStore.definirSelecionadoRelatorioSincronizacao(data);
            setOpenRelatorio(true);
            setSincronizando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSincronizando(false);
                toast.error(e.response?.data[0].mensagem);
                toast.error(e.response?.data.erros[0].notificacao);
            } else {
                setSincronizando(false);
                toast.error('Erro ao sincronizar tags');
            }
            setSincronizando(false);
            toast.error('Erro ao sincronizar tags');
        }
    }

    const sincronizarBiometriasCondominio = async (condominioId: string) => {
        setSincronizando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarBiometriasCondominio(condominioId);
            dispositivosStore.definirSelecionadoRelatorioSincronizacao(data);
            toast.success('Sincronização realizado com sucesso');
            setOpenRelatorio(true);
            setSincronizando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispositivosStore.definirSelecionadoRelatorioSincronizacao(e.response?.data);
                setOpenRelatorio(true);
                setSincronizando(false);
                toast.error(e.response?.data[0].mensagem);
            } else {
                setSincronizando(false);
                toast.error('Erro ao sincronizar biometrias');
            }
            setSincronizando(false);
            toast.error('Erro ao sincronizar biometrias');
        }
    }

    const sincronizarFaciaisCondominio = async (facial: SincronizarFacialCondominio) => {
        setSincronizando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarFacialCondominio(facial);
            dispositivosStore.definirSelecionadoRelatorioSincronizacao(data);
            toast.success('Sincronização realizado com sucesso');
            setOpenRelatorio(true);
            setSincronizando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispositivosStore.definirSelecionadoRelatorioSincronizacao(e.response?.data);
                setOpenRelatorio(true);
                setSincronizando(false);
                toast.error(e.response?.data[0].mensagem);
            } else {
                setSincronizando(false);
                toast.error('Erro ao sincronizar faciais');
            }
            setSincronizando(false);
            toast.error('Erro ao sincronizar faciais');
        }
    }

    const sincronizarControlesCondominio = async (condominioId: string) => {
        setSincronizando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarControlesCondominio(condominioId);
            dispositivosStore.definirSelecionadoRelatorioSincronizacao(data);
            toast.success('Sincronização realizado com sucesso');
            setOpenRelatorio(true);
            setSincronizando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSincronizando(false);
                dispositivosStore.definirSelecionadoRelatorioSincronizacao(e.response?.data);
                setOpenRelatorio(true);
                toast.success('Sincronização realizado com sucesso');
                toast.error(e.response?.data[0].mensagem);
            } else {
                setSincronizando(false);
                toast.error('Erro ao sincronizar controles');
            }
            setSincronizando(false);
            toast.error('Erro ao sincronizar controles');
        }
    }

    const sincronizarCartaoDoGuaritaParaControlIdCondominio = async (controlIdDestino: string) => {
        setSincronizando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarCartaoDoGuaritaParaControlIdCondominio(controlIdDestino);
            dispositivosStore.selecionarRelatorio(data);
            toast.success('Sincronização realizado com sucesso');
            setOpenRelatorio(true);
            setSincronizando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSincronizando(false);
                toast.error(e.response?.data[0].mensagem);
            } else {
                setSincronizando(false);
                toast.error('Erro ao sincronizar controles');
            }
            setSincronizando(false);
            toast.error('Erro ao sincronizar controles');
        }
    }

    const sincronizarControlIdOrigemDestino = async (controlId: SincronizarCartaoOrigemDestino) => {
        setSincronizando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarCartaoOrigemDestino(controlId);
            dispositivosStore.selecionarRelatorio(data);
            toast.success('Sincronização realizado com sucesso');
            setOpenRelatorio(true);
            setSincronizando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSincronizando(false);
                toast.error(e.response?.data[0].mensagem);
            } else {
                setSincronizando(false);
                toast.error('Erro ao sincronizar');
            }
            setSincronizando(false);
            toast.error('Erro ao sincronizar');
        }
    }

    const sincronizarGuaritaHikvision = async (guaritaId: string, hikvisionId: string) => {
        setSincronizando(true);
        try {
            const { data } = await DispositivosAPI.sincronizarGuaritaHikvision(guaritaId, hikvisionId);
            dispositivosStore.definirSelecionadoRelatorioSincronizacao([data]);
            toast.success('Sincronização realizado com sucesso');
            setOpenRelatorio(true);
            setSincronizando(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setSincronizando(false);
                toast.error(e.response?.data[0].mensagem);
            } else {
                setSincronizando(false);
                toast.error('Erro ao sincronizar');
            }
            setSincronizando(false);
            toast.error('Erro ao sincronizar');
        }
    }

    return {
        loading,
        statusColeta,
        coletando,
        cadastrarTag,
        salvandoTag,
        deletando,
        deletarTag,
        setStatusColeta,
        cadastrarBiometriaDigital,
        cadastrarBiometriaFacial,
        listarControlePorUnidade,
        deletarBiometriaDigital,
        deletarBiometriaFacial,
        cadastrarControle,
        cadastrarTagPassiva,
        deletarControle,
        mensagem,
        sincronizarControle,
        sincronizando,
        deletarTagPassiva,
        openRelatorio,
        setOpenRelatorio,
        controleRelatorio,
        enviarTagMorador,
        enviarBiometriaDigitalMorador,
        enviando,
        sincronizarTagsCondominio,
        sincronizarBiometriasCondominio,
        reiniciarControlId,
        reiniciarIntelbras,
        reiniciando,
        enviarFotoMorador,
        sincronizarFotoMorador,
        sincronizarFaciaisCondominio,
        sincronizarControlesCondominio,
        sincronizarCartaoDoGuaritaParaControlIdCondominio,
        sincronizarControlIdOrigemDestino,
        sincronizarDataHoraIntelbras,
        configurarServidorEventosIntelbras,
        sincronizarGuaritaHikvision
    }

}
