import { makeAutoObservable, runInAction } from "mobx";
import SiamAPI from "../api/SiamAPI";
import { Modulo } from "../interfaces/Modulo.interface";
import { Controladora, Dispositivos, DispositivosLista, Siam } from "../interfaces/Siam.interface";

class SiamStore {

    siam: Siam[] = [];
    controladora: Controladora[] = [];
    dispositivos: DispositivosLista[] = [];
    controladoraSelecionado: Controladora | null = null;
    selecionado: Siam | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listar() {
        this.loading = true;
        let condominioId = localStorage.getItem('condominio');
        try {
            if(condominioId) {
                const { data } = await SiamAPI.listarPorCondominio(condominioId);
                runInAction(() => {
                    this.loading = false;
                    if(data) this.siam = data;
                    else this.siam = [];
                });
            }
        } catch(e) {
            this.loading = false;
            
        }
    }

    async listarDispositivoPorSiamId(siamId: string) {
        this.loading = true;
        try {
            if(siamId) {
                const { data } = await SiamAPI.listarDispositivosPorSiamId(siamId);
                runInAction(() => {
                    this.loading = false;
                    this.dispositivos = data;
                });
            }
            this.loading = false;
        } catch(e) {
            this.loading = false;
            
        }
    }

    async listarControladoraPorCondominioId(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await SiamAPI.listarControladoraPorCondominioId(condominioId);
                runInAction(() => {
                    this.loading = false;
                    this.controladora = data;
                });
            }
        } catch(e) {
            this.loading = false;
            
        }
    }

    definirSelecionado(siamId: string) {
        let selecionado = this.siam.find((s) => s.id === siamId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
        else
        {
            this.selecionado = null;
        }
    }

    definirControladoraSelecionado(controladoraId: string) {
        let selecionado = this.controladora.find((s) => s.id === controladoraId);
        if(selecionado) {
            this.controladoraSelecionado = selecionado;
        }
    }

}

export default SiamStore;