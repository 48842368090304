import React, { useState, useEffect, useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import { BsCardChecklist, BsXCircle, BsCheckCircle, BsBookmarkCheck } from 'react-icons/bs';
import finger from '../../../assets/img/finger.png';
import { StoreContext } from '../../../stores';
import { BiometriaContainer, TransitionDiv, Title, Notificacao, RelatorioContainer, Sidebar, TitleRelatorio, Content, TitleResponse } from './styles/ModalBiometria.stye';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { MdErrorOutline, MdFingerprint } from 'react-icons/md';
import { observer } from 'mobx-react-lite';
import { Chip, Drawer } from '@mui/material';
import { useDispositivo } from '../../../hooks/Dispositivo.hook';
import { BiometriaDigital } from '../../../interfaces/Dispositivo.interface';
import Loading from '../../utils/Loading';
import { Icon } from '../../utils/RelatorioStyle';
import { Button, SelectPicker } from 'rsuite';
import ModalRelatorioBiometria from '../../utils/ModalRelatorioBiometria';
import { toast } from 'react-toastify';
interface ModalBiometriaProps {
  cadastrar: boolean;
  setCadastrar: React.Dispatch<React.SetStateAction<boolean>>
  setRoutes: React.Dispatch<React.SetStateAction<string>>
}

const CadastrarBiometria: React.FC<ModalBiometriaProps> = ({ cadastrar, setCadastrar, setRoutes }) => {

  const { equipamentoStore, acionamentoStore, moradorStore, themeStore, dispositivosStore } = useContext(StoreContext);
  const { statusColeta, coletando, cadastrarBiometriaDigital, loading, openRelatorio, setOpenRelatorio, mensagem } = useDispositivo();
  const [dedo, setDedo] = useState<number | null>(null);
  const [tipo, setTipo] = useState<number | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // acionamentoStore.definirPerfilSelecionado(null);
  }, []);

  const toggleOpen = () => setOpen(!open);

  const clear = () => {
    // acionamentoStore.definirPerfilSelecionado(null);
    setDedo(null);
    setTipo(null);
  }

  const coletarBiometria = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(tipo)
    if (tipo === undefined || tipo === null) toast.error("Selecione o tipo");
    else {
      let informacoesColetada: BiometriaDigital = {
        moradorId: moradorStore.selecionado?.id as string,
        equipamentoInicialId: equipamentoStore.selecionado?.equipamentoId as string,
        dedo: tipo === 0 || tipo === 1 ? 1 : 2,
        tipo: tipo as number
      }
      cadastrarBiometriaDigital(informacoesColetada, clear, toggleOpen);
    }
  }

  const retorno = () => {
    setCadastrar(false);
  }

  const relatorio = () => {
    return (
      <div className='mt-5 animation' style={{ padding: 40, background: 'rgba(0,0,0,0.0100)', borderRadius: 4, height: '100%', margin: 50 }}>
        <p style={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>Relatório do cadastro</p>
        <div className='d-flex justify-content-center'>
          <MdFingerprint size={22} color={'#228B22'} />
          <BsCardChecklist size={22} color={'#228B22'} className='ml-2' />
        </div>

        <div className='d-flex justify-content-center m-3'>
          {/* <button type={'button'} className='btn btn-success' onClick={() => setStatusColeta('')} style={{ borderRadius: 60 }}>Voltar</button> */}
        </div>
      </div>
    )
  }

  const erroNoCadastro = () => {
    return (
      <div className='mt-5 animation' style={{ padding: 40, background: 'rgba(0,0,0,0.0400)', borderRadius: 4, height: '100%', margin: 50 }}>
        <p style={{ fontSize: 16, fontWeight: 600, color: themeStore.theme === 'dark' ? '#fff' : 'rgba(0,0,0,0.8)', textAlign: 'center' }}>{mensagem}</p>
        <div className='d-flex justify-content-center'>
          <MdFingerprint size={22} color={'#FF0000'} />
          <BsXCircle size={22} color={'#FF0000'} className='ml-2' />
        </div>
        <div className='d-flex justify-content-center m-3'>
          {/* <button type={'button'} className='btn btn-success' onClick={() => setStatusColeta('')} style={{ borderRadius: 60 }}>Voltar</button> */}
        </div>
      </div>
    )
  }

  const renderStatusDaColeta = () => {
    if (coletando) {
      <div className='animation' style={{ margin: 50 }}>
        <Loading message='Coletando biometria...' />
      </div>
    }
    return (
      <div className='animation'>
        <div className='d-flex justify-content-center'>
          <img src={finger} style={{ height: 270 }} />
        </div>
        <p style={{ textAlign: 'center', margin: 20, fontSize: 14 }}>
          Clique no botão "Coletar biometria" para fazer o cadastro
        </p>

        <div className='mt-3'>
          <div>
            {/* {
                acionamentoStore.perfilSelecionado !== null
                ?
                <div className='d-flex justify-content-between' style={{flexWrap: 'wrap'}}>
                  {
                    acionamentoStore.perfilSelecionado.acionamentos.map((acionamento, i) => (
                      <Chip key={i} variant="outlined" color={themeStore.theme === 'dark' ? 'success' : 'primary'}  className='m-1' icon={<BsCheckCircle size={20} />} label={acionamento.nome} />
                    ))
                  }
                </div>
                :
                <div/>
              } */}
          </div>
        </div>

        <div className='mt-3'>
          <p className='p-0 m-0' style={{ fontWeight: 550, fontSize: 17 }}>Modo de cadastro</p>
          <p className='p-0 m-0 mb-2' style={{ fontSize: 11, width: '70%' }}>O modo "Normal" é utilizado apenas para fazer liberação no local e o modo "Pânico" é utilizado pra gerar um alerta para central caso o morador esteja em perigo</p>
          <SelectPicker
            style={{ width: '50%' }}
            searchable={false}
            placeholder={'Selecione o modo de cadastro'}
            data={[
              {
                label: 'Normal',
                value: 0
              },
              {
                label: 'Pânico',
                value: 1
              }
            ]}
            onChange={(e) => setTipo(e)}
          />
          {/* <select
            className='form-control shadow-none'
            required
            value={tipo?.toString() || ''}
            onChange={(e) => setTipo(parseInt(e.target.value))}
          >
            <option value={''}>Selecione</option>
            <option value={0}>Normal</option>
            <option value={1}>Pânico</option>
          </select> */}
        </div>
      </div>
    )
  }

  return (
    <>
      <BiometriaContainer onSubmit={(e) => coletarBiometria(e)} className='animation'>
        {/* <p>Cadastrar biometria no dispositivo <b>{equipamentoStore.selecionado?.nome}</b></p> */}

        <div>
          <TransitionDiv className=''>
            <SwitchTransition mode={'out-in'}>
              <CSSTransition
                key={coletando ? "Hello, world!" : "Goodbye, world!"}
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
              >
                <div>
                  {renderStatusDaColeta()}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </TransitionDiv>
        </div>

        <div className='d-flex justify-content-end mt-3'>
          {
            statusColeta === 'success' || statusColeta === 'error'
              ?
              ''
              :
              <Button type={'submit'} appearance={'primary'} color={'blue'} style={{ marginRight: 10 }} disabled={loading}>{coletando ? 'Coletando biometria...' : 'Coletar biometria'}</Button>
          }
          <Button type={'button'} onClick={() => {
            setRoutes('');
            setCadastrar(false);
            // equipamentoStore.definirSelecionado('');
          }}>
            {
              statusColeta === 'success' || statusColeta === 'error'
                ?
                'Voltar'
                :
                'Cancelar'
            }
          </Button>
        </div>
      </BiometriaContainer>

      <ModalRelatorioBiometria show={openRelatorio} hide={() => setOpenRelatorio(false)} hideAdd={() => {
        setRoutes('');
        setCadastrar(false);
      }} tipo={'Biometria cadastro'} />

    </>
  );
};

export default observer(CadastrarBiometria);
