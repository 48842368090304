import { Avatar, LinearProgress, Switch } from "@mui/material";
import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaWpforms } from "react-icons/fa";
import { StoreContext } from "../../stores";
import { useRamal } from "../../hooks/Ramal.hook";
import { Ramal, RamalRequest } from "../../interfaces/Ramal.interface";
import { Button, Input, InputPicker, Modal, SelectPicker } from "rsuite";
import { observer } from "mobx-react-lite";

interface PropsModal {
  show: boolean;
  hide: () => void;
  ramalSelecionado?: Ramal | null;
}

const AtualizarRamal: FC<PropsModal> = ({ show, hide, ramalSelecionado }) => {
  const { sipStore, cameraStore, layoutStore, condominioStore } = useContext(StoreContext);
  const { atualizar, atualizando } = useRamal();
  const [form, setForm] = useState<RamalRequest>({});
  const [selectLayout, setSelectLayout] = useState("");

  useEffect(() => {
    if (ramalSelecionado) {
      setForm({
        ...ramalSelecionado,
        ramalNumero:
          ramalSelecionado.tipo === "FXO"
            ? ramalSelecionado.ramalNumero.split(".")[0]
            : ramalSelecionado.ramalNumero,
        complemento:
          ramalSelecionado.tipo === "FXO"
            ? ramalSelecionado.ramalNumero.split(".")[1]
            : "" ?? "",
      });
      
    }
  }, [ramalSelecionado]);

  useEffect(() =>
  {
    if(condominioStore.selecionado)
      cameraStore.listarPorCondominioId(condominioStore.selecionado?.id);
  }, [condominioStore.selecionado, show])

  const salvar = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.complemento)
      form.ramalNumero = `${form.ramalNumero}.${form.complemento}`;
    else {
      form.complemento = "";
      form.ramalNumero = `${form.ramalNumero}`;
    }

    atualizar(form as Ramal, hide);
  };

  const FormRamal = () => {
    return (
      <Form onSubmit={(e) => salvar(e)}>
        <div className="d-flex">
          {form.tipo === "FXS" || form.tipo === "FXSCentral" ? (
            <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
              <Form.Label>
                <span className="text-danger">*</span>Ramal número
              </Form.Label>
              <Input
                required
                type="text"
                className="shadow-none"
                placeholder="Ex: 2020"
                value={form.ramalNumero || ""}
                onChange={(e) =>
                  setForm({ ...form, ramalNumero: e, complemento: "" })
                }
              />
            </Form.Group>
          ) : (
            <div className="d-flex" style={{ marginRight: 20 }}>
              <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                <Form.Label>
                  <span className="text-danger">*</span>Ramal número
                </Form.Label>
                <Input
                  required
                  type="text"
                  className="shadow-none"
                  placeholder="Ex: 2020"
                  value={form.ramalNumero || ""}
                  onChange={(e) => setForm({ ...form, ramalNumero: e })}
                />
              </Form.Group>
              <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                <Form.Label>
                  <span className="text-danger">*</span>Ramal complemento
                </Form.Label>
                <Input
                  type="text"
                  className="shadow-none"
                  placeholder="Ex: 2020"
                  value={form.complemento || ""}
                  onChange={(e) => setForm({ ...form, complemento: e })}
                />
              </Form.Group>
            </div>
          )}
          <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
            <Form.Label>
              <span className="text-danger">*</span>Nome
            </Form.Label>
            <Input
              required
              type="text"
              className="shadow-none"
              placeholder="Ex: PED. EXTERNO"
              value={form.nome || ""}
              onChange={(e) => setForm({ ...form, nome: e })}
            />
          </Form.Group>
        </div>

        <div className="d-flex">
          <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
            <Form.Label>
              <span className="text-danger">*</span>Ip dos dispositivo SIP
            </Form.Label>
            <SelectPicker
              block
              placeholder={"Selecione o dispositivo SIP"}
              data={sipStore.sips.map((sip) => ({
                label: `${sip.ip}:${sip.porta} ${sip.modelo}`,
                value: sip.id,
              }))}
              value={form.dispositivoSipId || ""}
              onChange={(e) => {
                if (e) setForm({ ...form, dispositivoSipId: e });
              }}
            />
            {/* <select
                        className='form-control shadow-none'
                        onChange={(e) => setForm({ ...form, dispositivoSipId: e.target.value })}
                        // value={form.dispositivosipId || ''}
                    >
                        <option>Selecione o ip do dispositivo SIP</option>
                        {
                            sipStore.sips.map((sip, i) => (
                                <option key={i} value={sip.id}>)</option>
                            ))
                        }
                    </select> */}
          </Form.Group>
          <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
            <Form.Label>
              <span className="text-danger">*</span>Tipo
            </Form.Label>
            <SelectPicker
              block
              placeholder={"Selecione o tipo do equipamento"}
              data={[
                {
                  label: "FXS",
                  value: "FXS",
                },
                {
                  label: "FXO",
                  value: "FXO",
                },
                {
                  label: "FXSCentral",
                  value: "FXSCentral",
                },
              ]}
              value={form.tipo || ""}
              onChange={(e) => {
                if (e) setForm({ ...form, tipo: e });
              }}
            />
            {/* <select
                        className='form-control shadow-none'
                        onChange={(e) => setForm({ ...form, tipo: e.target.value })}
                        value={form.tipo || ''}
                    >
                        <option value={'FXS'}>FXS</option>
                        <option value={'FXO'}>FXO</option>
                        <option value={'FXSCentral'}>FXSCentral</option>
                    </select> */}
          </Form.Group>
        </div>

        {/* <div>
                <Form.Group className=" p-1" controlId="formBasicEmail">
                    <Form.Label>Câmera do ramal</Form.Label>
                    <SelectPicker
                        block
                        placeholder={'Selecione'}
                        data={cameraStore.camerasCondominio.map((c) => ({ label: `${c.nomeDGuard}`, value: c.id }))}
                        value={form.cameraId || ''}
                        onChange={(e) => {if(e) setForm({ ...form, cameraId: e})}}
                    />
                </Form.Group>
            </div> */}

        <div className="m-1">
          <p>
            <span className="text-danger">*</span>Câmera
          </p>
          {layoutStore.layouts.length > 1 ? (
            <div>
              {layoutStore.layouts.map((layout, i) => (
                <Form.Check
                  key={i}
                  type={"checkbox"}
                  id={i.toString()}
                  label={`${layout.nomeDGuard}`}
                  onChange={(e) => {
                    setSelectLayout(layout.id);
                    cameraStore.listarPorLayout(layout.id);
                  }}
                  checked={selectLayout === layout.id ? true : false}
                />
              ))}
              <>
                {cameraStore.loading ? (
                  "CAREGANDO CAMERAS..."
                ) : (
                  <InputPicker
                    block
                    aria-required
                    data={cameraStore.cameras.map((camera) => ({
                      label: camera.nomeDGuard,
                      value: camera.id,
                    }))}
                    placeholder={"Informe a câmera"}
                    value={form.cameraId || ""}
                    onChange={(e) => setForm({ ...form, cameraId: e })}
                  />
                )}
              </>
            </div>
          ) : (
            <>
              {cameraStore.loading ? (
                <div>CARREGANDO CAMERAS...</div>
              ) : (
                <InputPicker
                  block
                  aria-required
                  data={cameraStore.camerasCondominio.map((camera) => ({
                    label: camera.nomeDGuard,
                    value: camera.id,
                  }))}
                  placeholder={"Informe a câmera"}
                  value={form.cameraId || ""}
                  onChange={(e) => setForm({ ...form, cameraId: e })}
                />
              )}
            </>
          )}
        </div>

        <div className="d-flex justify-content-end">
          <Button appearance="primary" disabled={atualizando} type="submit">
            {atualizando ? "Salvando..." : "Salvar"}
          </Button>
          <Button
            disabled={atualizando}
            type="button"
            onClick={hide}
            style={{ marginLeft: 10 }}
          >
            Fechar
          </Button>
        </div>
        {atualizando ? (
          <LinearProgress
            color="primary"
            style={{ height: 2, marginTop: 15 }}
          />
        ) : (
          ""
        )}
      </Form>
    );
  };

  return (
    <Modal open={show} onClose={hide} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex justify-content-center">
          <Avatar
            variant="rounded"
            style={{ background: "#663b5f", marginRight: 10 }}
          >
            <FaWpforms />
          </Avatar>
          <p>Atualizar Ramal ramal {ramalSelecionado?.nome}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{FormRamal()}</Modal.Body>
    </Modal>
  );
};

export default observer(AtualizarRamal);
