import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form, Table } from 'react-bootstrap';
import { Button, Dropdown, Input, Loader, Modal, SelectPicker } from 'rsuite';
import { Bravas, BravasRequest } from '../../../../interfaces/Bravas.interface';
import useBravas from '../../../../hooks/Bravas.hook';
import { StoreContext } from '../../../../stores';
import { useMip1000 } from '../../../../hooks/Mip100.hook';
import { Mip1000Rele, Mip1000ReleRequest } from '../../../../interfaces/Mip1000.interface';
import AtualizarRele from './AtualizarRele';
import ModalConfirmacao from '../../../utils/ModalConfirmacao';


interface ModalProps {
    show: boolean;
    hide: () => void;
}

const CadastrarRele: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<Mip1000ReleRequest>({});
    const { cadastrarRele, salvando, deletarRele, deletando } = useMip1000();
    const { condominioStore, mip1000Store, themeStore } = useContext(StoreContext);
    const [atualizarRele, setAtualizarRele] = useState(false);
    const [modalDeletarMip1000, setModalDeletarMip1000] = useState(false);

    const toggleModalDeletarMip1000 = () => setModalDeletarMip1000(!modalDeletarMip1000);

    const toggleAtualizarRele = () => setAtualizarRele(!atualizarRele);

    const numberList = Array.from({ length: 26 }, (_, index) => index + 1);
    const numberListRele = Array.from({ length: 10 }, (_, index) => index + 1);


    useEffect(() => {
        if (mip1000Store.selecionado)
        {
            mip1000Store.listarRelePorMip1000(mip1000Store.selecionado.id);
            setForm({
                mip1000Id: mip1000Store.selecionado.id,
                condominioId: mip1000Store.selecionado.condominioId,
                saida: 1,
                enderecoDispositivo: 1
            })
        }
    }, [mip1000Store.selecionado]);

    const clear = () =>
    {
        if(mip1000Store.selecionado)
        {
            setForm({
                mip1000Id: mip1000Store.selecionado.id,
                saida: 1,
                enderecoDispositivo: 1
            })
        }
    }

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(mip1000Store.selecionado)
        {
            cadastrarRele(form as Mip1000Rele, hide, clear);
        }
    }

    const deletarMMip1000Selecionado = () =>
    {
        if(mip1000Store.selecionadoRele) {
            deletarRele(mip1000Store.selecionadoRele);
        }
    }
  

    const FormCard = () => {
        return (
            <Form onSubmit={(e) => salvar(e)}>
                <div>
                    <Form.Group className=" p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" value={form.nome || ''} className='shadow-none' placeholder="Ex: Rele 1" onChange={(e) => setForm({ ...form, nome: e })} />
                    </Form.Group>
                    <Form.Group className=" p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Tipo</Form.Label>
                        <Input required type="text" value={form.tipo || ''} className='shadow-none' placeholder="Ex: rele" onChange={(e) => setForm({ ...form, tipo: e })} />
                    </Form.Group>
                    <Form.Group className="p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Saída</Form.Label>
                        <SelectPicker block data={numberListRele.map(x => ({ label: x, value: x }))}  value={form.saida || ''} placeholder="1" onChange={(e) => setForm({ ...form, saida: e })} />
                    </Form.Group>
                    <Form.Group className="p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Endereço Dispositivo</Form.Label><br/>
                        <SelectPicker block data={numberList.map(x => ({ label: x, value: x }))}  value={form.enderecoDispositivo || ''} placeholder="1" onChange={(e) => setForm({ ...form, enderecoDispositivo: e })} />
                        {/* <Input required type="text" className='shado-none' value={form.enderecoDispositivo || ''} placeholder="0" onChange={(e) => setForm({ ...form, enderecoDispositivo: e })} /> */}
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-4'>
                    <Button appearance="primary" disabled={salvando} type="submit">
                        {salvando ? 'Salvando...' : 'Salvar'}
                    </Button>
                    <Button disabled={salvando} type="button" onClick={hide} style={{ marginLeft: 10 }}>
                        Fechar
                    </Button>
                </div>
                {/* {salvando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''} */}
            </Form>
        )
    }

    const renderListRele = () => {
        return (
            <div className='box-table'>
                <div className='mt-1'>
                    <Table striped hover variant={themeStore.theme === 'dark' ? 'dark' : 'none'} size="lg">
                        <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Endereco Dispositivo</th>
                                <th scope="col">Saída</th>
                                <th scope="col">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                mip1000Store.releMip1000.map((x, i) => (
                                    <tr key={i} onClick={() => mip1000Store.definirSelecionadoRele(x.id)}>
                                        <td>{x.nome}</td>
                                        <td>{x.tipo}</td>
                                        <td>{x.enderecoDispositivo}</td>
                                        <td>{x.saida}</td>
                                        <td>
                                            <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'} placement='topEnd'>
                                                <Dropdown.Item onClick={() => {
                                                    toggleAtualizarRele();
                                                }}>Atualizar</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    toggleModalDeletarMip1000();
                                                }}>Deletar</Dropdown.Item>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }

    const RenderReles = () => {
        return (
            <div>
                <div>
                    <p className='modal-styled-title mt-4'>relés cadastrado</p>
                    {renderListRele()}
                </div>
            </div>
        )
    }

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastrar novo rele no MIP 1000 - IP</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {FormCard()}
                    {RenderReles()}
                </div>
            </Modal.Body>

            <AtualizarRele show={atualizarRele} hide={toggleAtualizarRele} />
            <ModalConfirmacao show={modalDeletarMip1000} hide={toggleModalDeletarMip1000} action={deletarMMip1000Selecionado} content={'Você tem certeza que deseja deletar esse MIP1000?'} loading={deletando} />
        </Modal>
    )
}

export default observer(CadastrarRele);