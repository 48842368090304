import React, { FC, useContext, useEffect, useState } from 'react';
import { Avatar, Drawer } from '@mui/material';
import user from '../../../../../assets/images/user.png';
import { Component, EquipamentoImage } from './styles/CadastrarFacial.style';
import { StoreContext } from '../../../../../stores';
import { observer } from 'mobx-react-lite';
import { useDispositivo } from '../../../../../hooks/Dispositivo.hook';
import { BiometriaDigital, BiometriaFacial } from '../../../../../interfaces/Dispositivo.interface';
import face from '../../../../../assets/images/face.png';
import { MdErrorOutline, MdFaceUnlock, MdFingerprint } from 'react-icons/md';
import { BsBookmarkCheck, BsCardChecklist, BsXCircle } from 'react-icons/bs';
import { BiometriaContainer, Content, Notificacao, Sidebar, Title, TitleRelatorio, TitleResponse, TransitionDiv } from '../../../biometria/styles/ModalBiometria.stye';
import { ListGroup } from 'react-bootstrap';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import axios from 'axios';
import { Icon } from '../../../../utils/RelatorioStyle';
import { Button, Loader, Message, Modal, Uploader, useToaster } from 'rsuite';
import { FaUpload } from 'react-icons/fa';
import NotFound from '../../../../utils/NotFound';
import Empty from '../../../../utils/Empty';



interface HikvisionProps {
    setRoutes: React.Dispatch<React.SetStateAction<string>>
    setCadastrar: React.Dispatch<React.SetStateAction<boolean>>
}

interface IArquivo
{
    name: boolean;
    url: string;
}


const CadastrarFacial: FC<HikvisionProps> = ({ setRoutes, setCadastrar }) => {

    const { acionamentoStore, moradorStore, equipamentoStore, dispositivosStore } = useContext(StoreContext);
    const { cadastrarBiometriaFacial, coletando, statusColeta, setStatusColeta, openRelatorio, setOpenRelatorio, enviarFotoMorador, enviando } = useDispositivo();
    const [openUpload, setOpenUpload] = useState(false);
    const toaster = useToaster();
    const [imageBase64, setImageBase64] = useState('');
    const [arquivo, setArquivo] = useState<IArquivo | null>(null);

    const get = async () => {
        try {
            if(equipamentoStore.selecionado && equipamentoStore.selecionado.imgUrl)
            await axios.get(equipamentoStore.selecionado.imgUrl);
        } catch {

        }
    }
    function blobToBase64(blob: Blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          if(blob) reader.readAsDataURL(blob);
        });
    }

    useEffect(() => {
        get();
    }, []);

    const clear = () => {
        // acionamentoStore.definirPerfilSelecionado(null);
    }

    const cadastrar = () => {
        let informacoesColetada: BiometriaFacial = {
            moradorId: moradorStore.selecionado?.id as string,
            equipamentoInicialId: equipamentoStore.selecionado?.equipamentoId as string,
          }
        cadastrarBiometriaFacial(informacoesColetada, clear);
    }

    const enviarFotoMoradorIntelbras = () =>
    {
        if(equipamentoStore.selecionado?.tipoNome === "IntelbrasFacial" && moradorStore.selecionado)
        {
            enviarFotoMorador(
                {
                    equipamentoId: equipamentoStore.selecionado?.equipamentoId,
                    moradorId: moradorStore.selecionado.id,
                    imageBase64Byte: [`${arquivo?.url.includes("data:image/jpeg;base64") ? arquivo?.url.replace('data:image/jpeg;base64,', '') : arquivo?.url.replace('data:image/png;base64,', '')}`]
                }
            )
        }
    }

    
    if(statusColeta === 'success')
    {
        return(
            <div className='mt-5 ' style={{padding: 40, background: 'rgba(0,0,0,0.0400)', borderRadius: 4, height: '100%', margin: 50}}>
                <p style={{fontSize: 16, fontWeight: 600, textAlign: 'center'}}>Relatório do cadastrado ao lado</p>
                    <div className='d-flex justify-content-center'>
                        <MdFingerprint size={22} color={'#228B22'}/> 
                        <BsCardChecklist size={22} color={'#228B22'} className='ml-2'/>
                    </div>
                <div>
                </div>
                <div className='d-flex justify-content-center m-3'>
                    <button type={'button'} className='btn btn-danger' onClick={() => setCadastrar(false)} style={{ borderRadius: 60 }}>Voltar</button>
                </div>

                <Drawer
                    anchor={'right'}
                    open={openRelatorio}
                    onClose={() => setOpenRelatorio(false)}
                >
                    <Sidebar>
                        <Title style={{ background: 'transparent' }} className={'d-flex justify-content-between'}>
                            <span>RELATÓRIO DE CADASTRO</span>
                        </Title>

                        <div className='d-flex justify-content-center'>
                            <iframe scrolling={'no'} src={moradorStore.selecionado?.urlImg} className={'img-equipamento'} style={{borderRadius: 12, margin: 15, height: 400}} />
                        </div>
                        
                        <TitleRelatorio>
                            <span>Relatório dos dispositivos com sucesso</span>
                        </TitleRelatorio>
                        <Content>
                            <ListGroup variant="flush">
                                {dispositivosStore?.relatorio?.acertos.map((item, i) => (
                                <ListGroup.Item key={i} className="pl-0">
                                    <div className="media ml-4">
                                    <Icon><BsBookmarkCheck /></Icon>
                                    <div className="media-body pl-2">
                                        {item.local}
                                        <div className="d-flex align-items-center align-content-center justify-content-between">
                                        <Notificacao>{item.notificacao}</Notificacao>
                                        </div>
                                    </div>
                                    </div>
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Content>

                        <TitleRelatorio>
                            <span>Relatório dos dispositivos com erros</span>
                        </TitleRelatorio>
                        <Content>
                            <ListGroup variant="flush">
                                {dispositivosStore?.relatorio?.erros.map((item, i) => (
                                <ListGroup.Item key={i} className="pl-0">
                                    <div className="media">
                                    <Icon><MdErrorOutline/></Icon>
                                    <div className="media-body pl-2">
                                        {item.local}
                                        <div className="d-flex align-items-center align-content-center justify-content-between">
                                        <Notificacao>Erro ao coletar</Notificacao>
                                        </div>
                                    </div>
                                    </div>
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Content>
                        <div className='d-flex justify-content-center m-3'>
                            <button type={'button'} className='btn btn-success' onClick={() => setOpenRelatorio(false)} style={{ borderRadius: 60 }}>Fechar</button>
                        </div>
                    </Sidebar>
                </Drawer>
            </div>
        )
    }

    if(statusColeta === 'error')
    {
        return(
            <div className='mt-5 animation' style={{padding: 40, background: 'rgba(0,0,0,0.0400)', borderRadius: 4, height: '100%', margin: 50}}>
                <p style={{fontSize: 16, fontWeight: 600, textAlign: 'center'}}>Erro ao cadastrar biometria!</p>
                <div className='d-flex justify-content-center'>
                <MdFingerprint size={22} color={'#FF0000'}/>
                <BsXCircle size={22} color={'#FF0000'} className='ml-2'/>
                </div>
                <div className='d-flex justify-content-between m-3'>
                <button type={'button'} className='btn btn-success' onClick={() => setStatusColeta('')} style={{ borderRadius: 60 }}>Tentar novamente</button>
                    <button type={'button'} className='btn btn-danger' onClick={() => setCadastrar(false)} style={{ borderRadius: 60 }}>Voltar</button>
                </div>
            </div>
        )
    }

    return(
        <>
        <div className='mt-3'>
            <p style={{fontSize: 17, fontWeight: 550, margin: 0}}>Cadastrar biometria facial no equipamento <b>{equipamentoStore.selecionado?.nome}</b></p>
            <p style={{width: '50%', fontSize: 13, margin: 0, marginTop: 5}}>
                Clique no botão abaixo para mandar um comando para realizar a captura da facial do morador ou enviar uma foto do morador para o equipamento
                <MdFaceUnlock size={23} style={{marginLeft: 10}} />
            </p>
            {/* <div className='d-flex justify-content-center'>
            <EquipamentoImage src={equipamentoStore.selecionado ? equipamentoStore.selecionado?.imgUrl.replace('outkeyfacial:outkey@', '') : ''} scrolling="no" title="Hikvision"/>
            </div> */}
            <div className='d-flex justify-content-between mt-4'>
                   <div>
                    {
                            equipamentoStore.selecionado?.tipoNome === "IntelbrasFacial"
                            ?
                            <Button type={'button'} appearance={'primary'} color={'orange'} onClick={() => setOpenUpload(true)} style={{marginRight: 10}}>Upload foto do morador</Button>
                            :
                            ''
                        }
                   </div>
                    <div className='d-flex'>
                        <Button type={'submit'} appearance={'primary'} disabled={coletando} onClick={() => cadastrar()} style={{marginRight: 10}}>{coletando ? 'Coletando biometria...' : 'Coletar biometria'}</Button>
                        <Button type={'button'} disabled={coletando} onClick={() => setCadastrar(false)} style={{marginRight: 10}}>Voltar</Button>
                    </div>
            </div>
        </div>


        <Modal open={openUpload} onClose={() => setOpenUpload(false)} style={{height: 750}} size={'lg'}>
            <Modal.Header>
                <div>
                    <p className='modal-styled-title'>Importar imagem do morador</p>
                    <p className='modal-styled-subtitle'>
                        Importe uma foto do morador para enviar para o equipamento
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                {/* <div className='d-flex justify-content-center p-3'>
                <input className='btn btn-success' type="file" name="file" onChange={(e) =>
                {
                    
                    const reader = new FileReader();
                    const file = e.target.files !== null ?e.target.files[0] : null;
                    // setFileInfo(e.target.files !== null ? e.target.files[0] : null)
                    
                    
                    if (reader !== undefined && file !== undefined) {
                    reader.onloadend = () => {
                        
                        setFileInfo(reader.result)
                    }
                    if(file) reader.readAsDataURL(file);
                    
                    }
                }} />
                </div> */}

                {/* <div className='d-flex justify-content-center p-3'>
                    {
                        fileInfo !== null
                        ?
                        <img src={fileInfo} style={{height: 250, width: 250}} />
                        :
                        <NotFound message='Seleciona a imagem' />
                    }
                </div> */}

                <Uploader autoUpload={false} action="/" draggable onChange={(e) =>
                {
                    if(e.length > 0)
                    {
                        blobToBase64(e[0]?.blobFile as any)
                        .then((base64String) =>
                        setArquivo({
                            name: e[0]?.name as any,
                            url: base64String as string
                        }))
                    }
                    else
                    {
                        setArquivo(null);
                    }
                }}>
                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>Clique ou deslize a imagem do morador aqui para realizar o upload</span>
                    </div>
                </Uploader>

                {/* <div className='d-flex justify-content-center p-3'>
                    {
                        fileInfo !== null
                        ?
                        <Button appearance='primary' disabled={enviando} onClick={enviarFotoMoradorIntelbras}>
                            {
                                enviando ? 'Enviando...' : 'Enviar foto'
                            }
                        </Button>
                        :
                        ''
                    }
                </div> */}
                <Drawer
                    anchor={'right'}
                    open={openRelatorio}
                    onClose={() => setOpenRelatorio(false)}
                >
                    <Sidebar>
                        <TitleResponse style={{ background: 'transparent' }} className={'d-flex justify-content-between'}>
                            <span>RELATÓRIO DE CADASTRO</span>
                        </TitleResponse>
{/* 
                        <div className='d-flex justify-content-center'>
                            <img src={dispositivosStore.biometriaFacialCadastrada?.template} className={'img-equipamento'} style={{borderRadius: 12, margin: 15, height: 400}} />
                        </div> */}
                        
                        <TitleResponse>
                            <span>Relatório dos dispositivos com sucesso</span>
                        </TitleResponse>
                        <Content>
                            <ListGroup variant="flush">
                                {
                                    dispositivosStore.relatorio?.acertos.length === 0
                                    ?
                                    <NotFound message={'Nenhum relatório de acertos encontrado'} />
                                    :
                                    <>
                                        {dispositivosStore?.relatorio?.acertos.map((item, i) => (
                                        <ListGroup.Item key={i} className="pl-0">
                                            <div className="media d-flex">
                                            <BsBookmarkCheck style={{alignSelf: 'center', fontSize: 21, marginRight: 5}} />
                                            <div className="media-body pl-2">
                                                {item.local}
                                                <div className="d-flex align-items-center align-content-center justify-content-between">
                                                <Notificacao>{item.notificacao}</Notificacao>
                                                </div>
                                            </div>
                                            </div>
                                        </ListGroup.Item>
                                        ))}
                                    </>
                                }
                            </ListGroup>
                        </Content>

                        <TitleResponse>
                            <span>Relatório dos dispositivos com erros</span>
                        </TitleResponse>
                        <Content>
                            <ListGroup variant="flush">
                                {
                                    dispositivosStore?.relatorio?.erros.length === 0
                                    ?
                                    <NotFound message={'Nenhum relatório de erros encontrado'} />
                                    :
                                    <>
                                        {dispositivosStore?.relatorio?.erros.map((item, i) => (
                                        <ListGroup.Item key={i} className="pl-0">
                                            <div className="media d-flex">
                                            <MdErrorOutline style={{alignSelf: 'center', fontSize: 21, marginRight: 5}} />
                                            <div className="media-body pl-2">
                                                {item.local}
                                                <div className="d-flex align-items-center align-content-center justify-content-between">
                                                <Notificacao>Erro ao coletar</Notificacao>
                                                <Notificacao>{item.notificacao}</Notificacao>
                                                </div>
                                            </div>
                                            </div>
                                        </ListGroup.Item>
                                        ))}
                                    </>
                                }
                            </ListGroup>
                        </Content>
                        <div className='d-flex justify-content-center m-3'>
                            <button type={'button'} className='btn btn-success' onClick={() => setOpenRelatorio(false)} style={{ borderRadius: 60 }}>Fechar</button>
                        </div>
                    </Sidebar>
                </Drawer>
            </Modal.Body>

            <Modal.Footer className='d-flex justify-content-end'>
                <div>
                    <Button appearance='primary' disabled={enviando || !arquivo} onClick={enviarFotoMoradorIntelbras}>
                        {
                            enviando ? 'Enviando...' : 'Enviar foto'
                        }
                    </Button>
                    <Button onClick={() => {
                        setOpenUpload(false);
                        setCadastrar(false);
                    }}>Fechar</Button>
                </div>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default observer(CadastrarFacial);