import { IconButton, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { BiCheck, BiLogOut } from 'react-icons/bi';
import { BsBookmarkCheck, BsPhone, BsThreeDots, BsThreeDotsVertical, BsTrash, BsX } from 'react-icons/bs';
import { IoIosBarcode } from 'react-icons/io';
import { MdErrorOutline, MdOutlineAddToHomeScreen } from 'react-icons/md';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { StoreContext } from '../../../stores';
import useControle from '../../../hooks/Controle.hook';
import Empty from '../../utils/Empty';
import { Container, ListControle, TransitionDiv } from './style/Controle.style';
import { useDispositivo } from '../../../hooks/Dispositivo.hook';
import NotFound from '../../utils/NotFound';
import { Controle, ControleRequest } from '../../../interfaces/Dispositivo.interface';
import Loading from '../../utils/Loading';
import { Content, Icon, Notificacao, Sidebar, Title, TitleRelatorio } from '../../utils/RelatorioStyle';
import { Carousel, ListGroup } from 'react-bootstrap';
import { Button, SelectPicker, Toggle, Drawer, Input, Dropdown, Loader } from 'rsuite';
import { red } from '@mui/material/colors';
import ModalRelatorioBiometria from '../../utils/ModalRelatorioBiometria';
import ModalRelatorioControle from '../../utils/ModalRelatorioControle';

interface ControleProps {
    hide: () => void;
}

const _Controle: FC<ControleProps> = ({ hide }) => {

    const { condominioStore, veiculoStore, bravasStore, dispositivosStore, mip1000Store } = useContext(StoreContext);
    const { cadastrarControle, salvandoTag, deletarControle, deletando, openRelatorio, setOpenRelatorio, cadastrarTagPassiva, deletarTagPassiva } = useDispositivo();
    const [transition, setTransition] = useState(false);
    const [serial, setSerial] = useState('');
    const [mode, setMode] = useState('');
    const [define, setDefine] = useState('');
    const [tipoRelatorio, setTipoRelatorio] = useState('');
    const [read, setRead] = useState(false);
    const [form, setForm] = useState<ControleRequest>({}); 
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [index, setIndex] = useState(0);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const toggleTransition = () =>
    {
        setTransition(!transition);
    }

    useEffect(() => {
        if(veiculoStore.selecionado) {
            dispositivosStore.listarControlePorVeiculo(veiculoStore.selecionado.id);
            dispositivosStore.listarTagPassivaPorVeiculo(veiculoStore.selecionado.id);
            setForm({ veiculoId: veiculoStore.selecionado.id, sincronizar: true });
        }
    }, [veiculoStore.selecionado]);

    useEffect(() =>
    {
        if(transition) window.scrollTo(0, 0);
    });

    useEffect(() =>
    {
        bravasStore.captura = null;
        mip1000Store.captura = null;
        mip1000Store.mip1000 = [];
        if(mode === 'mip1000')
        {
            if(veiculoStore.selecionado)
                setForm({ veiculoId: veiculoStore.selecionado.id, sincronizar: true });
                setRead(false);
        }
    }, [define, mode])

    const salvar = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(define === 'desktop')
        {
            form.serial = form.serial?.substring(1, form.serial.length)
        }

        if(define === 'bravas')
        {
            if(bravasStore.captura)
                form.serial = bravasStore.captura.events[0].data;
            else
                alert('Erro ao coletar serial do controle bravas');
        }

        if(define === 'mip1000')
        {
            if(mip1000Store.captura && veiculoStore.selecionado)
            {
                form.veiculoId = veiculoStore.selecionado?.id;
                form.serial = mip1000Store.captura.serial.toString();
                form.timeStamp = mip1000Store.captura.timeStamp;
                mip1000Store.captura = null;
                setRead(false);
            }
            else
                alert('Erro ao coletar soerial do controle mip1000');
        }
        
        if(mode == 'controle') 
        {
            cadastrarControle(form as Controle, toggleTransition);
            setTipoRelatorio('Cadastro Controle')
            setForm({ veiculoId: veiculoStore.selecionado?.id });
        }
        else if(mode == 'tagpassiva')
        {
            setTipoRelatorio('Cadastro Tag Passiva')
            cadastrarTagPassiva(form as Controle, toggleTransition);
        }
    }

    const renderControle = () => {

        if(dispositivosStore.loading) {
            return <Loading message='Carreggando controles...' />
        }

        if(dispositivosStore.controles?.length === 0) {
            return <NotFound message='Nenhum controle cadastrado nesse veículo' />
        }
        return(
            <ListControle>
                {
                    dispositivosStore.controles?.map((controle, i) =>
                        <div key={i} className={'d-flex justify-content-between content'} onClick={() => dispositivosStore.definirControleSelecionado(controle.id)}>
                            <div className='d-flex'>
                                <IoIosBarcode className='icon-serial' />
                                <p className='serial'>{controle.serial?.toUpperCase()}</p>
                            </div>
                            <Dropdown title="Ação" size={'xs'} appearance={'primary'} color={'violet'} loading={dispositivosStore.controleSelecionado?.id === controle.id && deletando}>
                                <Dropdown.Item onClick={() =>
                                {
                                    if(dispositivosStore.controleSelecionado)
                                    {
                                        setTipoRelatorio("Controle Exclusão");
                                        deletarControle(dispositivosStore.controleSelecionado.veiculoId, dispositivosStore.controleSelecionado.serial);
                                    }
                                }}>Deletar</Dropdown.Item>
                            </Dropdown>
                        </div> 
                    )
                }
            </ListControle>
        )
    }

    const renderTagPassiva = () => {

        if(!dispositivosStore.tagsPassiva) {
            return <NotFound message='Nenhuma tag passiva cadastrado nesse veículo' />
        }
        return(
            <ListControle>
                <div className={'content-serial d-flex justify-content-between'}>
                    <div className='d-flex'>
                        <IoIosBarcode className='icon-serial' />
                        <p className='serial'>{dispositivosStore.tagsPassiva.serial?.toUpperCase()}</p>
                    </div>
                    <Dropdown title="Ação" size={'xs'} appearance={'primary'} color={'violet'} loading={dispositivosStore.tagsPassiva && deletando}>
                            <Dropdown.Item onClick={() =>
                            {
                                if(dispositivosStore.tagsPassiva) 
                                {
                                    setTipoRelatorio("Tag passiva Exclusão");
                                    deletarTagPassiva(dispositivosStore.tagsPassiva);
                                }
                            }}>Deletar</Dropdown.Item>
                        </Dropdown>
                </div> 
            </ListControle> 
        )
    }

    const renderForm = () => {
        switch(mode)
        {
            case 'controle':
                return(
                    <form onSubmit={(e) => salvar(e)} className={'animation'}>
                        <div className='content p-3'>
                        <p style={{fontWeight: 600, fontSize: 20}}>{mode}</p>
                            <div className='d-flex justify-content-center'>
                                <div className='m-1 col-md-12'>
                                    <p><span className='text-danger'>*</span>Tipo de coleta serial</p>
                                    <SelectPicker
                                        block
                                        cleanable={false}
                                        placeholder={'Selecione'}
                                        data={[
                                            {
                                                label: 'Manual',
                                                value: 'manual'
                                            },
                                            {
                                                label: 'Desktop Reader',
                                                value: 'desktop'
                                            },
                                            {
                                                label: 'Captura Bravas',
                                                value: 'bravas'
                                            },
                                            {
                                                label: 'Captura MIP1000',
                                                value: 'mip1000'
                                            }
                                         ]}
                                         onChange={(e) => {if(e) setDefine(e)}}
                                    />
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <small><span className='text-danger'>*</span>Serial</small>
                                {
                                    define === 'desktop'
                                    ?
                                    <div>
                                        <p className='input-serial'>{form.serial?.substring(1, form.serial?.length)}</p>
                                        <input style={{opacity: '0', position: 'absolute', top: 0, left: 0}} required onChange={(e) => setForm({ ...form, serial: e.target.value })} />
                                    </div>
                                    :
                                    <>
                                        {
                                            define === 'bravas'
                                            ?
                                            <div className='p-2'>
                                                <div>
                                                    <p>Selecione o módulo de acesso bravas</p>
                                                    <SelectPicker
                                                        placeholder={'Selecione'}
                                                        block
                                                        data={bravasStore.bravas.map((b) => ({ label: b.nome, value: b.id }))}
                                                        onOpen={() =>
                                                        {
                                                            bravasStore.listar();
                                                        }}
                                                        renderMenu={(menu) =>
                                                        {
                                                            if(bravasStore.loading)
                                                                return <Loader content={'Carregando...'} />
                                                            return menu;
                                                        }}
                                                        onChange={(e) =>
                                                        {
                                                            if(e) bravasStore.definirSelecionado(e);
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <p className='mt-1'>Clique no botão abaixo para realizar a captura da serial do controle BRAVAS</p>
                                                    <Button appearance='primary' color={'violet'} size={'xs'} disabled={!bravasStore.selecionado}
                                                        onClick={() =>
                                                        {
                                                            if(bravasStore.selecionado)
                                                                bravasStore.capturarSerial(bravasStore.selecionado.id)
                                                        }}
                                                    >
                                                        {
                                                            bravasStore.loading
                                                            ?
                                                            <Loader content={'Aperte o botão...'} />
                                                            :
                                                            'Capturar'
                                                        }
                                                    </Button>
                                                </div>

                                                <div className='mt-2'>
                                                    {
                                                        bravasStore.captura
                                                        ?
                                                        <div className='p-2'>
                                                            <p>Serial: <b>{bravasStore.captura.events[0]?.data}</b></p>
                                                            <p>Local: <b>{bravasStore.captura.events[0]?.target}</b></p>
                                                            <p>Dispositivo: <b>{bravasStore.captura.events[0]?.info}</b></p>
                                                        </div>
                                                        :
                                                        <div/>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            define === 'mip1000'
                                            ?
                                            <div className='p-2'>
                                                <div>
                                                    <p>Selecione o módulo de acesso MIP1000</p>
                                                    <SelectPicker
                                                        placeholder={'Selecione'}
                                                        block
                                                        data={mip1000Store.mip1000.map((b) => ({ label: b.nome, value: b.id }))}
                                                        onOpen={() =>
                                                        {
                                                            if(condominioStore.selecionado) mip1000Store.listarPorCondominio(condominioStore.selecionado.id);
                                                        }}
                                                        renderMenu={(menu) =>
                                                        {
                                                            if(mip1000Store.loading)
                                                                return <Loader content={'Carregando...'} />
                                                            return menu;
                                                        }}
                                                        onChange={(e) =>
                                                        {
                                                            if(e) mip1000Store.definirSelecionado(e);
                                                            setRead(true);
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <p className='mt-1'>Clique no botão abaixo para realizar a captura da serial do controle MIP1000</p>
                                                    <Button appearance='primary' color={'violet'} size={'xs'} disabled={!read}
                                                        onClick={() =>
                                                        {
                                                            if(mip1000Store.selecionado)
                                                                mip1000Store.capturarSerial(mip1000Store.selecionado.id).then(() => setRead(true))
                                                        }}
                                                    >
                                                        {
                                                            mip1000Store.loading
                                                            ?
                                                            <Loader content={'pressione o botão...'} />
                                                            :
                                                            'Capturar'
                                                        }
                                                    </Button>
                                                </div>

                                                <div className='mt-2'>
                                                    {
                                                        mip1000Store.captura
                                                        ?
                                                        <div className='p-2'>
                                                            <p>Serial: <b>{mip1000Store.captura.serial}</b></p>
                                                        </div>
                                                        :
                                                        <div/>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <Input required disabled={define === '' ? true : false} type={'text'} value={form.serial || ''} placeholder='Inisira a serial aqui' onChange={(e) => setForm({ ...form, serial: e, veiculoId: veiculoStore.selecionado?.id })} />
                                        }
                                    </>
                                }
                            </div>

                            <div className='mt-2'>
                                <p>Sincronizar</p>
                                <small>Se o "Sincronizar" estiver habilitado o código é enviado direto para o equipamento, se não, fica salvo no banco de dados para futuramente realizar nova sincronização</small>
                                <br/>
                                <Toggle checked={form.sincronizar} onChange={(e) => setForm({ ...form, sincronizar: e })} checkedChildren={<BiCheck />} unCheckedChildren={<BsX />} />
                            </div>
                        </div>
                        
                        <div className='d-flex justify-content-end m-3'>
                            <Button appearance='primary' color={'blue'} disabled={salvandoTag || define === 'bravas' ? !bravasStore.captura : define === 'mip1000' ? !mip1000Store.captura : !form.serial} style={{marginRight: 10, width: 100}} type={'submit'}>{salvandoTag ? 'Salvando...' : 'Salvar'}</Button>
                            <Button  disabled={salvandoTag} style={{width: 100}} type={'button'} onClick={() => {
                                    setIndex(0)
                                    setForm({
                                    serial: ''
                                });
                            }}>Cancelar</Button>
                        </div>
                        
                    </form>
                )
            case 'tagpassiva':
                return(
                    <form onSubmit={(e) => salvar(e)} className={'animation'}>
                        <div className='content p-3'>
                            <p style={{fontWeight: 600, fontSize: 20}}>{mode}</p>
                            <div className='col-md-12'>
                                <small><span className='text-danger'>*</span>Serial</small>
                                <Input required type={'text'} value={form.serial || ''} placeholder='Inisira a serial aqui' onChange={(e) => setForm({ ...form, serial: e, veiculoId: veiculoStore.selecionado?.id })} />
                            </div>

                            <div className='mt-2'>
                                <p>Sincronizar</p>
                                <small>Se o "Sincronizar" estiver habilitado o código é enviado direto para o equipamento, se não, fica salvo no banco de dados para futuramente realizar nova sincronização</small>
                                <br/>
                                <Toggle checked={form.sincronizar} onChange={(e) => setForm({ ...form, sincronizar: e })} checkedChildren={<BiCheck />} unCheckedChildren={<BsX />} />
                            </div>
                        </div>
                        
                        <div className='d-flex justify-content-end m-3' >
                            <Button appearance='primary' color={'blue'} disabled={salvandoTag} style={{marginRight: 10, width: 100}} type={'submit'} >{salvandoTag ? 'Salvando...' : 'Salvar'}</Button>
                            <Button  disabled={salvandoTag} style={{width: 100}} type={'button'} className='' onClick={() => {
                                    setIndex(0)
                                    setForm({
                                    serial: ''
                                });
                            }}>Cancelar</Button>
                        </div>
                        
                    </form>
                )
            return <div/>
        }

    }
    

    return(
        <Container>
            <div className='header'>
                <div>
                    <p className='title'><BsPhone className='icon' /> Cadastro de dispositivo</p>
                    <p className='subtitle'>Cadastre controles para o veículo selecionado</p>
                </div>
                <BsX className='icon-close' onClick={() => hide()} />
            </div>
            
            {
                veiculoStore.selecionado?.acionamentoPerfilId === '00000000-0000-0000-0000-000000000000'
                ?
                <NotFound message='Veículo sem perfil cadastrado' />
                :
                <div>
                    <Carousel variant="dark" indicators={false} controls={false} activeIndex={index} interval={1000}>
                        <Carousel.Item>
                            <div className='animation'>
                                <div>
                                    <p style={{fontSize: 16, fontWeight: 550, marginLeft: 10}}>Dispositivos</p>
                                    {renderControle()}
                                </div>
                                <div>
                                <p style={{fontSize: 16, fontWeight: 550, marginLeft: 10}}>Tags Passivas</p>
                                    {renderTagPassiva()}
                                </div>
                                <div className='d-flex button-add mt-5' onClick={() => {
                                    setIndex(1)
                                    setMode('controle');
                                }}>
                                    <MdOutlineAddToHomeScreen className='icon-add' />
                                    <div>
                                        <p className='title-add'>Cadastrar novo controle</p>
                                        <p className='subtitle-add'>Faça o cadastro de um novo controle para o veículo selecionado</p>
                                    </div>
                                </div>
                                <div className='d-flex button-add' onClick={() => {
                                    setIndex(1)
                                    setMode('tagpassiva');
                                }}>
                                    <MdOutlineAddToHomeScreen className='icon-add' />
                                    <div>
                                    <p className='title-add'>Cadastrar nova tag passiva</p>
                                        <p className='subtitle-add'>Faça o cadastro de uma nova tag passiva para o veículo selecionado</p>
                                    </div>
                                </div>
                            <div className='d-flex button-add' onClick={() => hide()}>
                                    <BiLogOut className='icon-add' />
                                    <div>
                                        <p className='title-add'>Voltar</p>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>

                        <Carousel.Item>
                            {renderForm()}
                        </Carousel.Item>
                    </Carousel>
                </div>
                // <TransitionDiv className=''>
                //     <SwitchTransition mode={'out-in'}>
                //         <CSSTransition
                //             key={transition ? "YES!" : "NOT"}
                //             addEndListener={(node, done) => {
                //             node.addEventListener("transitionend", done, false);
                //             }}
                //             classNames="fade"
                //         >
                //             <div>
                //             {transition ? (
                //                 <div>
                                    // {renderForm()}
                //                 </div>
                //             ) : (
                //             <div className='animation'>
                //                 <div>
                //                     <p style={{fontSize: 16, fontWeight: 550, marginLeft: 10}}>Controles</p>
                //                     {renderControle()}
                //                 </div>
                //                 <div>
                //                     <p style={{fontSize: 16, fontWeight: 550, marginLeft: 10}}>Tags Passivas</p>
                //                     {renderTagPassiva()}
                //                 </div>
                //                 <div className='d-flex button-add mt-5' onClick={() => {
                //                 setTransition(!transition);
                //                 setMode('controle');
                //                 }}>
                //                     <MdOutlineAddToHomeScreen className='icon-add' />
                //                     <div>
                //                         <p className='title-add'>Cadastrar novo controle</p>
                //                         <p className='subtitle-add'>Faça o cadastro de um novo controle para o veículo selecionado</p>
                //                     </div>
                //                 </div>
                //                 <div className='d-flex button-add' onClick={() => {
                //                 setTransition(!transition)
                //                 setMode('tagpassiva');
                //                 }}>
                //                     <MdOutlineAddToHomeScreen className='icon-add' />
                //                     <div>
                //                         <p className='title-add'>Cadastrar nova tag passiva</p>
                //                         <p className='subtitle-add'>Faça o cadastro de uma nova tag passiva para o veículo selecionado</p>
                //                     </div>
                //                 </div>
                //                 <div className='d-flex button-add' onClick={() => hide()}>
                //                     <BiLogOut className='icon-add' />
                //                     <div>
                //                         <p className='title-add'>Voltar</p>
                //                     </div>
                //                 </div>
                //             </div>
                //             )}
                //             </div>
                //         </CSSTransition>
                //     </SwitchTransition>
                // </TransitionDiv>
            }

            <ModalRelatorioControle show={openRelatorio} hide={() => setOpenRelatorio(false)} tipo={tipoRelatorio}  />

            {/* <Drawer
                    placement={'right'}
                    open={openRelatorio}
                    onClose={() => setOpenRelatorio(false)}
                    style={{width: 450}}
                >
                    <div>
                        <Title style={{ background: 'transparent' }} className={'d-flex justify-content-between'}>
                            <span>RELATÓRIO DE CADASTRO</span>
                        </Title>
                        
                        <TitleRelatorio>
                            <span>Relatório dos dispositivos com sucesso</span>
                        </TitleRelatorio>
                        <Content>
                            <ListGroup variant="flush">
                                {dispositivosStore?.relatorio?.acertos.map((item, i) => (
                                <ListGroup.Item key={i} className="pl-0">
                                    <div className="media ml-4 d-flex">
                                        <Icon className='align-self-center' style={{marginRight: 5}}><BsBookmarkCheck /></Icon>
                                        <div className="media-body pl-2">
                                            {item.local}
                                            <div className="d-flex align-items-center align-content-center justify-content-between">
                                            <Notificacao>{item.notificacao}</Notificacao>
                                            </div>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Content>

                        <TitleRelatorio>
                            <span>Relatório dos dispositivos com erros</span>
                        </TitleRelatorio>
                        <Content>
                            <ListGroup variant="flush">
                                {dispositivosStore?.relatorio?.erros.map((item, i) => (
                                <ListGroup.Item key={i} className="pl-0">
                                    <div className="media d-flex">
                                        <Icon><MdErrorOutline/></Icon>
                                        <div className="media-body pl-2">
                                            {item.local}
                                            <div className="d-flex align-items-center align-content-center justify-content-between">
                                            <Notificacao>Erro ao coletar</Notificacao>
                                            </div>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Content>
                        <div className='d-flex justify-content-center m-3'>
                            <button type={'button'} className='btn btn-success' onClick={() => setOpenRelatorio(false)} style={{ borderRadius: 60 }}>Fechar</button>
                        </div>
                    </div>
            </Drawer> */}

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    if(dispositivosStore.controleSelecionado)
                    {
                        handleClose();
                        deletarControle(dispositivosStore.controleSelecionado.veiculoId, dispositivosStore.controleSelecionado.serial);
                    }
                }}>Deletar controle</MenuItem>
            </Menu>
            
        </Container>
    );
}

export default observer(_Controle);