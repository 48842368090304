import { observer } from 'mobx-react-lite'
import React, { FC, useState, useEffect, useContext } from 'react'
import { ContainerLista, HeaderItem } from '../styles/Guarita.style'
import { VscLayersActive } from 'react-icons/vsc'
import { Button, Dropdown, IconButton, InputPicker } from 'rsuite'
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PlusIcon from '@rsuite/icons/Plus';
import { StoreContext } from '../../../stores'
import { log } from 'console'
import { Content } from '../styles/Mip1000.style'
import { BsInfo } from 'react-icons/bs'
import CadastrarMip1000 from './CadastrarMip1000'
import AtualizarMip1000 from './AtualizarMip1000'
import CadastrarRele from './rele/CadastrarRele'
import AtualizarRele from './rele/AtualizarRele'
import { Table } from 'react-bootstrap'
import ExportIcon from '@rsuite/icons/Export';
import { useMip1000 } from '../../../hooks/Mip100.hook'
import ModalConfirmacao from '../../utils/ModalConfirmacao'
import { toast } from 'react-toastify'

interface Props
{
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>
}


const Mip1000Listagem : FC<Props> = ({ index, setIndex }) => {

  const { mip1000Store, condominioStore, themeStore } = useContext(StoreContext);
  const { deletar, deletando } = useMip1000();
  const [cadastrarMip, setCadastrarMip] = useState(false);
  const [atualizarMip, setAtualizarMip] = useState(false);
  const [cadastrarRele, setCadastrarRele] = useState(false);
  const [atualizarRele, setAtualizarRele] = useState(false);
  const [modalDeletarMip1000, setModalDeletarMip1000] = useState(false);
  const [route, setRoute] = useState('mip1000');

  const toggleCadastrarMip = () => setCadastrarMip(!cadastrarMip);
  const toggleAtualizarMip = () => setAtualizarMip(!atualizarMip);

  const toggleCadastrarRele = () => setCadastrarRele(!cadastrarRele);
  const toggleAtualizarRele = () => setAtualizarRele(!atualizarRele);
  const toggleModalDeletarMip1000 = () => setModalDeletarMip1000(!modalDeletarMip1000);

  const deletarMMip1000Selecionado = () =>
  {
      if(mip1000Store.selecionado) {
          deletar(mip1000Store.selecionado);
      }
  }

  useEffect(() =>
  {
    if(route === 'mip1000' && index === 4)
    {
      if(condominioStore.selecionado)
        mip1000Store.listarPorCondominio(condominioStore.selecionado.id);
    }
  }, [index]);

  const renderList = () =>
  {
    return(
        <div className='box-table'>
          <div className='mt-2'>
              <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                  <thead>
                      <tr>
                          <th scope="col">Nome guarita</th>
                          <th scope="col">Ip</th>
                          <th scope="col">Porta API</th>
                          <th scope="col">Usuário</th>
                          <th scope="col">Senha</th>
                          <th scope="col">Modelo</th>
                          <th scope="col">Muid</th>
                          <th scope="col">Relé</th>
                          <th scope="col">Ação</th>
                      </tr>
                  </thead>
                  <tbody>
                      {
                          mip1000Store.mip1000.map((guarita, i) => (
                          <tr key={i} onClick={() => mip1000Store.definirSelecionado(guarita.id)}> 
                              <td>{guarita.nome}</td>
                              <td>{guarita.ip}</td>
                              <td>{guarita.porta}</td>
                              <td>{guarita.usuario}</td>
                              <td>{guarita.senha}</td>
                              <td>{guarita.modelo}</td>
                              <td><Button size='xs' appearance='primary' color='violet' loading={mip1000Store.loading} onClick={() => 
                              {
                                if(!guarita.muid)
                                  mip1000Store.obterMuId(guarita.id);
                                else
                                  toast.warning("MuId já habilitado");
                              }}>{guarita.muid ? "Habilitado" : "Obter MuID"}</Button></td>
                              <td>
                                <Button size='xs' appearance='primary' color='violet' onClick={() =>
                                {
                                  mip1000Store.definirSelecionado(guarita.id)
                                  toggleCadastrarRele();
                                }}>Abrir</Button>
                              </td>
                              <td>
                              <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                  <Dropdown.Item onClick={() => {
                                      toggleAtualizarMip();
                                  }}>Atualizar</Dropdown.Item>
                                  <Dropdown.Item onClick={() => {
                                      toggleModalDeletarMip1000();
                                  }}>Deletar</Dropdown.Item>
                              </Dropdown>
                              </td>
                          </tr>
                          ))
                      }
                  </tbody>
              </Table>
          </div>
      </div>
      )
  }

  const renderRele = () =>
  {
    return(
      <div className='container p-1'>
        {/* <p className='subtitle-rele'>Informações adicionais:</p>
        <div className='d-flex content'>
          <div className='align-self-center'><BsInfo className='icon-info' /></div>
          <p className='info-rele'>Selecione o sistema guarita MIP 1000 - IP para ver as configurações do rele do equipamento</p>
          <InputPicker cleanable={false} placeholder={'Aba de navegação'} value={route} data={[{ label: 'MIP1000 IP - Lista', value: 'mip1000' }, { label: 'MIP1000 IP - Rele', value: 'rele' }]} onChange={(e) => setRoute(e)} style={{ width: 300 }} />
        </div> */}
      </div>
    )
  }

  const renderRoute = () =>
  {
    switch(route)
    {
      case 'mip1000':
        return renderList();
      case 'rele':
        return renderRele();
    }

    return(
      <div>ERRO</div>
    )
  }

  const renderButtonCadastro = () =>
  {
    switch(route)
    {
      case 'mip1000':
        return(
          <div className='align-self-center mt-2' style={{marginLeft: 20}}>
              <p style={{margin: 0, width: 150}}>Cadastre novo sistema guarita IP no condomínio</p>
            <IconButton appearance="primary" color="violet" icon={<PlusIcon />}  style={{marginBottom: 10}} onClick={toggleCadastrarMip}>
                Cadastrar novo MIP1000 IP
            </IconButton>
          </div>
        )
      case 'rele':
        return(
          <div className='align-self-center mt-2' style={{marginLeft: 20}}>
              <p style={{margin: 0, width: 200}}>Cadastre novo rele no sistema guarita IP no condomínio</p>
            <IconButton appearance="primary" color="violet" icon={<PlusIcon />}  style={{marginBottom: 10}} onClick={toggleCadastrarRele}>
                Cadastrar novo RELE no MIP1000 IP
            </IconButton>
          </div>
        )
    }
    return(
      <div>
        ERRO
      </div>
    )
  }

  return (
    <div>
      <ContainerLista className=''>
        <HeaderItem>
            <div className='d-flex box'>
                  <div className='content d-flex align-self-center'>
                      <VscLayersActive className='content-icon' />
                      <div>
                          <p className='subtitle'>Campo de cadastro e configurações do sistema MIP1000 IP</p>
                      </div>
                  </div>

                  <div style={{marginLeft: 30}} className='align-self-center'>
                      <p className='info-title'>Voltar para a lista</p>
                      <IconButton appearance="primary" color="violet" icon={<PagePreviousIcon />} onClick={() => setIndex(0)}>
                          Voltar
                      </IconButton>
                  </div>

                  {renderButtonCadastro()}
                  
              </div>
          </HeaderItem>


          <Content>
            {renderRoute()}
          </Content>
      </ContainerLista>


      <CadastrarMip1000 show={cadastrarMip} hide={toggleCadastrarMip} />
      <AtualizarMip1000 show={atualizarMip} hide={toggleAtualizarMip} />

      <CadastrarRele show={cadastrarRele} hide={toggleCadastrarRele} />
      <AtualizarRele show={atualizarRele} hide={toggleAtualizarRele} />

      <ModalConfirmacao show={modalDeletarMip1000} hide={toggleModalDeletarMip1000} action={deletarMMip1000Selecionado} content={'Você tem certeza que deseja deletar esse MIP1000?'} loading={deletando} />

    </div>
  )
}

export default observer(Mip1000Listagem);