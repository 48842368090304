import api from '../services/api/api';
import { Autorizacao, Visitante, VisitanteRequest } from '../interfaces/Visitante.interface';

export interface TipoVisitante {
    id: string;
    descricao: string;  
  }

class VisitanteAPI {

  listarAutorizacaoPorCondominio = async (condominioId: string) => await api.get<Autorizacao[]>(`/Visitante/autorizacao/condominio/${condominioId}`);

  listarAutorizacaoPorUnidade = async (unidadeId: string) => await api.get<Autorizacao[]>(`/Visitante/autorizacao/unidade/${unidadeId}`);

  listarPorCondominio = async (condominioId: string) => await api.get<Visitante[]>(`/Visitante/condominio/${condominioId}`);

  listarPorUnidade = async (unidadeId: string) => await api.get<Visitante[]>(`/Visitante/unidade/${unidadeId}`);

  salvar = async (request: Visitante) => await api.post<Visitante>('/Visitante', request);

  atualizar = async (request: Visitante) => await api.put<Visitante>(`/Visitante`, request);

  visitantePorNome = async (nome: string) => await api.get<Visitante[]>(`/Visitante/nome/${nome}`);  

  deletar = async (visitanteId: string) => await api.delete<Visitante>(`/Visitante/${visitanteId}`);

  filtrarVisitanteId = async (visitanteId: string) => await api.get<Visitante>(`/Visitante/id/${visitanteId}`);

  listarVisitanteEncerrado = async (unidadeId: string) => await api.get(`/Visitante/retorno/${unidadeId}`);

  retornarVisitante = async (request: Visitante) => await api.put<Visitante>(`/Visitante/retorno`, request);

  confirmarChegadaAutorizado = async (visitanteId: string) => await api.get(`/Visitante/confirmarvisitante`, { params: {
     visitanteId
  } });

  listarTipoVisitante = async () => api.get<TipoVisitante[]>(`/TipoVisitante`);
}

export default new VisitanteAPI();