import { useContext, useState } from "react";
import { toast } from "react-toastify";
import AcionamentoAPI from "../api/AcionamentoAPI";
import GuaritaAPI from "../api/GuaritaAPI";
import { StoreContext } from "../stores";
import { Acionamento } from "../interfaces/Acionamento.interface";
import { Guarita } from "../interfaces/Guarita.interface";


const useAcionamento = () => {

    const { acionamentoStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);

    const cadastrar = async (acionamento: Acionamento, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await AcionamentoAPI.cadastrarAcionamento(acionamento);
            if(status === 200) {
                acionamentoStore.acionamentos.push(data);
                toast.success('Acionamento cadastrado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar Acionamenot');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const atualizar = async (acionamento: Acionamento, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await AcionamentoAPI.atualizarAcionamento(acionamento);
            if(status === 200) {
                acionamentoStore.acionamentos = acionamentoStore.acionamentos.map( aci => aci.id === acionamento.id ? acionamento : aci );
                toast.success('Acionamento atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar acionamento');
                setAtualizando(false);
            }
        } catch(e) {
            setAtualizando(false);
            //
        }
    }

    const deletar = async (acionamento: Acionamento) => {
        setDeletando(true);
        try {
            const { status } = await AcionamentoAPI.deletarAcionamento(acionamento.id);
            if(status === 200) {
                acionamentoStore.acionamentos = acionamentoStore.acionamentos.filter( aci => aci.id !== acionamento.id );
                toast.success('Acionamento deletado com sucesso');
                setDeletando(false);
            } else {
                toast.error('Erro ao deletado acionamento');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
            //
        }
    }

    const atualizarOrdemAcionamento = async (acionamento: Acionamento[]) => {
        setAtualizando(true);
       
        const novaOrdem = {
            novaOrdem: acionamento.map(i => ({id: i.id, nome: i.nome, ordem: i.ordem}))
        }
      
        try {
          const { data } = await AcionamentoAPI.atualizarOrdem(novaOrdem as any);
          if(data === true) {
            toast.success(`Ordem do acionamento alterado com sucesso`);
            setAtualizando(false);
            // acionamentoStore.listar()
        } else {
            toast.error(`Erro ao alterar a ordem do acionamento`);
            setAtualizando(false);
          }
        } catch(e) {
        toast.error(`Erro ao alterar a ordem do acionamento`);
          setAtualizando(false);
        }
      }


    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
        atualizarOrdemAcionamento
    }

}


export default useAcionamento;