import styled from 'styled-components';
import PerfectScroll from 'react-perfect-scrollbar';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.bgColor};
  padding: 20px;
`

export const Header = styled.div`
  background: ${(props) => props.theme.colors.bgBox};
  border-radius: 8px;
  padding: 10px;
  // display: flex;
   // border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  .title {
      font-size: 12pt;
      font-weight: 550;
      margin: 0;
  }
  .subtitle {
      font-size: 9pt;
      margin: 0;
  }
  .btn-add-sip {
      background: #004C95;
      color: #fff;
      margin-right: 30px;
      border: 0;
      font-size: 11pt;
  }
`

export const ListSip = styled(PerfectScroll)`
  margin-top: 5px;
  align-self: center;
  border-radius: 10px;
  height: 65vh;
  // border-radius: 12px;
  margin-top: 8px;
  thead th {
    font-weight: 0;
  // background: ${(props) => props.theme.colors.bgBox};
  font-size: 13px;
    padding: 15px;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  } 
  tbody td {
    font-size: 12px;
  border: 0;
  padding: 16px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`
