import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AiOutlineReload } from 'react-icons/ai';
import { BiCar, BiDoorOpen } from 'react-icons/bi';
import { BsMegaphone, BsTrash } from 'react-icons/bs';
import { FaWpforms } from 'react-icons/fa'
import { MdOutlineDirectionsCar } from 'react-icons/md';
import { VscMegaphone } from 'react-icons/vsc';
import { Button, Input, InputPicker, Modal, Radio, RadioGroup } from 'rsuite';
import useAcionamento from '../../hooks/Acionamento.hook';
import { useBloco } from '../../hooks/Bloco.hook';
import { Acionamento, AcionamentoRequest } from '../../interfaces/Acionamento.interface';
import { Bloco, BlocoRequest } from '../../interfaces/Bloco.interface';
import { StoreContext } from '../../stores';
import { Icone } from './styles/Acionamento.style';

interface ModalProps {
    show: boolean;
    hide: () => void;
    acionamentoSelecionado?: Acionamento | null;
}

const ordem = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

const AtualizarAcionamento: FC<ModalProps> = ({ show, hide, acionamentoSelecionado }) => {

    const { cameraStore, layoutStore } = useContext(StoreContext);
    const [form, setForm] = useState<AcionamentoRequest>({});
    const { atualizar, atualizando } = useAcionamento();
    const [selectLayout, setSelectLayout] = useState('');

    useEffect(() => {
        if(acionamentoSelecionado) {
            setForm(acionamentoSelecionado);
        }
    }, [acionamentoSelecionado]);

    useEffect(() =>
    {
        if(show && acionamentoSelecionado)
        {
            const primeiroLayout = layoutStore.layouts[layoutStore.layouts.length - 1];
            if(primeiroLayout) cameraStore.listarPorLayout(primeiroLayout.id);
        }
    }, [show, acionamentoSelecionado]);

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as Acionamento, hide);
    }

    const FormAcionamento = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className=''>
                    <div className='m-1'>
                        <p><span className='text-danger'>*</span>Nome do acionamento</p>
                        <Input required placeholder='Informe o nome do acionamento' value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })} />
                    </div>

                    <div className='m-1'>
                        <p><span className='text-danger'>*</span>Tipo</p>
                        <InputPicker block aria-required data={[
                            {
                                label: 'Central',
                                value: 0
                            },
                            {
                                label: 'Todos',
                                value: 1
                            },
                            {
                                label: 'App',
                                value: 2
                            },
                            {
                                label: 'Nenhum',
                                value: 3
                            },
                        ]} placeholder={'Informe o tipo'} value={form.tipo} onChange={(e) => setForm({ ...form, tipo: e })} />
                    </div>
                </div>
                    <div className='m-1'>
                        <p><span className='text-danger'>*</span>Ordem</p>
                        <Input required placeholder='Informe a ordem do acionamento' value={form.ordem} onChange={(e) => setForm({ ...form, ordem: parseInt(e) })} />
                    </div>

                    <div className='m-1'>
                        <p><span className='text-danger'>*</span>Câmera</p>
                        {
                            layoutStore.layouts.length > 1
                            ?
                            <div>
                                {
                                    layoutStore.layouts.map((layout, i) =>
                                        <Form.Check 
                                            key={i}
                                            type={'checkbox'}
                                            id={i.toString()}
                                            label={`${layout.nomeDGuard}`}
                                            onChange={(e) => {
                                                setSelectLayout(layout.id);
                                                cameraStore.listarPorLayout(layout.id);
                                            }}
                                            checked={selectLayout === layout.id ? true : false }
                                        />
                                    )
                                }
                                <>
                                    {
                                        cameraStore.loading
                                        ?
                                        'CAREGANDO CAMERAS...'
                                        :
                                        <InputPicker block aria-required data={cameraStore.cameras.map((camera => ({ label: camera.nomeDGuard, value: camera.id })))} placeholder={'Informe a câmera'} value={form.cameraId || ''} onChange={(e) => setForm({ ...form, cameraId: e })} />
                                    }
                                </>
                            </div>
                            :
                            <>
                                {
                                    cameraStore.loading
                                    ?
                                    <div>
                                        CARREGANDO CAMERAS...
                                    </div>
                                    :
                                    <InputPicker block aria-required data={cameraStore.cameras.map((camera => ({ label: camera.nomeDGuard, value: camera.id })))} placeholder={'Informe a câmera'} value={form.cameraId || ''} onChange={(e) => setForm({ ...form, cameraId: e })} />
                                }
                            </>
                        }
                    </div>

                    <div>
                    <RadioGroup name="radioList" value={form.icone} onChange={(e) => setForm({ ...form, icone: e.toString() })} inline>
                        <Radio value="BiDoorOpen"><Icone><BiDoorOpen className='icon' /></Icone></Radio>
                        <Radio value="BiCar"><Icone><BiCar className='icon' /></Icone></Radio>
                        <Radio value="BsTrash"><Icone><BsTrash className='icon' /></Icone></Radio>
                        <Radio value="AiOutlineReload"><Icone><AiOutlineReload className='icon' /></Icone></Radio>
                        <Radio value="VscMegaphone"><Icone><VscMegaphone className='icon' /></Icone></Radio>
                    </RadioGroup>
                    </div>
                <div className='d-flex justify-content-end mt-2'>
                    <Button className='' type="button" onClick={hide}>
                        Fechar
                    </Button>
                    <Button appearance="primary" disabled={atualizando} className='' type="submit" style={{marginLeft: 10}}>
                        { atualizando ? 'Salvando...' : 'Salvar' }
                    </Button>
                </div>
                {atualizando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
                </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar acionamento {acionamentoSelecionado?.nome}</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormAcionamento()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarAcionamento);