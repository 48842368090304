import { readFile } from "fs";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import BlocoAPI from "../api/BlocoAPI";
import ComandoAPI from "../api/ComandoAPI";
import ReleAPI from "../api/ReleAPI";
import { StoreContext } from "../stores";
import { Bloco } from "../interfaces/Bloco.interface";
import { Comando } from "../interfaces/Comando.interface";
import { Rele } from "../interfaces/Rele.interface";


export const useComando = () => {

    const { comandoStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [success, setSuccess] = useState(false);
    
    const cadastrar = async (comando: Comando, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await ComandoAPI.cadastrarComando(comando);
            if(status === 200) {
                comandoStore.comandosPorAcionamento.push(data);
                setSalvando(false);
                hide();
                setSuccess(true);
                toast.success('Comando cadastrado com sucesso!')
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar comando')
        }
    }

    const atualizar = async (comando: Comando, acionamentoId: string, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await ComandoAPI.atualizarComando(comando);
            if(status === 200) {
                comandoStore.listarPorAcionamento(acionamentoId);
                setAtualizando(false);
                hide();
                setSuccess(true);
                toast.success('Comando atualizado com sucesso!')
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar comando')
        }
    }

    const atualizarNovaOrdem = async (novaOrdem: Comando[]) => {
        setAtualizando(true);
        try {
            const { status } = await ComandoAPI.atualizarNovaOrdemComando(novaOrdem);
            if(status === 200) {
                // comandoStore.comandosPorAcionamento = novaOrdemLista;
                setAtualizando(false);
                setSuccess(true);
                toast.success('Ordem atualizado com sucesso!')
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar as ordem do comando')
        }
    }

    const _deletar = async (comando: Comando) => {
        setDeletando(true);
        try {
            const { status } = await ComandoAPI.deletarComando(comando.id);
            if(status === 200) {
                comandoStore.comandosPorAcionamento = comandoStore.comandosPorAcionamento.filter(cmd => cmd.id !== comando.id);
                setDeletando(false);
                toast.success('Comando deletado com sucesso!')
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar comando')
        }
    }
    
    return {
        cadastrar,
        atualizar,
        _deletar,
        salvando,
        atualizando,
        deletando,
        success,
        atualizarNovaOrdem
    }

}