import styled from 'styled-components';

export const Container = styled.div`
    .title
    {
        margin: 0;
        padding-bottom: 5px;
    }

    padding: 20px;
    width: 97%;
    // .content
    // {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     padding: 20px;
    //     // height: 80vh;
    // }
    // .net
    // {
    //     margin: 0;
    //     color: ${(props) => props.theme.colors.text};
    //     font-size: 8pt;
    // }
    // .input
    // {
    //     background: red;
    // }
    .form-select
    {
        background: ${(props) => props.theme.colors.bgColor};
        color: ${(props) => props.theme.colors.text};
        padding: 7px;
        border: 1px solid ${(props) => props.theme.colors.borderColor};     
    }
`

export const Header = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    // border-bottom: 1px solid rgba(0,0,0,0.0800);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 1px 3px 3px rgba(0,0,0,0.0800);
    .title
    {
        margin: 0;
        font-size: 10pt;
        font-weight: 550;
    }
    .subtitle
    {
        margin: 0;
        font-size: 9pt;
    }
`;
