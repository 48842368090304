import { Layout, LayoutDGuard, ServidorDGuard } from "../interfaces/Layout.interface";
import api from "../services/api/api";

class LayoutAPI {

    autenticarDGuard = async (url: string, usuario: string, senha: string) => await api.get(url, { responseType:"blob", auth: { username: usuario, password: senha } });

    listarPorId = async (layoutId: string) => await api.get<Layout>(`/Layout/id${layoutId}`);

    listarLayoutDGuard = async () => await api.get<LayoutDGuard[]>(`/Layout/servidoresDguard`);

    ObterServidorDguard = async (id: string) => await api.get<ServidorDGuard>(`/ServidorDGuard/${id}`);

    listarServidoresDGuard = async () => await api.get<ServidorDGuard[]>(`/ServidorDGuard`);

    listarLayoutPorCondominioId = async (condominioId: string) => await api.get<Layout[]>(`/Layout/condominioId/${condominioId}`);

    cadastrarLayout = async (request: Layout) => await api.post(`/Layout`, request);

    atualizarLayout = async (request: Layout) => await api.put(`/Layout`, request);

    deletarLayout = async (layoutId: string) => await api.delete(`/Layout/${layoutId}`);

}

export default new LayoutAPI();