import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { Button, Modal } from 'rsuite';
import { useRede } from '../../hooks/Rede.hook';
import { Rede, RedeRequest } from '../../interfaces/Rede.interface';
import { StoreContext } from '../../stores';
import { FormContainer } from './styles/ModalCadastrar.style';

interface PropsModal {
    show: boolean;
    hide: () => void;
}

const ModalAtualizar: FC<PropsModal> = ({ show, hide }) => {
    
    let inputRef = useRef<HTMLInputElement>(null);
    const { redeStore } = useContext(StoreContext);
    const { atualizar, atualizando, deletar, deletando } = useRede();
    
    const [form, setForm] = useState<RedeRequest>({});

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as Rede, hide);
    }

    useEffect(() => {
        if(redeStore.selecionado)
        {
            setForm(redeStore.selecionado);
        }
    }, [redeStore.selecionado]);

    useEffect(() => {
        if(inputRef.current && show)
        {
            inputRef.current.focus();
        }
    }, [show]);

    const renderForm = () => {
        return(
            <FormContainer onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Subrede</Form.Label>
                        <Form.Control ref={inputRef} required type="text" className='shadow-none input' placeholder="192.168.0.0/10" value={form.subRede || ''} onChange={(e) => setForm({...form, subRede: e.target.value})} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-between'>
                    <Button  color="red" appearance="primary" type={'button'} disabled={deletando} className='btn btn-outline-danger shadow-none mt-2' onClick={() => {
                        if(redeStore.selecionado) deletar(redeStore.selecionado, hide);
                    }}>{deletando ? 'Deletando...' : 'Deletar'}</Button>
                    
                    <div className='mt-2'>
                        <Button appearance="primary" disabled={atualizando} type="submit">
                            { atualizando ? 'Salvando...' : 'Salvar' }
                        </Button>
                        <Button disabled={atualizando} type="button" onClick={hide} style={{marginLeft: 10}}>
                            Fechar
                        </Button>
                    </div>
                </div>
                </FormContainer>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar dados da rede {redeStore.selecionado?.subRede}</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que a atualização possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{renderForm()}</Modal.Body>
        </Modal>
    )
}

export default observer(ModalAtualizar);