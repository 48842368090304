import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import ChatAPI from '../api/ChatAPI';
import { Mensagem, StatusMensagemUsuarios } from '../interfaces/Chat';

class ChatStore {

    mensagens: Mensagem[] = [];
    selecionado: Mensagem | null = null;
    ultimoStatusMensagem: StatusMensagemUsuarios | null = null;
    historicoMensagem: StatusMensagemUsuarios[] = [];
    selecionadoMensagem: StatusMensagemUsuarios | null = null;
    novasMensagem: StatusMensagemUsuarios[] = [];
    loading: boolean = false;
    loadingMensagem: boolean = false;
    visualizado: boolean = false;
    historicoGeral: boolean = false;

    constructor() {
        makeAutoObservable(this)
        this.historicoGeral = false;
    }

    async listarnovasMensagens(centralDeAtendimento: string) {
        this.loading = true;
        try {
            const { data } = await ChatAPI.ObterStatusChatUsuarios(centralDeAtendimento);
            runInAction(() => {
                this.novasMensagem = data;
                this.loading = false;
            })
        } catch(e) {
            this.loading = false;
        }
    }

    async listarHistorico(centralDeAtendimento: string) {
        this.loading = true;
        try {
            const { data } = await ChatAPI.historicoMensagens(centralDeAtendimento);
            runInAction(() => {
                this.historicoMensagem = data;
                this.loading = false;
            })
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(mensagemId: string) {
        var selecionado = this.mensagens?.find((msn) => msn.id === mensagemId);
        if(selecionado) 
        {
            this.selecionado = selecionado;
        }
        // if(mensagem) {
        //     this.selecionado = mensagem
        // }
    }

    verificarVisualizacao(state: boolean) {
        this.visualizado = state
    }

    async selecionarMensagem(mensagem: StatusMensagemUsuarios | undefined) {
        this.loadingMensagem = true;
        try
        {
            if(mensagem)
            {
                const { data } = await ChatAPI.ObterMensagensPorUsuario(mensagem.condominioId, mensagem.usuarioId, 1, 100);
                runInAction(() => {
                    this.mensagens = data;
                    this.loadingMensagem = false;
                });
                this.loadingMensagem = false;
            }
            this.loadingMensagem = false;
        }
        catch(e)
        {
            
            toast.error('Erro ao selecionar mensagem');
            this.loadingMensagem = false;
        }
    }

    async definirMensagemSelecionado(mensagem: StatusMensagemUsuarios | null, tipo: string) {

        if(tipo === 'nova')
        {
            var selecionado = this.novasMensagem.find((n) => n === mensagem);
            if(selecionado)
            {
                this.selecionadoMensagem = selecionado;
            }
        }
        else if(tipo === 'historico')
        {
            var selecionado = this.historicoMensagem.find((h) => h === mensagem);
            
            if(selecionado)
            {
                this.selecionadoMensagem = selecionado;
            }
        }
    }


    toggleOpen()
    {
        this.historicoGeral = !this.historicoGeral;
    }
    
    // async definirHistoricoSelecionado(historico: StatusMensagemUsuarios | null) {
        // var selecionado = this.historicoMensagem.find((h) => h === historico);
        // if(selecionado)
        // {
        //     this.selecionadoHistorico = selecionado;
        // }
    // }

}

export default ChatStore;