import { useContext, useState } from "react";
import { toast } from "react-toastify";
import BlocoAPI from "../api/BlocoAPI";
import { StoreContext } from "../stores";
import { Bloco } from "../interfaces/Bloco.interface";


export const useBloco = () => {

    const { blocoStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [success, setSuccess] = useState(false);
    const [erro, setErro] = useState(false);
    
    const cadastrar = async (bloco: Bloco, hide: () => void) => {
        setSalvando(true);
        try {
            const { data } = await BlocoAPI.cadastrar(bloco);
            blocoStore.blocos.push(data);
            setSalvando(false);
            hide();
            toast.success('Bloco cadastrado com sucesso');
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar bloco');

        }
    }

    const cadastrarMultiplos = async (bloco: Bloco[], hide: () => void) => {
        setSalvando(true);
        try {
            const { data } = await BlocoAPI.cadastrarMultiplos(bloco);
            blocoStore.blocos = blocoStore.blocos.concat(data);            
            setSalvando(false);
            hide();
            toast.success('Bloco cadastrado com sucesso');
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar bloco');

        }
    }

    const atualizar = async (bloco: Bloco, hide: () => void) => {
        setSalvando(true);
        try {
            await BlocoAPI.atualizar(bloco);
            blocoStore.blocos = blocoStore.blocos.map(i => i.id === bloco.id ? bloco : i);
            setSalvando(false);
            hide();
            setSuccess(true);
            toast.success('Bloco atualizado com sucesso');

        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao atualizar bloco');
        }
    }

    const deletar = async (bloco: Bloco) => {
        setSalvando(true);
        try {
            await BlocoAPI.deletar(bloco.id);
            blocoStore.blocos = blocoStore.blocos.filter(i => i.id !== bloco.id);
            setSalvando(false);
            toast.success('Bloco deletado com sucesso');

        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao deletar bloco');

        }
    }
    
    return {
        cadastrar,
        cadastrarMultiplos,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
        success,
        erro
    }

}