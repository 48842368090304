import { Backdrop, CircularProgress, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import { borderBottom, borderRadius } from '@mui/system';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react-lite';
import react, { FC, useContext, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FaArrowDown } from 'react-icons/fa';
import MoradorAPI from '../../api/MoradorAPI';
import UsuarioAPI from '../../api/UsuarioAPI';
import { StoreContext } from '../../stores';
import { useMorador } from '../../hooks/Morador.hook';
import { Morador, MoradorRequest } from '../../interfaces/Morador.interface';
import { Unidade } from '../../interfaces/Unidade.interface';
import { cpfMask, maskCelular, maskTelefoneFixo } from '../utils/mask';
import { Visitante, VisitanteRequest } from '../../interfaces/Visitante.interface'
import { CardValidation, ContainerFormMorador, InforPessoal } from './styles/Autorizacao';
import VisitanteAPI from '../../api/VisitanteAPI';
import { useVisitante } from '../../hooks/Visitante.hook';
import { Button, ButtonGroup, DatePicker, Input, Modal, SelectPicker, Steps } from 'rsuite';
import validacao from '../../assets/img/validacao-morador.png';

export interface TipoVisitante {
    id: string;
    descricao: string;  
}

interface PropsMorador {
    show: boolean;
    hide: () => void;
}


const AtualizarVisitante: FC<PropsMorador> = ({ show, hide }) => {

    let condominioId = localStorage.getItem('condominio');
    const { moradorStore, unidadeStore, visitanteStore } = useContext(StoreContext);
    const { atualizar, atualizando } = useVisitante();
    const [form, setForm] = useState<VisitanteRequest>({});
    const [tipoVisitante, setTipoVisitante] = useState<TipoVisitante[]>([]);
    const [modoConfirmacao, setModoConfirmacao] = useState('');
    const [step, setStep] = useState(0);
    const onChange = nextStep => {
      setStep(nextStep < 0 ? 0 : nextStep > 2 ? 2 : nextStep);
    };
  
    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    useEffect(() => {
      (async () => {
          const { data } = await VisitanteAPI.listarTipoVisitante();
          if (data) setTipoVisitante(data)
          else setTipoVisitante([]);
      })()
  }, []);

    useEffect(() => {
        if(show && visitanteStore.selecionado){
            setForm(visitanteStore.selecionado);
            setStep(0);
        }
    }, [show, visitanteStore.selecionado]);

    const clear = () =>
    {
      setForm({ });
    }

    const salvar = () => {
        form.autorizado = true;
        atualizar(form as Visitante, hide);
    }

    const loading = () => {
        if(atualizando) {
            return(
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop> 
            )
        }
    }

    const renderModoConfirmacao = () =>
    {
      switch(modoConfirmacao)
      {
        case 'cpf':
          return(
            <div className='mt-2'>
              <p className='label'><span className='text-danger'>*</span>Cpf do autorizado</p>
              <Input
                placeholder="cpf"
                value={form.cpf || ''}
                onChange={(e) => setForm({ ...form, cpf: cpfMask( e ) })}
              />
            </div>  
          )
        case 'nascimento':
          return(
            <div className='mt-2'>
              <p className='label'><span className='text-danger'>*</span>Data de nascimento do autorizado</p>
              <DatePicker format="dd-MM-yyyy"
                placeholder="Nascimento"
                style={{width: 500}}
                onChange={(e) => {
                  if(e)
                  {
                    setForm({ ...form, nascimento: e })
                  }
                }}
              />
            </div>  
          )
        case 'celular':
          return(
            <div className='mt-2'>
              <p className='label'><span className='text-danger'>*</span>Celular do autorizado</p>
              <Input
                placeholder="Celular"
                value={form.celular || ''}
                onChange={(e) => setForm({ ...form, celular: maskCelular( e ) })}
              />
            </div>  
          )
      }
  
      return(
        <div/>
      )
    }

    const renderDadosVisitante = () =>
    {
      return(
        <>
          <div className=''>
            <p className='label'><span className='text-danger'>*</span>Nome do autorizado</p>
            <Input
                placeholder='Nome do visitante' 
                minLength={3}
                value={form.nome || ''}
                onChange={(e) => setForm({ ...form, nome: e.toUpperCase() })}
                required
            />    
            </div>

            <div className='row mt-1'>
              <div className='col'>
                  <p className='label'><span className='text-danger'>*</span>Informe o tipo do visitante</p>
                  <SelectPicker
                  block
                  placeholder={'Informe o tipo'}
                  data={tipoVisitante.slice().sort((a, b) => a.descricao > b.descricao ? 1 : -1).map((visitante) => ({ label: visitante.descricao, value: visitante.id }))}
                  value={form.tipoId || ''}
                  onChange={(e) => {
                      if(e) setForm({ ...form, tipoId: e });
                  }}
                  />
              </div>

              <div className='col'>
                  <p className='label'><span className='text-danger'>*</span>Morador que autorizou</p>
                  <SelectPicker
                  block
                  placeholder={'Informe o tipo'}
                  data={moradorStore.moradores.map((morador) => ({ label: morador?.nome?.toLocaleUpperCase(), value: morador.id }))}
                  value={form.moradorId || ''}
                  onChange={(e) => {
                      if(e) setForm({ ...form, moradorId: e });
                  }}
                  />
              </div>
            </div>

            <div className='header mt-2'>
              <p>Observação - Opcional</p>
            </div>

            <Input as="textarea" rows={3} placeholder="Observação para esse visitante. Isso é opcional!" onChange={(e) => setForm({ ...form, obs: e})}  />
        </>
      )
    }

    const renderDataHora = () =>
    {
      return(
        <>
          <div className='row mt-1'>
            <div className='col'>
                <p className='label'><span className='text-danger'>*</span>DataHora entrada</p>
                <Input
                    required
                    type="datetime-local"
                    value={form.dataHoraEntrada || ''}
                    onChange={(e) => setForm({ ...form, dataHoraEntrada: e})} 
                />
            </div>

            <div className='col'>
                <p className='label'><span className='text-danger'>*</span>DataHora saída</p>
                <Input
                required
                type="datetime-local"
                value={form.dataHoraSaida || ''}
                onChange={(e) => setForm({ ...form, dataHoraSaida: e})} 
                />
            </div>
            </div>

            <div className='header mt-2'>
            <p>Informação de confirmação - Opcional</p>
            </div>

            <SelectPicker
            block
            placeholder={'Selecione o modo de confirmação do autorizado'}
            data={[{ label: 'CPF', value: 'cpf' }, { label: 'nascimento', value: 'nascimento' }, { label: 'Celular', value: 'celular' }]}
            onChange={(e) => {
                if(e) setModoConfirmacao(e);
            }}
            />

            {renderModoConfirmacao()}
        </>
      )
    }

    const renderStatus = () =>
    {

        return(
            <CardValidation className='d-flex justify-content-center'>
                <div className='d-flex justify-content-center'>
                    <img src={validacao} className='app' />
                    <div className='align-self-center'>
                        <p className='title-app'>Finalizar cadastro</p>
                        <p className='subtitle-app'>Clique no botão abaixo para cadastrar esse novo visitante na unidade <b>{unidadeStore.selecionado?.andar} - {unidadeStore.selecionado?.blocoNome.toUpperCase()}</b></p>
                        <div className="d-flex justify-content-end mt-3">
                          <div className='d-flex'>
                            <Button type="submit" appearance='primary' color={'blue'} disabled={atualizando || !form.moradorId || !form.tipoId} onClick={salvar} loading={atualizando}>{ atualizando ? 'Salvando...' : 'Salvar' }</Button>
                            <Button onClick={hide} style={{marginLeft: 10}}>Fechar</Button>
                          </div>
                        </div>
                    </div>
                </div>
            </CardValidation>
        )
    }

    const renderStep = () =>
    {
        switch(step)
        {
            case 0:
                return renderDadosVisitante();
            case 1:
                return renderDataHora();
            case 2:
                return renderStatus();
        }
    }
    
    const renderForm = () =>
    {
        return(
            <ContainerFormMorador onSubmit={salvar}>
              <div>
                <Steps current={step}>
                    <Steps.Item title="Visitante" description="Dados do visitante" onClick={() => setStep(0)} />
                    <Steps.Item title="Acessos" description="Definir data e hora de entrada e entrada saída" onClick={() => setStep(1)} />
                    <Steps.Item title="Status" description="Validação de dados" onClick={() => setStep(2)} />
                </Steps>
                {renderStep()}
                <div className='d-flex justify-content-center mt-2'>
                    <div>
                        <ButtonGroup>
                            <Button onClick={onPrevious} disabled={step === 0 || atualizando}>
                            Voltar
                            </Button>
                            <Button onClick={onNext} disabled={step === 2 || atualizando}>
                            Próximo
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            {/* {loading()}
                <div className='header'>
                <p>Dados pessoais</p>
                </div>


                
                {
                salvando
                ?
                <LinearProgress style={{height: 1, width: '100%', position: 'absolute', bottom: 0, left: 0}} color='primary' />
                :
                ''
                }  */}
            </ContainerFormMorador>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header>
                <div>
                    <p className='modal-styled-title'>Atualizar visitante autorizado</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            {renderForm()}
        </Modal>
    )
}

export default observer(AtualizarVisitante);