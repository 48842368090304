import { Component, FC } from 'react';
import { Routes, Route, HashRouter, Navigate } from 'react-router-dom';
import Login from '../views/login/Login';
import Home from '../views/home/Home';
import Dashboard from '../views/dashboard/Dashboard';
import CondominiosView from '../views/CondominioView';
import { observer } from 'mobx-react-lite';
import jwtDecode from 'jwt-decode';
import LayoutCameras from '../components/layout/LayoutCameras';
import AutenticacaoDoisFatores from '../views/altenticacaodoisfatores';
import PageNotFound from '../views/page_not_found';
import Octos from '../views/octos';
import RelatorioEventos from '../components/relatorios/RelatorioEventos';

interface IPrivateRoute {
  children: any;
}

const isAuthenticated = () => {
  var token = localStorage.getItem("token");
  if (token !== null) {
    var decoded: any = jwtDecode(token);
    var current_time = new Date().getTime() / 1000;
    if (current_time > decoded.exp) {
      return false;
    }
    return true;
  }
}
export const PrivateRoute: FC<IPrivateRoute> = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to={'/'} />
}

const ComponentRoutes = () => {
    return(
        <HashRouter basename='/'>
            <Routes>
                <Route path={'/condominios/*'} 
                  element={
                    <PrivateRoute>
                      <CondominiosView/>
                    </PrivateRoute>
                  } 
                />
                <Route path={'/camerasdguard/visualizacao'}
                  element={
                    <PrivateRoute>
                      <LayoutCameras/>
                    </PrivateRoute>
                  }
                />
                <Route path={'/pushes'}
                  element={
                    <PrivateRoute>
                      <AutenticacaoDoisFatores/>
                    </PrivateRoute>
                  }
                />
                <Route path={'/'} element={<Login/>} />
                <Route path={'/relatorios/eventos'} element={<RelatorioEventos/>} />
                <Route path={'*'} element={<PageNotFound/>} />
            </Routes>
        </HashRouter>
    );
}

export default ComponentRoutes;