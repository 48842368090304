import { makeAutoObservable, runInAction } from "mobx";
import ReleAPI from "../api/ReleAPI";
import { Rele } from "../interfaces/Rele.interface";

class ReleStore {

    reles: Rele[] = [];
    selecionado: Rele | null = null;
    relePorId: Rele | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listar(moduloId: string) {
        try {
            this.loading = true;
            const { data } = await ReleAPI.listarRelePorModulo(moduloId);
            runInAction(() => {
                if(data) this.reles = data;
                else this.reles = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async listarPorId(releId: string) {
        try {
            this.loading = true;
            const { data } = await ReleAPI.listarRelePorId(releId);
            runInAction(() => {
                this.relePorId = data;
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async definirSelecionado(releId: string | null) {
        let selecionado = this.reles.find((rel) => rel.id === releId);
        if(selecionado) {
            this.selecionado = selecionado;
        } else {
            this.selecionado = null;
        }
    }

}

export default ReleStore;