import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Sidenav } from 'rsuite';
import { Navbar } from 'react-bootstrap';

interface Props
{
    active?: string;
}

export const Container = styled.div`
    /* background: ${(props) => props.theme.colors.bgColor}; */
    width: 100%;
    // height: 100vh;
    // @media(max-height: 800px)
    // {
    //     height: 100%;
    // }
`


export const NavigationBar = styled(Navbar)`
    padding: 0px;
    margin: 0px;
    border-bottom: 1px solid ${props => props.theme.colors.borderColor}
`
export const Dashboard = styled.div`
    .chat1
    {
        padding: 10px;
        background: ${(props) => props.theme.colors.bgBox};
        border-radius: 10px;
    }
    .doughnut
    {
        
        height: 90%;
    }
    .chat2
    {
        padding: 10px;
        background: ${(props) => props.theme.colors.bgBox};
        border-radius: 10px;
        height: 260px;
    }

    @media(max-height: 800px)
    {
        height: 79%;
        .doughnut
        {
            
            height: 90%;
        }
    }
`

export const Content = styled(PerfectScrollBar)`
    // margin: 20px;
    // padding: 12px;
    width: 100%;
    height: 73vh;
    // aligh-self: center;
    `;
    // background: red;


export const HeaderNavigation = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    margin-top: -10px;
    padding: 10px;
    .title
    {
    font-size: 12pt;
    margin: 0;
    font-weight: 550;
    }
    .subtitle
    {
    font-size: 9pt;
    margin: 0;
    }

    .box
    {
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    margin-top: 5px;
    }

    .content
    {
    padding: 20px;
    margin-top: 10px;
    width: 250px;
    }
    .content-icon
    {
    align-self: center;
    font-size: 25pt;
    margin-right: 20px;
    }
    .content-title
    {
    font-size: 10pt;
    margin: 0;
    font-weight: 550;
    }
    .content-subtitle
    {
    font-size: 9pt;
    margin: 0;
    }

    .info-title
    {
    font-size: 8pt;
    width: 80%;
    }
    .info-title-filtro
    {
    font-size: 8pt;
    width: 50%;
    }
`

export const Navigation = styled.div<Props>`
    margin-top: 60px;
    border-right: 1px solid ${(props) => props.theme.colors.borderColor};
    width: 30%;
    aligh-self: center;
    .content
    {
        padding: 30px;
        transition: 0.2s;

    }
    .content:hover
    {
        background: ${(props) => props.theme.colors.bgBox};
        cursor: pointer;
        transition: 0.2s;
    }
    .title
    {
        margin: 0;
        font-size: 12pt;
        font-weight: 600;
    }
    .subtitle
    {
        margin: 0;
        font-size: 8pt;
        font-weight: 200;
    }
`;


export const ListCondominio = styled.div`
    // max-height: 89vh;
    // align-self: center;
    // padding: 20px;
    // border: 1px solid ${(props) => props.theme.colors.borderColor};
    border-radius: 8px;
    .header {
        background: ${(props) => props.theme.colors.bgBox};
        padding: 10px;
        box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
        margin: 10px;
        border-radius: 8px;
        // display: flex;
        // justify-content: space-between;
    }
`

export const ListView = styled(PerfectScrollBar)`
    .content
    {
        padding: 5px;
        background: ${(props) => props.theme.colors.bgBox};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .title
    {
        margin: 0;
        font-size: 10pt;
        font-weight: 550;
    }
    .subtitle
    {
        margin: 0;
        font-size: 9pt;
    }

    ._link {
        text-decoration: none;
        color: ${(props) => props.theme.colors.text};
        display: flex;
        margin-bottom: 2px;
        padding-left: 20px;
        padding: 11px;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
        // transition: 0.2s;
        // font-size: 10pt;
    }
    ._link:hover {
        transition: 0.2s;
        padding-left: 15px;
    }
    .icon {
        align-self: center;
        color: ${(props) => props.theme.colors.text};
        // margin-right: 10px;
    }
    .item {
        font-weight: 500;
        text-decoration: none;
        background: blue:
        padding: 10px;
        margin: 0;
        align-self: center;
        margin-left: 10px;
    }

    thead th {
    font-weight: 0;
  // background: ${(props) => props.theme.colors.bgBox};
  font-size: 13px;
    padding: 10px;
    // border-bottom: 1px solid ${(props) => props.theme.colors.bgNav};
  } 
  tbody td {
    font-size: 12px;
  border: 0;
  padding: 15px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }

    @media(max-width: 1400px) { 
        height: 91%;
        margin: 10px;
        ._link {
            text-decoration: none;
            display: flex;
            padding: 5px;
            border-bottom: 1px solid rgba(0,0,0,0.0600);
            transition: 0.2s;
            margin-bottom: 2px;
            border-radius: 8px;
            font-size: 10pt;
        }
        ._link:hover {
            transition: 0.2s;
            padding-left: 15px;
            background: rgba(0,0,0,0.0800);
        }
        .icon {
        }
        .item {
            font-weight: 500;
            text-decoration: none;
            background: blue:
            padding: 10px;
            margin: 0;
            align-self: center;
            margin-left: 10px;
            font-size: 9pt;
        }
    
    }

`;