import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import BravasAPI from "../api/BravasAPI";
import GuaritaAPI from "../api/GuaritaAPI";
import ModuloAPI from "../api/ModuloAPI";
import { Bravas, BravasCapturaControle, BravasGruposCadastrado, BravasInfoacessoCadastrado, BravasListaAcessoCadastrado, BravasRele } from "../interfaces/Bravas.interface";
import { Guarita, GuaritaModulos } from "../interfaces/Guarita.interface";
import { Modulo } from "../interfaces/Modulo.interface";

class BravaStore {

    bravas: Bravas[] = [];
    rele: BravasRele | null = null;
    reles: BravasRele[] = [];
    acessos: BravasListaAcessoCadastrado | null = null;
    grupos: BravasGruposCadastrado | null = null;
    infoAcesso: BravasInfoacessoCadastrado | null = null;
    captura: BravasCapturaControle | null = null;
    selecionado: Bravas | null = null;
    loading: boolean = false;
    loadingAcesso: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listar() {
        this.loading = true;
        let condominioId = localStorage.getItem('condominio')
        try {
            if(condominioId) {
                const { data } = await BravasAPI.listarPorCondominio(condominioId);
                runInAction(() => {
                    if(data) this.bravas = data;
                    else this.bravas = [];
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarAcessosCadastrado(equipamentoId: string, onSelect: React.Dispatch<React.SetStateAction<string>> | any) {
        this.loading = true;
        try {
            if(equipamentoId) {
                const { data } = await BravasAPI.listarAcessosPorEquipamentoId(equipamentoId);
                runInAction(async () => {
                    this.acessos = data;
                    if(onSelect !== null && this.acessos.config !== null) 
                        onSelect(data.config.accesses[0])
                    if(this.acessos.config !== null)
                        await this.obterInformaçãoAcesso(equipamentoId, data.config.accesses[0]);
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async obterInformaçãoAcesso(equipamentoId: string, nome: string) {
        this.loadingAcesso = true;
        try {
            if(equipamentoId) {
                const { data } = await BravasAPI.ObterInformacaoAcesso(equipamentoId, nome);
                runInAction(() => {
                    this.infoAcesso = data;
                    this.loadingAcesso = false;
                });
            }
        } catch(e) {
            this.loadingAcesso = false;
        }
    }

    async listarGruposCadastrado(equipamentoId: string) {
        this.loading = true;
        try {
            if(equipamentoId) {
                const { data } = await BravasAPI.listarGruposCasdastrado(equipamentoId);
                runInAction(() => {
                    this.grupos = data;
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async capturarSerial(equipamentoId: string) {
        this.loading = true;
        try {
            if(equipamentoId) {
                const { data } = await BravasAPI.capturarSerial(equipamentoId);
                runInAction(() => {
                    this.captura = data;
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async obterInfoBravasRele(id: string) {
        this.loading = true;
        try {
            const { data } = await BravasAPI.obterInformacaoReleBravas(id);
            runInAction(() => {
                this.rele = data;
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async listarRelesPorBravasId(id: string) {
        this.loading = true;
        try {
            const { data } = await BravasAPI.listarRelesPorBravasId(id);
            runInAction(() => {
                this.reles = data;
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(bravasId: string) {
        let selecionado = this.bravas.find((b) => b.id === bravasId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
        else this.selecionado = null;
    }

}

export default BravaStore;