import { Maquina } from "../interfaces/Ramal.interface";
import { Usuario } from "../interfaces/Usuario.interface";
import api from "../services/api/api";


interface NovaSenha
{
    usuarioId: string;
    novaSenha: string;
    renovar: boolean;
}

interface EsqueceuSenha
{
    dados: string;
    primeiroAcesso: boolean;
}

const baseURL = process.env.REACT_APP_BASE_MS_AUTHENTICATION;

class UsuarioAPI {
    listarUsuarioPorId = async (usuarioId: string) => await api.get<Usuario>(`/Usuarios/${usuarioId}`);
    verificarCpf = async (cpf: string) => await api.get<Usuario>(`/Usuarios/${cpf}`);
    listarUsuarios = async () => await api.get<Usuario[]>(`/Usuarios`);
    atualizarUsuario = async (request: Usuario) => await api.put<Usuario>(`/Usuarios`, request);
    deletarUsuario = async (usuarioId: string) => await api.delete(`/Usuarios/${usuarioId}`);
    listarUsuariosRamais = async () => await api.get<Maquina[]>(`/Maquina`);
    atualizarAuthentication = async (usuarioId: string, novaSenha: string) => 
    {
        var _novaSenha: NovaSenha =
        {
            usuarioId: usuarioId,
            novaSenha: novaSenha,
            renovar: true
        }
        // return await api.put(`http://192.168.10.68:5101/atualizarSenhaAdmin`, _novaSenha);
        return await api.put(`${baseURL}/atualizarSenhaAdmin`, _novaSenha);
        // return await api.put(`http://192.168.19.101/auth/atualizarSenhaAdmin`, _novaSenha);
    }
    atualizarOperacaoAuthentication = async (usuarioId: string, novaSenha: string) => 
    {
        var _novaSenha: NovaSenha =
        {
            usuarioId: usuarioId,
            novaSenha: novaSenha,
            renovar: false
        }
        // return await api.put(`http://192.168.10.68:5101/atualizarSenhaAdmin`, _novaSenha);
        return await api.put(`${baseURL}/atualizarSenha`, _novaSenha);
        // return await api.put(`http://192.168.19.101/auth/atualizarSenhaAdmin`, _novaSenha);
    }
    esqueceuSenhaAuthentication = async (dados: string) => 
    {
        var esqueceuSenha: EsqueceuSenha =
        {
            dados: dados,
            primeiroAcesso: true
        }
        // return await api.put(`http://192.168.10.68:5101/atualizarSenhaAdmin`, _novaSenha);
        return await api.post(`${baseURL}/esqueceuSenhaOperacional`, esqueceuSenha);
        // return await api.put(`http://192.168.19.101/auth/atualizarSenhaAdmin`, _novaSenha);
    }
}

export default new UsuarioAPI();