import { Avatar, Chip, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState, useEffect } from 'react';
import { BsBroadcast, BsCaretLeft, BsDiagram3, BsX } from 'react-icons/bs';
import { StoreContext } from '../../stores';
import CadastrarPerfil from './CadastrarPerfil';
import { Acionamentos, Container, Header, List, Tipo } from './perfil.style/Perfil.style';
import { BiDotsHorizontalRounded, BiEdit, BiX } from 'react-icons/bi';
import AtualizarPerfil from './AtualizarPerfil';
import { useAcionamentoPerfil } from '../../hooks/AcionamentoPerfil.hook';
import Empty from '../utils/Empty';
import { VscGithubAction } from 'react-icons/vsc';
import { Button, Dropdown, IconButton, Timeline } from 'rsuite';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import PlusIcon from '@rsuite/icons/Plus';
import NotFound from '../utils/NotFound';
import SkeletonBlock from '../utils/SkeletonBlock';

const Perfil = () => { 

    const { acionamentoPerfilStore } = useContext(StoreContext);
    const { deletar, deletando } = useAcionamentoPerfil();
    const [modalDeletar, setModalDeletar] = useState(false);
    const [cadastrarPerfil, setCadastrarPerfil] = useState(false);
    const [editarPerfil, setEditarPerfil] = useState(false);

    const toggleCadastrar = () => setCadastrarPerfil(!cadastrarPerfil);
    const toggleEditar = () => setEditarPerfil(!editarPerfil);

    const toggleDeletar = () => setModalDeletar(!modalDeletar);

    const deletarPerfil = () =>
    {
        if(acionamentoPerfilStore.selecionado) {
            deletar(acionamentoPerfilStore.selecionado);
        }
    }

    useEffect(() =>
    {
        acionamentoPerfilStore.listar();
    }, []);

    const renderPerfis = () => {

        if(acionamentoPerfilStore.loading)
        {
            return <SkeletonBlock/>
        }

        if(acionamentoPerfilStore.perfis.length === 0) {
            return <Empty message='Nenhum perfil cadastrado nesse condomínio' />
        }

        return(
            <div className='content-list d-flex flex-wrap'>
                {
                    acionamentoPerfilStore.perfis.map((perfil, i) => (
                        <Timeline className='content' align={'left'} onClick={() => acionamentoPerfilStore.definirSelecionado(perfil.id)}>
                            <Timeline.Item>
                            <div className='mt-3 d-flex justify-content-between mb-2' style={{width: 340}}>
                                <p style={{fontWeight: 550, fontSize: 15}}>{perfil.nome} <BsCaretLeft/> Nome do perfil</p>
                                <Dropdown appearance='primary' color={'violet'} title="Ação" size={'xs'}>
                                    <Dropdown.Item onClick={toggleEditar}>Atualizar</Dropdown.Item>
                                    <Dropdown.Item onClick={toggleDeletar}>Deletar</Dropdown.Item>
                                </Dropdown>
                            </div>
                            {
                                perfil.acionamentos.map((acionamento, i) => 
                                {
                                    if(!acionamento) return <NotFound message='Nenhum acionamento adicionado' />
                                    return(
                                        <p className='m-0 mt-1'><span style={{fontWeight: 550}}>{acionamento.nome}</span> <BsCaretLeft/> Nome do acionamento</p>
                                    )
                                })
                            }
                            </Timeline.Item>
                        </Timeline>
                    ))
                }
            </div>
        )
    }

    return(
        <Container>
            <Header className='d-flex justify-content-between'> 
                <div>
                    <p className='title'>Perfil</p>
                    <p className='m-0'>Àrea de cadastro de perfis</p>
                </div>
                <div style={{marginLeft: 20, alignSelf: 'center'}}>
                    <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrar}>
                        Adicionar novo perfil
                    </IconButton>
                </div>
            </Header>

            <List>
                {
                    renderPerfis()
                }
            </List>

            <CadastrarPerfil show={cadastrarPerfil} hide={toggleCadastrar} />
            <AtualizarPerfil show={editarPerfil} hide={toggleEditar} perfilSelecionado={acionamentoPerfilStore.selecionado} />

            {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    toggleEditar();
                }}>Atualizar</MenuItem>
                <MenuItem onClick={() => {
                    toggleDeletar();
                    handleClose();
                }}>Deletar</MenuItem>
            </Menu> */}

            <ModalConfirmacao show={modalDeletar} hide={toggleDeletar} action={deletarPerfil} content={'Você tem certeza que deseja deletar esse perfil?'} loading={deletando} />

        </Container> 
    )
}


export default observer(Perfil);