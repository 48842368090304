import styled from 'styled-components';

export const Container = styled.div`
    .title-hikvision {
        margin-left: 16px;
        margin-right: 16px;
        font-size: 12pt;
        font-weight: 550;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    }
    .content {
        padding: 16px;
        display: flex;
        transition: 0.2s;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    }
    .content:hover {
        background: rgba(0,0,0,0.0800);
        transition: 0.2s;
        transform: scale(1.0300)
    }
    .icon {
        font-size: 17pt;
        margin-right: 10px;
        color: ${(props) => props.theme.colors.primary};
    }
    .title {
        font-size: 11pt;
        color: ${(props) => props.theme.colors.primary};
        margin: 0;
        font-weight: 550;
    }
    .subtitle {
        font-size: 9pt;
        margin: 0;
        width: 70%;
        color: ${(props) => props.theme.colors.text};
    }
`;