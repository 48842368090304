import { observer } from 'mobx-react-lite'
import { useContext, useState, useEffect } from 'react'
import { Button, Input, Loader, SelectPicker } from 'rsuite';
import { useCondominio } from '../../hooks/Condominio.hook';
import { Condominio, CondominioAuxiliar, CondominioAuxiliarRequest, CondominioRequest } from '../../interfaces/Condominio.interface';
import { StoreContext } from '../../stores';
import { Container, Header } from './styles/ConfiguracaoCondominio.style';
import { Form } from 'react-bootstrap';

const ConfiguracaoCondominio = () => {

    const token = process.env.REACT_APP_BASE_TOKEN_CONFIG;
    const { condominioStore } = useContext(StoreContext);
    const { atualizar, atualizando, atualizarCondominioAuxiliar, atualizando2 } = useCondominio();
    const [form, setForm] = useState<CondominioRequest>({});
    const [form2, setForm2] = useState<CondominioAuxiliarRequest>({});
    const [status, setStatus] = useState('ok');

    // useEffect(() =>
    // {
    //     let _token = window.prompt("informe o token");
    //     if(_token === token)
    //     {
    //         setStatus('ok');
    //     }
    //     else 
    //         setStatus('error');
    // }, []);

    const update = () => {
        atualizar(form as Condominio, () => { })
    }

    useEffect(() => {
        if (condominioStore.selecionado)
        {
            setForm2({
                ...form2,
                condominioId: condominioStore.selecionado.id
            })
            setForm(condominioStore.selecionado);
        }
        if(condominioStore.selecionado && condominioStore.selecionado.condominioAuxiliar)
        {
            setForm2(condominioStore.selecionado?.condominioAuxiliar);
        }
    }, [condominioStore.selecionado]);

    const renderComponent = () => {
        return (
            <div>
                <Header> 
                    <p className='title'>{condominioStore.selecionado?.nome}</p>
                    <p className='subtitle'>Configurações do condomínio</p>
                    <p className='info'>Preencha os campos para atualizar os dados desse condominío</p>
                </Header>

                <div className='mt-2'>
                    <p className='title'>Nome</p>
                    <Input placeholder='Nome do condomínio' className='input' value={form.nome} onChange={(e) => setForm({ ...form, nome: e })} />
                </div>

                <div className='mt-3'>
                    <p className='title'>Central de atendimento</p>
                    <SelectPicker
                        block
                        placeholder={'Selecione'}
                        onChange={(e) => setForm({ ...form, centralAtendimentoId: e as any })}
                        value={form.centralAtendimentoId || ''}
                        data={condominioStore.centrais.map((c) => ({ label: c.nome, value: c.id }))}
                    />
                </div>


                <Button disabled={atualizando} appearance="primary" className='mt-3' style={{ width: 200 }} onClick={update}>
                    {
                        atualizando
                            ?
                            <Loader title={'Salvando...'} />
                            :
                            'Salvar'
                    }
                </Button>


                <Header className='mt-3'>
                    <p className='subtitle'>Informações adicionais</p>
                    <p className='info'>Preencha os campos para atualizar os dados desse condominío</p>
                </Header>


                <div className='mt-3'>
                    <p className='title'>Canal de atendimento</p>
                    <SelectPicker
                        block
                        placeholder={'Selecione'}
                        value={form2.canal}
                        onChange={(e) => setForm2({ ...form2, canal: e as any })}
                        data={[{ label: 'VTCALL', value: 0 }, { label: 'DÍGITRO', value: 1 }]}
                    />
                </div>

                <div className='mt-3'>
                    <p className='title'>Tipo atendimento</p>
                    <SelectPicker
                        block
                        placeholder={'Selecione'}
                        value={form2.tipoPortaria}
                        onChange={(e) => setForm2({ ...form2, tipoPortaria: e as any })}
                        data={[{ label: 'Portaira remota', value: 0 }, { label: 'Portaria autônoma', value: 1 }]}
                    />
                </div>

                <div className='mt-3'>
                    <p className='title'>Endereço</p>
                    <Input placeholder='Nome do condomínio' className='input' value={form2.endereco} onChange={(e) => setForm2({ ...form2, endereco: e })} />
                </div>

                <div>
                    <p>Geolocalização do condomínio</p>
                </div>

                <div className='d-flex'>
                    <Form.Group className="p-1 col-md-6" controlId="formBasicEmail">
                        <Form.Label>Latitude</Form.Label>
                        <Input type="text" className='shadow-none' placeholder="0" value={form2.latitude} onChange={(e) => setForm2({ ...form2, latitude: e.replace(/[^0-9.,-]/g, '') })} />
                    </Form.Group>
                    <Form.Group className="p-1 col-md-6" controlId="formBasicEmail">
                        <Form.Label>Longitude</Form.Label>
                        <Input type="text" className='shadow-none' placeholder="0" value={form2.longitude} onChange={(e) => setForm2({ ...form2, longitude: e.replace(/[^0-9.,-]/g, '') })} />
                    </Form.Group>
                </div>
                <div className='d-flex'>
                    <Form.Group className="p-1 col-md-4" controlId="formBasicEmail">
                        <Form.Label>Altitude</Form.Label>
                        <Input type="text" className='shadow-none' placeholder="0" value={form2.altitude || ''} onChange={(e) => setForm2({ ...form2, altitude: e.replace(/[^0-9.,-]/g, '') })} />
                    </Form.Group>
                    <Form.Group className="p-1 col-md-4" controlId="formBasicEmail">
                        <Form.Label>Distância máxima</Form.Label>
                        <Input type="text" className='shadow-none' placeholder="0" value={form2.distanciaMax || ''} onChange={(e) => setForm2({ ...form2, distanciaMax: e.replace(/[^0-9.,-]/g, '') })} />
                    </Form.Group>
                    <Form.Group className="p-1 col-md-4" controlId="formBasicEmail">
                        <Form.Label>Altitude máxima</Form.Label>
                        <Input type="text" className='shadow-none' placeholder="0" value={form2.altitudeMax || ''} onChange={(e) => setForm2({ ...form2, altitudeMax: e.replace(/[^0-9.,-]/g, '') })} />
                    </Form.Group>
                </div>

                <Button disabled={atualizando2} appearance="primary" className='mt-3' style={{ width: 200 }} onClick={() => {
                    atualizarCondominioAuxiliar(form2 as CondominioAuxiliar, () => { })
                }}>
                    {
                        atualizando2
                            ?
                            <Loader title={'Salvando...'} />
                            :
                            'Salvar'
                    }
                </Button>
            </div>
        )
    }

    return (
        <Container className=''>
            {status === 'ok' ? renderComponent() : 'Token Inválido'}
        </Container>
    )
}

export default observer(ConfiguracaoCondominio);
