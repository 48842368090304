import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import react, { FC, FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Loader, Modal } from 'rsuite';
import { useBloco } from '../../hooks/Bloco.hook';
import { Bloco, BlocoRequest } from '../../interfaces/Bloco.interface';

interface ModalProps {
    show: boolean;
    hide: () => void;
    blocoSelecionado?: Bloco | null;
}

const AtualizarBloco: FC<ModalProps> = ({ show, hide, blocoSelecionado }) => {

    const [form, setForm] = useState<BlocoRequest>();
    const { atualizar, salvando, erro, success } = useBloco();
    const [status, setStatus] = useState('');
    const token = process.env.REACT_APP_BASE_TOKEN_BLOCOUNIDADE;

    useEffect(() => {
        if(blocoSelecionado) {
            setForm(blocoSelecionado);
        }
    }, [blocoSelecionado]);

    const salvar = () => {
        atualizar(form as Bloco, hide);
    }

    const FormBloco = () => {
        return(
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><span className='text-danger'>*</span>Nome do bloco</Form.Label>
                    <Input required type="text" className='shadow-none' placeholder="Nome" value={form?.nome || ''} onChange={(e) => setForm({ ...form, nome: e })} />
                    <Form.Text className="text-muted">
                    O campo acima informa a identificação do bloco no outkey
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label><span className='text-danger'>*</span>Identifcado NICE</Form.Label>
                    <Input required maxLength={4} type="text" className='shadow-none' placeholder="Identificado Nice" value={form?.identificadorNice || ''} onChange={(e) => setForm({ ...form, identificadorNice: e })}  />
                    <Form.Text className="text-muted">
                    Informe o identificado nice para ser salvo no sistema
                    </Form.Text>
                </Form.Group>
                {salvando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal backdrop={'static'} keyboard={false} open={show} onClose={hide}>
            <Modal.Header closeButton>
                {
                    status !== 'error'
                    ?
                    <div>
                        <p className='modal-styled-title'>Atualização do bloco</p>
                        <p className='modal-styled-subtitle'>
                        Atualizar bloco {blocoSelecionado?.nome.toUpperCase()}
                        </p>
                    </div>
                    :
                    <p className='modal-styled-title'>Token inválido</p>
                }
            </Modal.Header>
            <Modal.Body> { status !== 'error' ? FormBloco() : <p className=''>Você digitou as credenciais incorretamente.</p>}</Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" disabled={salvando || status === 'error'} onClick={salvar}>{ salvando ? <Loader title='Salvando...' /> : 'Salvar' }</Button>
                <Button appearance="default" disabled={salvando} onClick={hide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AtualizarBloco;