import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import GuaritaStore from '../../stores/GuaritaStore';
import useGuarita from '../../hooks/Guarita.hook';
import { useRele } from '../../hooks/Rele.hook';
import { GuaritaRequest, Guarita } from '../../interfaces/Guarita.interface';
import { Rele, ReleRequest } from '../../interfaces/Rele.interface';
import { Button, Input, Modal, Toggle } from 'rsuite';
import { observer } from 'mobx-react-lite';

interface PropsModal {
    show: boolean;
    hide: () => void;
    moduloId: string| undefined;
}

const CadastrarRele: FC<PropsModal> = ({ show, hide, moduloId }) => {
    
    const { cadastrar, salvando } = useRele();
    
    const [form, setForm] = useState<ReleRequest>({});

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        cadastrar(form as Rele, hide);
    }

    useEffect(() => {
        if(moduloId) {
            setForm({
                ...form,
                niceModuloId: moduloId,
                cameraId: '00000000-0000-0000-0000-000000000000',
                ordemExibicao: 0,
                nome: '0'
            });
        } 
    }, [moduloId]);

    const FormRele = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Identificação do relé</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Ex: Relé pedestre externo" onChange={(e) => setForm({...form, nome: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Número do relé</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="0000" onChange={(e) => setForm({...form, numeroRele: parseInt(e)})} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-center'>
                    <Toggle className='m-2' size="lg" checkedChildren="Não enviar alerta" unCheckedChildren="Enviar alerta" onChange={ e => setForm({ ...form, emitirAlerta: e }) } />
                    <Toggle className='m-2' size="lg" checkedChildren="Não enviar evento" unCheckedChildren="Enviar evento" onChange={ e => setForm({ ...form, emitirEvento: e }) } />
                </div>

                <div className='d-flex justify-content-end'>
                    <Button appearance='primary' disabled={salvando} type="submit">
                        { salvando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button style={{marginLeft: 10}} disabled={salvando} onClick={hide} type="button">
                        Fechar
                    </Button>
                </div>
                {salvando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
                </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastro de relé</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormRele()}</Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarRele);