import { Avatar, LinearProgress } from '@mui/material';
import React, { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { Button, Modal, SelectPicker } from 'rsuite';
import useGuarita from '../../hooks/Guarita.hook';
import useModulo from '../../hooks/Modulo.hook';
import { GuaritaRequest, Guarita } from '../../interfaces/Guarita.interface';
import { Modulo, ModuloRequest } from '../../interfaces/Modulo.interface';
import { StoreContext } from '../../stores';

interface PropsModal {
    show: boolean;
    hide: () => void;
    moduloSelecionado: Modulo | null;
}

const bits = [
    {
        label: 1,
        value: 1
    },
    {
        label: 2,
        value: 2
    },
    {
        label: 3,
        value: 3
    },
    {
        label: 4,
        value: 4
    },
    {
        label: 5,
        value: 5
    },
    {
        label: 6,
        value: 6
    },
    {
        label: 7,
        value: 7
    },
    {
        label: 8,
        value: 8
    }
];

const dispositivos = [
    {
        label: 'TX - (CONTROLE)',
        value: 1
    },
    {
        label: 'TA - (TAG ATIVO)',
        value: 2
    },
    {
        label: 'CT - (CTW, CTWB, CARTÃO)',
        value: 3
    },
    {
        label: 'BM - (BIOMETRIA)',
        value: 5
    },
    {
        label: 'TP - (TAG PASSIVO)',
        value: 6
    },
    {
        label: 'SN - (SENHA)',
        value: 7
    },
]

const AtualizarModulo: FC<PropsModal> = ({ show, hide, moduloSelecionado }) => {
    
    const { atualizar, atualizando } = useModulo();
    const { guaritaStore } = useContext(StoreContext);

    
    const [form, setForm] = useState<ModuloRequest>({});

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as Modulo, hide);
    }

    useEffect(() => {
        if(moduloSelecionado) {
            setForm(moduloSelecionado);
        }
    }, [moduloSelecionado]);

    const FormModulo = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                    <Form.Label><span className='text-danger'>*</span>Módulo guarita</Form.Label>
                    <SelectPicker
                        block
                        placeholder={'Selecione o módulo guarita'}
                        data={guaritaStore.guaritas.map(g => ({ label: g.nome, value: g.id }))}
                        value={form.niceId || ''}
                        onChange={(e) => 
                        {
                            if(e) setForm({ ...form, niceId: e })
                        }}
                    />
                </Form.Group>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Endereço CAN</Form.Label>
                        <SelectPicker
                            block
                            placeholder={'Selecione o ENDEREÇO CAN DO EQUIPAMENTO'}
                            data={bits}
                            value={form.enderecoCAN || ''}
                            onChange={(e) => {if(e) setForm({ ...form, enderecoCAN: parseInt(e.toString()) })}}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Dispositivos</Form.Label>
                        <SelectPicker
                            block
                            placeholder={'Selecione o tipo do dispositivo'}
                            data={dispositivos}
                            value={form.tipo || ''}
                            onChange={(e) => {if(e) setForm({ ...form, tipo: parseInt(e.toString()) })}}
                        />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end'>
                    <Button appearance='primary' disabled={atualizando} type="submit">
                        { atualizando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={atualizando} type="button" style={{marginLeft: 5}} onClick={hide}>
                        Fechar
                    </Button>
                </div>
                {atualizando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualização de módulo</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormModulo()}</Modal.Body>
        </Modal>
    )
}

export default AtualizarModulo;