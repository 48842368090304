import react from 'react';
import { runInAction, makeAutoObservable } from 'mobx';
import { Acionamento } from '../interfaces/Acionamento.interface';
import AcionamentoAPI from '../api/AcionamentoAPI';

class AcionamentoStore {

    acionamentos: Acionamento[] = [];
    selecionado: Acionamento | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
        this.definirSelecionado = this.definirSelecionado.bind(this)
    }

    async listar() {
        this.loading = true;
        try {
            let condominioAtual = localStorage.getItem('condominio');
            if(condominioAtual) {
                const { data } = await AcionamentoAPI.listarAcionamentosPorCondominio(condominioAtual);
                runInAction(() => {
                    if(data) this.acionamentos = data;
                    else this.acionamentos = [];
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }


    async definirSelecionado(acionamentoId: string | null) {
        let selecionado = this.acionamentos.find((aci) => aci.id === acionamentoId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
    }

}

export default AcionamentoStore;