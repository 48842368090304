import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Row as RowBase } from 'react-bootstrap';


export const Container = styled.div`
    width: 100%;
    position: fixed;
`
export const Header = styled.div`
    background: ${(props) => props.theme.colors.bgColor};
    .content
    {
        padding: 5px;
        margin-left: 20%;
    }
    .logo
    {
        height: 50px;
    }
    .title
    {
        align-self: center;
        margin: 0;
        padding-left: 10px;
        font-weight: 550;
        font-size: 15pt;
    }
    .subtitle
    {
        align-self: center;
        margin: 0;
        padding-left: 10px;
        font-size: 11pt;
        margin-top: -3px;
    }
`

export const Box = styled.div`
    padding: 5px;
    width: 60%;
    margin-top: 1%;
    .content
    {

    }
    .access
    {
        height: 400px;
    }
    .title
    {
        align-self: center;
        margin: 0;
        width: 75%;
        padding-left: 10px;
        font-weight: 600;
        font-size: 27pt;
    }
    .subtitle
    {
        align-self: center;
        margin: 0;
        padding-left: 10px;
        font-size: 11pt;
        width: 60%;
    }
    .left
    {
        width: 70%;
        align-self: center;
    }
    .center
    {
        width: 50%;
        align-self: center;
    }
    .right
    {
        // align-self: center;
        width: 50%;
        margin-top: 40px;
    }

    .title-auth
    {
        width: 60%;
    }
    .content-button
    {
        background: ${(props) => props.theme.colors.bgColor};
        border-radius: 4px;
        margin-top: 5px;
        box-shadow: 1px 3px 4px ${(props) => props.theme.colors.bgColor};
        padding: 5px;
    }
    .input-1
    {
        border: 0;
        box-shadow: none;
        background: ${(props) => props.theme.colors.bgColor};
    }
    .icon-send
    {
        align-self: center;
        font-size: 17pt;
        margin-right: 10px;
        cursor: pointer;
    }
    .icon-send:active
    {
        color:  ${(props) => props.theme.colors.primary};
    }

    
    .subtitle-auth
    {
        width: 60%;
        margin-top: 40px;
    }
`;