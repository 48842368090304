import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    .img
    {
        height: 300px;
    }
    .title
    {
        text-align: center;
        font-weight: 550;
        margin: 0;
        font-size: 22pt;
    }
    .subtitle
    {
        text-align: center;
        margin: 0;
        font-size: 9pt;
        width: 50%;
    }
`;