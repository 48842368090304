import { Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { useNotas } from '../../hooks/Nota.hook';
import { StoreContext } from '../../stores';
import NotaCadastrar from './NotaCadastrar';
import NotaModal from './NotaModal';
import { Container, ListNotas, Header } from './styles/NotasListagem.style';
import { converterDataHora } from '../../components/utils/mask';
import { Button, IconButton } from 'rsuite';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Nota, TipoNota } from '../../interfaces/Nota.interface';
import { CgArrowsV } from 'react-icons/cg';
import { Descricao } from './styles/NotaCadastrar.style';
import Loading from '../utils/Loading';
import NotFound from '../utils/NotFound';
import { arrayMoveImmutable } from 'array-move';
import NotaAPI from '../../api/NotaAPI';
import PlusIcon from '@rsuite/icons/Plus';
import { toast } from 'react-toastify';
import DragableIcon from '@rsuite/icons/Dragable';

interface ISortableList
{
    notas: Nota[];
    disabled: boolean;
    atualizarNota: (nota: Nota) => void;
}

interface ISortableItem
{
    nota: Nota;
    activeIcon: boolean;
    atualizarNota: (nota: Nota) => void;
}

const SortableItem = SortableElement(({ nota, activeIcon, atualizarNota } : ISortableItem) =>
  {

    const { notaStore } = useContext(StoreContext);

    return(
      <div onClick={() => {
        atualizarNota(nota);
      }} role={'button'}>
        {
          nota.tipo != 0
          ?
          <div className='d-flex descricao'>
          {
            !activeIcon ? <CgArrowsV style={{alignSelf: 'center', marginLeft: 6, marginRight: 6}} size={22} /> : ''
          }
          <div>
            <div>
              <p style={{fontSize: 18}}><b>Tipo:</b> {TipoNota[nota.tipo]}</p>
              <p><b>Descricao:</b></p>
              <p className={'descricao-content'}>{nota.descricao}</p>
            </div>
            <div className='mt-1'>
              <p className={'datahora'}>Criado por: <br/> {nota.usuario}</p>
              <p className={'datahora'}>Criado em:<br/> {converterDataHora(nota.validade)}</p>
            </div>
          </div>
        </div>
        :
        ''
        }
      </div>
    )
  }
) as any;

const SortableList = SortableContainer(({ notas, disabled, atualizarNota } : ISortableList) => {
    return (
      <div>
        {/* {notas?.filter((nota) => nota.tipo === 0).map((nota, i) => ( */}
        {notas?.map((nota, i) => (
          <SortableItem disabled={!disabled} atualizarNota={atualizarNota} activeIcon={!disabled} key={i} index={i} nota={nota} />
        ))}
      </div>
    );
}) as any;

const NotasListagem = () => {

  const { notaStore, condominioStore } = useContext(StoreContext);
  const [cadastrar, setCadastrar] = useState(false);
  const [atualizar, setAtualizar] = useState(false);
  const [ordenar, setOrdenar] = useState(false);

  const toggleCadastrar = () => setCadastrar(!cadastrar);
  const toggleAtualizar = () => setAtualizar(!atualizar);
  const toggleOrdenar = () => setOrdenar(!ordenar);
  
  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    notaStore.notas = arrayMoveImmutable(notaStore.notas, oldIndex, newIndex);
    notaStore.notas.map((_, i) => _.ordem = i);
    var novaOrdem = 
    {
      novaOrdemNotas: notaStore.notas
    }
    NotaAPI.atualizarOrdem(novaOrdem)
    .then((res) =>
    {
      toast.success("Ordem alterado com sucesso!");
    }).
    catch((e) =>
    {
      toast.error("Erro ao alterar notas");
    })
  };

  useEffect(() =>
  {
    if(condominioStore.selecionado)
      notaStore.listarNotasPorCondominio()
  }, [condominioStore.selecionado])

  const abrirNota = (nota: Nota) =>
  {
    toggleAtualizar();
    notaStore.definirSelecionado(nota.id)
  }

  const renderNotas = () => {
    if (notaStore.loading) {
      return <Loading message="Carregando notas..." />;
    }

    if (notaStore.notas.filter((n) => n.condominioId !== null).length === 0) {
      return (
        <div className="p-3">
          <NotFound message="Nenhuma nota encontrada para esse condomínio." />
        </div>
      );
    }

    return(
      <div>
        {/* <p style={{ padding: '5px', fontSize: '20px', fontWeight: 550 }}>Procedimento eclusa</p>
        <SortableList notas={notaStore.notas.filter(n => n.tipo === 1)} disabled={ordenar} atualizarNota={abrirNota} onSortEnd={({ oldIndex, newIndex }: any) => onSortEnd(oldIndex, newIndex)} /> */}

        <p style={{ padding: '5px', fontSize: '20px', fontWeight: 550 }}>Procedimento visitantes</p>
        <SortableList notas={notaStore.notas} disabled={ordenar} atualizarNota={abrirNota} onSortEnd={({ oldIndex, newIndex }: any) => onSortEnd(oldIndex, newIndex)} />

        {/* <p style={{ padding: '5px', fontSize: '20px', fontWeight: 550 }}>Procedimento morador</p>
        <SortableList notas={notaStore.notas.filter(n => n.tipo === 3)} disabled={ordenar} atualizarNota={abrirNota} onSortEnd={({ oldIndex, newIndex }: any) => onSortEnd(oldIndex, newIndex)} />

        
        <p style={{ padding: '5px', fontSize: '20px', fontWeight: 550 }}>Procedimento entrega</p>
        <SortableList notas={notaStore.notas.filter(n => n.tipo === 4)} disabled={ordenar} atualizarNota={abrirNota} onSortEnd={({ oldIndex, newIndex }: any) => onSortEnd(oldIndex, newIndex)} />
        
        <p style={{ padding: '5px', fontSize: '20px', fontWeight: 550 }}>Procedimento prestadores</p>
        <SortableList notas={notaStore.notas.filter(n => n.tipo === 5)} disabled={ordenar} atualizarNota={abrirNota} onSortEnd={({ oldIndex, newIndex }: any) => onSortEnd(oldIndex, newIndex)} /> */}

      </div>
    )
}

  return (
    <Container>
          <Header>
          <div className='d-flex justify-content-between'>
            <div>
                <p className='title'>Campo de cadastros de procedimentos</p>
                <p className='subtitle'>Cadastre novos procedimentos para ser exibida no sistema outkey</p>
            </div>
            <div className=''>
              <IconButton disabled={notaStore.notas.length === 0} appearance="primary" color="cyan" icon={<DragableIcon />} style={{marginRight: 10}} onClick={toggleOrdenar}>
                  Atualizar ordem das notas
              </IconButton>
              <IconButton appearance="primary" color="violet" size={'sm'} icon={<PlusIcon />} onClick={toggleCadastrar}>
                  Adicionar novo procedimento
              </IconButton>
            </div>
        </div>

        <ListNotas>
          {/* <Button appearance='primary' color={'blue'} className={'mt-2'} onClick={toggleOrdenar}>Alterar ordem das notas</Button> */}
          {renderNotas()}
        </ListNotas>
      </Header>

      
      <NotaCadastrar show={cadastrar} hide={toggleCadastrar} />
      <NotaModal show={atualizar} hide={toggleAtualizar} />
    </Container> 
  )
}

export default observer(NotasListagem);
