import axios from 'axios';
import jwt_decode from 'jwt-decode';

const baseURL = process.env.REACT_APP_BASE_MS_ACIONAMENTO;

var api = axios.create({ 
  baseURL : baseURL,
  headers: {
    "Content-Type": "application/json",
  }
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export default api;