import { Acionamento } from "../interfaces/Acionamento.interface";
import api from "../services/acionamento/api";

class AcionamentoAPI {

    listarAcionamentosPorCondominio = async (condominioId: string) => await api.get<Acionamento[]>(`/Acionamento/condominio/${condominioId}`);

    cadastrarAcionamento = async (request: Acionamento) => await api.post<Acionamento>(`/Acionamento`, request);

    atualizarAcionamento = async (request: Acionamento) => await api.put<Acionamento>(`/Acionamento`, request);

    deletarAcionamento = async (acionamentoId: string) => await api.delete(`/Acionamento/${acionamentoId}`);

    atualizarOrdem = async (request: Acionamento) => await api.put(`/Acionamento/novaOrdem`, request);

}

export default new AcionamentoAPI();