import styled from "styled-components";

export const Title = styled.p`
    margin: 0;
    fon-size: 15pt;
    font-weight: 550;
    color: ${(props) => props.theme.colors.text};
    `;
export const SubTitle = styled.p`
    margin: 0;
    font-size: 10pt;
    color: ${(props) => props.theme.colors.text};
    `;

export const FormCamera = styled.form`
    .info {
        font-size: 10pt;
        color: ${(props) => props.theme.colors.text};
        // margin-top: 15%;
    }
    .title {
        margin: 0;
        font-size: 10pt;
        font-weight: 550;
        color: ${(props) => props.theme.colors.text};
        margin-top: 20px;
    }
`;