import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_MS_DISPOSITIVO;

const api = axios.create({ baseURL });

api.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
} as any;

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config; 
});

export default api;