import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { InputPicker, SelectPicker, Pagination, Input, Button, Popover, Whisper, Modal, Loader, IconButton, InputGroup, DateRangePicker, DatePicker } from 'rsuite';
import { useDispositivo } from '../../hooks/Dispositivo.hook';
import { StoreContext } from '../../stores';
import { converterData, converterDataHora, converterHora, cpfMask, maskCelular, maskTelefoneFixo } from '../utils/mask';
import NotFound from '../utils/NotFound';
import RenderTipoLog from '../utils/RenderTipoLog';
import { Container, Content, Header, Scroll } from './styles/Relatorio.style';
import { Listagem } from './styles/RelatorioListagem.style';
import { BsPeople } from 'react-icons/bs';
import SearchIcon from '@rsuite/icons/Search';
import Loading from './Loading';
import DetailIcon from '@rsuite/icons/Detail';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import Workbook from 'react-excel-workbook';
import moment from 'moment';

const RelatorioVisitantes: FC = () => {

  const { condominioStore, unidadeStore, relatorioStore, themeStore } = useContext(StoreContext);

  const [search, setSearch] = useState('');
  const [inicio, setInicio] = useState<Date>();
  const [fim, setFim] = useState<Date>();
  const [containerHeight, setContainerHeight] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    const setHeight = () => {
      const windowHeight = window.innerHeight;
      setContainerHeight(windowHeight);
    };
    setSearch('');
    // relatorioStore.relatorioVisitante = [];
    window.addEventListener('resize', setHeight);
    setHeight();

    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  const filtro = relatorioStore.relatorioVisitante.filter(r =>
    r.nome?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.autorizado?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(search.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
    r.autorizadoPor?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(search.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
    r.dataHoraEntrada?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(search.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
    r.dataHoraSaida?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(search.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
    r.email?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(search.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
    r.descricao?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(search.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
    !search
).filter((v, i) => {
  const start = limit * (page - 1);
  const end = start + limit;
  return i >= start && i < end;
});


  const listar = () =>
  {
    if(condominioStore.selecionado && inicio && fim)
    {

      const dataInicio = moment(inicio).format('YYYY-MM-DDTHH:mm:ss');
      const dataFim = moment(fim).format('YYYY-MM-DDTHH:mm:ss');
      relatorioStore.obterRelatorioVisitantePorCondominioId(condominioStore.selecionado.id, dataInicio, dataFim);
    }
}
  
  const renderEventos = () =>
  {

    if(relatorioStore.loading)
    {
      return <Loading />
    }

    if(relatorioStore.relatorioVisitante.length === 0)
    {
      return <NotFound message={'Nenhum registro encontrado'} />
    }

    return(
        <Content>
            <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'}>
                <thead style={{padding: 15}} className='sticky-top'>
                    <tr> 
                        <th>Condominio</th>
                        <th>Unidade</th>
                        <th>Nome</th>
                        <th>Tipo visitante</th>
                        <th>Autorizado</th>
                        <th>Autorizado Por</th>
                        <th>Registrado Por</th>
                        <th>Entrada</th>
                        <th>Saída</th>
                        <th>Visitante Excluído</th>
                        <th>Morador Excluído</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filtro.map((f, i) =>
                        (
                            <tr key={i} className={'p-2'}>
                                <td>{f.condominio ? f.condominio : '-'}</td>
                                <td>{f.unidade ? f.unidade : '-'}</td>
                                <td>{f.nome ? f.nome : '-'}</td>
                                <td>{f.descricao ? f.descricao : '-'}</td>
                                <td>{f.autorizado ? f.autorizado : '-'}</td>
                                <td>{f.autorizadoPor ? f.autorizadoPor : '-'}</td>
                                <td>{f.registradoPor ? f.registradoPor : '-'}</td>
                                <td>{f.dataHoraEntrada}</td>
                                <td>{f.dataHoraSaida}</td>
                                <td>{f.visitanteExcluido ? f.visitanteExcluido : '-'}</td>
                                <td>{f.moradorExcluido ? f.moradorExcluido : '-'}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </Content>
      )
  }


  return (
    <Container>
        <Header>
            <div className='d-flex justify-content-between'>
                <div>
            </div>
            </div>

            <div className='d-flex box'>
                <div className='content d-flex'>
                    <BsPeople className='content-icon' />
                    <div>
                        <p className='content-title'>Relatório de visitantes do condomínio</p>
                        <p className='content-subtitle'>Clique no botão abaixo para puxar todos os dados</p>
                    </div>
                </div>

                <div className='d-flex mt-3'>
                  <div className='align-self-center'>
                    <div style={{marginLeft: 10}} className='align-self-center d-flex'>
                        <p className='info-title align-self-center'>Data Inicio:</p>
                        <DatePicker format="yyyy-MM-dd HH:mm" placeholder="Data de inicio" style={{ width: 200 }} onChange={(e) => 
                        {
                          if(e) setInicio(e)
                        }} />
                    </div>
                    
                    <div style={{marginLeft: 10}} className='align-self-center d-flex mt-1'>
                        <p className='info-title align-self-center'>Data Final:</p>
                        <DatePicker disabled={!inicio} format="yyyy-MM-dd HH:mm" placeholder="Data encerramento" style={{ width: 200 }} onChange={(e) => 
                        {
                          if(e) setFim(e)
                        }} />
                    </div>
                  </div>
                </div>


                <div style={{marginLeft: 10}} className='align-self-center mt-3'>
                    <p className='info-title'>Clique no botão abaixo para realizar a busca:</p>
                    <IconButton disabled={relatorioStore.loading || !inicio || !fim} appearance="primary" color="violet" icon={<SearchIcon />} onClick={listar}>
                        Buscar
                    </IconButton>
                </div>

                <div style={{marginLeft: '0px'}} className='align-self-center mt-3'>
                    <p className='info-title-filtro'>Campo de filtragem:</p>
                    <InputGroup inside>
                        <Input disabled={!relatorioStore.relatorioVisitante || relatorioStore.relatorioVisitante.length === 0 || relatorioStore.loading} placeholder='Buscar' value={search} onChange={(e) => {
                          setSearch(e);
                          setPage(1);
                        }} />
                        <InputGroup.Button disabled={!relatorioStore.relatorioVisitante || relatorioStore.relatorioVisitante.length === 0} onClick={() => setSearch('')}>
                            <CloseOutlineIcon />
                        </InputGroup.Button>
                    </InputGroup>
                </div>

                <div className='align-self-center' style={{marginLeft: 20}}>
                    <p className='info-title'>Exportar dados dos visitates(xlsx):</p>
                    {
                        !relatorioStore.relatorioVisitante || relatorioStore.relatorioVisitante.length === 0 || relatorioStore.loading
                        ?
                        <IconButton disabled={!relatorioStore.relatorioVisitante || relatorioStore.relatorioVisitante.length === 0 || relatorioStore.loading} appearance="primary" color="green" icon={<DetailIcon />}>
                            Exportar
                        </IconButton>
                        :
                        <Workbook
                          filename={`Relatório de visitantes do condominio ${condominioStore.selecionado?.nome} do dia ${moment(inicio).format('YYYY-MM-DDTHH:mm:ss')} até ${moment(fim).format('YYYY-MM-DDTHH:mm:ss')}.xlsx`}
                          element={
                              <IconButton disabled={!relatorioStore.relatorioMorador} appearance="primary" color="green" icon={<DetailIcon />}>
                                  Exportar
                              </IconButton>
                          }
                          >
                          <Workbook.Sheet data={relatorioStore.relatorioVisitante} name="First Sheet">
                            <Workbook.Column label="Condominio" value="condominio" />
                            <Workbook.Column label="Unidade" value="unidade" />
                            <Workbook.Column label="Nome" value="nome" />
                            <Workbook.Column label="TipoVisitante" value="descricao" />
                            <Workbook.Column label="Autorizado" value="autorizado" />
                            <Workbook.Column label="AutorizadoPor" value="autorizadoPor" />
                            <Workbook.Column label="RegistradoPor" value="registradoPor" />
                            <Workbook.Column label="Entrada" value="dataHoraEntrada" />
                            <Workbook.Column label="Saída" value="dataHoraSaida" />
                            <Workbook.Column label="VisitanteExcluido" value="visitanteExcluido" />
                            <Workbook.Column label="MoradorExcluido" value="moradorExcluido" />
                          </Workbook.Sheet>
                      </Workbook>
                    }
       
                </div>
                
            </div>
        </Header>
        {renderEventos()}
        {
          relatorioStore.relatorioVisitante.length > 0 ? 
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={10}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={relatorioStore.relatorioVisitante.length}
            limitOptions={[10, 20, 30]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
          :
          ''
        }
    </Container>
  )
}

export default observer(RelatorioVisitantes);