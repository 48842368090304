import { makeAutoObservable, runInAction } from "mobx";
import RedeAPI from "../api/RedeAPI";
import { Rede } from "../interfaces/Rede.interface";
import RelatorioAPI from "../api/RelatorioAPI";
import { IRelatorioMorador, IRelatorioVeiculo, IRelatorioVisitante } from "../interfaces/Relatorio.interface";
import { cpfMask, maskCelular, maskTelefoneFixo } from "../components/utils/mask";
import moment from "moment";

class RelatorioStore {

    relatorioMorador: IRelatorioMorador[] = [];
    relatorioVeiculo: IRelatorioVeiculo[] = [];
    relatorioVisitante: IRelatorioVisitante[] = [];
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }
    
    async obterRelatorioMoradorPorCondominioId(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId)
            {
                const { data } = await RelatorioAPI.obterMoradorPorCondominioId(condominioId);
                runInAction(() => {
                    if(data)
                    {
                        this.relatorioMorador = data;
                        this.relatorioMorador.forEach((x) =>
                        {
                            x.statusMoradorDescricao = x.statusMorador === 0 ? 'Liberado' : 'Bloqueado';
                            x.cpf = cpfMask(x.cpf ?? '');
                            x.celular = maskCelular(x.celular ?? '');
                            x.telefone = maskTelefoneFixo(x.telefone ?? '');
                        })

                    }
                    else this.relatorioMorador = [];
                    this.loading = false;
                });
            }
            this.loading = false;
        } catch(e) {
            this.loading = false;
        }
    }

    async obterRelatorioVeiculoPorCondominioId(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId)
            {
                const { data } = await RelatorioAPI.obterVeiculoPorCondominioId(condominioId);
                runInAction(() => {
                    if(data) this.relatorioVeiculo = data;
                    else this.relatorioVeiculo = [];
                    this.loading = false;
                });
            }
            this.loading = false;
        } catch(e) {
            this.loading = false;
        }
    }

    async obterRelatorioVisitantePorCondominioId(condominioId: string, dataInicio: string, dataFim: string) {
        this.loading = true;
        try {
            if(condominioId)
            {
                const { data } = await RelatorioAPI.obterVisitantesPorCondominioId(condominioId, dataInicio, dataFim);
                runInAction(() => {
                    if(data)
                    {

                        data.forEach((x) =>
                        {
                            x.dataHoraEntrada = moment(x.dataHoraEntrada).format('DD-MM-YYYY HH:mm:ss');
                            x.dataHoraSaida = moment(x.dataHoraSaida).format('DD-MM-YYYY HH:mm:ss');
                        })
                        this.relatorioVisitante = data;
                    }
                    else this.relatorioVisitante = [];
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

}

export default RelatorioStore;