import styled from 'styled-components';
import background from '../../../assets/img/login-bg.jpg';

export const Container = styled.div`
    display: flex;
`

export const ContainerInfo = styled.div`
    width: 70%;
    height: 100vh;
    background-image: url(${background});
    background-size: cover;
    background-position: left center;
    box-shadow: 10px 20px 40px rgba(0,0,0,0.0900);
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
        width: 50%;
    }
    .title {
        color: #fff;
        font-size: 20pt;
        margin: 0;
    }
    .subtitle {
        color: #fff;
        font-size: 12pt;
        margin: 0;
    }
    .copyright {
        color: #fff;
        font-size: 8pt;
        margin: 0;
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    @media(max-width: 1400px) {
        width: 75%;
        .container {
            width: 60%;
        }
        .title {
            color: #fff;
            font-size: 18pt;
            margin: 0;
        }
        .subtitle {
            color: #fff;
            font-size: 10pt;
            margin: 0;
            width: 80%
        }
    }

`

export const ContainerForm = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
        width: 50%;
    }
    .title {
        color: rgba(0,0,0,0.8);
        font-weight: 600;
        font-size: 20pt;
        margin: 0;
    }
    .subtitle {
        color: rgba(0,0,0,0.9);
        font-size: 14pt;
        font-weight: 500;
        margin: 0;
        margin-top:10px;
    }
    .info {
        color: #000;
        font-size: 11pt;
        margin: 0;
        width: 70%;
    }
    .input {
        padding: 25px;
        border-radius: 11px;
        margin-top: 10px;
    }
    .btn-enter {
        border: 0;
        padding: 15px;
        border-radius: 11px;
        background: #004C95;
        color: #fff;
        width: 100%;
        margin-top: 10px;
    }


    @media(max-width: 1400px) {
.container {
        width: 60%;
    }
    .title {
        font-size: 18pt;
    }
    .subtitle {
        font-size: 12pt;
    }
    .info {
        font-size: 10pt;
        margin-top: 5px;
        line-height: 15px;
    }
    .input {
        padding: 17px;
        border-radius: 11px;
        margin-top: 10px;
    }
    .btn-enter {
        border: 0;
        padding: 15px;
        border-radius: 11px;
        background: #004C95;
        color: #fff;
        width: 100%;
        margin-top: 10px;
    }
    }
`

export const ContainerModal = styled.div`
    .title {
        margin: 20px;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor}
        padding-bottom: 20px;
    }
    .button-acess {
        border: 0;
        background: ${(props) => props.theme.colors.bgBox};
        padding: 10px;
        margin: 50px;
        border-radius: 8px;
        box-shadow: 1px 5px 6px rgba(0,0,0,0.2);
        transition: 0.2s;
    }
    .button-acess:hover {
        box-shadow: 1px 5px 6px rgba(0,0,0,0.5);
        transition: 0.2s;
    }
    .img {
        height: 200px;
    }
    .title-img {
        color: ${(props) => props.theme.colors.text};
    }
`

export const TransitionDiv = styled.div`    
    width: 100%;
  .fade-enter .animation {
    opacity: 0;
    transform: translateX(0%);
  }
  .fade-enter-active .animation {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit .animation {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit-active .animation {
    opacity: 0;
    transform: translateX(30%);
  }
  .fade-enter-active .animation,
  .fade-exit-active .animation {
    transition: opacity 500ms, transform 500ms;
  }

  .toggle {
      color: rgba(0,0,0,0.7);
      margin-top: 20px;
      text-align: center;
      font-weight: 600
      transition: 0.2s;
      border-bottom: 1px solid rgba(0,0,0,0.0);
      padding-bottom: 10px;
      transition: 0.2s;
    }
    .toggle:hover {
        color: rgba(0,0,0,0.9);
        transition: 0.2s;
        cursor: pointer;
        border-bottom: 1px solid rgba(0,0,0,0.3);
    }
`

