import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Skeleton, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState, useEffect } from 'react';
import { BiServer } from 'react-icons/bi';
import { BsArrowRightShort, BsChat, BsHddNetwork } from 'react-icons/bs';
import { Button, Input } from 'rsuite';
import { StoreContext } from '../../stores';
import EmptyChat from '../utils/EmptyChat';
import Message from './Message';
import { Container, ContainerChat, ContainerMoradores, Header } from './styles/Chat.style';

const ChatListagem = () => {

    const { moradorStore, condominioStore } = useContext(StoreContext);
    const [cadastrar, setCadastrar] = useState(false);
    const [atualizar, setAtualizar] = useState(false);
    const [filtrar, setFiltrar] = useState('');

    const toggleCadastrar = () => setCadastrar(!cadastrar);
    const toggleAtualizar = () => setAtualizar(!atualizar);

    const filtragem = moradorStore.moradores.filter((m) => m.nome.toLowerCase().includes(filtrar.toLowerCase()) || !filtrar);

    useEffect(() =>
    {
        if(condominioStore.selecionado)
        {
            moradorStore.selecionado = null;
            moradorStore.listarPorCondominioId(condominioStore.selecionado.id);
        }
    }, [condominioStore.selecionado]);

    if(moradorStore.loading)
    {
        return(
            <div>
                <Skeleton variant="rectangular" width={310} height={'100vh'} />
            </div>
        )
    }

    const renderChat = () =>
    {
        if(!moradorStore.selecionado)
        {
            return <EmptyChat/>
        }
        return <Message/>
    }

    return (
        <Container>
            <div className='content d-flex'>
                <ContainerMoradores>
                    <div className='sticky-tops'>
                        <div className='header'> 
                            <p className='title'>Chat <BsChat size={20} style={{marginLeft: 5, marginTop: '-5px'}} /> </p>
                            <p className='subtitle'>Envie mensagens para os moradores do condomínio</p>
                        </div>
                        <Input placeholder='Buscar morador' onChange={(e) => setFiltrar(e)} />
                    </div>
                    <div>
                        {
                            filtragem.filter((m) => m.usuarioId !== null).map((morador, i) =>
                            (
                                <div key={i} className={'content'} onClick={() => moradorStore.definirSelecionado(morador.id)}>
                                    <p className='nome'>{morador.nome}</p>
                                    <p className='cpf'>{morador.cpf}</p>
                                    <p className='unidade'>{morador.unidadeIdentificacao}</p>
                                </div>
                            ))
                        }
                    </div>
                </ContainerMoradores>
                <ContainerChat>
                    {renderChat()}
                </ContainerChat>
            </div>
        </Container>
    )
}

export default observer(ChatListagem);
