import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Row as RowBase } from 'react-bootstrap';

interface Props
{
  active: boolean;
}

export const Box = styled.div`
    background: ${(props) => props.theme.colors.bgColor};
    width: 100%;
    position: fixed;
`

export const ContainerLoading = styled.div`
    background: rgba(0,0,0,0.7);
    width: 100%;
    position: absolute;
    z-index: 20;
    height: 100vh;
`

export const Scroll = styled(PerfectScrollBar)<Props>`
height: 100vh; 
display: flex;
flex-direction: column;
position: fixed;
top: 0;
left: 0;
background-color: ${(props) => props.theme.colors.default};
width: 240px;
flex-grow: 1;
// padding: 10px 0;
transition: 0.2s;

  .title-info
  {
    font-weight: 550;
    font-size: 8pt;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .logo
  {
    height: 20px;
  }
  .title
  {
    margin: 0;
    // margin-left: 10px;
    font-weight: 550;
    font-size: 11pt;
    color: ${(props) => props.theme.colors.text};
  }
  .subtitle
  {
    margin: 0;
    // margin-left: 10px;
    color: ${(props) => props.theme.colors.text};
  }

  .page-icon
  {
    font-size: 13pt;
    margin: 0;
    align-self: center;
    color: ${(props) => props.theme.colors.text};
  }
  .page-title
  {
    font-size: 9pt;
    margin: 0;
    align-self: center;
    margin-left: 10px;
    color: ${(props) => props.theme.colors.text};
  }

  .title-nav
  {
    margin: 10px;
    font-weight: 550;
    rgba(255,255,255,0.7);
    color: ${(props) => props.theme.colors.text};
  }

  .navigation {
    background: ${(props) => props.theme.colors.bgColor};
    width: 530px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    // padding-top: 20px;
    transition: width 0.3s ease;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    // margin-bottom: 10px;
  }

  a {
    display: block;
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
    padding: 6px;
    transition: 0.2s;
    border: 2px solid rgba(0,0,0,0);
    // margin-top: 4px;
  }

  a:hover {
    background: ${(props) => props.theme.colors.bgColor};
    border-radius: 4px;
    transition: 0.2s;
    padding-left: 10px;
  }

  li.active a {
    // background-color: #34495e;
    background: ${(props) => props.theme.colors.bgColor};
    border-radius: 4px;
    border-left: 3px solid ${(props) => props.theme.colors.primary};
  }

  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .footer
  {
    background: ${(props) => props.theme.colors.bgColor};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 11px;
  }
  .title-footer
  {
    font-size: 7pt;
    text-align: center;
    color: ${(props) => props.theme.colors.text};
  }
  .subtitle-footer
  {
    font-size: 7pt;
    text-align: center;
    font-weight: 600;
    color: ${(props) => props.theme.colors.text};
  }
`

export const Loading = styled.div`
    background: #004C95;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
        height: 50px;
    }
`

export const Row = styled(RowBase)`
  min-height: calc(100vh - (58px + 1.5rem));
  display: flex;
`;

export const TransitionDiv = styled.div`
  .fade-enter .animation-dashboard {
    opacity: 0;
  }
  .fade-enter-active .animation-dashboard {
    opacity: 1;
  }
  .fade-exit .animation-dashboard {
    opacity: 1;
  }
  .fade-exit-active .animation-dashboard {
    opacity: 0;
    transition: 0.2s;
  }
  .fade-enter-active .animation-dashboard,
  .fade-exit-active .animation-dashboard {
    transition: opacity 200ms;
  }
`

export const Footer = styled.div`
  background: ${(props) => props.theme.colors.bgBox};
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: 10px;
  .title
  {
    text-align: center;
  }
`;