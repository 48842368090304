import styled from 'styled-components';
import PerfectScroll from 'react-perfect-scrollbar';

export const Container = styled.div`
    background: ${(props) => props.theme.colors.bgColor};
    padding: 10px;
    .content
    {
        background: ${(props) => props.theme.colors.bgColor};
        padding: 10px;
        border-radius: 5px;
        box-shadow: 1px 3px 5px ${(props) => props.theme.colors.borderColor};
    }
`;