import axios, { AxiosError } from "axios";
import { useState, useContext } from "react";
import { toast } from "react-toastify";
import AuthAPI from "../api/AuthAPI";
import UsuarioAPI from "../api/UsuarioAPI";
import { Auth, DadosSms } from "../interfaces/Auth.interface";
import { Morador } from "../interfaces/Morador.interface";
import api from "../services/api/api";
import md5 from 'md5';
import { IResponse } from "../interfaces/Response.interface";
import { StoreContext } from "../stores";


export const useAuthentication = () => {

    const { authStore } = useContext(StoreContext);
    const [carregando, setCarrgeando] = useState(false);
    const [erro, setErro] = useState(false);
    const [status, setStatus] = useState(false);

    const baseURL = process.env.REACT_APP_BASE_MS_AUTHENTICATION;
    const serviceAdmin = process.env.REACT_APP_SERVICE_KEY_ADMIN;
    const serviceOutkey = process.env.REACT_APP_SERVICE_KEY_OUTKEY;

    const Authentication = async (auth: Auth, isCpf: boolean) => {
        setCarrgeando(true);
        let authFormat: Auth = {
            login: auth.login,
            senha: auth.senha
        }

        if (isCpf) {
            auth.login = auth.login.replace(/[^\w\s]/gi, '')
        }

        api.post(`${baseURL}/authentication`, {
            login: auth.login,
            password: md5(auth.senha),
            service: serviceAdmin
        })
            .then((res) => {
                AuthenticationOutkey(auth, isCpf);
            }).catch((error: AxiosError) => {
                if (error.response) {
                    toast.error(error.response.data.status);
                    setCarrgeando(false);
                }
            });
    }

    const AuthenticationOutkey = async (auth: Auth, isCpf: boolean) => {
        setCarrgeando(true);
        let authFormat: Auth = {
            login: auth.login,
            senha: auth.senha
        }

        if (isCpf) {
            auth.login = auth.login.replace(/[^\w\s]/gi, '')
        }

        api.post(`${baseURL}/authentication`, {
            login: auth.login,
            password: md5(auth.senha),
            service: serviceOutkey
        })
            .then((res) => {
                localStorage.setItem('token', res.data.status as any);
                setStatus(true);
                setCarrgeando(false);
            }).catch((error: AxiosError) => {
                if (error.response) {
                    toast.error(error.response.data.status);
                    setCarrgeando(false);
                }
            });
    }

    const enviarSmsEmail = async (dados: DadosSms) => {
        setCarrgeando(true);
        try {
            const { data } = await AuthAPI.enviarSmsEmail(dados);
            toast.success(data);
            setCarrgeando(false);
        } catch (e) {
            setCarrgeando(false);
            toast.error('Erro ao enviar sms para o usuário');
        }
    }

    const atualizarSenhaUsuario = async (morador: Morador, novaSenha: string) => {
        setCarrgeando(true);
        try {
            if (morador.usuarioId !== null) {
                novaSenha = md5(novaSenha)
                const { data, status } = await UsuarioAPI.atualizarAuthentication(morador.usuarioId, novaSenha);
                if (status === 200) {
                    toast.success('Senha do usuário atualizado com sucesso');
                    setCarrgeando(false);
                }
                setCarrgeando(false);
            }
            else {
                toast.error('Morador sem usuário cadastrado');
            }
        } catch (e) {
            setCarrgeando(false);
            toast.error('Erro ao atualizar senha do usuário');
        }
    }

    const atualizarSenhaUsuarioPorUsuarioId = async (usuarioId: string, novaSenha: string) => {
        setCarrgeando(true);
        try {
            novaSenha = md5(novaSenha)
            const { data, status } = await UsuarioAPI.atualizarOperacaoAuthentication(usuarioId, novaSenha);
            if (status === 200) {
                toast.success('Senha do usuário atualizado com sucesso');
                setCarrgeando(false);
                setTimeout(() =>
                {
                    window.location.reload()
                }, 1500);
            }
            setCarrgeando(false);
        } catch (e) {
            setCarrgeando(false);
            toast.error('Erro ao atualizar senha do usuário');
        }
    }

    const esqueceuSenhaUsuario = async (dados: string, hide: () => void) => {
        setCarrgeando(true);
        try {
            const { data, status } = await UsuarioAPI.esqueceuSenhaAuthentication(dados);
            if (status === 200) {
                if(data.error)
                {
                    toast.error(data.status);
                    setCarrgeando(false);
                }
                else
                {
                    toast.success(data.status);
                    setCarrgeando(false);
                    hide();
                }
            }
            setCarrgeando(false);  
        } catch (e) {
            let error = e as AxiosError;
            if(error) {
                setCarrgeando(false);
                toast.error(error.response?.data.status);
            }
        }
    }

    const refreshToken = async (token: string) => api.get<IResponse>(`${baseURL}/refreshToken`, {
        params:
        {
            token
        }
    })

    return {
        Authentication,
        carregando,
        erro,
        status,
        enviarSmsEmail,
        atualizarSenhaUsuario,
        esqueceuSenhaUsuario,
        atualizarSenhaUsuarioPorUsuarioId,
        refreshToken
    }

}