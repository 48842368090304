import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';

export const Container = styled.div`
    // height: 450px;
    padding: 20px;
    .title
    {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12pt;
    }
`

export const List = styled(PerfectScrollBar)`
  margin-top: 5px;
  align-self: center;
  width: 100%;
  border-radius: 10px;
  height: 40vh;
  // border-radius: 12px;
  margin-top: 8px;
  thead th {
    font-weight: 0;
  // background: ${(props) => props.theme.colors.bgBox};
  font-size: 13px;
    padding: 10px;
    border-top: none;
  } 
  tbody td {
    font-size: 12px;
  border: 0;
  padding: 15px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`

export const HeaderComando = styled.div`
    .title
    {
    font-size: 12pt;
    margin: 0;
    font-weight: 550;
    }
    .subtitle
    {
    font-size: 9pt;
    margin: 0;
    }

    .box
    {
    background: ${(props) => props.theme.colors.bgColor};
    box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    margin-top: 5px;
    }

    .content
    {
    padding: 20px;
    margin-top: 10px;
    width: 250px;
    }
    .content-icon
    {
    align-self: center;
    font-size: 25pt;
    margin-right: 20px;
    }
    .content-title
    {
    font-size: 10pt;
    margin: 0;
    font-weight: 550;
    }
    .content-subtitle
    {
    font-size: 9pt;
    margin: 0;
    }

    .info-title
    {
    font-size: 8pt;
    width: 80%;
    }
    .info-title-filtro
    {
    font-size: 8pt;
    width: 50%;
    }
`

export const ContentForm = styled.div`
    .box
    {
        background: ${(props) => props.theme.colors.bgColor};
        padding: 10px;
        border-radius: 8px;
    }
`;