import { Avatar, Box, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { StoreContext } from '../../stores';
import { useLayout } from '../../hooks/Layout.hook';
import { Layout, LayoutRequest } from '../../interfaces/Layout.interface';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Col, Input, Loader, Modal, SelectPicker, Toggle } from 'rsuite';


interface PropsModal {
    show: boolean;
    hide: () => void;
    condominioId?: string;
}

const CadastrarLayout: FC<PropsModal> = ({ show, hide, condominioId }) => {
    
    const { layoutStore } = useContext(StoreContext);
    const { cadastrar, salvando } = useLayout();
    const [form, setForm] = useState<LayoutRequest>({});

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        cadastrar(form as Layout, hide, clear);
    }

    const clear = () => {
        setForm({});
    }

    useEffect(() => {
        if(condominioId && show) {
            layoutStore.listarLayoutDGuard();
            setForm({
                ...form,
                condominioId: condominioId,
                idDGuard: '0'
            })
            layoutStore.listarServidoresDGuard();
        }
    }, [condominioId, show]);

    const Form = () => {
        return(
            <form onSubmit={(e) => salvar(e)}>
                <div>
                    <p><span className='text-danger'>*</span>Nome do layout</p>
                    <SelectPicker block placeholder={'Layout do servidor dguard'} virtualized loading={layoutStore.loading} data={layoutStore.layoutsDGuard.map((layout) => ({ label: layout.name, value: layout.name }))} onChange={(e) => {if(e) setForm({ ...form, nomeDGuard: e })}} />
                </div>

                <div className='mt-1'>
                    <p><span className='text-danger'>*</span>Servidor Dguard</p>
                    <SelectPicker block placeholder={'Sevidor dguard'} data={layoutStore.servidoresDGuard.map((servidor) => ({ label: `${servidor.nome} - ${servidor.ip}:${servidor.porta}`, value: servidor.id }))} onChange={(e) => setForm({ ...form, servidorDGuardId: e as any })} />
                </div>

                <div className='mt-1 d-flex'>
                    <div className='col-md-6 p-1'>
                        <p><span className='text-danger'>*</span>Usuário Dguard</p>
                        <Input required placeholder={'Usuário dguard'} value={form.usuarioDGuard} onChange={(e) => setForm({ ...form, usuarioDGuard: e })} />
                    </div>
                    <div className='col-md-6 p-1'>
                        <p><span className='text-danger'>*</span>Senha Dguard</p>
                        <Input required placeholder={'Senha dguard'} value={form.senhaDGuard} onChange={(e) => setForm({ ...form, senhaDGuard: e })} />
                    </div>
                </div>

                <div className='mt-1 d-flex'>
                    <div className='col-md-6 p-1'>
                        <p><span className='text-danger'>*</span>Host Remoto</p>
                        <Input required placeholder={'Host'} value={form.hostRemoto} onChange={(e) => setForm({ ...form, hostRemoto: e })} />
                    </div>
                    <div className='col-md-6 p-1'>
                        <p><span className='text-danger'>*</span>Porta Remoto</p>
                        <Input required placeholder={'Porta'} value={form.portaRemoto} onChange={(e) => setForm({ ...form, portaRemoto: parseInt(e) })} />
                    </div>
                </div>

                <div className='col-md-6 p-1'>
                    <p><span className='text-danger mb-1'>*</span>Host Remoto</p>
                    <Toggle size="lg" checkedChildren="Layout principal" unCheckedChildren="Layout secundária" onChange={(e) => setForm({ ...form, defaultDGuard: e })} />
                </div>

                <div className='d-flex justify-content-end'>
                    <div>
                        <Button disabled={salvando} className='m-1' appearance="primary" type={'submit'}>
                            {
                                salvando
                                ?
                                <Loader title={'Salvando...'} />
                                :
                                'Salvar'
                            }
                        </Button>
                        <Button className='m-1' appearance="default" type={'button'} onClick={hide}>Fechar</Button>
                    </div>
                </div>

                {/* // {salvando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''} */}
            </form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastro de layout</p>
                    <p className='modal-styled-subtitle'>
                        Cadastre nova unidade para cadastrar nocas câmeras
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{Form()}</Modal.Body>
            {/* {
                layoutStore.layoutsDGuard?.map(i=>i.layouts?.map(i=> i.name))
            } */}
        </Modal>
    )
}

export default observer(CadastrarLayout);