import { Octos, OctosCam, OctosRegister, OctosServers } from "../interfaces/Octos.interface";
import { Maquina, Ramal, RamalCentral, RamalGeral } from "../interfaces/Ramal.interface";
import api from "../services/api/api";

class OctosAPI {
    listarOctosPorCondominio = async (condominioId: string) => await api.get<Octos[]>(`/Octos/condominio/${condominioId}`);
    listarServersDguard = async () => await api.get<OctosServers[]>(`/Octos/obtertodosserverdguard`);
    listarCamDguardPorServerId = async (serverId: string) => await api.get<OctosCam[]>(`/Octos/obtercamerasporserverid?guid=${serverId}`);
    adicionar = async (request: OctosRegister) => await api.post(`/Octos`, request);
    atualizar = async (request: OctosRegister) => await api.put(`/Octos`, request);
    excluir = async (cameraId: string) => await api.delete(`/Octos/cameraid/${cameraId}`);
}

export default new OctosAPI();