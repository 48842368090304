import { useContext, useState } from "react";
import { toast } from "react-toastify";
import CondominioAPI from "../api/CondominioAPI";
import { StoreContext } from "../stores";
import { Condominio, CondominioAuxiliar } from "../interfaces/Condominio.interface";
import { AxiosError } from "axios";


export const useCondominio = () => {

    const { condominioStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [atualizando2, setAtualizando2] = useState(false);
    const [salvo, setSalvo] = useState(false);

    const cadastrar = async (condominio: Condominio, hide: () => void, clear: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await CondominioAPI.cadastrar(condominio);
            if(status === 200) {
                condominioStore.condominios.push(data);
                toast.success('Condomínio cadastrado com sucesso');
                setSalvando(false);
                setSalvo(salvo);
                hide();
                clear();
            } else {
                toast.error('Erro ao cadastrar condomínio');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const cadastrarCondominioAuxiliar = async (condominio: CondominioAuxiliar, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await CondominioAPI.cadastrarCondominioAuxiliar(condominio);
            if(status === 200) {
                condominioStore.listarCondominioAuxiliar();
                toast.success('Cadastrado com sucesso');
                setSalvando(false);
                setSalvo(salvo);
                hide();
            } else {
                toast.error('Erro ao cadastrar');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const atualizar = async (condominio: Condominio, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await CondominioAPI.atualizar(condominio);
            if(status === 200) {
                condominioStore.condominios = condominioStore.condominios.map( con => con.id === condominio.id ? condominio : con );
                if(condominioStore.selecionado) condominioStore.definirSelecionado(condominioStore.selecionado.id)
                toast.success('Condomínio atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar condomínio');
                setAtualizando(false);
            }
        } catch(e) {
            let error = e as AxiosError;
            if(error) {
                setSalvando(false);
                toast.error(error.response!.data[0].mensagem);
            }
            setAtualizando(false);
        }
    }

    const atualizarCondominioAuxiliar = async (condominio: CondominioAuxiliar, hide: () => void) => {
        setAtualizando2(true);
        try {
            const { data, status } = await CondominioAPI.atualizarCondominioAuxiliar(condominio);
            if(status === 200) {
                condominioStore.listarCondominioAuxiliar();
                toast.success('Atualizado com sucesso');
                setAtualizando2(false);
                setSalvo(salvo);
                hide();
            } else {
                toast.error('Erro ao atualizar');
                setAtualizando2(false);
            }
        } catch(e) {
            setAtualizando2(false);
            let error = e as AxiosError;
            if(error) {
                setSalvando(false);
                toast.error(error.response!.data[0].mensagem);
            }
        }
    }


    return {
        cadastrar,
        cadastrarCondominioAuxiliar,
        atualizar,
        atualizarCondominioAuxiliar,
        salvando,
        atualizando,
        atualizando2,
        salvo,
        setSalvo
    }

}
