import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { StoreContext } from "../stores";
import { Mip1000, Mip1000Rele } from "../interfaces/Mip1000.interface";
import Mip1000API from "../api/Mip1000API";


export const useMip1000 = () => {

    const { mip1000Store } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    
    const cadastrar = async (requets: Mip1000, hide: () => void, clear: () => void) => {
        setSalvando(true);

        try {
            const { data, status } = await Mip1000API.adicionar(requets as any);
            if(status === 200) {
                setSalvando(false);
                mip1000Store.listarPorCondominio(requets.condominioId);
                hide();
                clear();
                toast.success('Cadastrado com sucesso!');
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar');
        }
    }

    const atualizar = async (requets: Mip1000, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await Mip1000API.atualizar(requets as any);
            if(status === 200) {
                mip1000Store.mip1000 = mip1000Store.mip1000.map( gua => gua.id === requets.id ? requets : gua );
                setAtualizando(false);
                hide();
                toast.success('Atualizado com sucesso!');
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar');
        }
    }

    const deletar = async (request: Mip1000) => {
        setDeletando(true);
        try {
            const { status } = await Mip1000API.deletar(request.id);
            if(status === 200) {
                setDeletando(false);
                mip1000Store.mip1000 = mip1000Store.mip1000.filter( gua => gua.id !== request.id );
                toast.success('Deletado com sucesso!');
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar');
        }
    }

    const cadastrarRele = async (requets: Mip1000Rele, hide: () => void, clear: () => void) => {
        setSalvando(true);

        try {
            const { data, status } = await Mip1000API.adicionarRele(requets as any);
            if(status === 200) {
                setSalvando(false);
                mip1000Store.releMip1000.push(data);
                hide();
                clear();
                toast.success('Cadastrado com sucesso!');
            }
        } catch(e) {
            setSalvando(false);
            toast.error('Erro ao cadastrar');
        }
    }

    const atualizarRele = async (requets: Mip1000Rele, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await Mip1000API.atualizarRele(requets as any);
            if(status === 200) {
                mip1000Store.releMip1000 = mip1000Store.releMip1000.map( gua => gua.id === requets.id ? requets : gua );
                setAtualizando(false);
                hide();
                toast.success('Atualizado com sucesso!');
            }
        } catch(e) {
            setAtualizando(false);
            toast.error('Erro ao atualizar');
        }
    }

    const deletarRele = async (request: Mip1000Rele) => {
        setDeletando(true);
        try {
            const { status } = await Mip1000API.deletarRele(request.id);
            if(status === 200) {
                setDeletando(false);
                mip1000Store.releMip1000 = mip1000Store.releMip1000.filter( gua => gua.id !== request.id );
                toast.success('Deletado com sucesso!');
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar');
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        cadastrarRele,
        atualizarRele,
        deletarRele,
        salvando,
        atualizando,
        deletando,
    }

}