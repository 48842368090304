import { useContext, useState } from "react";
import { toast } from "react-toastify";
import MaquinaAPI from "../api/MaquinaAPI";
import { StoreContext } from "../stores";
import { Maquina } from "../interfaces/Maquina.interface";


export const useMaquina = () => {

    const { ramalStore, usuarioStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    
    const cadastrar = async (maquina: Maquina, hide: () => void, clear: () => void) => {
        setSalvando(true);

        try {
            const { data, status } = await MaquinaAPI.salvar(maquina as any);
            if(status === 200) {
                usuarioStore.listarUsuáriosLogados();
                setSalvando(false);
                hide();
                clear();
                toast.success('Maquina cadastrado com sucesso!');
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar maquina');
        }
    }

    const atualizar = async (maquina: Maquina, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await MaquinaAPI.atualizar(maquina as any);
            if(status === 200) {
                usuarioStore.listarUsuáriosLogados();
                setAtualizando(false);
                hide();
                toast.success('Maquina atualizado com sucesso!');
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar maquina');
        }
    }

    const deletar = async (maquina: Maquina) => {
        setDeletando(true);
        try {
            const { status } = await MaquinaAPI.deletar(maquina.id);
            if(status === 200) {
                usuarioStore.listarUsuáriosLogados();
                setDeletando(false);
                toast.success('Maquina deletado com sucesso!');
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar maquina');
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
    }

}