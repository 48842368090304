import react, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, ContainerVeiculos, Header } from './styles/Veiculo.style';
import { StoreContext } from '../../stores';
import { BiEdit, BiX } from 'react-icons/bi';
import { useVeiculo } from '../../hooks/Veiculo.hook';
import CadastrarVeiculo from './CadastrarVeiculo';
import AtualizarVeiculo from './AtualizarVeiculo';
import { Avatar, Menu, MenuItem, TextField } from '@mui/material';
import React from 'react';
import { Sincronizar, SincronizarRequest, VeiculoRequest } from '../../interfaces/Veiculo.interface';
import { ListGroup, Table } from 'react-bootstrap'; 
import Empty from '../utils/Empty';
import { BsBookmarkCheck, BsGrid3X3, BsListNested, BsPeople, BsPhone, BsThreeDots } from 'react-icons/bs';
import Controle from '../dispositivo/controle/Controle';
import { RiAlignLeft, RiRemoteControl2Line } from 'react-icons/ri';
import NotFound from '../utils/NotFound';
import { Button, Dropdown, Input, SelectPicker, IconButton, Drawer } from 'rsuite';
import { AiOutlineCar, AiOutlineReload } from 'react-icons/ai';
import { useDispositivo } from '../../hooks/Dispositivo.hook';
import { Content, Icon, Notificacao, Sidebar, Title, TitleRelatorio } from '../utils/RelatorioStyle';
import { MdErrorOutline } from 'react-icons/md';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import PageIcon from '@rsuite/icons/Page';import SearchIcon from '@rsuite/icons/Search';
import PlusIcon from '@rsuite/icons/Plus';
import ReloadIcon from '@rsuite/icons/Reload';
import SkeletonList from '../utils/SkeletonList';
import ModalRelatorioBiometria from '../utils/ModalRelatorioBiometria';
import ModalRelatorioControle from '../utils/ModalRelatorioControle';
import ModalRelatorioVeiculo from './ModalRelatorioVeiculo';
import Loading from './Loading';

const Veiculo = () => {

    const { veiculoStore, unidadeStore, dispositivosStore, condominioStore, themeStore } = useContext(StoreContext);
    const { deletar, deletando } = useVeiculo();
    const { openRelatorio, setOpenRelatorio, sincronizarControle, sincronizando } = useDispositivo();

    const [modalCadastrarVeiculo, setModalCadastrarVeiculo] = useState(false);
    const [modalEditarVeiculo, setModalEditarVeiculo] = useState(false);
    const [modalDeletar, setModalDeletar] = useState(false);
    const [modalRelatorio, setModalRelatorio] = useState(false);
    const [unidadeSelecionadoId, setUnidadeSelecionadoId] = useState('');
    const [value, setValue] = useState<VeiculoRequest | string>('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [search, setSearch] = useState('');
    const [searchSelecionado, setSearchSelecioando] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElFiltro, setAnchorElFiltro] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openFiltro = Boolean(anchorElFiltro);


    useEffect(() => {
        if(unidadeStore.selecionado)
        {
            veiculoStore.listar(unidadeStore.selecionado.id);
            dispositivosStore.listarControlesPorUnidade(unidadeStore.selecionado.id);
            setUnidadeSelecionadoId(unidadeStore.selecionado.id);
            unidadeStore.definirSelecionado(unidadeStore.selecionado.id)
        }
        else
        {
            let primeiraUnidade = unidadeStore.unidadesPorCondomino[0];
            if(primeiraUnidade) {
                veiculoStore.listar(primeiraUnidade.id);
                dispositivosStore.listarControlesPorUnidade(primeiraUnidade.id);
                setUnidadeSelecionadoId(primeiraUnidade.id);
                unidadeStore.definirSelecionado(primeiraUnidade.id)
            }
        }
    }, [condominioStore.selecionado]);


    const toggleCadastarVeiculo = () => setModalCadastrarVeiculo(!modalCadastrarVeiculo);
    const toggleEditarVeiculo = () => setModalEditarVeiculo(!modalEditarVeiculo);
    const toggleDrawer = () => setOpenDrawer(!openDrawer);
    const toggleModalDeletar = () => setModalDeletar(!modalDeletar);
    const toggleModalRelatorio = () => setModalRelatorio(!modalRelatorio);

    const deletarVeiculo = () =>
    {
        if(veiculoStore.selecionado) {
            deletar(veiculoStore.selecionado);
        }
    }

    const filtrarVeiculo = veiculoStore.veiculos.filter((veiculo) => 
      veiculo.marcaDescricao?.toLocaleLowerCase().includes(searchSelecionado.toLocaleLowerCase()) ||
      veiculo.modelo?.toLocaleLowerCase().includes(searchSelecionado.toLocaleLowerCase()) ||
      veiculo.placa?.toLocaleLowerCase().includes(searchSelecionado.toLocaleLowerCase()) ||
      veiculo.corDescricao?.toLocaleLowerCase().includes(searchSelecionado.toLocaleLowerCase()) ||
      !searchSelecionado
    );

    const renderVeiculo = () => {

        if(deletando) {
            return <Loading/>
        }

        if(veiculoStore.loading) {
            return <Loading/>
        }

        if(veiculoStore.veiculos.length === 0) {
            return <NotFound message={`Nenhum Veículo cadastrado na unidade ${unidadeStore.selecionado?.apartamento} ${unidadeStore.selecionado?.blocoNome.toUpperCase()}`} />
        }

        return(
            <div className='content-veiculos'>
                <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="md">
                    <thead>
                        <tr>
                            <th scope="col">Marca</th>
                            <th scope="col">Modelo</th>
                            <th scope="col">Placa</th>
                            <th scope="col">Cor</th>
                            <th scope="col">Dispositivo</th>
                            <th scope="col">Sincronizar</th>
                            <th scope="col">Relatório</th>
                            <th scope="col">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filtrarVeiculo.map((veiculo, i) => (
                                <tr key={i} onClick={() => veiculoStore.definirSelecionado(veiculo.id)}>
                                    <td>{veiculo.modelo}</td>
                                    <td>{veiculo.marcaDescricao}</td>
                                    <td>{veiculo.placa}</td>
                                    <td>{veiculo.corDescricao}</td>
                                    <td onClick={() => toggleDrawer()}>
                                        <IconButton size={'xs'} appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleDrawer}>
                                            Cadastrar
                                        </IconButton>
                                    </td>
                                    
                                    <td>
                                        <IconButton size={'xs'} appearance="primary" color="violet" icon={<ReloadIcon />} onClick={() => sincronizarControle({ veiculoId: veiculo.id })} loading={veiculoStore.selecionado?.id === veiculo.id ? sincronizando : false}>
                                            Enviar
                                        </IconButton>
                                    </td>

                                    <td onClick={toggleModalRelatorio}>
                                        <IconButton size={'xs'} appearance="primary" color="violet" icon={<PageIcon />}>
                                            Abrir
                                        </IconButton>
                                    </td>
                                    
                                    <td>
                                        <Dropdown title={'Ação'} appearance="primary" color="violet" size={'xs'}>
                                            <Dropdown.Item onClick={toggleEditarVeiculo}>Atualizar</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleModalDeletar}>Deletar</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                    
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    return(
        <Container>
            <Header>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='title'>Campo de cadastros de veiculos</p>
                        <p className='subtitle'>Selecione a unidade para visualizar os veiculos</p>
                    </div>
                    <div>
                        <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastarVeiculo}>
                            Adicionar novo veiculo na unidade {unidadeStore.selecionado?.apartamento}
                        </IconButton>
                    </div>
                </div>

                <div className='d-flex box'>
                    <div className='content d-flex'>
                        <AiOutlineCar className='content-icon' />
                        <div>
                            <p className='content-title'>Veiculos cadastrados </p>
                            <p className='content-subtitle'>{veiculoStore.veiculos.length} cadastrado na unidade {unidadeStore.selecionado?.apartamento}</p>
                        </div>
                    </div>
                    <div className='align-self-center'>
                        <div style={{marginLeft: 20}}>
                            <p>Selecione a unidade</p>
                            <SelectPicker
                                block
                                cleanable={false}
                                style={{width: 100}}
                                value={unidadeStore.selecionado?.id ?? unidadeStore.unidadesPorCondomino[0]?.id ?? ""}
                                placeholder={'Selecione'}
                                data={unidadeStore.unidadesPorCondomino.map((unidade) => ({ label: `${unidade.apartamento} - ${unidade.blocoNome}`, value: unidade.id }))}                            
                                onChange={(e) =>
                                {
                                    // veiculoStore.listarPorUnidadeId(e);
                                    if(e) unidadeStore.definirSelecionado(e);
                                    if(e) veiculoStore.listar(e);
                                    if(e) dispositivosStore.listarTagPorUnidade(e);
                                }}
                            />
                        </div>
                    </div>

                    <div style={{marginLeft: 40}} className='align-self-center'>
                        <p className='info-title-filtro'>Filtrar veiculos da unidade <span style={{fontWeight: 550, fontSize: 15}}>{unidadeStore.selecionado?.apartamento}</span></p>
                        <Input placeholder='Buscar' onChange={(e) => setSearchSelecioando(e)} />
                    </div>
                </div>

                <ContainerVeiculos>
                    {renderVeiculo()}
                </ContainerVeiculos>

            </Header>

            <Drawer
                placement={'right'}
                open={openDrawer}
                onClose={toggleDrawer}
                style={{width: 450}}
                >
                <Controle hide={toggleDrawer} />
            </Drawer>

            {/* <Drawer
                    placement={'right'}
                    open={openRelatorio}
                    onClose={() => setOpenRelatorio(false)}
                    style={{width: 450}}
                >
                    <div>
                        <Title style={{ background: 'transparent' }} className={'d-flex justify-content-between'}>
                            <span>RELATÓRIO DE CADASTRO</span>
                        </Title>
                        
                        <TitleRelatorio>
                            <span>Relatório dos dispositivos com sucesso</span>
                        </TitleRelatorio>
                        <Content>
                            <ListGroup variant="flush">
                                {dispositivosStore?.relatorio?.acertos.map((item, i) => (
                                <ListGroup.Item key={i} className="pl-0">
                                    <div className="media ml-4 d-flex">
                                        <Icon className='align-self-center' style={{marginRight: 5}}><BsBookmarkCheck /></Icon>
                                        <div className="media-body pl-2">
                                            {item.local}
                                            <div className="d-flex align-items-center align-content-center justify-content-between">
                                            <Notificacao>{item.notificacao}</Notificacao>
                                            </div>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Content>

                        <TitleRelatorio>
                            <span>Relatório dos dispositivos com erros</span>
                        </TitleRelatorio>
                        <Content>
                            <ListGroup variant="flush">
                                {dispositivosStore?.relatorio?.erros.map((item, i) => (
                                <ListGroup.Item key={i} className="pl-0">
                                    <div className="media d-flex">
                                        <Icon><MdErrorOutline/></Icon>
                                        <div className="media-body pl-2">
                                            {item.local}
                                            <div className="d-flex align-items-center align-content-center justify-content-between">
                                            <Notificacao>Erro ao coletar</Notificacao>
                                            </div>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Content>
                        <div className='d-flex justify-content-center m-3'>
                            <button type={'button'} className='btn btn-success' onClick={() => setOpenRelatorio(false)} style={{ borderRadius: 60 }}>Fechar</button>
                        </div>
                    </div>
            </Drawer> */}

            <CadastrarVeiculo show={modalCadastrarVeiculo} hide={toggleCadastarVeiculo} unidadeId={unidadeSelecionadoId} />
            <AtualizarVeiculo show={modalEditarVeiculo} hide={toggleEditarVeiculo} veiculoSelecionado={veiculoStore.selecionado} />

            <ModalConfirmacao show={modalDeletar} hide={toggleModalDeletar} action={deletarVeiculo} content={'Você tem certeza que deseja deletar esse veículo?'} loading={deletando} />
            <ModalRelatorioControle show={openRelatorio} hide={() => setOpenRelatorio(false)} tipo={'Controle Sincronização'}  />
            <ModalRelatorioVeiculo show={modalRelatorio} hide={toggleModalRelatorio} />

        </Container>
    )
}

export default observer(Veiculo);