import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import GuaritaStore from '../../stores/GuaritaStore';
import useGuarita from '../../hooks/Guarita.hook';
import { useRele } from '../../hooks/Rele.hook';
import { GuaritaRequest, Guarita } from '../../interfaces/Guarita.interface';
import { Rele, ReleRequest } from '../../interfaces/Rele.interface';
import { Button, Input, Loader, Modal } from 'rsuite';
import { observer } from 'mobx-react-lite';

interface PropsModal {
    show: boolean;
    hide: () => void;
    releSelecionado: Rele | null;
}

const AtualizarRele: FC<PropsModal> = ({ show, hide, releSelecionado }) => {
    
    const { atualizar, atualizando } = useRele();
    
    const [form, setForm] = useState<ReleRequest>({});

    const salvar = () => {
        atualizar(form as Rele, hide);
    }

    useEffect(() => {
        if(releSelecionado) {
            setForm(releSelecionado);
        } 
    }, [releSelecionado]);

    const FormRele = () => {
        return(
            <div>
                <div>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Identificação do relé</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Ex: Relé pedestre externo" value={form.nome || ''} onChange={(e) => setForm({...form, nome: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Número do relé</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="0000" value={form.numeroRele || ''} onChange={(e) => setForm({...form, numeroRele: parseInt(e)})} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-center'>
                    <FormControlLabel control={<Switch value={form.emitirAlerta || ''} onChange={ e => setForm({ ...form, emitirAlerta: e.target.checked }) } />} label="Emitir alerta" />
                    <FormControlLabel control={<Switch value={form.emitirEvento || ''} onChange={ e => setForm({ ...form, emitirEvento: e.target.checked }) } />} label="Emitir evento" />
                </div>
{/* 
                <div className='d-flex justify-content-end'>
                    <button disabled={atualizando} className='btn w-25 btn-success align-self-end shadow-none' type="submit">
                        { atualizando ? 'Salvando...' : 'Salvar' }
                    </button>
                </div> */}
                {atualizando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
                </div>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar dados do relé</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para atualizar os dados
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormRele()}</Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" disabled={atualizando} onClick={salvar}>{ atualizando ? <Loader title='Salvando...' /> : 'Salvar' }</Button>
                <Button appearance="default" disabled={atualizando} onClick={hide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(AtualizarRele);