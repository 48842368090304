import react, { useEffect, useState, useContext, FormEvent, useRef, createRef } from 'react'
import { observer } from 'mobx-react-lite'
import { CardMessage, Container, CardInput, MessageLeft, MessageRight, CardRespondeMessage, Users } from './styles/MessageGeral.style';
import { Input,Whisper, Dropdown, Popover, IconButton, Loader, Button } from 'rsuite';
import { BsArrowReturnLeft, BsArrowReturnRight, BsChatSquareText, BsX } from 'react-icons/bs';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { HiDotsHorizontal, HiOutlinePhotograph } from 'react-icons/hi';
import { BsFileEarmarkArrowDown } from 'react-icons/bs';
import { StoreContext } from '../../stores';
import Loading from '../utils/Loading';

const MessageGeral = () => {

    const ref = useRef(null);
    const refScroll = createRef<any>();
    const setUsuarios = new Set();
    const messageRef = useRef<HTMLDivElement>(null);
    // const { EnviarMensagem } = useChat();
    const { authStore, usuarioStore, chatStore } = useContext(StoreContext);
    const [mensagem, setMensagem] = useState("");
    const [temUsuario, setTemUsuario] = useState("");
    const [pinUser, setPinUser] = useState(false);
    const [prevScrollpos, setPrevScrollpos] = useState<number>();
    const [showButton, setShowButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalDescricao, setOpenModalDescricao] = useState(false);
    const [img, setImg] = useState({
        url: "",
        descricao: ""
    });
    

    const toggleOpenModal = () => setOpenModal(!openModal);
    const toggleOpenModalDescricao = () => setOpenModalDescricao(!openModalDescricao);

    const clear = () =>
    {
        setMensagem("");
    }

    useEffect(() => {
        if(messageRef.current) {
            messageRef.current.scrollIntoView({ block: 'end', behavior: 'auto' });
        }
    });


    // useEffect(() =>
    // {
    //     if(settingsStore.searchId !== null && chatStore.mensagens)
    //     {
    //         const element = document.getElementById(settingsStore.searchId);
    //         if (element) {
    //             element.scrollIntoView({ behavior: "auto" });
    //             // settingsStore.searchId = "";
    //         }
    //     }
    // }, [settingsStore.searchId])

    useEffect(() =>
    {
        setPrevScrollpos(document.getElementById("scroll")?.scrollTop ?? 0)
    }, [prevScrollpos])


    // useEffect(() =>
    // {
    //     settingsStore.searchId = null;
    //     settingsStore.searchMessage = null;
    // }, []);


    // useEffect(() =>
    // {
    //     if(chatStore.selecionado)
    //     {
    //         
    //     }
    // }, [chatStore.mensagens]);

    const filtroUsuario = chatStore.mensagens.filter((usuario) =>
    {
        const usuarioDuplicado = setUsuarios.has(usuario.usuarioNome);
        setUsuarios.add(usuario.usuarioNome);
        usuario.usuarioNome.toLowerCase().includes(temUsuario);
        return !usuarioDuplicado;
    } );

    const EnviarMensagemOperador = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // if(authStore.usuario && localStorage.getItem("PA"))
        // {
        //     var novaCor = generateRandomColor();
        //     if(chatStore.selecionado === 'privado' && usuarioStore.selecionado)
        //     {
        //         await EnviarMensagem({
        //             usuarioNome: authStore.usuario.nome,
        //             maquina: localStorage.getItem("PA")!.toString(),
        //             remetenteId: authStore.usuario.id,
        //             destinatarioId: usuarioStore.selecionado.id,
        //             mensagem: mensagem,
        //             status: 0,
        //             cor: chatStore.mensagens.length === 0 ? generateRandomColor() : chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id && !m.cor) ? generateRandomColor() : !chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id) ? generateRandomColor() : chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id)?.cor,
        //             idResposta: chatStore.responder?.id ?? undefined
        //         }, clear);
        //         chatStore.definirMensagemParaResponder(null);
        //     } else if(authStore.usuario && grupoStore.selecionado)
        //     {
        //          await EnviarMensagem({
        //             usuarioNome: authStore.usuario.nome,
        //             maquina: localStorage.getItem("PA")!.toString(),
        //             remetenteId: authStore.usuario.id,
        //             mensagem: mensagem,
        //             status: 0,
        //             grupoSala: grupoStore.selecionado.id,
        //             cor: chatStore.mensagens.length === 0 ? generateRandomColor() : chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id && !m.cor) ? generateRandomColor() : !chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id) ? generateRandomColor() : chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id)?.cor,
        //             idResposta: chatStore.responder?.id ?? undefined
        //         }, clear);
        //         // 
        //         // 
        //         // 
        //         chatStore.definirMensagemParaResponder(null);
        //     }
        // }
    }


    const renderMenu = ({ onClose, left, top, className }: any, ref: any)  =>
    {
      return(
        <Popover arrow={false} ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu style={{width: 300}}>
            <Dropdown.Item onClick={() =>
            {
                onClose();
                toggleOpenModal();
            }} style={{padding: 10}} icon={<HiOutlinePhotograph style={{marginRight: 19, fontSize: 22}} className='dropdown-icon'/>}>Foto</Dropdown.Item>
            {/* <Dropdown.Item onClick={onClose} style={{padding: 10}} icon={<BsFileEarmarkArrowDown style={{marginRight: 19, fontSize: 25}} className='dropdown-icon'/>}>Arquivo</Dropdown.Item> */}
          </Dropdown.Menu>
      </Popover>
      )
    }

    // if(chatStore.loading)
    // {
    //     return <Loading message='Carregando ultimas mensagens... Aguarde!' /> 
    // }

    useEffect(() =>
    {
        document?.querySelector('#streamWriter')?.addEventListener('keydown', function (e: any) {
            if (e.keyCode === 13) {
                // Ctrl + Enter
                if(e.ctrlKey) {
                    // 
        
                // Enter
                } else {
                    // 
                }
            }
        });
    }, [])

    return (
        <Container>

        </Container>
    )
}

export default observer(MessageGeral);