import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import RamalAPI from "../api/RamalAPI";
import { Maquina, Ramal, RamalCentral, RamalGeral } from "../interfaces/Ramal.interface";

class RamalStore {

    ramais: Ramal[] = [];
    ramaisGeral: RamalGeral[] = [];
    ramaisCentral: RamalCentral[] = [];
    maquinas: Maquina[] = [];
    selecionado: Ramal | null = null;
    maquinaSelecionado: Maquina | null = null;
    ramalCentralSelecionado: RamalCentral | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listarRamalCentral() {
        try {
            const { data } = await RamalAPI.listarRamaisCentral();
            runInAction(() => {
                if(data)
                    this.ramaisCentral = data;
                else
                    this.ramaisCentral = [];
            });
        } catch (e) {
            //
        }
    }

    async listarRamalGeral() {
        this.loading = true;
        try {
            const { data } = await RamalAPI.listarRamaisGeral();
            runInAction(() => {
                if(data)
                    this.ramaisGeral = data;
                else
                    this.ramaisGeral = [];
                this.loading = false;
            });
        } catch (e) {
            this.loading = false;
        }
    }

    async listar() {
        this.loading = true;
        let condominioId = localStorage.getItem('condominio');
        try {
            if (condominioId) {
                const { data } = await RamalAPI.listarRamalPorCondominioId(condominioId);
                runInAction(() => {
                    if(data)
                        this.ramais = data;
                    else
                        this.ramais = [];
                    this.loading = false;
                });
            }
        } catch (e) {
            this.loading = false;
        }
    }

    definirSelecionado(ramalId: string) {
        let selecionado = this.ramais.find((ram) => ram.id === ramalId);
        if (selecionado) {
            this.selecionado = selecionado;
        }
    }

    definirMaquinaSelecionado(maquina: Maquina) {
        // let selecionado = this.maquinas.find((maq) => maq.id === maquinaId);
        // if(selecionado) {
        this.maquinaSelecionado = maquina;
        // }
    }

}

export default RamalStore;