import { Guarita } from "../interfaces/Guarita.interface";
import { Controladora, Dispositivos, DispositivosLista, Siam } from "../interfaces/Siam.interface";
import api from "../services/api/api";

class SiamAPI {

    listarPorCondominio = async (condominioId: string) => await api.get<Siam[]>('/Siam/condominio', { params: { condominioId } });
    listarDispositivosPorSiamId = async (siamId: string) => await api.get<DispositivosLista[]>(`/Siam/dispositivo/obterDispositivos/${siamId}`);
    listarControladoraPorCondominioId = async (condominioId: string) => await api.get<Controladora[]>(`/Siam/controladora/ObterControladora/condominio/${condominioId}`);
    listarPorId = async (siamId: string) => await api.get<Siam>('/Siam/condominio', { params: { id : siamId } });
    cadastrar = async (siam: Siam) => await api.post(`/Siam`, siam);
    atualizar = async (siam: Siam) => await api.put(`/Siam`, siam);
    deletar = async (siamId: string) => await api.delete(`/Siam/${siamId}`);


    // controladoras
    cadastrarControladora = async (controladora: Controladora) => await api.post(`/Siam/controladora/cadastrarControladora`, controladora);
    atualizarControladora = async (controladora: Controladora) => await api.put(`/Siam/controladora/atualizarControladora`, controladora);
    deletarControladora = async (controladoraId: string) => await api.delete(`/Siam/controladora/excluirControladora/${controladoraId}`);
}

export default new SiamAPI();