import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsCameraVideo } from 'react-icons/bs';
import { StoreContext } from '../../stores';
import { useCamera } from '../../hooks/Camera.hook';
import { Camera, CameraPerfilIdentificacaoRequest, CameraRequest } from '../../interfaces/Cameras.interface';
import { Layout } from '../../interfaces/Layout.interface';
import { FormCamera, SubTitle, Title } from './styles/Modal.style';
import { Button, Input, Loader, Modal, Popover, SelectPicker, Whisper } from 'rsuite';
import LayoutAPI from '../../api/LayoutAPI';

interface Props {
    show: boolean;
    hide: () => void;
}

const CadastrarCameraPerfilIdentificacao:FC<Props> = ({ show, hide }) => {

    const { cameraStore, layoutStore, condominioStore } = useContext(StoreContext);
    const [form, setForm] = useState<CameraPerfilIdentificacaoRequest>({});
    const [imgCam, setImgCam] = useState('');
    const [loading, setLoading] = useState(false);
    const { cadastrarPerfil, salvando } = useCamera();

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        cadastrarPerfil(form as any, hide);
    }

    useEffect(() =>
    {
        if(condominioStore.selecionado)
        {
            setForm({
                ...form,
                condominioId: condominioStore.selecionado.id
            })
        }
    }, [condominioStore.selecionado]);

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header style={{border: 0}} closeButton>
                <div>
                    <Title>Cadastro de perfil de câmeras <BsCameraVideo/> </Title>
                    <SubTitle>Cadastre novo perfil de câmera para esse condomínio</SubTitle>
                </div>
            </Modal.Header>
            <Modal.Body>
                <FormCamera onSubmit={salvar}>
                    <p className='info'>Preencha todos os campos abaixo</p>
                    <div>
                        <p className='title'>Nome do perfil<span className='text-danger'>*</span></p>
                        <Input required placeholder='Nome de identifição do perfil' value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })}  />
                    </div>

                    <div className='d-flex justify-content-end mt-3'>
                        <div className='d-flex'>
                            <Button disabled={salvando} type={'submit'} appearance="primary" className='m-1'>
                                {
                                    salvando
                                    ?
                                    <Loader title={'Salvando...'} />
                                    :
                                    'Salvar'
                                }
                            </Button>
                            <Button type={'button'} className='m-1' onClick={hide}>Fechar</Button>
                        </div>
                    </div>
                </FormCamera>

            </Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarCameraPerfilIdentificacao);
