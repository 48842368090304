import styled from "styled-components";
import PerfectScroll from "react-perfect-scrollbar";

export const Container = styled.div`
    padding: 10px;
`;

export const Header = styled.div`
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  .title
  {
    font-size: 12pt;
    margin: 0;
    font-weight: 550;
  }
  .subtitle
  {
    font-size: 9pt;
    margin: 0;
  }

  .box
  {
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    margin-top: 5px;
  }

  .content
  {
    padding: 20px;
    margin-top: 10px;
    width: 250px;
  }
  .content-icon
  {
    align-self: center;
    font-size: 25pt;
    margin-right: 20px;
  }
  .content-title
  {
    font-size: 10pt;
    margin: 0;
    font-weight: 550;
  }
  .content-subtitle
  {
    font-size: 9pt;
    margin: 0;
  }

  .info-title
  {
    font-size: 8pt;
    width: 80%;
  }
  .info-title-filtro
  {
    font-size: 8pt;
    width: 80%;
  }
`;

export const ListCameras = styled(PerfectScroll)`
  margin-top: 5px;
  align-self: center;
  border-radius: 10px;
  height: 65vh;
  // border-radius: 12px;
  margin-top: 8px;
  thead th {
    font-weight: 0;
  // background: ${(props) => props.theme.colors.bgBox};
  font-size: 13px;
    padding: 15px;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  } 
  tbody td {
    font-size: 12px;
  border: 0;
  padding: 16px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
  .header
  {
    background: ${(props) => props.theme.colors.bgBox};
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .title
  {
    font-size: 12pt;
    margin: 0;
    font-weight: 550;
  }
  .subtitle
  {
    font-size: 9pt;
    margin: 0;
  }
`;