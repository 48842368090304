import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite';
import { useBloco } from '../../../hooks/Bloco.hook';
import { useSiam } from '../../../hooks/Siam.hook';
import { Bloco, BlocoRequest } from '../../../interfaces/Bloco.interface';
import { Siam, SiamRequest } from '../../../interfaces/Siam.interface';
import { StoreContext } from '../../../stores';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarSiam: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<SiamRequest>({});
    const { atualizar, atualizando } = useSiam();
    const { condominioStore, siamStore } = useContext(StoreContext);

    useEffect(() =>
    {
        if(siamStore.selecionado) setForm(siamStore.selecionado);
    }, [siamStore.selecionado])

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as Siam, hide);
    }


    const FormSiam = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>IP da placa IDBM</Form.Label>
                        <Input required type="text" value={form.ipIdbm || ''} className='shadow-none' placeholder="192.168.0.0" onChange={(e) => setForm({...form, ipIdbm: e})} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta da placa IDBM</Form.Label>
                        <Input required type="text" value={form.portaIdbm} className='shadow-none' placeholder="Ex: 80" onChange={(e) => setForm({...form, portaIdbm: parseInt(e)})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Usuário</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.usuario || ''} placeholder="admin" onChange={(e) => setForm({...form, usuario: e})} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Senha</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.senha || ''} placeholder="admin" onChange={(e) => setForm({...form, senha: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.nome || ''} placeholder="Nome do siam" onChange={(e) => setForm({...form, nome: e})}  />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Fabricante</Form.Label>
                        <SelectPicker
                            block
                            placeholder={'Selecione o fabricante'}
                            onChange={(e) => setForm({...form, fabricante: e as any})} 
                            value={form.fabricante || ''}
                            data={
                                [
                                    {
                                        label: 'SIAM',
                                        value: 'siam'
                                    }
                                ]
                            }
                        />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.modelo || ''} placeholder="Modelo do siam" onChange={(e) => setForm({...form, modelo: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip do servidor</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.ipServidor || ''} placeholder="Modelo do siam" onChange={(e) => setForm({...form, ipServidor: e})} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta do servidor</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.portaServidor || ''} placeholder="Ip do servidor" onChange={(e) => setForm({...form, portaServidor: parseInt(e)})} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end'>
                    <Button appearance="primary" disabled={atualizando} type="submit">
                        { atualizando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={atualizando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                {atualizando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastrar novo equipamento SIAM</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormSiam()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarSiam);