import React from 'react';

const RenderComandos = (tipo: number) => {
    switch (tipo) {
        case 0:
            return 'Guarita Direta';
        case 1:
            return 'Guarita Servidor';
        case 2:
            return 'ControlId';
        case 3:
            return 'AnvizT5';
        case 4:
            return 'HikvisonVandal';
        case 5:
            return 'HikvisonFacial';
        case 6:
            return 'IntelbrasFacial';
        case 9:
            return 'Siam';
        case 10:
            return 'Automação';
        case 11:
            return 'Bravas';
        case 12:
            return 'Mip1000';
        case 13:
            return 'ControlIdFacial';
    }
}

export default RenderComandos;
