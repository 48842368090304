import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import VeiculoAPI from "../api/VeiculoAPI";
import { Cores } from "../interfaces/Cor.interface";
import { Marcas } from "../interfaces/Marca.interface";
import { Veiculo, VeiculoRelatorio } from "../interfaces/Veiculo.interface";

class VeiculoStore {

    veiculos: Veiculo[] = [];
    veiculosCondominio: Veiculo[] = [];
    controles: VeiculoRelatorio[] = [];
    selecionado: Veiculo | null = null;
    cores: Cores[] = [];
    marcas: Marcas[] = [];
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listarPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            const { data } = await VeiculoAPI.listarVeiculoPorCondominio(condominioId);
            runInAction(() => {
                if(data)
                {
                    this.veiculosCondominio = data;
                    this.veiculosCondominio.forEach((veiculo) =>
                    {
                        veiculo.unidadeIdentificacao = `${veiculo.apartamento} - ${veiculo.bloco}`
                    })
                }
                else
                {
                    this.veiculosCondominio = [];
                }
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async listar(unidadeId: string) {
        this.loading = true;
        try {
            const { data } = await VeiculoAPI.listarVeiculoPorUnidade(unidadeId);
            runInAction(() => {
                if(data)
                {
                    this.veiculos = data;
                    this.veiculos.forEach((veiculo) =>
                    {
                        veiculo.unidadeIdentificacao = `${veiculo.apartamento} - ${veiculo.bloco}`
                    })
                }
                else
                {
                    this.veiculos = [];
                }
                setTimeout(() =>
                {
                    this.loading = false;
                }, 1000)
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async listarRelatorioControle(unidadeId: string) {
        this.loading = true;
        try {
            const { data } = await VeiculoAPI.listarRelatorioControleVeiculoPorUnidade(unidadeId);
            runInAction(() => {
                if(data)
                    this.controles = data;
                else
                    this.controles = data;
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async listarCorMarca() {
        try {
            const { data } = await VeiculoAPI.listarCores();
            const request = await VeiculoAPI.listarMarcas();
            runInAction(() => {
                this.cores = data;
                this.marcas = request.data
            });
        } catch(e) {
            
        }
    }


    definirSelecionado(veiculoId: string) {
        let selecionado = this.veiculos.find((vei) => vei.id === veiculoId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
    }

}

export default VeiculoStore;