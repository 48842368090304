import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, List, Loader, Modal } from 'rsuite';
import useAcionamento from '../../hooks/Acionamento.hook';
import { useMorador } from '../../hooks/Morador.hook';
import { useUnidade } from '../../hooks/Unidade.hook';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import { StoreContext } from '../../stores';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarOrdem: FC<ModalProps> = ({ show, hide }) => {

    const { acionamentoStore, condominioStore } = useContext(StoreContext);
    const [form, setForm] = useState<UnidadeRequest>({});
    const { atualizarOrdemAcionamento, atualizando } = useAcionamento();

    const handleSortEnd = ({ oldIndex, newIndex } : any) =>
    {
        acionamentoStore.acionamentos = arrayMoveImmutable(acionamentoStore.acionamentos , oldIndex, newIndex);
        acionamentoStore.acionamentos.map((_, i) => _.ordem = i);
        atualizarOrdemAcionamento(acionamentoStore.acionamentos );
    }

    const Listagem = () => {

        if(atualizando)
        {
          return(
            <div className={'d-flex justify-content-center mt-5'}>
              <Loader content={'Salvando nova ordem dos ramais... aguarde!'} />
            </div>
          )
        }

        return(
            <List sortable onSort={handleSortEnd}>
                {
                    acionamentoStore.acionamentos .map((acionamento, i) =>
                    <List.Item style={{background: 'transparent', borderBottom: '1px solid rgba(0,0,0,0.2)', margin: 4}} className='p-3' key={i} index={i}>{acionamento.nome?.toUpperCase()}</List.Item>)
                }
            </List>
        )
    }

    return(
        <Modal open={show} onClose={() => atualizando ? {} : hide()}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar ordem dos acionamentos do condomínio { condominioStore.selecionado?.nome }</p>
                    <p className='modal-styled-subtitle'>
                        Arraste para cima ou para baixo para alterar ordem dos ramais
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{Listagem()}</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => atualizando ? {} : hide()}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(AtualizarOrdem);