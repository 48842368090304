import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite';
import { Bravas, BravasRequest } from '../../../../interfaces/Bravas.interface';
import { StoreContext } from '../../../../stores';
import { Mip1000Rele, Mip1000ReleRequest } from '../../../../interfaces/Mip1000.interface';
import { useMip1000 } from '../../../../hooks/Mip100.hook';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarRele: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<Mip1000ReleRequest>({});
    const { atualizarRele, atualizando } = useMip1000();
    const { mip1000Store } = useContext(StoreContext);

    const numberList = Array.from({ length: 26 }, (_, index) => index + 1);

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizarRele(form as Mip1000Rele, hide);
    }

    useEffect(() =>
    {
        if(mip1000Store.selecionadoRele)
            setForm(mip1000Store.selecionadoRele);
    }, [mip1000Store.selecionadoRele]);


    const FormCard = () => {
        return (
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" value={form.nome || ''} className='shadow-none' placeholder="Ex: Rele 1" onChange={(e) => setForm({ ...form, nome: e })} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Endereço Dispositivo</Form.Label><br/>
                        <SelectPicker block data={numberList.map(x => ({ label: x, value: x }))}  value={form.enderecoDispositivo} onChange={(e) => setForm({ ...form, enderecoDispositivo: e })} />
                        {/* <Input required type="text" className='shado-none' value={form.enderecoDispositivo || ''} placeholder="0" onChange={(e) => setForm({ ...form, enderecoDispositivo: e })} /> */}
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-4'>
                    <Button appearance="primary" disabled={atualizando} type="submit">
                        {atualizando ? 'Salvando...' : 'Salvar'}
                    </Button>
                    <Button disabled={atualizando} type="button" onClick={hide} style={{ marginLeft: 10 }}>
                        Fechar
                    </Button>
                </div>
                {/* {salvando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''} */}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar rele no MIP 1000 - IP</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormCard()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarRele);