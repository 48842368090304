import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import VeiculoAPI from "../api/VeiculoAPI";
import VisitanteAPI from "../api/VisitanteAPI";
import { Cores } from "../interfaces/Cor.interface";
import { Marcas } from "../interfaces/Marca.interface";
import { Veiculo } from "../interfaces/Veiculo.interface";
import { Autorizacao, Visitante } from "../interfaces/Visitante.interface";

class VisitanteStore {

    visitantes: Visitante[] = [];
    autorizados: Autorizacao[] = [];
    selecionado: Visitante | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listarAutorizadoPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            const { data } = await VisitanteAPI.listarAutorizacaoPorUnidade(unidadeId);
            runInAction(() => {
                if(data)
                {
                    this.autorizados = data;
                    this.autorizados.forEach((autorizacao) =>
                    {
                        autorizacao.acionamentos = JSON.parse(autorizacao.acionamentos as any);
                    })
                }
                else
                {
                    this.autorizados = [];
                }
                this.loading = false;
            });
        } catch(e) {
            
            this.loading = false;
        }
    }

    async listarAutorizadoPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            const { data } = await VisitanteAPI.listarAutorizacaoPorCondominio(condominioId);
            runInAction(() => {
                if(data)
                {
                    this.autorizados = data;
                    this.autorizados.forEach((autorizacao) =>
                    {
                        autorizacao.acionamentos = JSON.parse(autorizacao.acionamentos as any);
                    })
                }
                else
                {
                    this.autorizados = [];
                }
                this.loading = false;
            });
        } catch(e) {
            
            this.loading = false;
        }
    }

    async listarPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            const { data } = await VisitanteAPI.listarPorUnidade(unidadeId);
            runInAction(() => {
                if(data)
                    this.visitantes = data;
                else
                    this.visitantes = []; 
                this.loading = false;
            });
        } catch(e) {
            
            this.loading = false;
        }
    }

    async listarPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            const { data } = await VisitanteAPI.listarPorCondominio(condominioId);
            runInAction(() => {
                if(data)
                    this.visitantes = data;
                else
                    this.visitantes = []; 
                this.loading = false;
            });
        } catch(e) {
            
            this.loading = false;
        }
    }

    definirSelecionado(VisitanteId: string) {
        let selecionado = this.visitantes.find((vst) => vst.id === VisitanteId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
    }

}

export default VisitanteStore;