import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect } from 'react'
import { Button, Modal } from 'rsuite';
import { StoreContext } from '../../stores';
import RenderTipoModulo from '../utils/RenderTipoModulo';
import SkeletonList from '../utils/SkeletonList';
import { Container } from './styles/InfoComando.style';
import { ETipoModulo } from '../../enums/ETipoModulo';

interface Props
{
    show: boolean;
    hide: () => void;
}

const InfoComando: FC<Props> = ({ show, hide }) => {

    const { comandoStore, releStore, automacaoAcionamentoStore, bravasStore, mip1000Store } = useContext(StoreContext);

    useEffect(() =>
    {
        if(comandoStore.selecionado && show)
        {
            
            if(comandoStore.selecionado.tipo === 10)
                automacaoAcionamentoStore.listar(comandoStore.selecionado.caminhoId);
            else if(comandoStore.selecionado.tipo === 11)
                bravasStore.obterInfoBravasRele(comandoStore.selecionado.caminhoId)
            else if(comandoStore.selecionado.tipo === 12)
                mip1000Store.obterRelePorId(comandoStore.selecionado.caminhoId)
            else
                releStore.listarPorId(comandoStore.selecionado.caminhoId)
        }
    }, [comandoStore.selecionado, show]);

    const renderInfo = () =>
    {

        if(comandoStore.selecionado?.tipo === 10)
        {
            return(
                <div>
                    <div>
                        <p>Ordem da programação da automação</p>
                    </div>
                    {
                        automacaoAcionamentoStore.automacaoAcionamento.map((a, i) =>
                        (
                            <div key={i}>
                                <p style={{margin: 0, marginTop: 10}}>{i}° - {a.nome}</p>
                                <p style={{margin: 0}}>Tempo após comando: <b>{a.delay} - milissegundo</b></p>
                            </div>
                        ))
                    }
                </div>
            )
        }

        if(comandoStore.selecionado?.tipo === 11)
        {

            if(bravasStore.loading)
                return <SkeletonList/>

            return(
                <div>
                    <div>
                        <p>Informações das portas de acesso do módulo BRAVAS</p>
                    </div>
                    <p className='mt-2'><b>Nome do rele:</b> {bravasStore.rele?.nome}</p>
                    <p><b>Número do rele:</b> {bravasStore.rele?.numeroRele}</p>
                </div>
            )
        }

        if(comandoStore.selecionado?.tipo === 12)
        {

            if(bravasStore.loading)
                return <SkeletonList/>

            return(
                <div>
                    <div>
                        <p>Informações das portas de acesso do módulo MIP1000</p>
                    </div>
                    <p className='mt-2'><b>Nome do rele:</b> {mip1000Store.infoRele?.nome}</p>
                    <p><b>Número do rele:</b> {mip1000Store.infoRele?.enderecoDispositivo}</p>
                </div>
            )
        }

        return(
            <Container>
                <div className='box-content'>
                    <p className='content'><span className='info'>Nome do Rele: </span>{releStore.relePorId?.nome}</p>
                    {releStore.relePorId?.niceModulo.tipo && <p className='content'><span className='info'>Modulo: </span>{ETipoModulo[releStore.relePorId?.niceModulo.tipo]}</p>}
                    {releStore.relePorId?.niceModulo.enderecoCAN && <p className='content'><span className='info'>Endereço CAN: </span>{releStore.relePorId?.niceModulo.enderecoCAN}</p>}
                    <p className='content'><span className='info'>Rele: </span>{releStore.relePorId?.numeroRele}</p>
                </div>
            </Container>
        )
    }

    return (
        <Modal
            open={show}
            onClose={hide}
        >
            <Modal.Header>
            <Modal.Title>Informações do comando</Modal.Title>
            <p style={{fontWeight: 550, fontSize: 18, marginTop: 15}}>{comandoStore.selecionado?.acionamentoNome}</p>
            </Modal.Header>
            <Modal.Body>
                {renderInfo()}
            </Modal.Body>
            <Modal.Footer>
                <Button color="red" appearance="primary" onClick={hide}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(InfoComando);