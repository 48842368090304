import { FormControlLabel, Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react'
import { BsPhone } from 'react-icons/bs';
import { Button, CheckTreePicker, Input, InputPicker, Loader, Modal, Toggle } from 'rsuite';
import { useAuthentication } from '../../hooks/Auth.hook';
import { usePermissoes } from '../../hooks/Permissoes.hook';
import { DadosSms, DadosSmsRequest } from '../../interfaces/Auth.interface';
import { Permissoes, PermissoesRequest } from '../../interfaces/Permissoes.interface';
import { StoreContext } from '../../stores';
import Loading from '../utils/Loading';
import { cpfMask, maskCelular } from '../utils/mask';
import NotFound from '../utils/NotFound';
import { Container } from './styles/PermissoesListagem.style';

interface Props
{
    show: boolean;
    hide: () => void;
}


const PermissoesListagem: FC<Props> = ({ show, hide }) => {

  const { moradorStore } = useContext(StoreContext);
  const { enviarSmsEmail, carregando, atualizarSenhaUsuario } = useAuthentication();
  const { buscarPorId, permissoes, setPermissoes, salvando, cadastrar, atualizar, loading, vazio } = usePermissoes();
  const [dados, setDados] = useState<DadosSmsRequest>({});
  const [mode, setMode] = useState(null);
  const [novaSenha, setNovaSenha] = useState('');

  useEffect(() =>
  {
    if(show && moradorStore.selecionado)
    {
      buscarPorId(moradorStore.selecionado.id);
      setMode(null);
    }
  }, [show, moradorStore.selecionado]);

  const salvaPermissoes = () =>
  {
    let newObject: PermissoesRequest = 
    {
      moradorId: moradorStore.selecionado?.id,
      ...permissoes
    }
    cadastrar(newObject as Permissoes, hide);
   
  }

  const enviarSms = () =>
  {
    enviarSmsEmail(dados as DadosSms);
  }

  const atualizarSenha = () =>
  {
    if(moradorStore.selecionado && moradorStore.selecionado?.usuarioId !== null)
    {
      // 
      // 
      atualizarSenhaUsuario(moradorStore.selecionado, novaSenha);
    }
    else
    {
      alert('Morador sem usuário');
    }
  }

  const atualizarPermissoes = () =>
  {
    let newObject: PermissoesRequest = 
    {
      moradorId: moradorStore.selecionado?.id,
      ...permissoes
    }
    atualizar(newObject as Permissoes, hide);
  }

  const selecionarTodos = () =>
  {
    setPermissoes({
      ...permissoes,
      acionamentos: true,
      agenda: true,
      ajustes: true,
      cadastros: true,
      cameras: true,
      chat: true,
      chaves: true,
      morador: true,
      pets: true,
      qrcode: true,
      relatorios: true,
      veiculos: true,
      visitas: true
    })
  }

  const desmarcarTodos = () =>
  {
    setPermissoes({
      ...permissoes,
      acionamentos: false,
      agenda: false,
      ajustes: false,
      cadastros: false,
      cameras: false,
      chat: false,
      chaves: false,
      morador: false,
      pets: false,
      qrcode: false,
      relatorios: false,
      veiculos: false,
      visitas: false
    })
  }

  const renderForm = () =>
  {

    if(vazio)
    {
      // return <NotFound message={'Morador sem nenhum tipo de autorização ao APP'} />
      return(
        <div>
          <div className='d-flex justify-content-center mb-3'>
            <NotFound message={'Morador sem nenhum tipo de autorização ao APP'} />
          </div>
          <div className='d-flex justify-content-center mb-3'>
            <small>Cadastre novas autorizações que usuário pode ter no aplicativo</small>
          </div>
          <Container>
          <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar agenda" checked={permissoes.agenda} unCheckedChildren="Habilitar agenda" onChange={(e) => setPermissoes({ ...permissoes, agenda: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar acionamentos" checked={permissoes.acionamentos} unCheckedChildren="Habilitar acionamentos" onChange={(e) => setPermissoes({ ...permissoes, acionamentos: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar câmeras" checked={permissoes.cameras} unCheckedChildren="Habilitar câmeras" onChange={(e) => setPermissoes({ ...permissoes, cameras: e })} />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar morador" checked={permissoes.morador} unCheckedChildren="Habilitar morador" onChange={(e) => setPermissoes({ ...permissoes, morador: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar pets" checked={permissoes.pets} unCheckedChildren="Habilitar pets" onChange={(e) => setPermissoes({ ...permissoes, pets: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar visitas" checked={permissoes.visitas} unCheckedChildren="Habilitar pets" onChange={(e) => setPermissoes({ ...permissoes, visitas: e })} />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar veiculos" checked={permissoes.veiculos} unCheckedChildren="Habilitar veiculos" onChange={(e) => setPermissoes({ ...permissoes, veiculos: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar chaves" checked={permissoes.chaves} unCheckedChildren="Habilitar chaves" onChange={(e) => setPermissoes({ ...permissoes, chaves: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar chat" checked={permissoes.chat} unCheckedChildren="Habilitar chat" onChange={(e) => setPermissoes({ ...permissoes, chat: e })} />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar cadastros" checked={permissoes.cadastros} unCheckedChildren="Habilitar chat" onChange={(e) => setPermissoes({ ...permissoes, cadastros: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar qrCode" checked={permissoes.qrcode} unCheckedChildren="Habilitar qrCode" onChange={(e) => setPermissoes({ ...permissoes, qrcode: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar relatórios" checked={permissoes.relatorios} unCheckedChildren="Habilitar relatórios" onChange={(e) => setPermissoes({ ...permissoes, relatorios: e })} />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar ajustes" checked={permissoes.ajustes} unCheckedChildren="Habilitar ajustes" onChange={(e) => setPermissoes({ ...permissoes, ajustes: e })} />
            </div>
          </div>



            <div className='d-flex justify-content-center mt-3'>
              <Button color="blue" appearance="primary" disabled={salvando} className='btn btn-outline-success shadow-none' onClick={salvaPermissoes}>
                {salvando ? 'Salvando...' : 'Salvar'}
              </Button>
            </div>
            
          </Container>
        </div>
      )
    }

    if(loading)
    {
      return <Loading message='Carregando permissões do morador...' />
    }

    return(
      <Container>
        <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar agenda" checked={permissoes.agenda} unCheckedChildren="Habilitar agenda" onChange={(e) => setPermissoes({ ...permissoes, agenda: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar acionamentos" checked={permissoes.acionamentos} unCheckedChildren="Habilitar acionamentos" onChange={(e) => setPermissoes({ ...permissoes, acionamentos: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar câmeras" checked={permissoes.cameras} unCheckedChildren="Habilitar câmeras" onChange={(e) => setPermissoes({ ...permissoes, cameras: e })} />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar morador" checked={permissoes.morador} unCheckedChildren="Habilitar morador" onChange={(e) => setPermissoes({ ...permissoes, morador: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar pets" checked={permissoes.pets} unCheckedChildren="Habilitar pets" onChange={(e) => setPermissoes({ ...permissoes, pets: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar visitas" checked={permissoes.visitas} unCheckedChildren="Habilitar pets" onChange={(e) => setPermissoes({ ...permissoes, visitas: e })} />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar veiculos" checked={permissoes.veiculos} unCheckedChildren="Habilitar veiculos" onChange={(e) => setPermissoes({ ...permissoes, veiculos: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar chaves" checked={permissoes.chaves} unCheckedChildren="Habilitar chaves" onChange={(e) => setPermissoes({ ...permissoes, chaves: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar chat" checked={permissoes.chat} unCheckedChildren="Habilitar chat" onChange={(e) => setPermissoes({ ...permissoes, chat: e })} />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar cadastros" checked={permissoes.cadastros} unCheckedChildren="Habilitar chat" onChange={(e) => setPermissoes({ ...permissoes, cadastros: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar qrCode" checked={permissoes.qrcode} unCheckedChildren="Habilitar qrCode" onChange={(e) => setPermissoes({ ...permissoes, qrcode: e })} />
            </div>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar relatórios" checked={permissoes.relatorios} unCheckedChildren="Habilitar relatórios" onChange={(e) => setPermissoes({ ...permissoes, relatorios: e })} />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className='content'>
              <Toggle size="lg" checkedChildren="Desabilitar ajustes" checked={permissoes.ajustes} unCheckedChildren="Habilitar ajustes" onChange={(e) => setPermissoes({ ...permissoes, ajustes: e })} />
            </div>
          </div>


        <div className='d-flex justify-content-center mt-3'>
          <Button color={'green'} appearance="primary" disabled={salvando} className='shadow-none m-1' onClick={selecionarTodos}>
              Selecionar todos
          </Button>
          <Button disabled={salvando} color="blue" appearance="primary" className='btn btn-outline-success shadow-none m-1' onClick={atualizarPermissoes}>
            {salvando ? 'Salvando...' : 'Salvar'}
          </Button>
          <Button color={'red'} appearance="primary" disabled={salvando} className='shadow-none m-1' onClick={desmarcarTodos}>
              Desmarcar todos
          </Button>
        </div>
      </Container>
    )
  }

  const renderAlterarSenha = () =>
  {
    return(
      <Container>
        {
          moradorStore.selecionado?.usuarioId === null
          ?
            'Morador sem usuário'
          :
          <>
            <p>Selecione o modo de envio da nova senha do morador</p>
            <InputPicker
              block
              placeholder={'Informe o modo de alteração de senha'}
              value={mode}
              data={[
                {
                  label: 'Digitar nova senha',
                  value: 'digito'
                },
                {
                  label: 'Enviar por email ou SMS',
                  value: 'smsemail'
                }
              ]}
              onChange={(e) => setMode(e)}
            />
            {
              mode !== null && mode === 'smsemail'
              ?
              <>
                <InputPicker 
                  className='mt-2'
                  placeholder={'Selecione'}
                  data={[
                    {
                      label: moradorStore.selecionado?.celular ? maskCelular(moradorStore.selecionado?.celular) : 'Morador sem celular cadastrado',
                      value: moradorStore.selecionado?.celular
                    },
                    {
                      label: moradorStore.selecionado?.email ? moradorStore.selecionado?.email : 'Morador sem email cadastrado',
                      value: moradorStore.selecionado?.email
                    },
                    {
                      label: moradorStore.selecionado?.cpf ? cpfMask(moradorStore.selecionado?.cpf) : 'Morador sem cpf cadastrado',
                      value: moradorStore.selecionado?.cpf
                    },
                  ]} block onChange={(e) => setDados({ dados: e, primeiroAcesso: false })} />
                <Button className={'mt-2'} disabled={!dados.dados} onClick={() => enviarSms()}>{ carregando ? <Loader content="Enviando a nova senha..." /> : 'Enviar' }</Button>
              </>
              :
              <>
                {
                  mode === null
                  ?
                  ''
                  :
                  <div className='mt-2'>
                    <Input placeholder='Informe a nova senha para o morador' onChange={(e) => setNovaSenha(e)} />
                    <Button className={'mt-2'} disabled={novaSenha.length < 6} onClick={() => atualizarSenha()}>{ carregando ? <Loader content="Atualizando senha..." /> : 'Atualizar' }</Button>
                  </div>
                }
              </>
            }
          </>
        }
      </Container>
    )
  }

  return (
      <Modal backdrop={'static'} size={'lg'} keyboard={false} open={show} onClose={hide}>
        <Modal.Header closeButton>
          <p className='m-0 mt-2 mb-2'><BsPhone /> Controle de acesso do APP Hiseg</p>
        </Modal.Header> 
        
        <div>
          <p className='m-0 mt-2 mb-2'>Acessos</p>
          {renderForm()}
          <p className=' m-0 mt-2 mb-2'>Enviar nova senha</p>
          {renderAlterarSenha()}
          <div className='d-flex justify-content-end m-0 mt-2 mb-2'>
            <Button appearance='primary' color='blue' onClick={hide}>Fechar</Button>
          </div>
        </div>
      </Modal>
  )
}

export default observer(PermissoesListagem);
