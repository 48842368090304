import api from "../services/api/api";
import { Acionamento } from "../interfaces/Acionamento.interface";
import { Captura, Mip1000, Mip1000Rele } from "../interfaces/Mip1000.interface";

class Mip1000API {

    listarPorCondominio = async (condominioId: string) => await api.get<Mip1000[]>(`/Mip1000/obterporcondominioid/${condominioId}`);

    capturarSerial = async (equipamentoId: string) => await api.get<Captura>(`/Mip1000/controleleitura/${equipamentoId}`);

    adicionar = async (request: Mip1000) => await api.post<Mip1000>(`/Mip1000`, request);

    atualizar = async (request: Mip1000) => await api.put<boolean>(`/Mip1000`, request);

    deletar = async (id: string) => await api.delete<boolean>(`/Mip1000/${id}`);

    listarRelePorMip1000Id = async (condominioId: string) => await api.get<Mip1000Rele[]>(`/Mip1000Rele/mip1000Id?mip1000Id=${condominioId}`);

    obterRelePorId = async (condominioId: string) => await api.get<Mip1000Rele>(`/Mip1000Rele/${condominioId}`);

    obterMuId = async (id: string) => await api.put(`/Mip1000/atualizarmuid/${id}`);
    
    adicionarRele = async (request: Mip1000Rele) => await api.post<Mip1000Rele>(`/Mip1000Rele`, request);

    atualizarRele = async (request: Mip1000Rele) => await api.put<boolean>(`/Mip1000Rele`, request);

    deletarRele = async (id: string) => await api.delete<boolean>(`/Mip1000Rele/${id}`);

}

export default new Mip1000API();