import styled from 'styled-components';

export const Container = styled.div`
    padding: 10px;
    margin: 20px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
`

export const Header = styled.div`
    padding: 10px;
    background: ${(props) => props.theme.colors.bgBox};
    border-radius: 7px;
    margin-top: 10px;
    box-shadow: 1px 3px 5px rgba(0,0,0,0.0200);
    .header
    {
        background: ${(props) => props.theme.colors.bgBox};
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
        padding: 10px;
        width: 100%;
    }
    .title {
        font-size: 11pt;
        font-weight: 550;
        width: 22%;
        margin: 0;
    }
    .subtitle {
        font-size: 9pt;
        margin: 0;
    }
    .option
    {
        display: flex;
    }
`

export const ListLayout = styled.div`
    border-radius: 4px;
    margin-top: 20px;
    background: ${(props) => props.theme.colors.bgColor};
    thead th {
        font-weight: 0;
        font-size: 13px;
        padding: 10px;
        border-bottom: 1px solid ${(props) => props.theme.colors.bgNav};
    } 
    tbody td {
        font-size: 12px;
        padding: 12px;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    }
`