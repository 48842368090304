import { useContext, useState } from "react";
import { toast } from "react-toastify";
import CameraAPI from "../api/CameraAPI";
import ComandoAPI from "../api/ComandoAPI";
import { StoreContext } from "../stores";
import { Camera, CameraPerfilIdentificacao, CameraPerfilLista, NovaOrdem } from "../interfaces/Cameras.interface";
import { Comando } from "../interfaces/Comando.interface";


export const useCamera = () => {

    const { cameraStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    
    const cadastrar = async (camera: Camera, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await CameraAPI.cadastrar(camera);
            if(status === 200) {
                cameraStore.listarPorLayout(camera.layoutId);
                setSalvando(false);
                hide();
                toast.success('Câmera cadastrado com sucesso!')
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar comando')
        }
    }

    const cadastrarPerfil = async (perfil: CameraPerfilIdentificacao, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await CameraAPI.cadastrarPerfil(perfil);
            if(status === 200) {
                cameraStore.listarCamerasPerfisIdentificacaoPorCondominioId(perfil.condominioId);
                setSalvando(false);
                hide();
                toast.success('Perfil cadastrado com sucesso!')
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar perfil')
        }
    }

    const cadastrarCameraPerfil = async (cameraPerfil: CameraPerfilLista, hide: () => void, clear: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await CameraAPI.cadastrarCameraPerfil(cameraPerfil);
            if(status === 200) {
                cameraStore.listarCamerasPerfilPorCamerasPerfisIdentificacao(cameraPerfil.perfis[0].cameraPerfilIdentificacaoId);
                setSalvando(false);
                clear();
                hide();
                toast.success('Câmera cadastrado no perfil com sucesso!')
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar câmera no perfil')
        }
    }

    const atualizar = async (camera: Camera, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await CameraAPI.atualizar(camera);
            if(status === 200) {
                cameraStore.cameras = cameraStore.cameras.map(cam => cam.id === camera.id ? camera : cam);
                setAtualizando(false);
                hide();
                toast.success('Câmera atualizado com sucesso!')
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar câmera')
        }
    }

    const atualizarOrdem = async (ordem: NovaOrdem, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await CameraAPI.atualizarOrdem(ordem);
            if(status === 200) {
                setAtualizando(false);
                hide();
                toast.success('Ordem atualizado com sucesso!')
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar ordem')
        }
    }

    const atualizarPerfil = async (perfil: CameraPerfilIdentificacao, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await CameraAPI.atualizarPerfil(perfil);
            if(status === 200) {
                cameraStore.camerasPerfisIdentificacao = cameraStore.camerasPerfisIdentificacao.map(p => p.id === perfil.id ? perfil : p);
                setAtualizando(false);
                hide();
                toast.success('Perfil atualizado com sucesso!')
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar perfil')
        }
    }

    const deletar = async (camera: Camera) => {
        setDeletando(true);
        try {
            const { status } = await CameraAPI.deletar(camera.id);
            if(status === 200) {
                cameraStore.cameras = cameraStore.cameras.filter(cam => cam.id !== camera.id);
                setDeletando(false);
                toast.success('Câmera deletado com sucesso!')
            }
        } catch(e) {
            setDeletando(false);
            toast.error('Erro ao deletar câmera')
        }
    }

    const deletarPerfil = async (perfil: CameraPerfilIdentificacao, hide: () => void) => {
        setDeletando(true);
        try {
            const { status } = await CameraAPI.deletarPerfil(perfil.id);
            if(status === 200) {
                cameraStore.camerasPerfisIdentificacao = cameraStore.camerasPerfisIdentificacao.filter(p => p.id !== perfil.id);
                setDeletando(false);
                toast.success('Perfil deletado com sucesso!');
                hide();
            }
        } catch(e) {
            setDeletando(false);
            toast.error('Erro ao deletar perfil')
        }
    }

    const deletarCameraPerfil = async (id: string) => {
        setDeletando(true);
        try {
            const { status } = await CameraAPI.deletarCameraPerfil(id);
            if(status === 200) {
                cameraStore.camerasPerfil = cameraStore.camerasPerfil.filter(p => p.id !== id);
                setDeletando(false);
                toast.success('Perfil deletado com sucesso!')
            }
        } catch(e) {
            setDeletando(false);
            toast.error('Erro ao deletar perfil')
        }
    }
    
    return {
        cadastrar,
        cadastrarPerfil,
        atualizar,
        deletar,
        cadastrarCameraPerfil,
        atualizarPerfil,
        atualizarOrdem,
        deletarPerfil,
        deletarCameraPerfil,
        salvando,
        atualizando,
        deletando
    }

}