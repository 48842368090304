import react, { useContext, useState } from 'react';
import { Avatar, LinearProgress, Menu } from '@mui/material';
import { BsDoorOpen, BsHddNetwork, BsTags, BsThreeDots } from 'react-icons/bs';
import { Card, Container, Header, List } from './styles/DispositivoCard.style';
import { StoreContext } from '../../stores';
import { observer } from 'mobx-react-lite';
import { VscEllipsis } from 'react-icons/vsc';
import { Anviz, ControlId, Hikvision } from '../../interfaces/Equipamento.interface';
import useControlId from '../../hooks/Equipamento.hook';
import Loading from '../utils/Loading';
import CadastrarControlId from './controlId/CadastrarControlId';
import AtualizarControlId from './controlId/AtualizarControlId';
import CadastrarHikvision from './hikvision/CadastrarHikvision';
import AtualizarHikvision from './hikvision/AtualizarHikvision';
import NotFound from '../utils/NotFound';
import { Button, Drawer, Dropdown, IconButton, Loader, Modal, Nav, Popover, SelectPicker, Timeline, Whisper } from 'rsuite';
import { FaPlus, FaUser } from 'react-icons/fa';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import { AiOutlineCheck, AiOutlineReload } from 'react-icons/ai';
import { MdFingerprint, MdOutlineRouter, MdWarning } from 'react-icons/md';
import { useDispositivo } from '../../hooks/Dispositivo.hook';
import { BiErrorCircle } from 'react-icons/bi';
import CadastrarIntelbras from './intelbras/CadastrarIntelbras';
import AtualizarIntelbras from './intelbras/AtualizarIntelbras';
import PlusIcon from '@rsuite/icons/Plus';
import HistoryIcon from '@rsuite/icons/History';
import MoreIcon from '@rsuite/icons/More';
import ReloadIcon from '@rsuite/icons/Reload';
import GearIcon from '@rsuite/icons/Gear';
import ProjectIcon from '@rsuite/icons/Project';
import SkeletonList from '../utils/SkeletonList';
import ExportIcon from '@rsuite/icons/Export';
import CadastrarAnviz from './anviz/CadastrarAnviz';
import AtualizarAnviz from './anviz/AtualizarAnviz';
import ModalConfigEvento from './intelbras/ModalConfigEvento';
import { Table } from 'react-bootstrap';

const DispositivoCard = () => {

    let condominioId = localStorage.getItem('condominio');

    const { equipamentoStore, themeStore, condominioStore, dispositivosStore } = useContext(StoreContext);
    const { deletarControlId, deletandoControlId, deletarHikvision, deletandoHikvision, deletarIntelbras, deletandoIntelbras, deletarAnviz, deletandoAnviz } = useControlId();
    const { sincronizarTagsCondominio, sincronizando, sincronizarBiometriasCondominio, openRelatorio, setOpenRelatorio, reiniciarControlId, reiniciarIntelbras, sincronizarDataHoraIntelbras } = useDispositivo();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElHikvision, setAnchorElHikvision] = useState<null | HTMLElement>(null);
    const [anchorElAnviz, setAnchorElAnviz] = useState<null | HTMLElement>(null);
    const [routes, setRoutes] = useState('controlid');
    const [modalDeletarControlId, setModalDeletarControlId] = useState(false);
    const [modalDeletarHikvision, setModalDeletarHikvision] = useState(false);
    const [modalDeletarIntelbras, setModalDeletarIntelbras] = useState(false);
    const [modalDeletarAnviz, setModalDeletarAnviz] = useState(false);
    const [modalConfigIntelbras, setModalConfigIntelbras] = useState(false);


    const [cadastrarControlId, setCadastrarControlId] = useState(false);
    const [cadastrarHikvision, setCadastrarHikvision] = useState(false);
    const [cadastrarIntelbras, setCadastrarIntelbras] = useState(false);
    const [cadastrarAnviz, setCadastrarAnviz] = useState(false);

    const [atualizarControlId, setAtualizarControlId] = useState(false);
    const [atualizarHikvision, setAtualizaHikvision] = useState(false);
    const [atualizarIntelbras, setAtualizaIntelbras] = useState(false);
    const [atualizarAnviz, setAtualizarAnviz] = useState(false);

    const toggleCadastrarControlId = () => setCadastrarControlId(!cadastrarControlId);
    const toggleCadastrarHikvision = () => setCadastrarHikvision(!cadastrarHikvision);
    const toggleCadastrarIntelbras = () => setCadastrarIntelbras(!cadastrarIntelbras);
    const toggleCadastrarAnviz = () => setCadastrarAnviz(!cadastrarAnviz);
    const toggleConfigIntelbras = () => setModalConfigIntelbras(!modalConfigIntelbras);
 
    const toggleAtualizarControlId = () => setAtualizarControlId(!atualizarControlId);
    const toggleAtualizarHikvision = () => setAtualizaHikvision(!atualizarHikvision);
    const toggleAtualizarIntelbras = () => setAtualizaIntelbras(!atualizarIntelbras);
    const toggleAtualizarAnviz = () => setAtualizarAnviz(!atualizarAnviz);

    const toggleDeletarControlId = () => setModalDeletarControlId(!modalDeletarControlId);
    const toggleDeletarHikvision = () => setModalDeletarHikvision(!modalDeletarHikvision);
    const toggleDeletarIntelbras = () => setModalDeletarIntelbras(!modalDeletarIntelbras);
    const toggleDeletarAnviz = () => setModalDeletarAnviz(!modalDeletarAnviz);

    const deletarAnvizSelecionado = () =>
    {
        if(equipamentoStore.anvizSelecionado)
        {
            deletarAnviz(equipamentoStore.anvizSelecionado);
        }
        else
        {
            alert('Anviz não selecionado');
        }
    }

    const deletarControlIdSelecionado = () =>
    {
        if(equipamentoStore.controlIdSelecionado)
        {
            deletarControlId(equipamentoStore.controlIdSelecionado);
        }
        else
        {
            alert('ControlId não selecionado');
        }
    }

    const deletarHikvisionSelecionado = () =>
    {
        if(equipamentoStore.hikvisionSelecionado)
        {
            deletarHikvision(equipamentoStore.hikvisionSelecionado);
        }
        else
        {
            alert('Hikvision não selecionado');
        }
    }

    const deletarIntelbrasSelecionado = () =>
    {
        if(equipamentoStore.intelbrasSelecionado)
        {
            deletarIntelbras(equipamentoStore.intelbrasSelecionado);
        }
        else
        {
            alert('Intelbras não selecionado');
        }
    }

    const renderControlIds = () => {

        if(equipamentoStore.loading) {
            return <SkeletonList/>
        }

        if(equipamentoStore.controlIds.length === 0) {
            return <NotFound message='Nenhum equipamento cadastrado' />
        }

        return(
            <div>
                <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Modelo</th>
                            <th>Ip</th>
                            <th>Porta</th>
                            <th>Usuario</th>
                            <th>Senha</th>
                            <th>Tipo</th>
                            <th>Serial</th>
                            <th>Configurações</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            equipamentoStore.controlIds.map((c, i) =>
                            (
                                <tr key={i} onClick={() => equipamentoStore.definirControlIdSelecionado(c.id)}>
                                    <td>{c.nome}</td>
                                    <td>{c.modelo}</td>
                                    <td>
                                        <IconButton appearance="primary" color="violet" size={'xs'} icon={<ExportIcon />} href={`http://${c.ip}:${c.porta}`} target='blank'>
                                            {c.ip}
                                        </IconButton>
                                    </td>
                                    <td>{c.porta}</td>
                                    <td>{c.usuario}</td>
                                    <td>{c.senha}</td>
                                    <td>{c.tipo}</td>
                                    <td>{c.serial}</td>
                                    <td>
                                        <Dropdown title={'Configurações'} size={'xs'} appearance={'primary'} color={'violet'} icon={<GearIcon/>}>
                                            <Dropdown.Item onClick={() => reiniciarControlId(c.id)}>Reiniciar equipamento</Dropdown.Item>
                                            <Dropdown.Item disabled>Sincronizar data/hora</Dropdown.Item> 
                                        </Dropdown>
                                    </td>
                                    <td>
                                        <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                            <Dropdown.Item onClick={toggleAtualizarControlId}>Atualizar</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleDeletarControlId}>Deletar</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }



    const renderIntelbras = () => {

        if(equipamentoStore.loading) {
            return <SkeletonList/>
        }

        if(equipamentoStore.intelbras.length === 0) {
            return <NotFound message='Nenhum equipamento cadastrado' />
        }

        return(
            <div>
                <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Modelo</th>
                            <th>Ip</th>
                            <th>Porta</th>
                            <th>Usuario</th>
                            <th>Senha</th>
                            <th>Tipo</th>
                            <th>Serial</th>
                            <th>Mac</th>
                            <th>Configurações</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            equipamentoStore.intelbras.map((i, index) =>
                            (
                                <tr key={index} onClick={() => equipamentoStore.definirIntelbrasSelecionado(i.id)}>
                                    <td>{i.nome}</td>
                                    <td>{i.modelo}</td>
                                    <td>
                                        <IconButton appearance="primary" color="violet" size={'xs'} icon={<ExportIcon />} href={`http://${i.ip}:${i.porta}`} target='blank'>
                                            {i.ip}
                                        </IconButton>
                                    </td>
                                    <td>{i.porta}</td>
                                    <td>{i.usuario}</td>
                                    <td>{i.senha}</td>
                                    <td>{i.tipo}</td>
                                    <td>{i.serial}</td>
                                    <td>{i.mac}</td>
                                    <td>
                                        <Dropdown title={'Configurações'} size={'xs'} appearance={'primary'} color={'violet'} icon={<GearIcon/>}>
                                            <Dropdown.Item onClick={() => reiniciarIntelbras(i.id)}>Reiniciar equipamento</Dropdown.Item>
                                            <Dropdown.Item onClick={() => sincronizarDataHoraIntelbras(i.id)}>Sincronizar data/hora</Dropdown.Item>
                                            <Dropdown.Item onClick={() => toggleConfigIntelbras()}>Configurar servidor de envio de eventos</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                    <td>
                                        <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                            <Dropdown.Item onClick={toggleAtualizarIntelbras}>Atualizar</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleDeletarIntelbras}>Deletar</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    const renderHikvision = () => {

        if(equipamentoStore.loading) {
            return <SkeletonList/>
        }

        if(equipamentoStore.hikvision.length === 0) {
            return <NotFound message='Nenhum equipamento cadastrado' />
        }

        return(
            <div>
                <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Modelo</th>
                            <th>Ip</th>
                            <th>Porta</th>
                            <th>Usuario</th>
                            <th>Senha</th>
                            <th>Tipo</th>
                            <th>Serial</th>
                            {/* <th>Configurações</th> */}
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            equipamentoStore.hikvision.map((h, index) =>
                            (
                                <tr key={index} onClick={() => equipamentoStore.definirHikvisionSelecionado(h.id)}>
                                    <td>{h.nome}</td>
                                    <td>{h.modelo}</td>
                                    <td>
                                        <IconButton appearance="primary" color="violet" size={'xs'} icon={<ExportIcon />} href={`http://${h.ip}:${h.porta}`} target='blank'>
                                            {h.ip}
                                        </IconButton>
                                    </td>
                                    <td>{h.porta}</td>
                                    <td>{h.usuario}</td>
                                    <td>{h.senha}</td>
                                    <td>{h.tipo}</td>
                                    <td>{h.serial}</td>
                                    {/* <td>
                                        <Dropdown title={'Configurações'} size={'xs'} appearance={'primary'} color={'violet'} icon={<GearIcon/>}>
                                            <Dropdown.Item onClick={() => re(h.id)}>Reiniciar equipamento</Dropdown.Item>
                                            <Dropdown.Item disabled>Sincronizar data/hora</Dropdown.Item>
                                        </Dropdown>
                                    </td> */}
                                    <td>
                                        <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                            <Dropdown.Item onClick={toggleAtualizarHikvision}>Atualizar</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleDeletarHikvision}>Deletar</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    const renderAnviz = () => {

        if(equipamentoStore.loading) {
            return <SkeletonList/>
        }

        if(equipamentoStore.anviz.length === 0) {
            return <NotFound message='Nenhum equipamento cadastrado' />
        }

        return(
            <div>
                <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Modelo</th>
                            <th>Ip</th>
                            <th>Porta</th>
                            <th>Usuario</th>
                            <th>Senha</th>
                            <th>Tipo</th>
                            <th>Serial</th>
                            {/* <th>Configurações</th> */}
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            equipamentoStore.anviz.map((i, index) =>
                            (
                                <tr key={index} onClick={() => equipamentoStore.definirAnvizSelecionado(i.id)}>
                                    <td>{i.nome}</td>
                                    <td>{i.modelo}</td>
                                    <td>
                                        <IconButton appearance="primary" color="violet" size={'xs'} icon={<ExportIcon />} href={`http://${i.ip}:${i.porta}`} target='blank'>
                                            {i.ip}
                                        </IconButton>
                                    </td>
                                    <td>{i.porta}</td>
                                    <td>{i.usuario}</td>
                                    <td>{i.senha}</td>
                                    <td>{i.tipo}</td>
                                    <td>{i.serial}</td>
                                    {/* <td>
                                        <Dropdown title={'Configurações'} size={'xs'} appearance={'primary'} color={'violet'} icon={<GearIcon/>}>
                                            <Dropdown.Item disabled onClick={() => {}}>Reiniciar equipamento</Dropdown.Item>
                                            <Dropdown.Item disabled>Sincronizar data/hora</Dropdown.Item>
                                        </Dropdown>
                                    </td> */}
                                    <td>
                                        <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                            <Dropdown.Item onClick={toggleAtualizarAnviz}>Atualizar</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleDeletarAnviz}>Deletar</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    const renderRoutes = () =>
    {
        switch(routes)
        {
            case 'controlid':
                return(
                    <>
                        {renderControlIds()}
                    </>
                )
            case 'hikvision':
                return(
                    <>
                        {renderHikvision()}
                    </>
                )
            case 'intelbras':
                return(
                    <div>
                        {renderIntelbras()}
                    </div>
                )
            case 'anviz':
                return(
                    <div>
                        {renderAnviz()}
                    </div>
                )
        }
    }

    return(
        <Container>
            <Header>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='title'>Campo de cadastros de equipamentos</p>
                        <p className='subtitle'>Selecione a marca do equipamento para cadastrar</p>
                    </div>
                    <div>
                        <IconButton disabled={!routes} appearance="primary" color="violet" icon={<PlusIcon />} onClick={() =>
                        {
                            if(routes === 'controlid') toggleCadastrarControlId();
                            else if(routes === 'intelbras') toggleCadastrarIntelbras();
                            else if(routes === 'hikvision') toggleCadastrarHikvision();
                            else if(routes === 'anviz') toggleCadastrarAnviz();
                            else alert('erro');
                        }}>
                            Adicionar novo equipamento
                        </IconButton>
                    </div>
                </div>
                <div className='d-flex box'>
                    <div className='content d-flex'>
                        <BsDoorOpen className='content-icon' />
                        <div>
                            <p className='content-title'>Equipamentos de acesso do condomínio </p>
                            <p className='content-subtitle'>Selecione o equipamento abaixo para visualizar ou cadastrar novos dispositivos</p>
                        </div>
                    </div>
                    <div className='align-self-center'>
                        <div style={{marginLeft: 20}}>
                            <p>Selecione o equipamento</p>
                            <SelectPicker
                                block
                                cleanable={false}
                                value={routes}
                                style={{width: 200}}
                                placeholder={'Selecione'}
                                data={
                                    [
                                        {
                                            label: 'CONTROL ID',
                                            value: 'controlid'
                                        },
                                        {
                                            label: 'INTELBRAS',
                                            value: 'intelbras'
                                        },
                                        {
                                            label: 'HIKVISION',
                                            value: 'hikvision'
                                        },
                                        {
                                            label: 'ANVIZ',
                                            value: 'anviz'
                                        }
                                    ]
                                }                            
                                onChange={(e) =>
                                {
                                    setRoutes(e as any);
                                }}
                            />
                        </div>
                    </div>


                    <div style={{marginLeft: 30}} className='align-self-center'>
                        <p style={{margin: 0}}><b>CONTROL ID</b> cadastrado</p>
                        <IconButton appearance="primary" color="violet" icon={<ProjectIcon  />}>
                            {equipamentoStore.controlIds.length} cadastrado
                        </IconButton>
                    </div>

                    <div style={{marginLeft: 10}} className='align-self-center'>
                        <p style={{margin: 0}}><b>INTELBRAS</b> cadastrado</p>
                        <IconButton appearance="primary" color="violet" icon={<ProjectIcon  />}>
                            {equipamentoStore.intelbras.length} cadastrado
                        </IconButton>
                    </div>

                    <div style={{marginLeft: 10}} className='align-self-center'>
                        <p style={{margin: 0}}><b>HIKVISION</b> cadastrado</p>
                        <IconButton appearance="primary" color="violet" icon={<ProjectIcon  />}>
                            {equipamentoStore.hikvision.length} cadastrado
                        </IconButton>
                    </div>
                    <div style={{marginLeft: 10}} className='align-self-center'>
                        <p style={{margin: 0}}><b>ANVIZ</b> cadastrado</p>
                        <IconButton appearance="primary" color="violet" icon={<ProjectIcon  />}>
                            {equipamentoStore.anviz.length} cadastrado
                        </IconButton>
                    </div>
                </div>
            </Header>

                    
            <List>
                {renderRoutes()}
            </List>


            <CadastrarControlId show={cadastrarControlId} hide={toggleCadastrarControlId} condominioId={condominioId} />
            <AtualizarControlId show={atualizarControlId} hide={toggleAtualizarControlId} />

            <CadastrarHikvision show={cadastrarHikvision} hide={toggleCadastrarHikvision} condominioId={condominioId} />
            <AtualizarHikvision show={atualizarHikvision} hide={toggleAtualizarHikvision} />

            <CadastrarIntelbras show={cadastrarIntelbras} hide={toggleCadastrarIntelbras} condominioId={condominioId} />
            <AtualizarIntelbras show={atualizarIntelbras} hide={toggleAtualizarIntelbras} />

            <CadastrarAnviz show={cadastrarAnviz} hide={toggleCadastrarAnviz} condominioId={condominioId} />
            <AtualizarAnviz show={atualizarAnviz} hide={toggleAtualizarAnviz} />

            <ModalConfigEvento show={modalConfigIntelbras} hide={toggleConfigIntelbras} />

            <ModalConfirmacao show={modalDeletarControlId} hide={toggleDeletarControlId} action={deletarControlIdSelecionado} content={'Você tem certeza que deseja deletar esse CONTROL ID?'} loading={deletandoControlId} />
            <ModalConfirmacao show={modalDeletarHikvision} hide={toggleDeletarHikvision} action={deletarHikvisionSelecionado} content={'Você tem certeza que deseja deletar esse HIKVISION?'} loading={deletandoHikvision} />
            <ModalConfirmacao show={modalDeletarIntelbras} hide={toggleDeletarIntelbras} action={deletarIntelbrasSelecionado} content={'Você tem certeza que deseja deletar essa INTELBRAS?'} loading={deletandoIntelbras} />
            <ModalConfirmacao show={modalDeletarAnviz} hide={toggleDeletarAnviz} action={deletarAnvizSelecionado} content={'Você tem certeza que deseja deletar esse ANVIZ?'} loading={deletandoAnviz} />

            <Drawer overflow backdrop open={openRelatorio} onClose={() => setOpenRelatorio(false)}>
                <Drawer.Header>
                <Drawer.Title>RELATÓRIO DA SINCRONIZAÇÃO</Drawer.Title>
                <Drawer.Actions>
                    <Button onClick={() => setOpenRelatorio(false)}>Fechar</Button>
                </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    {
                        dispositivosStore.relatorioSincronizacao.map((relatorio, i) =>
                        (
                            <div key={i} className={'mt-2'}>
                                <p className='mt-1' style={{fontSize: 17, fontWeight: 550}}>MORADOR: {relatorio.nome?.toUpperCase()}</p>
                                <p className='mt-1' style={{fontSize: 13, fontWeight: 550}}>Sincronização realizado com sucesso</p>
                                <div className='p-2'>
                                    {
                                        relatorio.acertos.length !== 0
                                        ?
                                        <>
                                            {
                                                relatorio.acertos.map((acerto, i) =>
                                                (
                                                    <span key={i}>
                                                        <Timeline className="custom-timeline">
                                                            <Timeline.Item dot={<AiOutlineCheck size={20} />}>
                                                                <p className='m-0' style={{fontSize: 12, fontWeight: 850}}>{acerto.local}</p>
                                                                <p className='m-0' style={{color: 'green'}}>{acerto.notificacao}</p>
                                                            </Timeline.Item>
                                                        </Timeline>
                                                    </span>
                                                ))
                                            }
                                        </>
                                        :
                                        'SEM ERRO'
                                    }
                                </div>
                                <p className='mt-1' style={{fontSize: 15, fontWeight: 550}}>Sincronização com erros</p>
                                <div className='p-2'>
                                    {
                                        relatorio.erros.length !== 0
                                        ?
                                        <span key={i}>
                                            {
                                                relatorio.erros.map((erro, i) =>
                                                (
                                                    <>
                                                        <Timeline className="custom-timeline">
                                                            <Timeline.Item dot={<BiErrorCircle size={20} />}>
                                                                <p className='m-0' style={{fontSize: 12, fontWeight: 850}}>{erro.local ? erro.local : 'sem local'}</p>
                                                                <p className='m-0' style={{color: 'red'}}>{erro.notificacao}</p>
                                                            </Timeline.Item>
                                                        </Timeline>
                                                    </>
                                                ))
                                            }
                                        </span>
                                        :
                                        'SEM ERRO'
                                    }
                                </div>
                            </div>
                        ))
                    }
                    {/* erros
                    {
                        dispositivosStore.relatorio?.erros.map((acerto) =>
                        <p>{acerto.local}</p>)
                    } */}
                </Drawer.Body>
            </Drawer>
        </Container>
    )
}

export default observer(DispositivoCard);