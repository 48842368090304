import axios from "axios";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import GuaritaAPI from "../api/GuaritaAPI";
import ModuloAPI from "../api/ModuloAPI";
import VeiculoAPI from "../api/VeiculoAPI";
import { StoreContext } from "../stores";
import VeiculoStore from "../stores/VeiculoStore";
import { Veiculo } from "../interfaces/Veiculo.interface";


export const useVeiculo = () => {

    const { veiculoStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);

    const cadastrar = async (veiculo: Veiculo, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await VeiculoAPI.cadastrarVeiculo(veiculo);
            if(status === 200) {
                veiculoStore.veiculos.push(data);
                toast.success('Veículo cadastrado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar veiculo');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const atualizar = async (veiculo: Veiculo, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await VeiculoAPI.atualizarVeiculo(veiculo);
            if(status === 200) {
                veiculoStore.veiculos = veiculoStore.veiculos.map( vei => vei.id === veiculo.id ? veiculo : vei );
                toast.success('Veículo atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar veículo');
                setAtualizando(false);
            }
        } catch(e) {
            setAtualizando(false);
            //
        }
    }

    const deletar = async (veiculo: Veiculo) => {
        setDeletando(true);
        try {
            const { status } = await VeiculoAPI.deletarVeiculo(veiculo.id);
            if(status === 200) {
                veiculoStore.veiculos = veiculoStore.veiculos.filter( vei => vei.id !== veiculo.id );
                toast.success('Veículo deletado com sucesso');
                setDeletando(false);
            } else {
                toast.error('Erro ao deletado veículo');
                setDeletando(false);
            }
        } catch(e) {
            if(axios.isAxiosError(e)) {
                if(e.response?.data[0].mensagem === 'Excluído com sucesso!') {
                    veiculoStore.veiculos = veiculoStore.veiculos.filter( vei => vei.id !== veiculo.id );
                    toast.success('Veículo deletado com sucesso');
                    setDeletando(false);
                } else {
                    toast.error('Erro ao deletado veículo');
                    setDeletando(false);
                }
            }
            setDeletando(false);
            //
        }
    }


    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando
    }

}
