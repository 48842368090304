import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { StoreContext } from "../stores";
import { Nota } from "../interfaces/Nota.interface";
import NotaAPI from "../api/NotaAPI";


export const useNotas = () => {

    const { notaStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    
    const cadastrar = async (nota: Nota, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await NotaAPI.salvar(nota);
            if(status === 200) {
                notaStore.listarNotasPorCondominio();
                setSalvando(false);
                hide();
                toast.success('Nota cadastrado com sucesso!')
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar nota')
        }
    }

    const atualizar = async (nota: Nota, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await NotaAPI.atualizar(nota);
            if(status === 200) {
                notaStore.notas = notaStore.notas.map(no => no.id === nota.id ? nota : no);
                setAtualizando(false);
                hide();
                toast.success('Nota atualizado com sucesso!');
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar nota');
        }
    }

    const deletar = async (nota: Nota, hide: () => void) => {
        setDeletando(true);
        try {
            const { status } = await NotaAPI.remover(nota.id);
            if(status === 200) {
                notaStore.notas = notaStore.notas.filter(no => no.id !== nota.id);
                setDeletando(false);
                hide();
                toast.success('Nota deletado com sucesso!')
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar nota')
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando
    }

}