import styled from 'styled-components';
import PerfectScroll from 'react-perfect-scrollbar';

export const Container = styled.div`

`

export const Header = styled.div`

`;

export const ContainerMoradores = styled(PerfectScroll)`
    align-self: center;
    margin: 15px;
    border-radius: 8px;
    width: 30vw;
    height: 88vh;
    border-right: 1px solid ${(props) => props.theme.colors.borderColor};
    background: ${(props) => props.theme.colors.bgBox};
    .header
    {
        padding: 17px;
        // background: ${(props) => props.theme.colors.primary};
    }
    .title
    {
        margin: 0;
        font-weight: 550;
        font-size: 11pt;
        // color: #fff;
    }
    .subtitle
    {
        margin: 0;
        font-size: 10pt;
        // color: #fff;
    }
    .content
    {
        padding: 15px;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
        transition: 0.2s;
    }
    .content:hover
    {
        background: ${(props) => props.theme.colors.bgColor};
        transition: 0.2s;
        cursor: pointer;
    }
    .nome
    {
        margin: 0;
        font-size: 10pt;
    }
    .cpf
    {
        margin: 0;
        font-size: 8pt;
    }
    .unidade
    {
        margin: 0;
        font-weight: 550;
        font-size: 9pt;
    }

    @media(max-height: 715px)
    {
        height: 85vh;
    }

`

export const ContainerChat = styled.div`
    // background: blue;
    width: 100%;
`