import { Evento, EventoRelatorio } from '../interfaces/Evento.interface';
import { Funcionario, TipoFuncionario } from '../interfaces/Funcionario.interface';
import api from '../services/api/api';

class FuncionarioAPI {
    adicionar = async (funcionario: Funcionario) => await api.post('/Funcionario', funcionario);
    atualizar = async (funcionario: Funcionario) => await api.put('/Funcionario', funcionario);
    deletar = async (id: string) => await api.delete(`/Funcionario/${id}`);

    obterTodos = async () => await api.get<Funcionario[]>('/Funcionario/ObterTodosFuncionarios');
    obterTipoFuncionario = async () => await api.get<TipoFuncionario[]>('/Funcionario/TiposFuncionarios');
    notificarCondominio = async (condominioId: string, funcionarioId: string) => await api.post('/Funcionario/NotificarCondominio', {
        condominioId, funcionarioId
    })
}
 
export default new FuncionarioAPI();