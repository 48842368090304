import { useState } from "react";
import AutenticacaoDoisFatoresAPI from "../api/AutenticacaoDoisFatoresAPI";
import { ValidarToken } from "../interfaces/AutenticacaoDoisFatores";
import { toast } from "react-toastify";
import { AxiosError } from "axios";


const useAutenticacaoDoisFatores = () => {

    const [loading, setLoading] = useState(false);
    const [loadingValdiation, setLoadingValidation] = useState(false);
    const [validated, setValidated] = useState(false);
    const [sendCode, setSendCode] = useState(false);

    const enviarCodigo = async (cpf: string) => {
        setLoading(true);
        try {
            const { data, status } = await AutenticacaoDoisFatoresAPI.enviarCodigo(cpf);
            setLoading(false);
            if(status === 200)
            {
                setSendCode(true);
                toast.success("Token enviado para o seu celular!");
            }
            else
            {
                setSendCode(false);
                toast.error("Token enviado para o seu celular!");
            }
        } catch(e) {
            let error = e as AxiosError;
            setLoading(false);
            toast.error(error.response!.data[0].mensagem);
        }
    }

    
    const validarCodigo = async (codigo: ValidarToken) => {
        setLoadingValidation(true);
        setValidated(false);
        try {
            const { data, status } = await AutenticacaoDoisFatoresAPI.validarCodigo(codigo);
            setLoadingValidation(false);
            if(status === 200)
            {
                setValidated(true);
                toast.success("TOKEN CORRETO COM SUCESSO");
            }
            else
            {
                setValidated(false);
                toast.error("Token inválido!");
            }
        } catch(e) {
            setValidated(false);
            let error = e as AxiosError;
            setLoadingValidation(false);
            toast.error(error.response!.data[0].mensagem);
        }
    }

    return {
        enviarCodigo,
        validarCodigo,
        validated,
        sendCode,
        loading,
        loadingValdiation
    }

}


export default useAutenticacaoDoisFatores;