import { observer } from 'mobx-react-lite'
import React, { FC, useContext, useEffect, useRef } from 'react'
import { Button, Modal } from 'rsuite';
import { StoreContext } from '../../stores';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Pie, PolarArea } from 'react-chartjs-2';
import { Container } from './styles/Relatorio.style';
import NotFound from '../utils/NotFound';
import { useReactToPrint } from 'react-to-print';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);


interface Props
{
    show: boolean;
    hide: () => void;
}

const ModalRelatorio: FC<Props> = ({ show, hide }) => {

    const { moradorStore, dispositivosStore, condominioStore } = useContext(StoreContext);
    let componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef?.current
    });
    useEffect(() =>
    {
        if(show && moradorStore.selecionado)
        {
            dispositivosStore.listarTagPorMorador(moradorStore.selecionado.id);
            dispositivosStore.listarBiometriaDigitalPorMorador(moradorStore.selecionado.id);
            dispositivosStore.listarBiometriaFacialPorMorador(moradorStore.selecionado.id);
        }
    }, [show])

    const data = {
        labels: ['TAGS', 'BIOMETRIA DIGITAL', 'BIOMETRIA FACIAL'],
        datasets: [
          {
            label: 'Qtd',
            data: [dispositivosStore.tags.length, dispositivosStore.biometriaCadastrada.length, dispositivosStore.biometriaFacialCadastrada ? 1 : 0],
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(255, 159, 64, 0.5)',
            ],
            borderWidth: 0
          },
        ],
      };

      const renderData = () =>
      {
        if(dispositivosStore.tags.length === 0 && dispositivosStore.biometriaCadastrada.length === 0 && dispositivosStore.biometriaFacialCadastrada === null)
            return <NotFound message='Nenhum dispositivo cadastrado' />

        return(
            <Container ref={componentRef}>
                <p style={{fontSize:'11pt'}}><b>Condominio:</b> {condominioStore.selecionado?.nome} <br/><b>Morador:</b> {moradorStore.selecionado?.nome} <br/> <b>Unidade:</b> {moradorStore.selecionado?.unidadeIdentificacao} - {moradorStore.selecionado?.blocoIdentificacao.toUpperCase()}</p>
                <div className='d-flex justify-content-center'>
                    <div>
                        <Pie data={data} style={{height: 250, width: 250}} />
                    </div>
                    <div className='align-self-center content'>
                        <p><b>TAGS:</b> {dispositivosStore.tags.length}</p>
                        <p><b>BIOMETRIA DIGITA:</b> {dispositivosStore.biometriaCadastrada.length}</p>
                        <p><b>BIOMETRIA FACIAL:</b> {dispositivosStore.biometriaFacialCadastrada ? 1 : 0}</p>
                    </div>
                </div>
            </Container>
        )
      }

    return (
        <Modal open={show} onClose={hide}>
        <Modal.Header>
           <p style={{fontWeight: 550, fontSize:'11pt'}}>Relatório de tags e biometrias do morador <br/> </p>
        </Modal.Header>
        <Modal.Body>
            {renderData()}
        </Modal.Body>
        <Modal.Footer>
            <Button appearance='primary' color={'violet'} onClick={handlePrint} disabled={dispositivosStore.tags.length === 0 && dispositivosStore.biometriaCadastrada.length === 0 && dispositivosStore.biometriaFacialCadastrada === null}>Exportar</Button>
            <Button appearance='primary'  onClick={hide}>Fechar</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default observer(ModalRelatorio);
