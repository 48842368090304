import styled from 'styled-components';
import PerfecScroolBar from 'react-perfect-scrollbar';

interface Props
{
    mensagemId?: string;
    selecionadoId?: string | undefined;
}

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 90%;
`;

export const Users = styled.div`
    transition: 0.2s;
    padding: 10px;
    border-left: 2px solid rgba(0,0,0,0);
    padding-left: 5px;
    &:hover
    {
        transition: 0.2s;
        border-left: 2px solid ${(props) => props.theme.colors.primary};
        padding-left: 5px;
        cursor: pointer;
    }
`;

export const CardMessage = styled(PerfecScroolBar)`
    height: 80%;
    width: 100%;
    justify-content: center;
    .contentCardChat
    {
        width: 83%;
        padding: 20px;
    }
    @media(max-width: 1200px)
    {
        width: 100%;
        margin-left: 150px;
        .contentCardChat
        {
            width: 100%;
            padding: 20px;
        }
    }

    @media(max-height: 620px)
    {
        height: 77%;
    }
    @media(max-height: 500px)
    {
        height: 70%;
    }
    @media(max-height: 400px)
    {
        height: 67%;
    }
    @media(max-height: 300px)
    {
        height: 60%;
    }

    .contentResponse
    {
        background: ${(props) => props.theme.colors.borderColor};
        padding: 10px;
        margin-top: 15px;
        margin-bottom: -15px;
        border-radius: 10px;
        box-shadow: 1px 4px 5px rgba(0,0,0,0.200);
        z-index: 4px;
    }
    .labelResponse
    {
        margin: 0;
        font-weight: 550;
        font-size: 7pt;
    }
    .messageResponse
    {
        word-break: break-all;
        font-weight: 700;
        margin: 0;
    }
`;


export const CardInput = styled.form`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -20%);
    background: ${(props) => props.theme.colors.bgBox};
    width: 80%;
    padding: 15px;
    border-radius: 10px;
    .icon-message
    {
        align-self: center;
        font-size: 20pt;
        margin-right: 20px;
    }
    .input
    {
        background: transparent;
        border: 0;
        width: 60vw;
    }
    @media(max-width: 1200px)
    {
        left: 73%;
        width: 100%;
        .icon-message
        {
            align-self: center;
            font-size: 20pt;
            margin-right: 0px;
        }
        .input
        {
            background: transparent;
            border: 0;
            width: 40vw;
        }
    }
    @media(max-width: 1000px)
    {
        left: 55%;
        width: 100%;
        .icon-message
        {
            align-self: center;
            font-size: 20pt;
            margin-right: 0px;
        }
        .input
        {
            background: transparent;
            border: 0;
            width: 20vw;
        }
    }
    @media(max-width: 700px)
    {
        left: 50%;
        width: 80%;
        .icon-message
        {
            align-self: center;
            font-size: 20pt;
            margin-right: 0px;
        }
        .input
        {
            background: transparent;
            border: 0;
            width: 50vw;
        }
    }
`;

export const CardRespondeMessage = styled.div`
    border-left: 1px solid ${(props) => props.theme.colors.primary};
    // margin: 10px;
    // border-radius: 4px;
    .content-box
    {
        // padding-left: 20px;
    }
    .user
    {
        margin: 0;
        font-size: 10pt;
        font-weight: 600;
        padding-top: 5px;
    }
    .message
    {
        margin: 0;
        font-size: 9pt;
        margin-top: -13px;
        margin-bottom: -30px;
        // padding-left: 20px;
        // align-self: center;
        word-break: break-all;
        width: 100%;
        -ms-word-break: break-all;
        text-justify: inter-word;

    }
    .icon
    {
        align-self: center;
        margin-right: 20px;
        font-size: 25pt;
    }

    // @media(max-height: 900px)
    // {
    //     background: red;
    //     margin-top: -20px;
    //     z-index: 1;
    // }
`;

export const MessageLeft = styled.div<Props>`
    // background: ${(props) => props.theme.colors.bgBox};
    // margin: 0;
    // margin-top: 5px;
    // width: 40%;
    // padding: 10px;
    // box-shadow: 1px 2px 4px rgba(0,0,0,0.0200);
    // border-radius: 15px 15px 15px 0px;
    .label
    {
        margin: 0;
        font-size: 9pt;
        word-break: break-all;
        -ms-word-break: break-all;
    
        /* Non standard for webkit */
            word-break: break-word;
       
           -webkit-hyphens: auto;
              -moz-hyphens: auto;
               -ms-hyphens: auto;
                   hyphens: auto;
    }
    .usuario
    {
        margin: 0;
        font-size: 10pt;
        // margin-left: -20px;
        // color: ${(props) => props.theme.colors.danger};
        font-weight: 600;
    }
    .datahora
    {
        margin: 0;
        margin-top: 5px;
        font-size: 7pt;
    }

    .blink {
        // animation: blinker 2s linear infinite;
        animation: blinker 2s linear;
        background: ${(props) => props.theme.colors.bgBox};
        margin-top: 10px;
        width: 80%;
        padding: 16px;
        box-shadow: 1px 2px 4px rgba(0,0,0,0.0200);
        border-radius: 8px 8px 8px 0px;
    }
    
    .content-chat
    {
        background: ${(props) => props.theme.colors.bgBox};
        margin: 0;
        margin-top: 5px;
        width: 40%;
        padding: 10px;
        box-shadow: 1px 2px 4px rgba(0,0,0,0.0200);
        border-radius: 15px 15px 15px 0px;
    }
    
    @keyframes blinker {
        50% {
            background: ${(props) => props.theme.colors.warning};
        }
    }
`;

export const MessageRight = styled.div<Props>`
    // background: ${(props) => props.theme.colors.primary};
    // margin: 0;
    // margin-top: 5px;
    // width: 40%;
    // color:#fff;
    // padding: 10px;
    // box-shadow: 1px 2px 4px rgba(0,0,0,0.0200);
    // border-radius: 15px 15px 0px 15px;
    .label
    {
        margin: 0;
        font-size: 9pt;
        font-weight: 550;
        word-break: break-all;
        -ms-word-break: break-all;
    
        /* Non standard for webkit */
            word-break: break-word;
    
        -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
                hyphens: auto;
    }
    .user
    {
        margin: 0;
        font-size: 9pt;
        font-weight: 700;
    }
    .datahora
    {
        margin: 0;
        font-size: 7pt;
    }
    .blink {
        // animation: blinker 2s linear infinite;
        animation: blinker 2s linear;
        background: ${(props) => props.theme.colors.primary};
        margin-top: 10px;
        width: 100%;
        padding: 15px;
        box-shadow: 1px 2px 4px rgba(0,0,0,0.0200);
        border-radius: 12px 12px 0px 12px;
        color: #fff;
    }
    
    .content-chat
    {
        // background: red;
        background: ${(props) => props.theme.colors.primary};
        margin-top: 10px;
        width: 100%;
        padding: 15px;
        box-shadow: 1px 2px 4px rgba(0,0,0,0.0200);
        border-radius: 12px 12px 0px 12px;
        color: #fff;
    }
 
`;