import styled from 'styled-components';

export const Container = styled.div`
    .header
    {
        background: ${(props) => props.theme.colors.bgBox};
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
        border-radius: 10px;
        box-shadow: 1px 3px 5px rgba(0,0,0,0.0200);
        padding: 10px;
        width: 100%;
    }
    .cam
    {
        flex-wrap: wrap;
    }
    .img-cam
    {
        border-radius: 5px;
        width: 20vw;
        border: 3px solid ${(props) => props.theme.colors.borderColor};
        // height: 350px;
        // width: 350px;
        margin: 10px;
    }
    
`