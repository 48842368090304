import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../stores';
import { Container, Header } from './styles/Guarita.style';
import { BsHdd } from 'react-icons/bs';
import { MdOutlineRouter } from 'react-icons/md';
import Rele from '../rele/Rele';
import { IconButton } from 'rsuite';
import { AiOutlineRight } from 'react-icons/ai';
import ModalVersaoModulos from './ModalVersaoModulos';
import ProjectIcon from '@rsuite/icons/Project';
import { Carousel } from 'react-bootstrap';
import NiceListagem from './nice/NiceListagem';
import SiamListagem from './siam/SiamListagem';
import BravasListagem from './bravas/BravasListagem';
import Mip1000Listagem from './mip1000/Mip1000Listagem';

const GuaritaListagem = () => {

    const { guaritaStore, releStore, siamStore, condominioStore, bravasStore } = useContext(StoreContext);
    const [openCollapse, setOpenCollapse] = useState(false);
    const[openVersaoModulo, setOpenVersaoModulo] = useState(false);
    const [index, setIndex] = useState(0);

    const toggleOpenVersaoModulo = () => setOpenVersaoModulo(!openVersaoModulo);

    const toggleCollapse = () => {
        setOpenCollapse(!openCollapse);
        releStore.definirSelecionado(null);
    }

    useEffect(() => {
        let primeiroGuarita = guaritaStore.guaritas[guaritaStore.guaritas.length -1];
        if(primeiroGuarita) {
            guaritaStore.listarModulo(primeiroGuarita.id);
            guaritaStore.definirSelecionado(primeiroGuarita.id);
        }
        if(condominioStore.selecionado)
        {
            siamStore.listarControladoraPorCondominioId(condominioStore.selecionado.id);
            bravasStore.listar();
        }
    }, [condominioStore.selecionado]);

    return(
        <Container>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='title'>Campo de cadastros de sistema de controles de acesso</p>
                    <p className='subtitle'>Equipamentos de guaritas do condomínio</p>
                </div>
            </div>

            <Carousel variant="dark" indicators={false} controls={false} activeIndex={index} interval={1000}>
                <Carousel.Item>

                <Header>
                    <div className='d-flex box'>
                        <div className='content d-flex'>
                            <MdOutlineRouter className='content-icon' />
                            <div>
                                <p className='content-title'>Equipamentos para cadastro</p>
                                <p className='content-subtitle'>Selecione o modelo do equipamento que você deseja cadastrar no condomínio</p>
                            </div>
                        </div>

                        <div style={{marginLeft: 30}} className='align-self-center'>
                            <p style={{margin: 0}}><b>NICE</b></p>
                            <IconButton appearance="primary" color="violet" icon={<ProjectIcon  />}>
                                {guaritaStore.guaritas.length} cadastrado
                            </IconButton>
                        </div>

                        <div style={{marginLeft: 10}} className='align-self-center'>
                            <p style={{margin: 0}}><b>SIAM</b></p>
                            <IconButton appearance="primary" color="violet" icon={<ProjectIcon  />}>
                                {siamStore.siam.length} cadastrado
                            </IconButton>
                        </div>

                        <div style={{marginLeft: 10}} className='align-self-center'>
                            <p style={{margin: 0}}><b>BRAVAS</b></p>
                            <IconButton appearance="primary" color="violet" icon={<ProjectIcon  />}>
                                {bravasStore.bravas.length} cadastrado
                            </IconButton>
                        </div>
                    </div>
                </Header>

                    <div className='box-carrosel d-flex justify-content-between mt-3' onClick={() => setIndex(1)}>
                        <div className='d-flex'>
                            <BsHdd className='align-self-center icon' />
                            <div>
                                <p className='title'>NICE</p>
                                <p className='subtitle'>
                                    Sistema guarita de controle de acesso da <b>NICE</b>
                                    <br/>
                                    Acesse e cadastre os módulos CTW ou TX de acordo com a necessidade do condomínio
                                </p>
                            </div>
                        </div>
                        <AiOutlineRight className='align-self-center icon' />
                    </div>

                    <div className='box-carrosel d-flex justify-content-between' onClick={() => setIndex(2)}>
                        <div className='d-flex'>
                            <BsHdd className='align-self-center icon' />
                            <div>
                                <p className='title'>SIAM</p>
                                <p className='subtitle'>
                                    Sistema guarita de controle de acesso da <b>SIAM</b>
                                    <br/>
                                    Cadastre o equipamento e adicione controladoras para os acessos
                                </p>
                            </div>
                        </div>
                        <AiOutlineRight className='align-self-center icon' />
                    </div>

                    <div className='box-carrosel d-flex justify-content-between' onClick={() => setIndex(3)}>
                        <div className='d-flex'>
                            <BsHdd className='align-self-center icon' />
                            <div>
                                <p className='title'>BRAVAS</p>
                                <p className='subtitle'>
                                    Sistema guarita de controle de acesso da <b>BRAVAS</b>
                                    <br/>
                                    Acesse o equipamento e cadastre novos acessos e grupos de acordo com a necessidade do condomínio
                                </p>
                            </div>
                        </div>
                        <AiOutlineRight className='align-self-center icon' />
                    </div>

                    <div className='box-carrosel d-flex justify-content-between' onClick={() => setIndex(4)}>
                        <div className='d-flex'>
                            <BsHdd className='align-self-center icon' />
                            <div>
                                <p className='title'>MIP 1000 IP</p>
                                <p className='subtitle'>
                                    Sistema guarita de controle de acesso do <b>MIP 1000 IP</b>
                                    <br/>
                                    Acesse o equipamento e cadastre novos acessos e grupos de acordo com a necessidade do condomínio
                                </p>
                            </div>
                        </div>
                        <AiOutlineRight className='align-self-center icon' />
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <NiceListagem setIndex={setIndex} />
                </Carousel.Item>

                <Carousel.Item>
                    <SiamListagem setIndex={setIndex} />
                </Carousel.Item>

                <Carousel.Item>
                    <BravasListagem setIndex={setIndex} />
                </Carousel.Item>

                <Carousel.Item>
                    <Mip1000Listagem index={index} setIndex={setIndex} />
                </Carousel.Item>
            </Carousel>

        </Container>
    )
}


export default observer(GuaritaListagem); 