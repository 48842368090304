import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, List, Loader, Modal } from 'rsuite';
import useAutomacaoAcionamento from '../../../hooks/AutomacaoAcionamento.hook';
import { StoreContext } from '../../../stores';
import SkeletonList from '../../utils/SkeletonList';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarOrdem: FC<ModalProps> = ({ show, hide }) => {

    const { automacaoAcionamentoStore, automacaoAcionamentoIdentificacaoStore } = useContext(StoreContext);
    const { atualizarOrdem, salvando } = useAutomacaoAcionamento();

    const handleSortEnd = ({ oldIndex, newIndex } : any) =>
    {
        automacaoAcionamentoStore.automacaoAcionamento = arrayMoveImmutable(automacaoAcionamentoStore.automacaoAcionamento, oldIndex, newIndex);
        automacaoAcionamentoStore.automacaoAcionamento.map((_, i) => _.ordem = i);
        var ordem = 
        {
            novaOrdem: automacaoAcionamentoStore.automacaoAcionamento.map(i => ({id: i.id, ordem: i.ordem}))
        } as any;
        if(automacaoAcionamentoIdentificacaoStore.selecionado)
            atualizarOrdem(ordem, automacaoAcionamentoIdentificacaoStore.selecionado.id, () => {});
    }

    const Listagem = () => {

        if(salvando)
        {
          return <SkeletonList/>
        }

        return(
            <List sortable onSort={handleSortEnd}>
                {
                    automacaoAcionamentoStore.automacaoAcionamento.map((a, i) =>
                    <List.Item className='p-3' key={i} index={i}>{a.nome?.toUpperCase()}</List.Item>)
                }
            </List>
        )
    }

    return(
        <Modal open={show} onClose={() => salvando ? {} : hide()}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar ordem da programação da automação</p>
                    <p className='modal-styled-subtitle'>
                        Arraste para cima ou para baixo para alterar ordem dos moradores
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{Listagem()}</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => salvando ? {} : hide()}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(AtualizarOrdem);