import { FC, useContext, useEffect } from 'react';
import {
    Routes,
    Route,
} from 'react-router-dom';
import Condominios from '../components/condominios/Condominios';
import Dashboard from '../views/dashboard/Dashboard';
import { StoreContext } from '../stores';
import { observer } from 'mobx-react-lite';
import { useSentinela } from '../hooks/Sentinela.hook';
import Funcionario from '../components/funcionario';
import Ramais from '../components/ramais/Ramais';
import { RxDashboard, RxExit } from 'react-icons/rx';
import { BsBuilding, BsFileEarmarkText, BsNewspaper, BsPaperclip, BsReceipt, BsUiChecks } from 'react-icons/bs';
import { IoIosSettings } from 'react-icons/io';
import { HiServer } from 'react-icons/hi';
import { RiListSettingsLine } from 'react-icons/ri';
import { AiOutlineNotification } from "react-icons/ai";

  
const CondominiosView: FC = () => {

  const { validarToken } = useSentinela();
  const stores = useContext(StoreContext);
  const { 
    condominioStore,
    authStore,
    usuarioStore,
    ramalStore
   } = stores;

  useEffect(() => {
    condominioStore.listar();
    condominioStore.listarCentrais();
    authStore.usuarioAutenticado();
    usuarioStore.listarUsuáriosLogados();
    validarToken();
  }, [condominioStore]);

  const navigation =
{

  iniciotitle: 'PÁGINA INICIAL',
  dashboard: 'Dashboard',
  icondashboard: <BsFileEarmarkText/>,
  condominios: 'Condomínios',
  iconcondominios: <BsBuilding/>,
  unidadeinfotitle: 'CONFIGURAÇÕES DE UNIDADE',
  blocounidadetitle: 'Bloco',
  blocounidadeicon: <BsUiChecks/>,
  perfiltitle: 'Perfil',
  perfilicon: <BsReceipt/>,
  unidadetitle: 'Unidade',
  unidadeicon: <IoIosSettings/>,
  notastitle: 'Notas',
  notasicon: <BsPaperclip/>,
  relatoriotitle: 'Relatórios gerais',
  relatorioicon: <BsNewspaper/>,
  

  configuracaotititle: 'CONFIGURAÇÕES DO CONDOMÍNIO',
  operacaotititle: 'Operação T.I',
  operacaotiicon: <HiServer/>,
  editarcondominiotitle: 'Editar condominio',
  editarcondominioicon: <RiListSettingsLine/>,
  avisotitle: 'Avisos',
  avisoicon: <AiOutlineNotification/>,
  sistemainfotitle: 'SISTEMA',
  sistemaicon: <RxExit/>,
  sistematitle: 'Sair',
}


  return (
    // <HashRouter>
      <div className="d-flex">
        <Routes>
          <Route path="/ramais" element={<Ramais/>} />    
          <Route path="/dashboard/*" element={<Dashboard navigation={navigation} />} />    
          <Route path="/" element={<Condominios/>}/>
        </Routes>
      </div>
    // </HashRouter>
    
  );
};
  
  export default observer(CondominiosView);