import styled from 'styled-components';
import PerfectScroll from 'react-perfect-scrollbar';

export const Container = styled(PerfectScroll)`
    padding: 20px;
    height: 85vh;
    overflow: auto;
`;

export const Header = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
    padding: 10px;
    border-radius: 10px;
    .title
    {
        margin: 0;
        font-size: 16pt;
        font-weight: 550;
        align-self: center;
    }
    .subtitle
    {
        font-size: 12pt;
        margin-top: 10px;
    }
    .info
    {
        margin: 0;
        font-size: 8pt;
    }
`;
