import styled from "styled-components";
import PerfectScrollBar from 'react-perfect-scrollbar';

export const Container = styled.div`
    // margin-top: 40px;
    // padding: 10px;
    height: calc(100% - 1000px);
    width: 97.5%;
`;

export const Header = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    border-radius: 8px;
    // margin-bottom: -50px;
    padding: 14px;
    // margin: 30px;
    justify-content: space-between;
    .title
    {
        margin: 0;
        font-size: 10pt;
        font-weight: 550;
    }
    .subtitle
    {
        margin: 0;
        font-size: 9pt;
    }
`;


export const List = styled.div`
    // padding: 30px;
    // margin-top: -30px;
    height: 10vh;
    .content
    {
        width: 45%;
        margin: 10px;
        box-shadow: 1px 3px 4px ${(props) => props.theme.colors.borderColor};
    }
    .title
    {
        font-size: 12pt;
        margin: 0;
        font-weight: 550;
        margin-top: 15px;
        margin-bottom: 5px;
    }
`;