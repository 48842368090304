import { useContext, useState } from "react";
import { toast } from "react-toastify";
import BlocoAPI from "../api/BlocoAPI";
import { StoreContext } from "../stores";
import { Bloco } from "../interfaces/Bloco.interface";
import { Usuario } from "../interfaces/Usuario.interface";
import UsuarioAPI from "../api/UsuarioAPI";


export const useUsuario = () => {

    const { authStore } = useContext(StoreContext);
    const [atualizando, setAtualizando] = useState(false);
    const [success, setSuccess] = useState(false);

    const atualizar = async (usuario: Usuario) => {
        setAtualizando(true);
        try {
            await UsuarioAPI.atualizarUsuario(usuario);
            setAtualizando(false);
            authStore.usuario = usuario;
            setSuccess(true);
            toast.success('Usuário atualizado com sucesso');

        } catch(e) {
            
            setAtualizando(false);
            toast.error('Erro ao atualizar usuário');
        }
    }

    return {
        atualizar,
        atualizando,
        success,
    }

}