import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';

export const Container = styled(PerfectScrollBar)`
    padding: 10px;
    height: 100vh;
    .title
    {
        font-size: 18pt;
        font-weight: 530;
        padding: 10px;
    }
`;

export const ContainerComponent = styled.div`
    .title-item
    {
        font-weight: 600;
        margin-bottom: 20px;
        font-size: 15pt;
        border-bottom: 1px solid rgba(0,0,0,0.2);
    }
    padding: 30px;
    b
    {
        font-size: 11pt;
        align-self: center;
    }
    .box
    {
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
        padding: 20px;
        margin-bottom: 30px;
    }
    .icon
    {
        font-size: 15pt;
    }
    .title
    {
        width: 35%;
        font-size: 9pt;
    }
    .title-export
    {
        width: 55%;
        font-size: 9pt;
        margin-bottom: 10px;
    }
    .card-button
    {
        margin-left: 30px;
    }
`;
