import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsCameraVideo } from 'react-icons/bs';
import { StoreContext } from '../../stores';
import { useCamera } from '../../hooks/Camera.hook';
import { Camera, CameraRequest } from '../../interfaces/Cameras.interface';
import { Layout } from '../../interfaces/Layout.interface';
import { FormCamera, SubTitle, Title } from './styles/Modal.style';
import { Button, Input, Loader, Modal, Popover, SelectPicker, Whisper } from 'rsuite';
import LayoutAPI from '../../api/LayoutAPI';

interface Props {
    show: boolean;
    hide: () => void;
    layout: Layout | null;
}

const CadastrarCamera:FC<Props> = ({ show, hide, layout }) => {

    const { cameraStore, layoutStore } = useContext(StoreContext);
    const [form, setForm] = useState<CameraRequest>({});
    const [imgCam, setImgCam] = useState('');
    const [loading, setLoading] = useState(false);
    const { cadastrar, salvando } = useCamera();

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let novaCamera = {
            layoutId: layout?.id,
            idDGuard: form.idDGuard,
            nomeDGuard: form.nomeDGuard,
            cameraAPP: form.cameraAPP
        }
        cadastrar(novaCamera as any, hide);
    }

    useEffect(() =>
    {
        if(show && layoutStore.selecionado)
        {
            cameraStore.listarCamerasDGuardPorLayout(layoutStore.selecionado.id);
        }
    }, [show, layoutStore.selecionado]);

    useEffect(() =>
    {
        setLoading(true);
        (async () =>
        {
            if(form.idDGuard && layout)
            {
                const servidorDguard = (await LayoutAPI.ObterServidorDguard(layout.servidorDGuardId)).data;
                var resposta = await LayoutAPI.autenticarDGuard(`http://${servidorDguard.ip}:${servidorDguard.portaCgi}/camera.cgi?camera=${form.idDGuard}&qualidade=70&stream=1`, servidorDguard.usuarioDGuard, servidorDguard.senhaDGuard);
                var reader = new window.FileReader();
                reader.readAsDataURL(resposta.data); 
                reader.onload = function() {
                    return reader.result;
                }
                var objectURL = URL.createObjectURL(resposta.data);
                setImgCam(objectURL);
                setLoading(false);
            }
            setLoading(false);
        })();
    }, [form.idDGuard]);

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header style={{border: 0}} closeButton>
                <div>
                    <Title>Cadastro de câmera <BsCameraVideo/> </Title>
                    <SubTitle>Cadastre novas câmeras para esse condomínio</SubTitle>
                </div>
            </Modal.Header>
            <Modal.Body>
                <FormCamera onSubmit={salvar}>
                    <p className='info'>Preencha todos os campos abaixo</p>

                    <div>
                        <p className='title'>Selecione o lauout<span className='text-danger'>*</span></p>
                        <SelectPicker
                            placeholder={'Selecione o id da câmera do dguard'}
                            block
                            value={form.layoutId}
                            data={layoutStore.layouts.map((l) => ({ label: l.nomeDGuard, value: l.id }))}
                            onChange={(e) => {if(e) layoutStore.definirSelecionado(e)}}
                        />
                    </div>

                    <div>
                        <p className='title'>Nome<span className='text-danger'>*</span></p>
                        <Input required placeholder='Nome de identifição da câmera' value={form.nomeDGuard || ''} onChange={(e) => setForm({ ...form, nomeDGuard: e })}  />
                    </div>

                    <div>
                        <p className='title'>IdDGuard<span className='text-danger'>*</span></p>
                        <SelectPicker
                            placeholder={'Selecione o id da câmera do dguard'}
                            block
                            value={form.idDGuard}
                            data={cameraStore.camerasDGuard.map((camera) => ({ label: camera.nome, value: camera.id }))}
                            onChange={(e) => {if(e) setForm({ ...form, idDGuard: e })}}
                        />
                    </div>

                    {
                        loading
                        ?
                        'Carregando câmera...'
                        :
                        <>
                            {
                                imgCam == "" || imgCam == null || !form.idDGuard
                                ?
                                ""
                                :
                                <img src={imgCam} style={{height: 150, width: 150, borderRadius: 8, marginTop: 10}} />
                            }
                        </>
                    }

                    <Form.Check 
                        type={'switch'}
                        label={'Camera APP'}
                        className={'mt-3'}
                        onChange={(e) => {
                            setForm({ ...form, cameraAPP: e.target.checked })
                        }}
                    />
                    <div className='d-flex justify-content-end'>
                        <div className='d-flex'>
                            <Button disabled={!form.idDGuard || salvando} type={'submit'} appearance="primary" className='m-1'>
                                {
                                    salvando
                                    ?
                                    <Loader title={'Salvando...'} />
                                    :
                                    'Salvar'
                                }
                            </Button>
                            <Button type={'button'} className='m-1' onClick={hide}>Fechar</Button>
                        </div>
                    </div>
                    {/* <button disabled={salvando} className={`btn btn${salvando ? '' : '-outline'}-success w-100 mt-3`}>{salvando ? <span><CircularProgress size={15} style={{marginBottom: '-3px', marginRight: 5, color: '#fff'}} /> Salvando...</span> : 'Salvar'}</button> */}
                </FormCamera>

            </Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarCamera);
