import api from "../services/api/api";
import { Camera, CameraDGuard } from "../interfaces/Cameras.interface";
import { ServidorDGuard } from "../interfaces/Layout.interface";

class ServidorDGuardAPI {

    cadastrar = async (servidorDGuard: ServidorDGuard) => await api.post<ServidorDGuard>(`/ServidorDGuard`, servidorDGuard);
    atualizar = async (servidorDGuard: ServidorDGuard) => await api.put<ServidorDGuard>(`/ServidorDGuard`, servidorDGuard);
    deletar = async (servidorDGuardId: string) => await api.delete(`/ServidorDGuard/${servidorDGuardId}`);

}

export default new ServidorDGuardAPI();