export interface Nota {
    id: string;
    condominioId: string;
    unidadeId: string;
    validade: Date;
    tipo: TipoNota;
    descricao: string;
    ordem: number;
    usuario: string;
  }
  
export interface NotaRequest extends Partial<Nota> {}

export enum TipoNota
{
  Normal = 0,
  ProcedimentoEclusa = 1,
  ProcedimentoVisitante = 2,
  ProcedimentoMorador = 3,
  ProcedimentoEntrega = 4,
  ProcedimentoPrestador = 5
}