import { makeAutoObservable, runInAction } from "mobx";
import LayoutAPI from "../api/LayoutAPI";
import LogAPI from "../api/LogAPI";
import RamalAPI from "../api/RamalAPI";
import { converterData, converterHora, cpfMask, maskCelular, maskTelefoneFixo } from "../components/utils/mask";
import { Layout, LayoutDGuard, ServidorDGuard } from "../interfaces/Layout.interface";
import { Log } from "../interfaces/Log.interface";
import { Nota } from "../interfaces/Nota.interface";
import { Ramal } from "../interfaces/Ramal.interface";
import { Visitante } from "../interfaces/Visitante.interface";

class LogStore {
    morador: Log[] = [];
    notas: Log[] = [];
    dispositivo: Log[] = [];
    visitante: Log[] = [];
    biometriaDigital: Log[] = [];
    tag: Log[] = [];
    veiculo: Log[] = [];
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listarLogMoradoresPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                
                const { data } = await LogAPI.listarMoradorPorCondominio(condominioId); 
                
                runInAction(() => {
                    this.morador = data;
                    
                    this.morador.forEach((morador) =>
                    {
                        morador.parametros = JSON.parse(morador.parametros);
                        morador.nome = `${morador.nome.split(" ")[0]} ${morador.nome.split(" ")[1]}`;
                        morador.dataHora = `${converterData(new Date(morador.dataHora))} ${converterHora(new Date(morador.dataHora))}`;
                        morador.parametros.Celular = morador.parametros.Celular !== null ? maskCelular(morador.parametros.Celular) : 'vazio';
                        morador.parametros.Telefone = morador.parametros.Telefone !== null ? maskTelefoneFixo(morador.parametros.Telefone) : 'vazio';
                        morador.parametros.CPF = morador.parametros.CPF !== null ? cpfMask(morador.parametros.CPF) : 'vazio';
                        // morador.parametros.Unidade = `${morador.parametros?.Unidade?.Apartamento.toString()} - ${morador.parametros.Unidade?.Bloco?.Nome.toString()}`;
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogMoradoresPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            if(unidadeId) {
                const { data } = await LogAPI.listarMoradorPorUnidade(unidadeId);
                runInAction(() => {
                    this.morador = data;
                    this.morador.forEach((morador) =>
                    {
                        morador.parametros = JSON.parse(morador.parametros);
                    })
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }


    async listarLogNotasPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await LogAPI.listarNotaPorCondominio(condominioId); 
                runInAction(() => {
                    this.notas = data;
                    this.notas.forEach((nota) =>
                    {
                        nota.parametros = JSON.parse(nota.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogNotasPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            if(unidadeId) {
                const { data } = await LogAPI.listarNotaPorUnidade(unidadeId);
                runInAction(() => {
                    this.notas = data;
                    this.notas.forEach((nota) =>
                    {
                        nota.parametros = JSON.parse(nota.parametros);
                    })
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogDispositivoPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await LogAPI.listarDispositivoPorCondominio(condominioId);
                runInAction(() => {
                    this.dispositivo = data;
                    this.dispositivo.forEach((dispositivo) =>
                    {
                        dispositivo.parametros = JSON.parse(dispositivo.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogsDispositivoPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            if(unidadeId) {
                const { data } = await LogAPI.listarDispositivoPorUnidade(unidadeId);
                runInAction(() => {
                    this.dispositivo = data;
                    this.dispositivo.forEach((dispositivo) =>
                    {
                        dispositivo.parametros = JSON.parse(dispositivo.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogVisitanteAutorizadoPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await LogAPI.listarVisitanteAutorizadoPorCondominio(condominioId);
                runInAction(() => {
                    this.visitante = data;
                    this.visitante.forEach((visitante) =>
                    {
                        visitante.parametros = JSON.parse(visitante.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogVisitanteAutorizadoPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            if(unidadeId) {
                const { data } = await LogAPI.listarVisitanteAutorizadoPorUnidade(unidadeId);
                runInAction(() => {
                    this.visitante = data;
                    this.visitante.forEach((visitante) =>
                    {
                        visitante.parametros = JSON.parse(visitante.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogVisitanteAtivosPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await LogAPI.listarVisitanteAtivosPorCondominio(condominioId);
                runInAction(() => {
                    this.visitante = data;
                    this.visitante.forEach((visitante) =>
                    {
                        visitante.parametros = JSON.parse(visitante.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogVisitanteAtivosPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            if(unidadeId) {
                const { data } = await LogAPI.listarVisitanteAtivosPorUnidade(unidadeId);
                runInAction(() => {
                    this.visitante = data;
                    this.visitante.forEach((visitante) =>
                    {
                        visitante.parametros = JSON.parse(visitante.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogVisitanteEncerradoPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await LogAPI.listarVisitanteEncerradosPorCondominio(condominioId);
                runInAction(() => {
                    this.visitante = data;
                    this.visitante.forEach((visitante) =>
                    {
                        visitante.parametros = JSON.parse(visitante.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogVisitanteEncerradoPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            if(unidadeId) {
                const { data } = await LogAPI.listarVisitanteEncerradosPorUnidade(unidadeId);
                runInAction(() => {
                    this.visitante = data;
                    this.visitante.forEach((visitante) =>
                    {
                        visitante.parametros = JSON.parse(visitante.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogBiometriaDigitalPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await LogAPI.listarBiometriaDigitalPorCondominio(condominioId);
                runInAction(() => {
                    this.biometriaDigital = data;
                    this.biometriaDigital.forEach((biometriaDigital) =>
                    {
                        biometriaDigital.parametros = JSON.parse(biometriaDigital.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogBiometriaDigitalPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            if(unidadeId) {
                const { data } = await LogAPI.listarBiometriaDigitalPorUnidade(unidadeId);
                runInAction(() => {
                    this.biometriaDigital = data;
                    this.biometriaDigital.forEach((biometriaDigital) =>
                    {
                        biometriaDigital.parametros = JSON.parse(biometriaDigital.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogTagPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await LogAPI.listarTagPorCondominio(condominioId);
                runInAction(() => {
                    this.tag = data;
                    this.tag.forEach((tag) =>
                    {
                        tag.parametros = JSON.parse(tag.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogTagPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            if(unidadeId) {
                const { data } = await LogAPI.listarTagPorUnidade(unidadeId);
                runInAction(() => {
                    this.tag = data;
                    this.tag.forEach((tag) =>
                    {
                        tag.parametros = JSON.parse(tag.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogVeiculoPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await LogAPI.listarVeiculoPorCondominio(condominioId);
                runInAction(() => {
                    this.veiculo = data;
                    this.veiculo.forEach((veiculo) =>
                    {
                        veiculo.parametros = JSON.parse(veiculo.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarLogVeiculoPorUnidade(unidadeId: string) {
        this.loading = true;
        try {
            if(unidadeId) {
                const { data } = await LogAPI.listarVeiculoPorUnidade(unidadeId);
                runInAction(() => {
                    this.veiculo = data;
                    this.veiculo.forEach((veiculo) =>
                    {
                        veiculo.parametros = JSON.parse(veiculo.parametros);
                    });
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

}

export default LogStore;