import { Nota, NotaRequest } from '../interfaces/Nota.interface';
import api from '../services/api/api';

interface INovaOrdem 
{
  novaOrdemNotas: Nota[]
}

class NotaAPI {
  listarPorCondominioId = (condominioId: string) => api.get<Nota[]>(`/nota/condominio/${condominioId}`);

  listarPorUnidadeId = (unidadeId: string) => api.get<Nota[]>(`/nota/unidade/${unidadeId}`);

  remover = async (id: string) => api.delete(`nota/${id}`);

  atualizarOrdem = async (novaOrdem: INovaOrdem) => {
    await api.put('/Nota/OrdenarNotas', novaOrdem);
  };

  atualizar = async (request: Nota) => await api.put<Nota>('/nota', request);

  salvar = async (request: Nota) => await api.post<Nota>('/nota', request);
}

export default new NotaAPI();