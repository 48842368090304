import { useContext, useState } from "react";
import { toast } from "react-toastify";
import SiamAPI from "../api/SiamAPI";
import { Controladora, Siam } from "../interfaces/Siam.interface";
import { StoreContext } from "../stores";


export const useSiam = () => {

    const { siamStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletandoSiam, setDeletando] = useState(false);
    const [reiniciando, setReiniciando] = useState(false);
    const [resetando, setResetando] = useState(false);

    const cadastrarControladora = async (controladora: Controladora, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await SiamAPI.cadastrarControladora(controladora);
            if(status === 200) {
                siamStore.controladora.push(data)
                toast.success('Controladora cadastrado com sucesso');
                hide();
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            toast.error('Erro ao cadastrar controladora');
            //
        }
    }

    const atualizarControladora = async (controladora: Controladora, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await SiamAPI.atualizarControladora(controladora);
            siamStore.controladora = siamStore.controladora.map( c => c.id === controladora.id ? controladora : c );
            if(status === 200) {
                toast.success('Controladora atualizado com sucesso');
                hide();
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            toast.error('Erro ao atualizar controladora');
            //
        }
    }

    const deletarControladora = async (controladora: Controladora) => {
        setDeletando(true);
        try {
            const { data, status } = await SiamAPI.deletarControladora(controladora.id);
            siamStore.controladora = siamStore.controladora.filter( c => c.id !== controladora.id);
            if(status === 200) {
                toast.success('Controladora excluído com sucesso');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
            toast.error('Erro ao excluir controladora');
            //
        }
    }

    // const resetar = async (guarita: Guarita, hide: () => void) => {
    //     setResetando(true);
    //     try {
    //         const { data, status } = await GuaritaAPI.resetar(guarita.id);
    //         if(status === 200) {
    //             toast.success('Guarita resetado com sucesso');
    //             hide();
    //             setResetando(false);
    //         } else {
    //             toast.error('Erro ao resetar guarita');
    //             setResetando(false);
    //         }
    //     } catch(e) {
    //         setResetando(false);
    //         toast.error('Erro ao resetar guarita');
    //         //
    //     }
    // }

    // const atualizarReceptores = async (guarita: Guarita, hide: () => void) => {
    //     setAtualizando(true);
    //     try {
    //         const { data, status } = await GuaritaAPI.atualizarReceptores(guarita.id);
    //         if(status === 200) {
    //             toast.success('Guarita com os receptores atualizado com sucesso');
    //             hide();
    //             setAtualizando(false);
    //         } else {
    //             toast.error('Erro ao atualizar receptores do guarita');
    //             setAtualizando(false);
    //             toast.error('Erro ao atualizar receptores do guarita');
    //         }
    //     } catch(e) {
    //         setAtualizando(false);
    //         //
    //     }
    // }

    const cadastrar = async (siam: Siam, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await SiamAPI.cadastrar(siam);
            if(status === 200) {
                siamStore.listar();
                toast.success('Siam cadastrado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar guarita');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const atualizar = async (siam: Siam, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await SiamAPI.atualizar(siam);
            if(status === 200) {
                siamStore.siam = siamStore.siam.map( s => s.id === siam.id ? siam : s );
                toast.success('Siam atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar o siam');
                setAtualizando(false);
            }
        } catch(e) {
            setAtualizando(false);
            //
        }
    }

    const deletarSiam = async (siam: Siam) => {
        setDeletando(true);
        try {
            const { status } = await SiamAPI.deletar(siam.id);
            if(status === 200) {
                siamStore.siam = siamStore.siam.filter( s => s.id !== siam.id );
                toast.success('Siam deletado com sucesso');
                setDeletando(false);
            } else {
                toast.error('Erro ao deletado siam');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
            //
        }
    }


    return {
        cadastrar,
        atualizar,
        deletarSiam,
        salvando,
        atualizando,
        deletandoSiam,
        cadastrarControladora,
        atualizarControladora,
        deletarControladora
        // reiniciar,
        // reiniciando,
        // resetar,
        // resetando
    }

}