import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import React, { FC, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { useRele } from '../../hooks/Rele.hook';
import { Rele, ReleRequest } from '../../interfaces/Rele.interface';
import { useRede } from '../../hooks/Rede.hook';
import { Rede, RedeRequest } from '../../interfaces/Rede.interface';
import { StoreContext } from '../../stores';
import { FormContainer } from './styles/ModalCadastrar.style';
import { observer } from 'mobx-react-lite';
import { useServidorDGuard } from '../../hooks/ServidorDGuard.hook';
import { ServidorDGuard, ServidorDGuardRequest } from '../../interfaces/Layout.interface';
import { Button, Input, Loader, Modal } from 'rsuite';

interface PropsModal {
    show: boolean;
    hide: () => void;
}

const ModalCadastrar: FC<PropsModal> = ({ show, hide }) => {
    
    let inputRef = useRef<HTMLInputElement>(null);
    const { condominioStore } = useContext(StoreContext);
    const { cadastrar, salvando } = useServidorDGuard();
    
    const [form, setForm] = useState<ServidorDGuardRequest>({});

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        cadastrar(form as ServidorDGuard, hide);
    }

    useEffect(() => {
        if(inputRef.current && show)
        {
            inputRef.current.focus();
        }
    }, [show]);

    const renderForm = () => {
        return(
            <FormContainer onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome do servidor</Form.Label>
                        <Input ref={inputRef} required type="text" className='shadow-none' placeholder="SERVIDOR 1" value={form.nome || ''} onChange={(e) => setForm({...form, nome: e})} />
                    </Form.Group>

                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip</Form.Label>
                        <Input ref={inputRef} required type="text" className='shadow-none' placeholder="192.168.0.0" value={form.ip || ''} onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta</Form.Label>
                        <Input ref={inputRef} required type="text" className='shadow-none' placeholder="80" value={form.porta?.toString() || ''} onChange={(e) => setForm({...form, porta: parseInt(e)})} />
                    </Form.Group>

                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>usuarioDGuard</Form.Label>
                        <Input ref={inputRef} required type="text" className='shadow-none' placeholder="admin" value={form.usuarioDGuard || ''} onChange={(e) => setForm({...form, usuarioDGuard: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>senhaDGuard</Form.Label>
                        <Input ref={inputRef} required type="text" className='shadow-none' placeholder="******" value={form.senhaDGuard || ''} onChange={(e) => setForm({...form, senhaDGuard: e})} />
                    </Form.Group>

                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta CGI</Form.Label>
                        <Input ref={inputRef} required type="text" className='shadow-none' placeholder="80" value={form.portaCgi || ''} onChange={(e) => setForm({...form, portaCgi: parseInt(e)})} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-4'>
                    <div className='d-flex'>
                        <Button type={'submit'} appearance="primary" style={{marginRight: 10}} disabled={salvando || form.nome?.length === 0}>{ salvando ? <Loader title='Salvando...' /> : 'Salvar' }</Button>
                        <Button type={'button'} appearance="default" disabled={salvando} onClick={hide}>Fechar</Button>
                    </div>
                </div>
            </FormContainer>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastro novo servidor DGUARD</p>
                    <p className='modal-styled-subtitle'>Preencha todos os campos para realizar o cadastro</p>
                </div>
            </Modal.Header>
            <Modal.Body>{renderForm()}</Modal.Body>
        </Modal>
    )
}

export default observer(ModalCadastrar);