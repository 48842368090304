import api from "../services/api/api";
import { Auth, DadosSms } from "../interfaces/Auth.interface";
import { Usuario } from "../interfaces/Usuario.interface";

class AuthAPI {

    auth = async (auth: Auth) => await api.post<Auth>(`/Auth/login`, auth);
    usuario = async () => await api.get<Usuario>('/auth/user');
    enviarSmsEmail = async (dado: DadosSms) => await api.post<Usuario>('/Auth/enviarSmsEmail/morador', dado);

}

export default new AuthAPI();