export default {
  title: 'dark',
  layout: {
    menu: {
      width: 80,
      bgColor: '#213991',
    },
  },
  colors: {
    default: '#27282d',
    avatar: '#242644',
    primary: '#5448c8',
    success: 'green', 
    danger: '#FE193F',
    warning: '#f8961e',
    text: '#D3D3D3',
    bgColor: '#191a1c', 
    bgBox: '#27282d',
    bgNav: '#27282d',
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
};
