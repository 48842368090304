import { Comando } from "../interfaces/Comando.interface";
import api from "../services/acionamento/api";

class ComandoAPI {

    listarComandoPorAcionamentoId = async (acionamentoId: string) => await api.get(`/Comando/acionamento/${acionamentoId}`);

    listarComandoPorCaminhoId = async (caminhoId: string) => await api.get(`/Comando/comando/${caminhoId}`);

    cadastrarComando = async (request: Comando) => await api.post(`/Comando`, request);

    atualizarComando = async (request: Comando) => await api.put(`/Comando`, request);

    atualizarNovaOrdemComando = async (request: Comando[]) => await api.put(`/Comando/AtualizarOrdem`, request);

    deletarComando = async (comandoId: string) => await api.delete(`/Comando/${comandoId}`);

}


export default new ComandoAPI();