import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import React, { FC, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { useRele } from '../../hooks/Rele.hook';
import { Rele, ReleRequest } from '../../interfaces/Rele.interface';
import { useRede } from '../../hooks/Rede.hook';
import { Rede, RedeRequest } from '../../interfaces/Rede.interface';
import { StoreContext } from '../../stores';
import { observer } from 'mobx-react-lite';
import { Button, Modal, SelectPicker } from 'rsuite';
import { FormContainer } from '../../components/servidorDGuard/styles/ModalCadastrar.style';
import { OctosRegister, OctosRegisterRequest } from '../../interfaces/Octos.interface';
import useOctos from '../../hooks/Octos.hook';

interface PropsModal {
    show: boolean;
    hide: () => void;
}

const CadastrarOctos: FC<PropsModal> = ({ show, hide }) => {
    
    let inputRef = useRef<HTMLInputElement>(null);
    const { condominioStore, octosStore, cameraStore } = useContext(StoreContext);
    const { cadastrar, salvando } = useOctos();
    
    const [form, setForm] = useState<OctosRegisterRequest>({});

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        form.serverIdDguard = form.serverIdDguard?.replace('{', '').replace('}', '')
        cadastrar(form as OctosRegister, hide);
    }

    useEffect(() => {
        if(inputRef.current && show)
        {
            inputRef.current.focus();
        }
    }, [show]);

    const renderMenuServer = menu => {
        if (octosStore.loading) {
          return (
            <p style={{ padding: 15, color: '#999', textAlign: 'center' }}>
              CARREGANDO DADOS... POR FAVOR, AGUARDE
            </p>
          );
        }
        return menu;
      };

      const renderMenuCam = menu => {
        if (cameraStore.loading) {
          return (
            <p style={{ padding: 15, color: '#999', textAlign: 'center' }}>
              CARREGANDO DADOS... POR FAVOR, AGUARDE
            </p>
          );
        }
        return menu;
      };

    const renderForm = () => {
        return(
            <FormContainer onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Server Id</Form.Label>
                        <SelectPicker
                                block
                                cleanable={false}
                                size={'md'}
                                value={form.serverIdDguard ?? ""}
                                placeholder={'Selecione'}
                                data={octosStore.servers.map((s) => ({ label: `${s.name}`, value: s.guid }))}                            
                                onChange={(e) =>
                                {
                                    if(e)
                                        setForm({ ...form, serverIdDguard: e });
                                }}
                                renderMenu={renderMenuServer}
                                onOpen={() => 
                                {
                                    if(octosStore.servers.length === 0)
                                        octosStore.listarServersDGuard()
                                }}
                            />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Camera Id DGuard</Form.Label>
                        <SelectPicker
                                block
                                disabled={!form.serverIdDguard}
                                cleanable={false}
                                size={'md'}
                                value={form.cameraIdDguard}
                                placeholder={'Selecione'}
                                data={octosStore.cams.map((c) => ({ label: `${c.name}`, value: c.id }))}                            
                                onChange={(e) =>
                                {
                                    setForm({ ...form, cameraIdDguard: e as number });
                                }}
                                renderMenu={renderMenuServer}
                                onOpen={() => 
                                {
                                    if(form.serverIdDguard)
                                        octosStore.listarCamerasPorServerId(form.serverIdDguard)
                                }}
                            />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Camera Condomínio</Form.Label>
                        <SelectPicker
                                block
                                disabled={form.cameraIdDguard === undefined}
                                cleanable={false}
                                size={'md'}
                                value={form.cameraId ?? ""}
                                placeholder={'Selecione'}
                                data={cameraStore.camerasCondominio.map((c) => ({ label: `${c.nomeDGuard}`, value: c.id }))}                            
                                onChange={(e) =>
                                {
                                    if(e)
                                        setForm({ ...form, cameraId: e });
                                }}
                                renderMenu={renderMenuCam}
                                onOpen={() => 
                                    {
                                        if(condominioStore.selecionado)
                                            cameraStore.listarPorCondominioId(condominioStore.selecionado.id);
                                    }}
                            />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-3'>
                    <Button appearance="primary" disabled={salvando} type="submit">
                        { salvando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={salvando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                </FormContainer>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastrar nova configurações octos</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{renderForm()}</Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarOctos);