import React from 'react'
import { Container } from './styles/EmptyChat.style';
import image from '../../assets/img/chat.png';

const EmptyChat = () => {
  return (
    <Container className='d-flex justify-content-center'>
        <div className='align-self-center'>
          <div className='d-flex justify-content-center'>
            <img src={image} className={'img'} />
          </div>
          <p className='title'>Seja bem vindo ao chat!</p>
          <div className='d-flex justify-content-center'>
            <p className='subtitle'>
                Selecione o morador para enviar mensagem
            </p>
          </div>
        </div>
    </Container>
  )
}

export default EmptyChat;