import api from "../services/api/api";
import { Camera, CameraDGuard, CameraPerfil, CameraPerfilIdentificacao, CameraPerfilLista, NovaOrdem } from "../interfaces/Cameras.interface";

class CameraAPI {

    // CAMERAS
    listarPorCondominioId = async (condominioId: string) => await api.get<Camera[]>(`/Camera/condominio/${condominioId}`);
    listarPorLayout = async (layoutId: string) => await api.get<Camera[]>(`/Camera/layoutId/${layoutId}`);
    listarCamerasDoServidorDGuardPorLayoutId = async (layoutId: string) => await api.get<CameraDGuard[]>(`/DGuard/listarCamerasDGuardPorLayout?layoutId=${layoutId}`);
    cadastrar = async (camera: Camera) => await api.post<Camera>(`/Camera`, camera);
    atualizar = async (camera: Camera) => await api.put<Camera>(`/Camera`, camera);
    deletar = async (id: string) => await api.delete(`/Camera/${id}`);
    atualizarOrdem = async (ordem: NovaOrdem) => await api.put(`/Camera/AtualizarOrdem`, ordem);

    // CAMERAS PERFIL IDENTIFICACAO
    listarCameraPerfilIdentificacaoPorCondominio = async (condominioId: string) => await api.get<CameraPerfilIdentificacao[]>(`/CameraPerfilIdentificacao/ObterPorCondiminioId`, { params: { condominioId } });
    cadastrarPerfil = async (perfil: CameraPerfilIdentificacao) => await api.post<Camera>(`/CameraPerfilIdentificacao`, perfil);
    atualizarPerfil = async (perfil: CameraPerfilIdentificacao) => await api.put<Camera>(`/CameraPerfilIdentificacao`, perfil);
    deletarPerfil = async (id: string) => await api.delete(`/CameraPerfilIdentificacao`, { params: { id } });

    // CAMERAS PERFIL
    listarCameraPerfilPorCameraPerfilIdentificacaoId = async (cameraPerfilIdentificacaoId: string) => await api.get<CameraPerfil[]>(`/CameraPerfil/ObterPorCameraPerfilIdentificacaoId`, { params: { id: cameraPerfilIdentificacaoId } });
    cadastrarCameraPerfil = async (cameraPerfil: CameraPerfilLista) => await api.post<Camera>(`/CameraPerfil`, cameraPerfil);
    deletarCameraPerfil = async (id: string) => await api.delete(`/CameraPerfil`, { params: { id } });

}

export default new CameraAPI();