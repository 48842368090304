import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import ComponentRoutes from './routes/Routes';
import { StoreContext } from './stores';
import GlobalStyles from './styles/globalStyles';
import dark from './styles/themes/dark';
import light from './styles/themes/light';
import 'rsuite/styles/index.less'; 
import { CustomProvider } from 'rsuite';

const App = () => {

  const { themeStore } = useContext(StoreContext);

  return (
    <CustomProvider theme={themeStore.theme === 'dark' ? 'dark' : 'light'}>
    <ThemeProvider theme={(themeStore.theme === 'dark') ? dark : light}>
      {/* <ToastContainer 
        position="bottom-right"
        autoClose={3500}
        newestOnTop={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      <Toaster/> */}
      <GlobalStyles />
      <ComponentRoutes/>
    </ThemeProvider>
  </CustomProvider>
    
  );
}

export default observer(App);