import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Checkbox, CheckboxGroup, List, Loader, Modal, SelectPicker } from 'rsuite';
import { useMorador } from '../../hooks/Morador.hook';
import { useUnidade } from '../../hooks/Unidade.hook';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import { StoreContext } from '../../stores';
import { Container } from './styles/AlterarUnidadeMorador.style';
import { MoverMoradores, MoverMoradoresRequest } from '../../interfaces/Morador.interface';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AlterarUnidadeMoradores: FC<ModalProps> = ({ show, hide }) => {

    const { unidadeStore, moradorStore } = useContext(StoreContext);
    const [form, setForm] = useState<MoverMoradoresRequest>({});
    const { moverMoradorParaOutraUnidade, atualizando } = useUnidade();
    const [value, setValue] = useState([]);

    const handleChange = value => setValue(value);

    useEffect(() =>
    {
        setForm({});
        setValue([]);
    }, [show]);

    const salvar = () =>
    {
        if(value.length !== 0 && unidadeStore.selecionado)
        {
            form.moradores = value;
            moverMoradorParaOutraUnidade(form as MoverMoradores, unidadeStore.selecionado.id, hide)
        }
    }

    const Listagem = () => {
        return(
            <div className='content'>
                <div>
                    
                    </div>
                    <CheckboxGroup name="checkboxList" value={value} onChange={handleChange}>
                        {moradorStore.moradores.map(item => (
                        <Checkbox key={item.id} value={item.id}>
                            {item.nome}
                        </Checkbox>
                        ))}
                    </CheckboxGroup>

                </div>
        )
    }

    return(
        <Modal open={show} onClose={() => atualizando ? {} : hide()}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Mover moradores para outra unidade</p>
                    <p className='modal-styled-subtitle'>
                        Selecione o morador que você deseja mover para outra unidade
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='align-self-center'>
                    <div>
                        <p style={{width: '40%'}}>Selecione a nova unidade à qual os moradores pertencerão:</p>
                        <SelectPicker
                            block
                            cleanable={false}
                            // value={value.toString()}
                            value={form.novaUnidade}
                            placeholder={'Selecione'}
                            disabledItemValues={[unidadeStore.selecionado?.id ?? '']}
                            data={unidadeStore.unidadesPorCondomino.map((unidade) => ({ label: `${unidade.apartamento} - ${unidade.blocoNome}`, value: unidade.id }))}                            
                            onChange={(e) =>
                            {
                                if(e)
                                    setForm({ ...form, novaUnidade: e })
                            }}
                        />
                    </div>
                </div>
                {
                    !form.novaUnidade ? ''
                    :
                    <Container>
                        {Listagem()}
                    </Container>
                }
            </Modal.Body>
            <Modal.Footer>

                <Button appearance='primary' color={'violet'} disabled={atualizando} loading={atualizando} onClick={salvar}>Salvar</Button>
                <Button disabled={atualizando} onClick={() => atualizando ? {} : hide()}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(AlterarUnidadeMoradores);