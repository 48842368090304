import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../stores';
import NotFound from '../utils/NotFound';
import Loading from '../utils/Loading';
import { Container } from './styles/LayoutCameras.style';
import axios from 'axios';

const LayoutCameras = () => {

    const { layoutStore } = useContext(StoreContext);
    const [notFound, setNotFound] = useState(false);
    
    var idlayout = localStorage.getItem("idlayout") ?? "vazio";

    useEffect(() => {
        const cleanup = () => {
            localStorage.removeItem("idlayout");
        }
        window.addEventListener('beforeunload', cleanup);
        return () => {
            window.removeEventListener('beforeunload', cleanup);
        }
    }, []);

    useEffect(() =>
    {
        if(idlayout !== null || idlayout != "vazio" || idlayout !== "")
        {
            layoutStore.listarPorId(idlayout);
        }
        if(idlayout === "vazio")
        {
            setNotFound(true);
            setTimeout(() =>
            {
                window.close();
            }, 2000);
        }
    }, []);

    if(notFound)
    {
        return <NotFound message='Erro ao pegar informações do layout!' />
    }

    if(layoutStore.loading)
    {
        return <Loading message='Carregando informações do layout...' />
    }

    return (
        <Container>
            <div className='header'>
                <p className='info'>Nome do layout: {layoutStore.layoutGeral?.nomeDGuard}</p>
                <p className='info'>Usuário DGuard: {layoutStore.layoutGeral?.usuarioDGuard}</p>
                <p className='info'>Host remoto do layout: {layoutStore.layoutGeral?.hostRemoto}</p>
                <p className='info'>Porta remoto do layout: {layoutStore.layoutGeral?.portaRemoto}</p>
                <p className='info'>Ip do servidor: {layoutStore.servidorGeral?.ip}</p>
                <p className='info'>Porta do servidor: {layoutStore.servidorGeral?.porta}</p>
                <p className='info'>Porta do cgi do servidor: {layoutStore.servidorGeral?.portaCgi}</p>
                <p className='info'>Usuário servidor: {layoutStore.servidorGeral?.usuarioDGuard}</p>
            </div>

            <div className='d-flex justify-content-center cam'>
                {
                    layoutStore.images.map((image, i) =>
                    (
                        <div key={i}>
                            <img
                                className={'img-cam'}
                                src={image.url}
                            />
                        </div>
                    ))
                }
            </div>
        </Container>
    )
}

export default observer(LayoutCameras);
