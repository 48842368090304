import { Autocomplete, Backdrop, Box, CircularProgress, Menu, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { AiOutlineCaretLeft, AiOutlineTags, AiOutlineUser } from 'react-icons/ai';
import { BiEdit, BiFingerprint, BiPlus, BiUser, BiX } from 'react-icons/bi';
import { BsPeople, BsPhone, BsThreeDots } from 'react-icons/bs';
import { RiAlignLeft } from 'react-icons/ri'
import { GrUserExpert } from 'react-icons/gr';
import { VscTable } from 'react-icons/vsc';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { StoreContext } from '../../stores';
import { useMorador } from '../../hooks/Morador.hook';
import { useVisitante } from '../../hooks/Visitante.hook';
import { Morador } from '../../interfaces/Morador.interface';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import CadastrarTag from '../dispositivo/tag/CadastrarTag';
import Empty from '../utils/Empty';
import { converterData, converterHora, cpfMask, maskCelular } from '../utils/mask';
import AtualizarMorador from './AtualizarMorador';
import AtualizarVisitante from './AtualizarVisitante';
import CadastrarMorador from './CadastrarMorador';
import CadastrarVisitante from './CadastrarVisitante';
import ModalBiometria from '../dispositivo/biometria/ModalBiometria';
import { CardMorador, Container, Header, ListMorador, TransitionDiv } from './styles/Moradores.style';
import { FiUserCheck } from 'react-icons/fi';
import NotFound from '../utils/NotFound';
import PermissoesListagem from '../permissoes/PermissoesListagem';
import AssociarMoradorCard from './AssociarMoradorCard';
import CadastrarUsuarioCard from './CadastrarUsuarioCard';
import { Button, Dropdown, Input, Nav, Navbar, SelectPicker, IconButton, Tag, Whisper, Tooltip } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import AtualizarOrdem from './AtualizarOrdem';
import BuscaMorador from './BuscaMorador';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import { CgArrowsV } from 'react-icons/cg';
import DragableIcon from '@rsuite/icons/Dragable';
import SearchIcon from '@rsuite/icons/Search';
import SkeletonList from '../utils/SkeletonList';
import ModalRelatorioMorador from './ModalRelatorioMorador';
import Loading from './Loading';
import AlterarUnidadeMorador from './AlterarUnidadeMorador';


const MoradorListagem = () => {

    const { unidadeStore, visitanteStore, moradorStore, dispositivosStore, condominioStore, themeStore } = useContext(StoreContext);
    const { deletar, deletando } = useMorador();
    const { _deletar, _deletando } = useVisitante();
    
    const [modalCadastrarMorador, setModalCadastrarMorador] = useState(false);
    const [modalEditarMorador, setModalEditarMorador] = useState(false);
    const [modalEditarVisitante, setModalEditarVisitante] = useState(false);
    const [modalDeletarMorador, setModalDeletarMorador] = useState(false);
    const [modalDeletarVisitante, setModalDeletarVisitante] = useState(false);
    const [modalRelatorio, setModalRelatorio] = useState(false);
    const [state, setState] = useState(true);
    const [state1, setState1] = useState(true);
    const [searchMorador, setSearchMorador] = useState('');
    const [searchVisitante, setSearchVisitante] = useState('');
    const [routes, setRoutes] = useState('moradores');  
    const [cadastrarTag, setCadastrarTag] = useState(false);
    const [cadastrarBiometria, setCadastrarBiometria] = useState(false);
    const [value, setValue] = useState<UnidadeRequest | string>('');
    const [openMovaMorador, setOpenMoverMorador] = useState(false);
    const [openPermissioesApp, setOpenPermissoesApp] = useState(false);
    const [atualizarOrdem, setAtualizarOrdem] = useState(false);
    const [openAddUsuario, setOpenAddUsuario] = useState(false);
    const [openAssociarMorador, setOpenAssociarMorador] = useState(false);
    const [openBuscarMorador, setOpenBuscaMorador] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElVisitantes, setAnchorElVisitantes] = React.useState<null | HTMLElement>(null);
    const [anchorOrdem, setAnchorElOrdem] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openVisitantes = Boolean(anchorElVisitantes);
    const openOrdem = Boolean(anchorOrdem);

    const toggleCadastrarMorador = () => setModalCadastrarMorador(!modalCadastrarMorador);
    const toggleEditarMorador = () => setModalEditarMorador(!modalEditarMorador);
    const toggleEditarVisitante = () => setModalEditarVisitante(!modalEditarVisitante);
    const toggleCadastrarTag = () => setCadastrarTag(!cadastrarTag);
    const toggleCadastrarBiometria = () => setCadastrarBiometria(!cadastrarBiometria);
    const toggleOpenPermissioesApp = () => setOpenPermissoesApp(!openPermissioesApp);
    const toggleOpenAssociarMorador = () => setOpenAssociarMorador(!openAssociarMorador);
    const toggleOpenAddUsuario = () => setOpenAddUsuario(!openAddUsuario);
    const toggleAtualizarOrdem = () => setAtualizarOrdem(!atualizarOrdem);
    const toggleOpenBuscaMorador = () => setOpenBuscaMorador(!openBuscarMorador);
    const toggleModalDeletarMorador = () => setModalDeletarMorador(!modalDeletarMorador);
    const toggleModalDeletarVisitante = () => setModalDeletarVisitante(!modalDeletarVisitante);
    const toggleModalRelatorio = () => setModalRelatorio(!modalRelatorio);
    const toggleOpenMoverMorador = () => setOpenMoverMorador(!openMovaMorador);

    const deletarMorador = () =>
    {
        if(moradorStore.selecionado) {
            deletar(moradorStore.selecionado);
        }
    }

    const deletarVisitante = () =>
    {
        if(visitanteStore.selecionado) {
            _deletar(visitanteStore.selecionado);
        }
    }

 
    useEffect(() => {
        let unidadeId = localStorage.getItem('@unidadeId');
        unidadeStore.listarPorCondominioId()
        .then(() =>
        {
            if(unidadeId) 
            {
                moradorStore.listarPorUnidadeId(unidadeId);
                visitanteStore.listarPorUnidade(unidadeId);
                unidadeStore.definirSelecionado(unidadeId);
                dispositivosStore.listarControlesPorUnidade(unidadeId);
            }
            else
            {
                let primeiraUnidade = unidadeStore.unidadesPorCondomino[0];
                if(primeiraUnidade) {
                    moradorStore.listarPorUnidadeId(primeiraUnidade.id); 
                    visitanteStore.listarPorUnidade(primeiraUnidade.id);
                    unidadeStore.definirSelecionado(primeiraUnidade.id);
                    dispositivosStore.listarControlesPorUnidade(primeiraUnidade.id);
                }
            }
        })
        .catch(() => alert('erro'))
        
    }, [condominioStore.selecionado]);

    const filtrarMorador = moradorStore.moradores.filter(morador =>
        morador.nome?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(searchMorador.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
        morador.status?.toLocaleLowerCase().includes(searchMorador.toLocaleLowerCase()) ||
        !searchMorador
    );

    const filtrarVisitante = visitanteStore.visitantes.filter(visitante =>
        visitante.nome?.toLocaleLowerCase().includes(searchVisitante.toLocaleLowerCase()) ||
        !searchVisitante
    );

    const renderMoradores = () =>
    {

        if(!unidadeStore.selecionado) 
        {
            return <NotFound message='Selecione a unidade' />
        }

        if(moradorStore.loading)
        {
            return <Loading />
        }

        if(deletando) return <Loading />

        if(moradorStore.moradores.length === 0) 
        {
            return <NotFound message='Nenhum morador(a) cadastrado' />
        }

        return(
            <div>
                <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="md">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>Email</th>
                            <th>Celular</th>
                            <th>Nascimento</th>
                            <th>Cadastros</th>
                            <th>Status</th>
                            <th>APP</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filtrarMorador.map((morador, i) =>
                            (
                                <tr key={i} onClick={() => moradorStore.definirSelecionado(morador.id)}>
                                    <td>{morador.nome}</td>
                                    <td>{morador.cpf ? cpfMask(morador.cpf) : '-'}</td>
                                    <td>{morador.email ? morador.email : '-'}</td>
                                    <td>{morador.celular ? maskCelular(morador.celular) : '-'}</td>
                                    <td>{morador.nascimento ? morador.nascimento : '-'}</td>
                                    {/* <td>{morador.nascimento ? converterData(new Date(morador.nascimento)) : '-'}</td> */}
                                    <td>
                                        <Dropdown title={'Cadastros'} size={'xs'} appearance={'primary'} color={'violet'}>
                                            <Dropdown.Item onClick={toggleCadastrarTag}>Tags</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleCadastrarBiometria}>Biometrias</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleModalRelatorio}>Relatório</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                    <td><Tag color={morador.status.toLocaleLowerCase() == 'liberado' ? 'green' : 'red'}>{morador.status}</Tag></td>
                                    <td>
                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>
                                                                                                                        <div>
                                                                                                                            <p><IconButton appearance="primary" color={'green'} icon={<BsPhone/>} size={'xs'}/> <AiOutlineCaretLeft/> Indica que o morador <span style={{fontWeight: 550}}>TEM</span> acesso ao APP</p>             
                                                                                                                            <p><IconButton appearance="primary" color={'red'} icon={<BsPhone/>} size={'xs'}/> <AiOutlineCaretLeft/> Indica que o morador <span style={{fontWeight: 550}}>NÃO TEM</span> acesso ao APP</p>             
                                                                                                                        </div>
                                                                                                                    </Tooltip>}>
                                        <IconButton appearance="primary" color={morador.usuarioId ? 'green' : 'red'} icon={<BsPhone/>} size={'xs'} onClick={
                                            () =>
                                            {
                                                if(morador.usuarioId) toggleOpenPermissioesApp();
                                                else toggleOpenAddUsuario()
                                            }
                                        } />
                                    </Whisper>
                                        
                                    </td>
                                    <td>
                                        <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'} placement={'bottomEnd'}>
                                            <Dropdown.Item onClick={() =>
                                            {
                                                // moradorStore.definirSelecionado(morador.id);
                                                toggleEditarMorador();
                                            }}>Atualizar</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleModalDeletarMorador}>Deletar</Dropdown.Item>
                                            <Dropdown.Item disabled={morador.usuarioId === null} onClick={toggleOpenAssociarMorador}>Cadastrar esse morador a outro condomínio</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    return(
        <Container>
            <Header>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='title'>Campo de cadastros de moradores</p>
                        <p className='subtitle'>Selecione a unidade para visualizar os moradores</p>
                    </div>
                    <div>
                        <IconButton appearance="primary" color="violet" icon={<PlusIcon />} disabled={!unidadeStore.selecionado} onClick={toggleCadastrarMorador}>
                            Adicionar novo morador na unidade {unidadeStore.selecionado?.apartamento}
                        </IconButton>
                    </div>
                </div>

                <div className='d-flex box'>
                    <div className='content d-flex'>
                        <BsPeople className='content-icon' />
                        <div>
                            <p className='content-title'>Moradores cadastrados </p>
                            <p className='content-subtitle'>{moradorStore.moradores.length} cadastrado na unidade {unidadeStore.selecionado?.apartamento}</p>
                        </div>
                    </div>
                    <div className='align-self-center'>
                        <div style={{marginLeft: 20}}>
                            <p>Selecione a unidade</p>
                            <SelectPicker
                                block
                                cleanable={false}
                                style={{width: 100}}
                                size={'xs'}
                                // value={value.toString()}
                                value={unidadeStore.selecionado?.id}
                                placeholder={'Selecione'}
                                data={unidadeStore.unidadesPorCondomino.map((unidade) => ({ label: `${unidade.apartamento} - ${unidade.blocoNome}`, value: unidade.id }))}                            
                                onChange={(e) =>
                                {
                                    if(e) moradorStore.listarPorUnidadeId(e);
                                    if(e) unidadeStore.definirSelecionado(e);
                                    if(e) visitanteStore.listarPorUnidade(e);
                                    if(e) dispositivosStore.listarTagPorUnidade(e);
                                }}
                            />
                        </div>
                    </div>

                    <div style={{marginLeft: 30}} className='align-self-center'>
                        <p className='info-title'>Atualizar ordem dos moradores</p>
                        <IconButton size={'xs'} appearance="primary" color="violet" icon={<DragableIcon />} onClick={toggleAtualizarOrdem}>
                            Atualizar
                        </IconButton>
                    </div>

                    <div style={{marginLeft: 0}} className='align-self-center'>
                        <p className='info-title'>Mova os moradores para outra unidade</p>
                        <IconButton size={'xs'} appearance="primary" color="violet" icon={<DragableIcon />} onClick={toggleOpenMoverMorador}>
                            Mover
                        </IconButton>
                    </div>

                    <div style={{marginLeft: 0}} className='align-self-center'>
                        <p className='info-title'>Buscar morador do condomínio pelo nome</p>
                        <IconButton size={'xs'} appearance="primary" color="violet" icon={<SearchIcon />} onClick={toggleOpenBuscaMorador}>
                            Buscar
                        </IconButton>
                    </div>

                    <div style={{marginLeft: 0}} className='align-self-center'>
                        <p className='info-title-filtro'>Filtrar moradores da unidade <span style={{fontWeight: 550, fontSize: 15}}>{unidadeStore.selecionado?.apartamento}</span></p>
                        <Input placeholder='Buscar' onChange={(e) => setSearchMorador(e)} />
                    </div>
                </div>

                <ListMorador>
                    {renderMoradores()}
                </ListMorador>
            </Header>

            <CadastrarMorador show={modalCadastrarMorador} hide={toggleCadastrarMorador} />
            <AtualizarMorador show={modalEditarMorador} hide={toggleEditarMorador}/>
            <AtualizarVisitante visitanteSelecionado={visitanteStore.selecionado} show={modalEditarVisitante} hide={toggleEditarVisitante} />
            <CadastrarTag show={cadastrarTag} hide={toggleCadastrarTag} morador={moradorStore.selecionado} />
            <ModalBiometria show={cadastrarBiometria} hide={toggleCadastrarBiometria} />
            <PermissoesListagem show={openPermissioesApp} hide={toggleOpenPermissioesApp} />
            <AssociarMoradorCard show={openAssociarMorador} hide={toggleOpenAssociarMorador} />
            <CadastrarUsuarioCard show={openAddUsuario} hide={toggleOpenAddUsuario} />
            <AtualizarOrdem show={atualizarOrdem} hide={toggleAtualizarOrdem} />
            <BuscaMorador show={openBuscarMorador} hide={toggleOpenBuscaMorador} />
            <ModalRelatorioMorador show={modalRelatorio} hide={toggleModalRelatorio} />
            <AlterarUnidadeMorador show={openMovaMorador} hide={toggleOpenMoverMorador} />

            <ModalConfirmacao show={modalDeletarMorador} hide={toggleModalDeletarMorador} action={deletarMorador} content={'Você tem certeza que deseja deletar esse morador?'} loading={deletando} />
            <ModalConfirmacao show={modalDeletarVisitante} hide={toggleModalDeletarVisitante} action={deletarVisitante} content={'Você tem certeza que deseja deletar esse visitante autorizado?'} loading={_deletando} />

        </Container>
    )
}

export default observer(MoradorListagem);