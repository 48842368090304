import { makeAutoObservable } from 'mobx';

class ThemeStore {
  theme: string = localStorage.getItem('theme') === 'dark' ? 'dark' : 'light';

  constructor() {
    makeAutoObservable(this);
  }

  async toggle() {
    let value = 'light';

    if (this.theme === 'light') {
      value = 'dark';
    }

    this.theme = value;
    localStorage.setItem('theme', value);
  }

  light() {
    let value = 'light';
    this.theme = value;
    localStorage.setItem('theme', value);
  }

  dark() {
    let value = 'dark';
    this.theme = value;
    localStorage.setItem('theme', value);
  }
}

export default ThemeStore;
