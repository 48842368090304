import React from 'react';

const RenderTipoAcionamento = (tipo: number) => 
{
    switch(tipo)
    {
        case 0:
            return 'Central';
        case 1:
            return 'Todos';
        case 2:
            return 'App';
        case 3:
            return 'Nenhum'; 
    }
}

export default RenderTipoAcionamento;
