import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import AcionamentoPerfilAPI from '../api/AcionamentoPerfilAPI';
import { StoreContext } from '../stores';
import { IPerfil } from '../interfaces/AcionamentoPerfil.interface';


export const useAcionamentoPerfil = () => {

    const { acionamentoPerfilStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);

    const salvar = async (perfil: IPerfil, hide: () => void) => {
        setSalvando(true);
        try {   
            const { data } = await AcionamentoPerfilAPI.salvar(perfil);
            acionamentoPerfilStore.perfis.push(data);
            setSalvando(false);
            toast.success('Perfil cadastrado com sucesso');
            hide();
        } catch(e) {
            toast.error('Erro ao cadastrar perfil');
            setSalvando(false);
        }
    }

    const atualizar = async (perfil: IPerfil, hide: () => void) => {
        setAtualizando(true);
        try {   
            await AcionamentoPerfilAPI.atualizar(perfil);
            acionamentoPerfilStore.listarPorCondominio(perfil.condominioId);
            setAtualizando(false);
            hide();
            toast.success('Perfil atualizado com sucesso');
        } catch(e) {
            setAtualizando(false);
            toast.error('Erro ao atualizar perfil');
        }
    }

    const deletar = async (perfil: IPerfil) => {
        setDeletando(true);
        try {   
            await AcionamentoPerfilAPI.deletar(perfil.id);
            acionamentoPerfilStore.listarPorCondominio(perfil.condominioId);
            toast.success('Perfil deletado com sucesso');
            setDeletando(false);
        } catch(e) {
            toast.error('Erro ao deletar perfil');
            setDeletando(false);
        }
    }

    return {
        salvar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando
    }

}