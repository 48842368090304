import { Guarita } from "../interfaces/Guarita.interface";
import api from "../services/api/api";

class GuaritaAPI {

    listarGuaritaPorCondominio = async (condominioId: string) => await api.get<Guarita[]>(`/Nice/condominioId/${condominioId}`);
    
    cadastrarGuarita = async (guarita: Guarita) => await api.post(`/Nice`, guarita);

    atualizarGuarita = async (guarita: Guarita) => await api.put(`/Nice`, guarita);

    deletarGuarita = async (guaritaId: string) => await api.delete(`/Nice/${guaritaId}`);

    reiniciar = async (guaritaId: string) => await api.get(`/Nice/reiniciar/${guaritaId}`);

    versaoTodosModulos = async (guaritaId: string) => await api.get(`/Nice/versaotodosmodulos`, { params: { guaritaId } });

    resetar = async (guaritaId: string) => await api.get(`/Nice/reset`, { params: { guaritaId } });

    atualizarReceptores = async (guaritaId: string) => await api.get(`/Nice/atualizarreceptores`, { params: { guaritaId } });

}

export default new GuaritaAPI();