import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { Button, InputNumber, InputPicker, Loader, Modal } from 'rsuite';
import AcionamentoAPI from '../../api/AcionamentoAPI';
import { useComando } from '../../hooks/Comando.hook';
import { Acionamento } from '../../interfaces/Acionamento.interface';
import { Comando, ComandoRequest } from '../../interfaces/Comando.interface';
import { Equipamento } from '../../interfaces/Equipamento.interface';
import { Rele, ReleRequest } from '../../interfaces/Rele.interface';
import { StoreContext } from '../../stores';

interface PropsModal {
    show: boolean;
    hide: () => void;
}

const AtualizarComando: FC<PropsModal> = ({ show, hide }) => {
    
    const { comandoStore, acionamentoStore, themeStore, equipamentoStore, guaritaStore, releStore } = useContext(StoreContext);
    const { atualizar, atualizando } = useComando();
    const [comandoGuarita, setComandoGuarita] = useState<ComandoRequest>({});
    const [comandoEquipamento, setComandoEquipamento] = useState<ComandoRequest>({});
    const [equipamentoSelecionado, setEquipamentoSelecionado] = useState<Equipamento>();
    const [mode, setMode] = useState('');

    const salvar = () => {
        if(comandoStore.selecionado)
        {
            
            switch(mode)
            {
                case 'guarita':
                    atualizar(comandoGuarita as Comando, acionamentoStore.selecionado!.id, hide);
                    break;
                case 'equipamento':
                    // comandoEquipamento.tipo = equipamentoSelecionado?.tipo
                    atualizar(comandoEquipamento as Comando, acionamentoStore.selecionado!.id, hide);
            }
        }
        // atualizar(comando as Comando, hide);
    }

    useEffect(() => {
            if(comandoStore.selecionado && show) {
            setMode('');
            equipamentoStore.listarEquipamentosBiometriaPorCondominio(comandoStore.selecionado.condominioId);
            var temEquipamento = equipamentoStore.equipamentosBiometria.find((eq) => eq.equipamentoId === comandoStore.selecionado?.caminhoId);
            if(temEquipamento)
            {
                setMode('equipamento');
                setComandoEquipamento(comandoStore.selecionado);
            } 
            else
            {
                setMode('guarita');
                setComandoGuarita(comandoStore.selecionado);
            }
        } 
    }, [comandoStore.selecionado, show]);

    const guaritas = guaritaStore.guaritas.map((guarita) => ({
        label: `${guarita.nome} - ${guarita.porta}`,
        value: guarita.id
    }));

    const modulos = guaritaStore.modulos.map((modulo) => ({
        label: `${modulo.tipoModulo}`,
        value: modulo.id
    }));

    const reles = releStore.reles.map((rele) => ({
        label: `${rele.numeroRele}`,
        value: rele.id
    }));

    if(atualizando) {
        return(
            <div>
                ATUALIZANDO...
            </div>
        )
    }

    const renderModulosGuaritas = () =>
    {
        return(
            <div>
                <p></p>
                <div className='d-flesx justify-content-center mt-3'>
                    <div>
                    <div className={'m-2'}>
                        <p>Tipo do comando</p>
                        <InputPicker value={comandoGuarita.tipo} data={
                            [
                                {
                                    label: 'GUARITA DIRETA',
                                    value: 0
                                },
                                {
                                    label: 'GUARITA SERVIDOR',
                                    value: 1
                                }
                            ]
                        } block onChange={(e) =>
                        {
                            setComandoGuarita({ ...comandoGuarita, tipo: e  });
                        }} />
                    </div>

                    <div className='d-flex justify-content-end'>
                        <Button
                            onClick={() => salvar()}
                        >
                            { false ? <Loader content="Salvando..." />: 'Salvar' }
                        </Button>
                    </div>
                </div>
            </div>
            </div>
        )
    }

    const renderEquipamentosCadastrados = () =>
    {
        return(
            <div className=''>
                <div className={'m-2'}>
                    <p>Selecione o equipamento</p>
                    <InputPicker value={comandoEquipamento.caminhoId || ""} data={equipamentoStore.equipamentosBiometria.map((eq) => ({ label: `${eq.nome} - ${eq.tipoNome}`, value: eq.equipamentoId }))} block onChange={(e) =>
                    {
                        var selecionado = equipamentoStore.equipamentosBiometria.find((equipamento) => equipamento.equipamentoId === e);
                        if(selecionado) setEquipamentoSelecionado(selecionado);;
                        setComandoEquipamento({ ...comandoEquipamento, caminhoId: e });
                    }} />
                </div>
                
                <div className='d-flex justify-content-end'>
                    <Button
                        // disabled={!releSelecionado}
                        onClick={() => salvar()}
                    >
                        { false ? <Loader content="Salvando..." />: 'Salvar' }
                    </Button>
                </div>
            </div>
        )
    }

    const renderRoutes = () =>
    {
        switch(mode)
        {
            case 'guarita':
                return renderModulosGuaritas();
            case 'equipamento':
                return renderEquipamentosCadastrados();
        }
        return(
            <div>
                CARREGANDO...
            </div>
        )
    }

    return(
        <Modal open={show} onClose={hide} backdropStyle={{ background: 'rgba(0,0,0,0.9)' }} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title className='d-flex justify-content-center'>
                    <Avatar variant="rounded" style={{ background: '#663b5f', marginRight: 10 }}>
                        <FaWpforms/>
                    </Avatar>
                    <p>Atualizar comando</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderRoutes()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarComando);