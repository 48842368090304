import jwt_decode from 'jwt-decode';
import { useAuthentication } from './Auth.hook';

interface Token
{
    exp: number;
}

export const useSentinela = () =>
{

    const { refreshToken } = useAuthentication();

    const validarToken = async () =>
    {
        
        let idInterval;
        idInterval = setInterval(async () =>
        {
            var token_outkey_cache = token_outkey_cache = localStorage.getItem('token');
            if(token_outkey_cache)
            {
                let token : Token = jwt_decode(token_outkey_cache ?? "");
                var current_time = Date.now();
                var dateTimeCurrent = new Date(new Date(current_time).toLocaleString());
                var dateTimeExpired = new Date(new Date(token.exp * 1000).toLocaleString());

                // 
                // 

                if ( dateTimeExpired < dateTimeCurrent) {
                    
                    var check = window.confirm('Token expirado! Você deseja continuar usando o sistema? \n "Ok" -> Para continuar usando \n "Cancelar" -> para sair ');
                    if(check)
                    {
                        try
                        {
                            if(token_outkey_cache)
                            {
                                var newTokenOutkey = await refreshToken(token_outkey_cache);
                                if(!newTokenOutkey.data.error)
                                {
                                    localStorage.setItem('token', newTokenOutkey.data.status);
                                }
                                else
                                {
                                    
                                }
                            }
                        }
                        catch(e)
                        {
                            alert('Erro ao renovar token');
                        }
                    }
                    else
                    {
                        clearInterval(idInterval);
                        localStorage.clear();
                        window.location.href = '/';
                    }
                }
            }
        }, 2000);
    }

    return {
        validarToken
    }
}