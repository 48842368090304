import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { BsCameraVideo } from 'react-icons/bs';
import { StoreContext } from '../../stores';
import { useCamera } from '../../hooks/Camera.hook';
import { Camera, CameraRequest } from '../../interfaces/Cameras.interface';
import { Layout } from '../../interfaces/Layout.interface';
import { useMaquina } from '../../hooks/Maquina.hook';
import { Maquina, MaquinaRequest } from '../../interfaces/Ramal.interface';
import { FormCamera, SubTitle, Title } from '../cameras/styles/Modal.style';
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite';

interface Props {
    show: boolean;
    hide: () => void;
}

const CadastrarMaquina: FC<Props> = ({ show, hide }) => {

    const { ramalStore } = useContext(StoreContext);
    const { cadastrar, salvando } = useMaquina();
    const [form, setForm] = useState<MaquinaRequest>({});

    // var ramais = ramalStore?.ramaisCentral[0].ramais?.map((r) => ({ label: r.ramalNumero, value: r.id }))

    useEffect(() => {
        if (show) {
            ramalStore.listarRamalCentral();
        }
    }, [show])

    const limpar = () => {
        setForm({});
    }

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        cadastrar(form as any, hide, limpar);
    }

    const allRamais = ramalStore.ramaisCentral.flatMap(central =>
        central.ramais.map(r => ({ label: r.ramalNumero, value: r.id }))
    );

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header style={{ border: 0 }} closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastro de maquina </p>
                    <p className='modal-styled-title'>Cadastre novas maquinas para operação</p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={salvar}>
                    <p className='info'>Preencha todos os campos abaixo</p>

                    <div className='col-md-12'>
                        <p className='title'>Ramal<span className='text-danger'>*</span></p>
                        <SelectPicker
                            block
                            onChange={(value) => {
                                if (value) setForm({ ...form, ramalId: value });
                            }}
                            value={form.ramalId || ''}
                            data={allRamais}
                            placeholder={'Selecione o ramal'}
                        />
                    </div>

                    <div className='d-flex'>
                        <div className='col-md-6 p-1'>
                            <p className='title'>Nome<span className='text-danger'>*</span></p>
                            <Input required placeholder='Nome da maquina' value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })} />
                        </div>
                        <div className='col-md-6 p-1'>
                            <p className='title'>Ip<span className='text-danger'>*</span></p>
                            <Input required placeholder='Ip da maquina' value={form.ip || ''} onChange={(e) => setForm({ ...form, ip: e })} />
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='col-md-6 p-1'>
                            <p className='title'>Usuário DGuard<span className='text-danger'>*</span></p>
                            <Input required placeholder='Usuário DGuard' value={form.usuarioDGuard || ''} onChange={(e) => setForm({ ...form, usuarioDGuard: e })} />
                        </div>
                        <div className='col-md-6 p-1'>
                            <p className='title'>Senha DGuard<span className='text-danger'>*</span></p>
                            <Input required placeholder='********' value={form.senhaDGuard || ''} onChange={(e) => setForm({ ...form, senhaDGuard: e })} />
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='col-md-12'>
                            <p className='title'>monitor DGuard<span className='text-danger'>*</span></p>
                            <Input type={'number'} max={10} required placeholder='Monitor DGuard' value={form.monitorDGuard || ''} onChange={(e) => setForm({ ...form, monitorDGuard: parseInt(e) })} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                        <div className='d-flex'>
                            <Button type={'submit'} appearance="primary" style={{ marginRight: 10 }} disabled={salvando || form.nome?.length === 0}>{salvando ? <Loader title='Salvando...' /> : 'Salvar'}</Button>
                            <Button type={'button'} appearance="default" disabled={salvando} onClick={hide}>Fechar</Button>
                        </div>
                    </div>
                    {/* <button disabled={salvando} className={`btn btn${salvando ? '' : '-outline'}-success w-100 mt-3`}>{salvando ? <span><CircularProgress size={15} style={{marginBottom: '-3px', marginRight: 5, color: '#fff'}} /> Salvando...</span> : 'Salvar'}</button> */}
                </form>

            </Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarMaquina);
