import { makeAutoObservable, runInAction } from "mobx";
import { Octos, OctosCam, OctosServers } from "../interfaces/Octos.interface";
import OctosAPI from "../api/OctosAPI";

class OctosStore {

    octos: Octos[] = [];
    servers: OctosServers[] = [];
    cams: OctosCam[] = [];
    selecionado: Octos | null = null;
    selecionadoServer: OctosServers | null = null;
    loading: boolean = false;
    
    constructor() {
        makeAutoObservable(this);
    }

    async listarServersDGuard() {
        this.loading = true;
        try {
            const { data } = await OctosAPI.listarServersDguard();
            runInAction(() => {
                if(data)
                    this.servers = data;
                else
                    this.servers = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async listarCamerasPorServerId(serverId: string) {
        this.loading = true;
        try {
            const { data } = await OctosAPI.listarCamDguardPorServerId(serverId);
            runInAction(() => {
                if(data)
                    this.cams = data;
                else
                    this.cams = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async listar(condominioId: string) {
        this.loading = true;
        try {
            const { data } = await OctosAPI.listarOctosPorCondominio(condominioId);
            runInAction(() => {
                if(data)
                    this.octos = data;
                else
                    this.octos = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(cameraId: string | null) {
        if(cameraId !== null) {
            let selecionado = this.octos.find((o) => o.cameraId === cameraId);
            if(selecionado) {
                this.selecionado = selecionado;
            }
        } else {
            this.selecionado = null;
        }
    }

    definirServerSelecionado(serverId: string | null) {
        if(serverId !== null) {
            let selecionado = this.servers.find((o) => o.guid === serverId);
            if(selecionado) {
                this.selecionadoServer = selecionado;
            }
        } else {
            this.selecionado = null;
        }
    }

}

export default OctosStore;