import { observer } from 'mobx-react-lite'
import React, { FC, useContext, useEffect, useRef } from 'react'
import { Button, Modal } from 'rsuite';
import { StoreContext } from '../../stores';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Pie, PolarArea } from 'react-chartjs-2';
import { Container } from './styles/Relatorio.style';
import NotFound from '../utils/NotFound';
import { useReactToPrint } from 'react-to-print';
import { RiRemoteControlLine } from 'react-icons/ri';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);


interface Props
{
    show: boolean;
    hide: () => void;
}

const ModalRelatorioVeiculo: FC<Props> = ({ show, hide }) => {

    const { unidadeStore, dispositivosStore, condominioStore, veiculoStore } = useContext(StoreContext);
    let componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef?.current
    });
    useEffect(() =>
    {
        if(show && veiculoStore.selecionado)
        {
            dispositivosStore.listarControlePorVeiculo(veiculoStore.selecionado.unidadeId);
        }
    }, [show])

    const data = {
        labels: ['TAGS', 'BIOMETRIA DIGITAL', 'BIOMETRIA FACIAL'],
        datasets: [
          {
            label: 'Qtd',
            data: [dispositivosStore.tags.length, dispositivosStore.biometriaCadastrada.length, dispositivosStore.biometriaFacialCadastrada ? 1 : 0],
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(255, 159, 64, 0.5)',
            ],
            borderWidth: 0
          },
        ],
      };

      const renderData = () =>
      {
        if(dispositivosStore.controles.length === 0)
            return <NotFound message='Nenhum dispositivo cadastrado' />

        return(
            <Container ref={componentRef}>
                <p style={{fontSize:'11pt'}}><b>Condominio:</b> {condominioStore.selecionado?.nome} <br/><b>Placa:</b> {veiculoStore.selecionado?.placa} <br/><b>Modelo:</b> {veiculoStore.selecionado?.modelo} <br/><b>Cor:</b> {veiculoStore.selecionado?.corDescricao}</p>
                <div className='d-flex justify-content-center mt-3'>
                    <RiRemoteControlLine style={{fontSize: 35}} />
                    <p style={{alignSelf: 'center', fontSize: 14}}>{dispositivosStore.controles.length} controles cadastrado nesse veículo</p>
                </div>
            </Container>
        )
      }

    return (
        <Modal open={show} onClose={hide}>
        <Modal.Header>
           <p style={{fontWeight: 550, fontSize:'11pt'}}>Relatório de tags e biometrias do morador <br/> </p>
        </Modal.Header>
        <Modal.Body>
            {renderData()}
        </Modal.Body>
        <Modal.Footer>
            <Button appearance='primary' color={'violet'} onClick={handlePrint} disabled={dispositivosStore.controles.length === 0}>Exportar</Button>
            <Button appearance='primary'  onClick={hide}>Fechar</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default observer(ModalRelatorioVeiculo);