import { useContext, useState } from "react";
import { toast } from "react-toastify";
import BlocoAPI from "../api/BlocoAPI";
import { StoreContext } from "../stores";
import { Bloco } from "../interfaces/Bloco.interface";
import RedeAPI from "../api/RedeAPI";
import { Rede } from "../interfaces/Rede.interface";


export const useRede = () => {

    const { redeStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    
    const cadastrar = async (rede: Rede, hide: () => void) => {
        setSalvando(true);
        try {
            const { data } = await RedeAPI.cadastrar(rede);
            redeStore.redes.push(data);
            redeStore.listarPorCondominio();
            setSalvando(false);
            hide();
            toast.success('Rede cadastrado com sucesso');
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar rede');

        }
    }

    const atualizar = async (rede: Rede, hide: () => void) => {
        setAtualizando(true);
        try {
            await RedeAPI.atualizar(rede);
            redeStore.redes = redeStore.redes.map(r => r.id === rede.id ? rede : r);
            setAtualizando(false);
            hide();
            toast.success('Rede atualizado com sucesso');

        } catch(e) {
            setAtualizando(false);
            toast.error('Erro ao atualizar rede');
        }
    }

    const deletar = async (rede: Rede, hide: () => void) => {
        setDeletando(true);
        try {
            await RedeAPI.deletar(rede.id);
            redeStore.redes = redeStore.redes.filter(r => r.id !== rede.id);
            setDeletando(false);
            hide();
            toast.success('Rede deletado com sucesso');

        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar rede');

        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
    }

}