import { Avatar, LinearProgress } from '@mui/material';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import GuaritaStore from '../../stores/GuaritaStore';
import useGuarita from '../../hooks/Guarita.hook';
import { GuaritaRequest, Guarita } from '../../interfaces/Guarita.interface';
import { Button, Input, Loader, Modal, SelectPicker, Timeline } from 'rsuite';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../stores';
import NotFound from '../utils/NotFound';

interface PropsModal {
    show: boolean;
    hide: () => void;
}

const ModalVersaoModulos: FC<PropsModal> = ({ show, hide }) => {

    const { guaritaStore } = useContext(StoreContext);

    useEffect(() =>
    {
        if(show) guaritaStore.modulosGuarita = [];
    }, [show]);

    const renderModulos = () =>
    {

        if(guaritaStore.modulosGuarita.length === 0)
        {
            return <NotFound message='Nenhum módulo encontrado nesse guarita' />
        }

        return(
            <div>
                {
                    guaritaStore.modulosGuarita.length > 0
                    ?
                    <div className='mt-3'>
                        {
                            guaritaStore.modulosGuarita.map((modulo, i) =>
                            (
                                <Timeline align={'alternate'}>
                                    <Timeline.Item style={{padding: 10}}>
                                    <p className='m-0'>{modulo.nome}</p>
                                    <p className='m-0'>ENDEREÇO CAN: {modulo.enderecoCAN}</p>
                                    </Timeline.Item>
                                </Timeline>
                            ))
                        }
                    </div>
                    :
                    ''
                }
            </div>
        )
    }

    const Container = () => {
        
        return(
            <div>
                <div className='d-flex justify-content-center'>
                    <Button disabled={guaritaStore.loading} onClick={() => {
                        if(guaritaStore.selecionado) guaritaStore.listarVersaoModuloPorGuarita(guaritaStore.selecionado.id);
                    }}>
                        {
                            guaritaStore.loading
                            ?
                            <Loader content={'Carregando módulos...'} />
                            :
                            'Buscar'
                        }
                    </Button>
                </div>
                {renderModulos()}
            </div>
        )
    }

    return(
        <Modal open={show} onClose={() => guaritaStore.loading ? {} : hide()}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Lista dos módulos do guarita</p>
                    <p className='modal-styled-subtitle'>
                        Lista de todos os módulos conectado no módulo guarita selecionado
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{Container()}</Modal.Body>
        </Modal>
    )
}

export default observer(ModalVersaoModulos);