import { useContext, useState } from "react";
import { toast } from "react-toastify";
import UnidadeAPI from "../api/UnidadeAPI";
import { StoreContext } from "../stores";
import { Unidade } from "../interfaces/Unidade.interface";
import { MoverMoradores } from "../interfaces/Morador.interface";


export const useUnidade = () => {

    const { unidadeStore, blocoStore, moradorStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [_deletando, setDeletando] = useState(false);
    const [success, setSuccess] = useState(false);
    const [erro, setErro] = useState(false);
    
    const cadastrar = async (unidade: Unidade, hide: () => void) => {
        setSalvando(true);
        try {
            const { data } = await UnidadeAPI.cadastrar(unidade);
            unidadeStore.unidades.push(data);
            unidadeStore.unidadesPorCondomino.push(data);
            setSalvando(false);
            hide();
            toast.success('Unidade cadastrado com sucesso');
        } catch(e) {
            setSalvando(false);
            toast.error('Erro ao cadastrar unidade');
        }
    }

    const cadastrarMultiplos = async (unidade: Unidade[], hide: () => void) => {
        setSalvando(true);
        try {
            const { data } = await UnidadeAPI.cadastrarMultiplos(unidade);
            unidadeStore.unidades = unidadeStore.unidades.concat(data);            
            setSalvando(false);
            hide();
            toast.success('Unidades cadastrado com sucesso');
        } catch(e) {
            setSalvando(false);
            toast.error('Erro ao cadastrar unidade');
        }
    }

    const atualizar = async (unidade: Unidade, hide: () => void) => {
        setAtualizando(true);
        try {
            await UnidadeAPI.atualizar(unidade);
            // unidadeStore.unidades = unidadeStore.unidades.map(i => i.id == unidade.id ? unidade : i);
            blocoStore.definirSelecionado(unidade.blocoId);
            unidadeStore.listar(unidade.blocoId)
            
            setAtualizando(false);
            hide();
            toast.success('Unidade atualizado com sucesso');
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar unidade');
        }
    }

    const moverMoradorParaOutraUnidade = async (mover: MoverMoradores, unidadeAtual: string, hide: () => void) => {
        setAtualizando(true);
        try {
            await UnidadeAPI.moverMoradorParaOutraUnidade(mover);
            moradorStore.listarPorUnidadeId(unidadeAtual)
            setAtualizando(false);
            hide();
            toast.success('Moradores movidos com sucesso');
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar');
        }
    }

    const _deletar = async (unidade: Unidade) => {
        setDeletando(true);
        try {
            await UnidadeAPI.deletar(unidade.id);
            unidadeStore.unidades = unidadeStore.unidades.filter(i => i.id !== unidade.id);
            setDeletando(false);
            toast.success('Unidade deletado com sucesso');
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar unidade');
        }
    }
    
    return {
        cadastrar,
        cadastrarMultiplos,
        atualizar,
        moverMoradorParaOutraUnidade,
        _deletar,
        salvando,
        atualizando,
        _deletando,
        success,
        erro
    }

}