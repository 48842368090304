import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../stores';
import { useCamera } from '../../hooks/Camera.hook';
import { Container, Header, ListCameras } from './styles/ListagemCameras.style'
import { SelectPicker, Button, Input, Dropdown, IconButton, Toggle, Nav } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import SearchIcon from '@rsuite/icons/Search';
import CadastrarCameraPerfilIdentificacao from './CadastrarCameraPerfilIdentificacao';
import AtualizarCameraPerfilIdentificacao from './AtualizarCameraPerfilIdentificacao';
import FolderFillIcon from '@rsuite/icons/FolderFill';
import SkeletonList from '../utils/SkeletonList';
import NotFound from '../utils/NotFound';
import { CgArrowsV } from 'react-icons/cg';
import DragableIcon from '@rsuite/icons/Dragable';
import { BsCollection, BsPeopleFill } from 'react-icons/bs';
import { Table } from 'react-bootstrap';
import { AiOutlineCaretLeft } from 'react-icons/ai';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import CadastrarCamerasPerfil from './CadastrarCamerasPerfil';

const ListagemPerfisCameras = () => {

  const { cameraStore, layoutStore, condominioStore, themeStore } = useContext(StoreContext);
  const { deletarCameraPerfil, deletando, atualizar, atualizando } = useCamera();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cadastrarPerfil, setCadastrarPerfil] = useState(false);
  const [cadastrarCamerasPerfil, setCadastrarCamerasPerfil] = useState(false);
  const [atualizarPerfil, setAtualizarPerfil] = useState(false);
  const [modalDeletar, setModalDeletar] = useState(false);

  useEffect(() =>
  {
    layoutStore.listar();
  }, [])

  useEffect(() => {
    cameraStore.camerasPerfil = [];
    cameraStore.perfilSelecionado = null;
    if(condominioStore.selecionado) {
      cameraStore.listarCamerasPerfisIdentificacaoPorCondominioId(condominioStore.selecionado.id);
    }
  }, [condominioStore.selecionado]);

  const toggleCadastrarCamerasPerfil = () => setCadastrarCamerasPerfil(!cadastrarCamerasPerfil);
  const toggleCadastrarPerfil = () => setCadastrarPerfil(!cadastrarPerfil);
  const toggleAtualizarPerfil = () => setAtualizarPerfil(!atualizarPerfil);
  const toggleModalDeletar = () => setModalDeletar(!modalDeletar);

  const renderPerfis = () =>
  {

    if(cameraStore.loading) return <SkeletonList/>

    if(cameraStore.camerasPerfil.length === 0) return <NotFound message='Nenhuma câmera cadastrada nesse perfil' />

    return(
      <div className='mt-1'>
        <div className='header'>
          <p className='title'>Câmeras cadastradas</p>
          <p className='subtitle'>Lista de todas as câmeras cadastrada no perfil {cameraStore.perfilSelecionado?.nome}</p>
        </div>
        <div className=''>
        <div className='content-camera'>
            <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
              <thead>
                <tr className='tr'>
                  <th scope="col">Id DGuard</th>
                  <th scope="col">Nome</th>
                  {/* <th scope="col">Câmera Habilitada</th> */}
                  <th scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {
                    cameraStore.camerasPerfil.map((cameraPerfil, i) => (
                      <tr key={i} onClick={() => {
                        cameraStore.definirCameraPerfilSelecionado(cameraPerfil.id);
                      }}>
                        <td>{cameraPerfil.idDGuard} <AiOutlineCaretLeft/> id da câmera dguard </td>
                        <td>{cameraPerfil.nomeDGuard}</td>
                        {/* <td style={{width: 200}}>
                          <Toggle 
                            checkedChildren="Sim" 
                            unCheckedChildren="Não" 
                            checked={cameraPerfil.acionamentoId ? true : false}
                          />
                        </td> */}
                        <td>
                          <Dropdown appearance='primary' color={'violet'} size={'xs'} title={'Ação'} style={{alignSelf: 'center'}}>
                            {/* <Dropdown.Item onClick={() =>
                            {
                              // toggleAtualizarCamera();
                            }}>Atualizar</Dropdown.Item> */}
                            <Dropdown.Item onClick={() =>
                            {
                              // deletar(camera);
                              toggleModalDeletar();
                            }}>Deletar</Dropdown.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    )
  }
  return (
    <Container>
      <Header>
        <div className='d-flex justify-content-between'>
            <div>
                <p className='title'>Cadastros de perfis de câmeras</p>
                <p className='subtitle'>Cadastre novos perfis e adicione as câmeras</p>
            </div>
            <div>
                <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarPerfil}>
                    Adicionar novo perfil de câmeras
                </IconButton>
            </div>
        </div>


        <div className='d-flex box'>
          <div className='content d-flex'>
              <BsCollection className='content-icon' />
              <div>
                  <p className='content-title'>Perfis cadastrado</p>
                  <p className='content-subtitle'>{cameraStore.camerasPerfisIdentificacao.length} cadastrado</p>
              </div>
          </div>
          <div className='align-self-center'>
              <div style={{marginLeft: 20}}>
                  <div className='d-flex'>
                    <p style={{marginRight: 5, alignSelf: 'center'}}>Selecione o perfil</p>
                    {
                      cameraStore.perfilSelecionado ? <Button appearance='primary' color={'violet'} size={'xs'} onClick={toggleAtualizarPerfil}>Atualizar perfil {cameraStore.perfilSelecionado.nome} </Button> : <div/>
                    }
                  </div>
                  <SelectPicker
                      block
                      cleanable={false}
                      style={{width: 300, marginTop: 5}}
                      // value={value.toString()}
                      value={cameraStore.perfilSelecionado?.id}
                      placeholder={'Selecione'}
                      data={cameraStore.camerasPerfisIdentificacao.map((p) => ({ label: `${p.nome}`, value: p.id }))}                            
                      onChange={(e) =>
                      {
                        cameraStore.definirPerfilIdentificacaoSelecionado(e)
                        if(e) cameraStore.listarCamerasPerfilPorCamerasPerfisIdentificacao(e)
                      }}
                  />
              </div>
          </div>

          <div style={{marginLeft: 20}} className='align-self-center'>
              <p className='info-title-filtro'>Filtrar câmera</p>
              <Input placeholder='Buscar' onChange={(e) => {}} />
          </div>
      </div>

          <ListCameras>
            <div className='m-1'>
              <IconButton disabled={!cameraStore.perfilSelecionado} appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarCamerasPerfil}>
                  Adicionar novo perfil de câmeras
              </IconButton>
            </div>
            {renderPerfis()}
          </ListCameras>
        </Header>

        
        

      <CadastrarCamerasPerfil show={cadastrarCamerasPerfil} hide={toggleCadastrarCamerasPerfil} />
      <CadastrarCameraPerfilIdentificacao show={cadastrarPerfil} hide={toggleCadastrarPerfil} />
      <AtualizarCameraPerfilIdentificacao show={atualizarPerfil} hide={toggleAtualizarPerfil} />
      <ModalConfirmacao show={modalDeletar} hide={toggleModalDeletar} action={() => { if(cameraStore.camerasPerfilSelecionado) deletarCameraPerfil(cameraStore.camerasPerfilSelecionado.id) }} content={'Você tem certeza que deseja deletar essa câmera desse perfil?'} loading={deletando} />

    </Container>
  )
}

export default observer(ListagemPerfisCameras);
