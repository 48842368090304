import styled from 'styled-components';
import PerfectScroll from 'react-perfect-scrollbar';

export const Container = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    height: 50vh;
    padding: 10px;
    margin: 0;

    .title
    {
        margin: 0;
        margin-top: 10px;
    }
`
