export default {
  title: 'light',
  layout: {
    menu: {
      width: 80,
      bgColor: '#242644',
    },
  },
  colors: {
    default: '#fff',
    avatar: '#242644',
    primary: '#5448c8',
    success: 'green',
    danger: '#FE193F',
    warning: '#f8961e',
    text: 'rgba(0,0,0,0.7)',
    bgColor: '#F5F5F5',
    bgBox: '#fff',
    bgNav: '#004C95',
    textNav: 'rgba(0,0,0,0.7)',
    borderColor: '#dee2e6',
  },
};
