import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';

export const Container = styled(PerfectScrollBar)`
    .title
    {
        font-size: 18pt;
        font-weight: 530;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
        padding: 10px;
    }
`


export const InfoUser = styled.div`
    padding: 30px;
    background: ${(props) => props.theme.colors.bgBox};
    border-radius: 10px;
    // height: 100vh;
    box-shadow: 1px 3px 4px rgba(0,0,0,0.0100);
    .title-input
    {
        margin-bottom: 3px;
        font-weight: 700;
        margin-top: 10px;
    }
    .input
    {
        background: ${(props) => props.theme.colors.bgColor};
        color: ${(props) => props.theme.colors.text};
        width: 30vw;
    }
    .obs
    {
        font-size: 8pt;
        width: 30%;
        margin-top: 5px;
    }
    .info
    {
        font-size: 15pt;
        font-weight: 700;
        width: 30%;
        margin-top: 5px;
    }
`;

export const ContainerModal = styled.form`
    padding: 10px;
    background: ${(props) => props.theme.colors.bgColor};

    .icon
    {
        font-size: 30pt;
    }
    .title
    {
        margin: 0;
        text-align: center;
        font-weight: 600;
        font-size: 15pt;
    }
    .subtitle
    {
        margin: 0;
        text-align: center;
        font-size: 8pt;
        width: 70%;
    }
    .input-pw
    {
        width: 50%;
        margin-top: 20px;
        background: ${(props) => props.theme.colors.bgBox};
        color: ${(props) => props.theme.colors.text};
        text-align: center;
    }
`;
