import { useContext, useState } from "react";
import { toast } from "react-toastify";
import RamalAPI from "../api/RamalAPI";
import { StoreContext } from "../stores";
import { Ramal } from "../interfaces/Ramal.interface";


export const useRamal = () => {

    const { ramalStore} = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [reiniciando, setReiniciando] = useState(false);
    const [success, setSuccess] = useState(false);
     
    const cadastrar = async (ramal: Ramal, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await RamalAPI.cadastrarRamal(ramal);
            if(status === 200) {
                ramalStore.ramais.push(data);
                setSalvando(false);
                hide();
                setSuccess(true);
                toast.success('Ramal cadastrado com sucesso!')
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar ramal')
        }
    }

    const atualizar = async (ramal: Ramal, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await RamalAPI.atualizarRamal(ramal);
            if(status === 200) {
                ramalStore.ramais = ramalStore.ramais.map(ram => ram.id === ramal.id ? ramal : ram);
                setAtualizando(false);
                hide();
                setSuccess(true);
                toast.success('Ramal atualizado com sucesso!');
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar ramal');
        }
    }

    const atualizarOrdem = async (ramal: Ramal[]) => {
        setAtualizando(true);
        const novaOrdem = {
            novaOrdem: ramal.map(i => ({id: i.id, nome: i.nome, ordemExibicao: i.ordemExibicao}))
        }
      
        try {
          const { data } = await RamalAPI.atualizarOrdem(novaOrdem as any);
          if(data === true) {
            toast.success(`Ordem do ramal alterado com sucesso`);
            setAtualizando(false);
        } else {
            toast.error(`Erro ao alterar a ordem do ramal`);
            setAtualizando(false);
        }
        setAtualizando(false);
        } catch(e) {
        toast.error(`Erro ao alterar a ordem do ramal`);
          setAtualizando(false);
          
        }
    }

    const deletar = async (ramal: Ramal) => {
        setDeletando(true);
        try {
            const { status } = await RamalAPI.deletarRamal(ramal.id);
            if(status === 200) {
                ramalStore.ramais = ramalStore.ramais.filter(ram => ram.id !== ramal.id);
                setDeletando(false);
                toast.success('Ramal deletado com sucesso!')
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar ramal')
        }
    }

    const reiniciar = async (dispositivoSipId) => {
        setReiniciando(true);
        try {
            const { status } = await RamalAPI.reiniciarRamal(dispositivoSipId);
            if(status === 200) {
                setReiniciando(false);
                toast.success('Ramal reiniciado com sucesso!')
            }
        } catch(e) {
            setReiniciando(false);
            toast.error('Erro ao reiniciar ramal')
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        reiniciar,
        atualizarOrdem,
        reiniciando,
        salvando,
        atualizando,
        deletando,
        success,
    }

}