import { observer } from 'mobx-react-lite'
import { FC, useState, useContext } from 'react';
import { Button, Input, Modal } from 'rsuite';
import forgoutpassword from '../../assets/img/forgoutpassword.png'
import { Container } from './styles/AtualizarSenha.style';
import { useAuthentication } from '../../hooks/Auth.hook';
import { StoreContext } from '../../stores';
import { toast } from 'react-toastify';

interface Props
{
    show: boolean;
    hide: () => void;
}

const AualizarSenha : FC<Props> = ({ show, hide }) => {


    const { authStore } = useContext(StoreContext);
    const { atualizarSenhaUsuarioPorUsuarioId, carregando } = useAuthentication();
    const [dados, setDados] = useState('');

    const onChangeAction = () =>
    {
        if(authStore.usuario)
            atualizarSenhaUsuarioPorUsuarioId(authStore.usuario.id, dados);
        else
            toast.error('Erro interno, Nenhum usuário selecionado');
    }

    return (
        <Modal open={show} onClose={carregando ? () => {} : hide}>
        <Modal.Body>
            <Container className='d-flex justify-content-center'>
                <img src={forgoutpassword} className='img' />
                <div className='align-self-center'>
                    <p className='title'>Olá, {authStore.usuario?.nome}</p>
                    <p className='subtitle'>
                        Você recebeu uma nova senha por email/sms e agora é só colocar uma nova!
                    </p>

                    <div>
                        <p className='title-form'>Coloque seu nova senha</p>
                        <Input placeholder='Dados' className='input' onChange={setDados} />
                    </div>
                </div>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button appearance='primary' color={'violet'} disabled={!dados || carregando || dados.length < 6} onClick={onChangeAction}>Enviar</Button>
            <Button onClick={() => {
                authStore.loggout();
                window.location.replace('/')
            }} disabled={carregando}>Deslogar</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default observer(AualizarSenha);
