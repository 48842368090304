import { makeAutoObservable, runInAction, configure } from 'mobx';
import EventoAPI from '../api/EventoAPI';
import { Evento, EventoRelatorio } from '../interfaces/Evento.interface';

configure({
  enforceActions: "never",
})

class EventoStore {
  eventos: Evento[] = [];
  eventosGeral: EventoRelatorio[] = [];
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async listarUltimosEventosPorCondominioId(condominioId: string, dataInicial: string, dataFinal: string) {
    this.loading = true;
    try
    {
      const { data } = await EventoAPI.listarUltimosEventosPorCondominioId(condominioId, dataInicial, dataFinal);
      runInAction(() =>
      {
        this.eventosGeral = data;
        this.loading = false;
      })
    }
    catch(e)
    {
      this.loading = false;
    }
  }

  async listarUltimosPorCondominioIdEquantidade(condominioId: string, quantidade: number) {
    this.loading = true;
    try
    {
      const { data } = await EventoAPI.listarUltimosEventosPorCondominioIdEquantidade(condominioId, quantidade);
      runInAction(() =>
      {
        this.eventos = data;
        this.loading = false;
      })
    }
    catch(e)
    {
      this.loading = false;
    }
  }

  async listarUltimosEventosPorUnidade(unidadeId: string, dataInicial: string, dataFinal: string) {
    this.loading = true;
    try
    {
      const { data } = await EventoAPI.listarUltimosEventosPorUnidadeId(unidadeId, dataInicial, dataFinal);
      runInAction(() =>
      {
        this.eventos = data;
        this.loading = false;
      })
    }
    catch(e)
    {
      this.loading = false;
    }
  }

}

export default EventoStore;
