import { Avatar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite'
import { useCondominio } from '../../hooks/Condominio.hook'
import { Condominio, CondominioRequest } from '../../interfaces/Condominio.interface'
import { StoreContext } from '../../stores'


interface Props
{
    show: boolean;
    hide: () => void;
}


const AtualizarCondominio: FC<Props> = ({ show, hide }) => {

    const { condominioStore } = useContext(StoreContext);
    const { atualizar, atualizando } = useCondominio();
    const [form, setForm] = useState<CondominioRequest>({});

    const atualizarCondominio = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as Condominio, hide);
    }

    useEffect(() =>
    {
        if(show && condominioStore.selecionado)
        {
            setForm(condominioStore.selecionado);
        }
    }, [show, condominioStore.selecionado]);

    const renderForm = () =>
    {
        return(
            <Form onSubmit={(e) => atualizarCondominio(e)}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label><span className='text-danger'>*</span>Central de atendimento</Form.Label>
                    <SelectPicker
                        block
                        placeholder={'Selecione'}
                        onChange={(e) => { if(e) setForm({ ...form, centralAtendimentoId: e}) }}
                        value={form.centralAtendimentoId || ''}
                        data={condominioStore.centrais.map((c) => ({ label: c.nome, value: c.id }))}
                    />
                    {/* <select
                        className='form-control shadow-none'
                        onChange={(e) => setForm({ ...form, centralAtendimentoId: e.target.value })}
                        value={form.centralAtendimentoId || ''}
                    >
                        <option value="">Selecione a central de atendimento</option>
                        {
                            condominioStore.centrais.map((central, i) => (
                                <option key={i} value={central.id}>{central.nome}</option>
                            ))
                        }
                    </select> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><span className='text-danger'>*</span>Nome do condomínio</Form.Label>
                    <Input required type="text" className='shadow-none' placeholder="Nome" value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })} />
                </Form.Group>

                <div className='d-flex justify-content-end'>
                    <div className='d-flex'>
                    <Button appearance="primary" type={'submit'}>
                        {
                            atualizando
                            ?
                            <Loader title={'Salvando...'} />
                            :
                            'Salvar'
                        }
                    </Button>
                    <Button style={{marginLeft: 5}} type={'button'} onClick={hide}>Voltar</Button>
                </div>
                </div>
            </Form>
        )
    }

    return (
        <Modal open={show} onClose={hide} size={'sm'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar o nome do condomínio</p>
                    <p className='m-0'>Condomínio { condominioStore.selecionado ? condominioStore.selecionado.nome : '' }</p>
                </div>
            </Modal.Header>
            <Modal.Body>{renderForm()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarCondominio);