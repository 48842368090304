import React, { FC, useContext, useEffect, useState } from 'react';
import { Container, Header, List } from './styles/Bloco.style';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { StoreContext } from '../../stores';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { converterDataHora } from '../utils/mask';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { AiFillEdit, AiOutlineAppstoreAdd, AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import CadastrarBloco from './CadastrarBloco';
import { Bloco } from '../../interfaces/Bloco.interface';
import { observer } from 'mobx-react-lite';
import { BiEdit, BiX } from 'react-icons/bi';
import AtualizarBloco from './AtualizarBloco';
import { Unidade } from '../../interfaces/Unidade.interface';
import { useBloco } from '../../hooks/Bloco.hook';
import CadastrarUnidade from './CadastrarUnidade';
import { useUnidade } from '../../hooks/Unidade.hook';
import AtualizarUnidade from './AtualizarUnidade';
import { BsBookmarkDash, BsCaretLeft, BsColumnsGap, BsInfoCircle, BsPlus, BsThreeDotsVertical, BsUiChecksGrid, BsX } from 'react-icons/bs';
import { VscKebabVertical } from 'react-icons/vsc';
import imgbloco from '../../assets/img/bloco.png'; 
import { FaRegNewspaper, FaSearch } from 'react-icons/fa';
import Empty from '../../components/utils/Empty';
import { ButtonToolbar, SelectPicker, Button, Dropdown, IconButton, Tag } from 'rsuite';
import NotFound from '../utils/NotFound';
import Loading from '../utils/Loading';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import ModalListagemUnidade from './ModalUnidadeListagem';
import PlusIcon from '@rsuite/icons/Plus';
import { MdOutlineEdit } from 'react-icons/md';
import SkeletonList from '../utils/SkeletonList';
import { Table } from 'react-bootstrap';

const _Bloco: FC = () => {

    const token = process.env.REACT_APP_BASE_TOKEN_BLOCOUNIDADE;
    const { blocoStore, condominioStore, unidadeStore, themeStore } = useContext(StoreContext);
    const { selecionado } = condominioStore; 
    const { blocos } = blocoStore;
    const { unidades } = unidadeStore;

    const { deletar, deletando } = useBloco();
    const [state, setState] = useState(true);
    const [search, setSearch] = useState('');
    const [cadastrarBlocoModal, setCadastrarBlocoModal] = useState(false);
    const [atualizarBlocoModal, setAtualizarBlocoModal] = useState(false);
    const [cadastrarUnidadeModal, setCadastrarUnidadeModal] = useState(false);
    const [atualizarUnidadeModal, setAtualizarUnidadeModal] = useState(false);
    const [modalConfirmacaoBloco, setModalConfirmacaoBloco] = useState(false);
    const [anchorElBloco, setAnchorElBloco] = React.useState<null | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openBloco = Boolean(anchorElBloco);
    const open = Boolean(anchorEl);

    const [modalOpenListagem, setModalOpenListagem] = useState(false);

    const blocosListagem = blocoStore.blocos.map(bloco => ({ label: bloco.nome.toLocaleUpperCase(), value: bloco.id })).sort((a, b) => a.label > b.label ? 1 : -1);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null); 
    };

    const handleClickBloco = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElBloco(event.currentTarget);
      };
      const handleCloseBloco = () => {
        setAnchorElBloco(null);
      };

    const toggleModalOpenListagem = () => setModalOpenListagem(!modalOpenListagem);

    const toggleCadastrarBloco = () => setCadastrarBlocoModal(!cadastrarBlocoModal);
    const toggleAtualizarBloco = () => setAtualizarBlocoModal(!atualizarBlocoModal);

    const toggleCadastrarUnidade = () => setCadastrarUnidadeModal(!cadastrarUnidadeModal);
    const toggleAtualizarUnidade = () => setAtualizarUnidadeModal(!atualizarUnidadeModal);

    const toggleConfirmacaoBloco = () => 
    {
        setModalConfirmacaoBloco(!modalConfirmacaoBloco);
    }

    const deletarBloco = () =>
    {
        if(blocoStore.selecionado) {
            deletar(blocoStore.selecionado);
            // let primeiroBloco = blocos[0]?.id;
            // if(primeiroBloco) {
            //     unidadeStore.listar(primeiroBloco);
            //     blocoStore.definirSelecionado(primeiroBloco);
            // }
        }
        else alert('Selecione o bloco')
    }

    const filtrarUnidade = unidadeStore.unidades.filter(unidade => 
        unidade.apartamento.toLocaleString().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        !search    
    ).sort((a, b) => a.apartamento - b.apartamento)

    // useEffect(() => {
    //     let primeiroBloco = blocos[0]?.id;
    //     if(primeiroBloco) {
    //         unidadeStore.listar(primeiroBloco);
    //         blocoStore.definirSelecionado(primeiroBloco);
    //     }
    // }, []);

    useEffect(() =>
    {
        blocoStore.listar();
    }, [])

    const renderBloco = () => {

        if(blocoStore.loading)
        {
            return <SkeletonList/>
        }

        return(
            <List className='d-flex justify-content-start flex-wrap'>
                <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="md">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Identificador NICE</th>
                            <th>Unidades</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            blocoStore.blocos.slice().sort((a, b) => a.identificadorNice.toLocaleUpperCase() > b.identificadorNice.toLocaleUpperCase() ? 1 : -1).map((bloco, i) => (
                                <tr key={i} className={'a'} onClick={() => {
                                    blocoStore.definirSelecionado(bloco.id);
                                    unidadeStore.listar(bloco.id);
                                }}>
                                    <td><span style={{fontWeight: 600}}>{bloco.nome}</span> <BsCaretLeft/> Identificação do bloco</td>
                                    <td><span style={{fontWeight: 600}}>{bloco.identificadorNice}</span> <BsCaretLeft/> Informação salva no guarita</td>
                                    <td>
                                        <Button color="violet" appearance="primary" size={'xs'} onClick={() =>
                                        {
                                            toggleModalOpenListagem();
                                            blocoStore.definirSelecionado(bloco.id);
                                        }}>Visualizar</Button>
                                    </td>
                                    <td>
                                    <Dropdown color="violet" appearance="primary" title="Ação" size={'xs'}>
                                        <Dropdown.Item onClick={toggleAtualizarBloco}>Atualizar</Dropdown.Item>
                                        <Dropdown.Item onClick={toggleConfirmacaoBloco}>Deletar</Dropdown.Item>
                                    </Dropdown>
                                    </td>
                                </tr>
                            ))
                        }  
                    </tbody>
                </Table>
            </List>
        //     <div className='d-flex'>
        //              
        //     </div>
        )
    }

    // const renderunidade = () => {

    //     if(unidadeStore.loading)
    //     {
    //         return <Loading message={'Carregando unidades...'} />
    //     }

    //     if(unidadeStore.unidades.length === 0) {
    //         return(
    //             <div className='d-flex m-4'>
    //                 <div className='align-self-center m-2'>
    //                     <NotFound message='Nenhuma unidade cadastrado' />
    //                 </div>
    //             </div>
    //         )
    //     }

    //     return(
    //         <div className='d-flex content-unidade'>
    //             {
    //                 filtrarUnidade.map((unidade, i) => (
    //                     <div key={i} className={'content d-flex justify-content-between'} onClick={() => unidadeStore.definirSelecionado(unidade.id)}>
    //                         <div className='d-flex'>
    //                             <p className='apt'>{unidade.apartamento}°</p>
    //                             <div style={{alignSelf: 'center'}}>
    //                                 <p className='bloco'>Unidade do bloco {unidade.blocoNome?.toLocaleUpperCase()}</p>
    //                                 <p className='andar'>Unidade localizada no andar {unidade.andar}</p>
    //                             </div>
    //                         </div>
    //                         <VscKebabVertical className='icon' role={'button'} onClick={(e) => handleClick(e as any)} />
    //                     </div>
    //                 ))
    //             }
                
    //         </div>
    //     )
    // }

    return(
        <Container>

            <div className='dashboard d-flex'>
                <div className='d-flex dashboard-content'>
                    <BsBookmarkDash className='dashboard-icon'/>
                    <div className='align-self-center'>
                        <p className='dashboard-title'>Blocos</p>
                        <p className='dashboard-subtitle'><span style={{fontWeight: 600}}>{blocoStore.blocos.length}</span> Bloco cadastrado nesse condomínio.</p>
                        <p className='dashboard-info'>Clique no botão "Visualizar" para ver as unidades.</p>
                    </div>
                </div>

                <div className='d-flex dashboard-content'>
                    <BsInfoCircle size={50} className='dashboard-icon'/>
                    <div className='align-self-center'>
                        <p className='dashboard-title'>Info</p>
                        <p className='dashboard-subtitle'>Sobre cadastro de blocos e unidades.</p>
                        <p className='dashboard-info'>Para que a unidade do condomínio seja exibida no sistema OUTKEY é necessário efetuar o cadastro completo: <br/> 1° - Bloco; &nbsp; 2° - Unidade.</p>
                    </div>
                </div>
            </div>

            <Header className='d-flex justify-content-between'>
                <div>
                    <p className='title'>Blocos/Unidades</p>
                    <p className='m-0'>Àrea de cadastro de blocos e unidades</p>
                </div>
                <div style={{marginLeft: 20, alignSelf: 'center'}}>
                    <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={() => toggleCadastrarBloco()}>
                        Adicionar novo bloco
                    </IconButton>
                </div>
            </Header>
            
            {renderBloco()}





































            
            {/* <HeaderInfo className='d-flex justify-content-between'>
                <div className='d-flesx'>
                    <p className='title'>Cadastro de blocos e unidades</p>
                    <p className='subtitle'>Faça os cadastrado de blocos e unidades para esse condomínio</p>
                </div>
                <div className='align-self-center'>
                    <Button className='align-self-center' onClick={() => toggleCadastrarBloco()}>Cadastrar novo bloco</Button>
                </div>
            </HeaderInfo> */}

            {/* <div>
                <Button style={{marginTop: 20}} placeholder={'Selecione o bloco'} onClick={() => toggleCadastrarBloco()}>Cadastrar novo bloco</Button>
            </div> */}
{/*             
            <div className='d-flex flex-wrap'>
                {
                    blocoStore.blocos.slice().sort((a, b) => a.nome.toLocaleUpperCase() > b.nome.toLocaleUpperCase() ? 1 : -1).map((bloco) =>
                    (
                        <div className='content'>
                            <div className='d-flex'>
                                <p className='title' style={{marginRight: 10}}>Identificação do bloco no condomínio selecionado</p>
                                <Dropdown size='xs' title={'Ação'}>
                                    <Dropdown.Item onClick={ () =>
                                        {
                                            blocoStore.definirSelecionado(bloco.id);
                                            toggleAtualizarBloco();
                                        }}>Atualizar</Dropdown.Item>
                                    <Dropdown.Item onClick={toggleConfirmacaoBloco}>Deletar</Dropdown.Item>
                                </Dropdown>
                            </div>
                            <div className='box' onClick={() =>
                            {
                                toggleModalOpenListagem();
                                blocoStore.definirSelecionado(bloco.id);
                            }}>
                                <div className='d-flex'>
                                    <p className='bloco'>{bloco.nome.toUpperCase()}</p>
                                </div>
                                <p className='title'>{bloco.identificadorNice !== null ? <p style={{color: 'green', fontSize: 12, margin: 0}}>Tem identificador nice</p> : <p style={{color: 'red', fontSize: 12, margin: 0}}>Sem identificador nice</p>}</p>
                                
                            </div>
                            <p className='subtitle'>
                                Clique no bloco para ver a lista e de todas as unidades cadastrada no condomínio
                                e para cadastrar novos blocos
                            </p>
                        </div>
                    ))
                }
            </div> */}
            {/* <Header className='d-flex justify-content-between'>
                <div>
                    <p className='title'>Selecione o bloco para ver as unidades cadastrada</p>
                    <SelectPicker value={blocoStore.selecionado?.id} data={blocosListagem} style={{ width: 324 }} onChange={(e) => {
                        blocoStore.definirSelecionado(e);
                        unidadeStore.listar(e);
                    }} />
                    <Button style={{marginLeft: 20}} placeholder={'Selecione o bloco'} onClick={() => toggleCadastrarBloco()}>Cadastra novo bloco</Button>
                    <Dropdown style={{marginLeft: 20}} title="Ajuste">
                        <Dropdown.Item onClick={toggleAtualizarBloco}>Atualizar</Dropdown.Item>
                        <Dropdown.Item onClick={() =>
                        {
                            toggleConfirmacaoBloco();
                        }}>Deletar bloco</Dropdown.Item>
                    </Dropdown>
                </div>
            </Header> */}

            {/* <ContainerUnidade>
                <div className='d-flex'>
                    <div>
                        <p className='title'>Listagem das unidades cadastrada</p>
                        <p className='subtitle'>Total: {unidadeStore.unidades.length}</p>
                    </div>
                    <div className='align-self-center' style={{marginLeft: 30}}>
                        <Button onClick={toggleCadastrarUnidade}>Cadastrar nova unidade</Button>
                    </div>
                </div>
                {renderunidade()}
            </ContainerUnidade> */}

            <CadastrarBloco show={cadastrarBlocoModal} hide={toggleCadastrarBloco} condominioId={selecionado?.id} />
            <AtualizarBloco show={atualizarBlocoModal} hide={toggleAtualizarBloco} blocoSelecionado={blocoStore.selecionado} />
            

            <ModalListagemUnidade show={modalOpenListagem} hide={toggleModalOpenListagem} blocoSelecionado={blocoStore.selecionado} />

            <ModalConfirmacao show={modalConfirmacaoBloco} hide={toggleConfirmacaoBloco} action={deletarBloco} content={'Você tem certeza que deseja deletar esse bloco?'} loading={deletando} />

        </Container>
    );
}

export default observer(_Bloco);