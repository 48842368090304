import { IPerfil } from "../interfaces/AcionamentoPerfil.interface";
import api from "../services/acionamento/api";

class AcionamentoPerfilAPI {
    obterPorId = async (id: string) => await api.get<IPerfil>(`AcionamentoPerfil/${id}`);
    listar = async (condominioId: string) => await api.get<IPerfil[]>(`/acionamentoperfil/condominio/${condominioId}`);
    salvar = async (perfil: IPerfil) => await api.post<IPerfil>(`AcionamentoPerfil`, perfil);
    atualizar = async (perfil: IPerfil) => await api.put<IPerfil>(`AcionamentoPerfil`, perfil);
    deletar = async (perfilId: string) => await api.delete<IPerfil>(`AcionamentoPerfil/${perfilId}`);
}

export default new AcionamentoPerfilAPI();