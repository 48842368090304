import { DispositivoSip } from "../interfaces/Sip.interface";
import api from "../services/api/api";

class DispositivoSipAPI {

    listarModelos = async () => await api.get(`/DispositivosSip/modelos`);

    listarDispositivoSipPorId = async (id: string) => await api.get<DispositivoSip>(`/DispositivosSip/${id}`);

    listarDispositivoSipPorCondominioId = async (condominioId: string) => await api.get<DispositivoSip[]>(`/DispositivosSip/condominioId/${condominioId}`);

    cadastrarDispositivoSip = async (request: DispositivoSip) => await api.post<DispositivoSip>('/DispositivosSip', request);

    atualizarDispositivoSip = async (request: DispositivoSip) => await api.put<DispositivoSip>('/DispositivosSip', request);

    deletarDispositivoSip = async (DispositivoSipId: string) => await api.delete(`/DispositivosSip/${DispositivoSipId}`)

}

export default new DispositivoSipAPI();