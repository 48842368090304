import { observer } from 'mobx-react-lite';
import React, { useState, useContext, useEffect } from 'react';
import { Container, Header, List } from './styles/Octos.style'
import { Dropdown, IconButton, Input } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import { StoreContext } from '../../stores';
import SkeletonList from '../../components/utils/SkeletonList';
import NotFound from '../../components/utils/NotFound';
import { Table } from 'react-bootstrap';
import CadastrarOctos from './CadastrarOctos';
import ModalConfirmacao from '../../components/utils/ModalConfirmacao';
import useOctos from '../../hooks/Octos.hook';

const OctosScreen = () => {

  const { octosStore, condominioStore, themeStore } = useContext(StoreContext);
  const { _deletando, deletar } = useOctos();
  const [search, setSearch] = useState('');
  const [cadastrar, setCadastrar] = useState(false);
  const [atualizar, setAtualizar] = useState(false);
  const [excluir, setExcluir] = useState(false);

  const toggleCadastrar = () => setCadastrar(!cadastrar);
  const toggleAtualizar = () => setAtualizar(!atualizar);
  const toggleExcluir = () => setExcluir(!excluir);

  useEffect(() =>
  {
    if(condominioStore.selecionado)
      octosStore.listar(condominioStore.selecionado.id)
  }, [condominioStore.selecionado])

  const filtrar = octosStore.octos.filter((o) => 
    o.condominioNome.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    o.nomeServidor.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    o.nomeCamera.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    o.acionamentoNome.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    !search
  );

  const deletarOctos = () =>
  {
      if(octosStore.selecionado) {
          deletar(octosStore.selecionado.cameraId);
      }
  }


  const renderLista = () =>
  {

  if(octosStore.loading)
  {
      return <SkeletonList/>
  }

  if(octosStore.octos.length === 0)
  {
      return <NotFound message={'Nenhuma configuração octos cadastrado'} />
  }

  return(
      <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
          <thead>
                  <tr>
                  <th scope="col">Condominio</th> 
                  <th scope="col">Camera Id DGuard</th> 
                  <th scope="col">Nome Câmera</th>
                  <th scope="col">Acionamento</th>
                  <th scope="col">Servidor</th>
                  <th scope="col">Id DGuard</th>
                  <th scope="col">Ação</th>
                  </tr>
              </thead>
              <tbody>
              {
                  filtrar.map((layout, i) => (
                  <tr key={i} onClick={() => octosStore.definirSelecionado(layout.cameraId)}>
                      <td>{layout.condominioNome}</td>
                      <td>{layout.cameraIdDguard}</td>
                      <td>{layout.nomeCamera}</td>
                      <td>{layout.acionamentoNome}</td>
                      <td>{layout.nomeServidor}</td>
                      <td>{layout.idDGuard}</td>
                      <td>
                          <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                              <Dropdown.Item onClick={toggleExcluir}>Deletar</Dropdown.Item>
                          </Dropdown>
                      </td>
                  </tr>
                  ))
              }
          </tbody>
      </Table>
  )
  }

  return (
    <Container>
      <Header>
        <div className='box'>
          <div className='d-flex justify-content-between'>
              <div>
                  <p className='title'>Campo de configurações octos</p>
                  <p className='subtitle'>Clique no botão abaixo para cadastrar configurações necessária</p>
              </div>
              <div>
                  <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrar}>
                      Cadastrar 
                  </IconButton>
              </div>
          </div>

          <div className='align-self-center mt-3'>
              <p className='info-title-filtro'>Filtrar lista de câmeras octos configurado</p>
              <Input placeholder='Buscar' className='mt-2' onChange={(e) => setSearch(e)} style={{ width: 350 }} />
          </div>
        </div>

        <List>
          {renderLista()}
        </List> 
      </Header>

      <CadastrarOctos show={cadastrar} hide={toggleCadastrar} />
      <ModalConfirmacao show={excluir} hide={toggleExcluir} action={deletarOctos} content={'Você tem certeza que deseja deletar esse octos eventos??'} loading={_deletando} />

    </Container>
  )
}

export default observer(OctosScreen);
