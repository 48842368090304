import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { StoreContext } from "../stores";
import { OctosRegister } from "../interfaces/Octos.interface";
import OctosAPI from "../api/OctosAPI";


const useOctos = () => {

    const { octosStore, condominioStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [_deletando, setDeletando] = useState(false);

    const cadastrar = async (octos: OctosRegister, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await OctosAPI.adicionar(octos);
            if(status === 200) {
                if(condominioStore.selecionado)
                    octosStore.listar(condominioStore.selecionado.id);
                toast.success('Cadastrado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const atualizar = async (octos: OctosRegister, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await OctosAPI.atualizar(octos);
            if(status === 200) {
                if(condominioStore.selecionado)
                    octosStore.listar(condominioStore.selecionado.id);
                toast.success('Atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar');
                setAtualizando(false);
            }
        } catch(e) {
            setAtualizando(false);
            //
        }
    }

    const deletar = async (cameraId: string) => {
        setDeletando(true);
        try {
            const { status } = await OctosAPI.excluir(cameraId);
            if(status === 200) {
                if(condominioStore.selecionado)
                    octosStore.listar(condominioStore.selecionado.id);
                toast.success('Deletado com sucesso');
                setDeletando(false);
            } else {
                toast.error('Erro ao deletado');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
        }
    }


    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        _deletando
    }

}


export default useOctos;