import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonBlock = () => {
  return (
    <div className='d-flex' style={{ flexWrap: 'wrap' }}>
        <Skeleton variant="rectangular" className='m-2' height={160} width={400} style={{borderRadius: 8}} />
        <Skeleton variant="rectangular" className='m-2' height={160} width={400} style={{borderRadius: 8}} />
        <Skeleton variant="rectangular" className='m-2' height={160} width={400} style={{borderRadius: 8}} />
        <Skeleton variant="rectangular" className='m-2' height={160} width={400} style={{borderRadius: 8}} />
        <Skeleton variant="rectangular" className='m-2' height={160} width={400} style={{borderRadius: 8}} />
        <Skeleton variant="rectangular" className='m-2' height={160} width={400} style={{borderRadius: 8}} />
        {/* <Skeleton variant="rounded" width={210} height={60} /> */}
    </div>
  )
}

export default SkeletonBlock;