import { makeAutoObservable } from "mobx";
import AuthAPI from "../api/AuthAPI";
import { Usuario } from "../interfaces/Usuario.interface";

class AuthStore {
    usuario: Usuario | null = null;
    access: string = "";
    password: string = "His@2022"

    constructor() {
        makeAutoObservable(this);
    }

    async usuarioAutenticado() {
        const usuario = await AuthAPI.usuario();
        this.usuario = usuario.data;
      }

    loggout() {
        localStorage.clear()
    }

    liberarAcesso()
    {
        this.access = "true";
        localStorage.setItem("access", this.access);
    }

    removerAcesso()
    {
        this.access = "false";
        localStorage.removeItem("access");
    }

}

export default AuthStore;