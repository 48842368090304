import { Avatar, LinearProgress } from '@mui/material';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import GuaritaStore from '../../stores/GuaritaStore';
import useGuarita from '../../hooks/Guarita.hook';
import { GuaritaRequest, Guarita } from '../../interfaces/Guarita.interface';
import { Button, Input, Modal, SelectPicker } from 'rsuite';
import { observer } from 'mobx-react-lite';

interface PropsModal {
    show: boolean;
    hide: () => void;
    guaritaSelecionado: Guarita | null;
}

const AtualizarGuarita: FC<PropsModal> = ({ show, hide, guaritaSelecionado }) => {
    
    const { atualizar, atualizando } = useGuarita();

    const [form, setForm] = useState<GuaritaRequest>({});

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as Guarita, hide);
    }

    useEffect(() => {
        if(guaritaSelecionado) {
            setForm(guaritaSelecionado);
        }
    }, [guaritaSelecionado]);

    const FormGuarita = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>IP</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.ip} placeholder="192.168.0.0" onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.porta} placeholder="Ex: 80" onChange={(e) => setForm({...form, porta: parseInt(e)})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-12 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.nome} placeholder="Nome do guarita" onChange={(e) => setForm({...form, nome: e})}  />
                    </Form.Group>
                    {/* <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Fabricante</Form.Label>
                        <SelectPicker
                            block
                            value={form.fabricante}
                            placeholder={'Selecione o fabricante'}
                            onChange={(e) => setForm({...form, fabricante: e})} 
                            data={
                                [
                                    {
                                        label: 'NICE',
                                        value: 'NICE'
                                    }
                                ]
                            }
                        />
                    </Form.Group> */}
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.modelo} placeholder="Modelo do guarita" onChange={(e) => setForm({...form, modelo: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end'>
                    <Button appearance="primary" disabled={atualizando} type="submit">
                        { atualizando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={atualizando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                {atualizando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
                </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>atualização de guarita</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que a atualizacao possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormGuarita()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarGuarita);