import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite';
import useBravas from '../../../hooks/Bravas.hook';
import { Bravas, BravasRequest } from '../../../interfaces/Bravas.interface';
import { StoreContext } from '../../../stores';
import { useMip1000 } from '../../../hooks/Mip100.hook';
import { Mip1000 } from '../../../interfaces/Mip1000.interface';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const CadastrarMip1000: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<BravasRequest>({});
    const { cadastrar, salvando } = useMip1000();
    const { condominioStore } = useContext(StoreContext);


    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(condominioStore.selecionado)
        {
            form.condominioId = condominioStore.selecionado.id;
            cadastrar(form as Mip1000, hide, () => {});
        }
    }


    const FormCard = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>IP</Form.Label>
                        <Input required type="text" value={form.ip || ''} className='shadow-none' placeholder="192.168.0.0" onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta API</Form.Label>
                        <Input required type="number" value={form.porta} className='shadow-none' placeholder="Ex: 80" onChange={(e) => setForm({...form, porta: parseInt(e)})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Usuário</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.usuario || ''} placeholder="admin" onChange={(e) => setForm({...form, usuario: e})} />
                    </Form.Group>
                    <Form.Group className=" col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Senha</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.senha || ''} placeholder="admin" onChange={(e) => setForm({...form, senha: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.nome || ''} placeholder="Nome" onChange={(e) => setForm({...form, nome: e})}  />
                    </Form.Group>
                    <Form.Group className=" col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.modelo || ''} placeholder="Modelo" onChange={(e) => setForm({...form, modelo: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end'>
                    <Button appearance="primary" disabled={salvando} type="submit">
                        { salvando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={salvando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                {/* {salvando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''} */}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastrar novo equipamento MIP 1000 - IP</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormCard()}</Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarMip1000);