import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonList = () => {
  return (
    <div>
        <Skeleton animation="wave" height={80} style={{borderRadius: 8}} />
        <Skeleton animation="wave" height={80} style={{borderRadius: 8, marginTop: '-25px'}} />
        <Skeleton animation="wave" height={80} style={{borderRadius: 8, marginTop: '-25px'}} />
        <Skeleton animation="wave" height={80} style={{borderRadius: 8, marginTop: '-25px'}} />
        {/* <Skeleton variant="rounded" width={210} height={60} /> */}
    </div>
  )
}

export default SkeletonList;