
import { observer } from 'mobx-react-lite';
import react, { FC, useContext, useEffect, useState } from 'react';
import MoradorAPI from '../../../api/MoradorAPI';
import { StoreContext } from '../../../stores';
import { useMorador } from '../../../hooks/Morador.hook';
import { cpfMask, maskCelular, maskTelefoneFixo } from '../../utils/mask';
import { Button, Input, InputNumber, Loader, Modal, SelectPicker, Toggle } from 'rsuite';
import { AutomacaoAcionamentoIdentificacao, AutomacaoAcionamentoIdentificacaoRequest } from '../../../interfaces/AutomacaoAcionamentoIdentificacao.interface';
import useAutomacaoAcionamentoIdentificacao from '../../../hooks/AutomacaoAcionamentoIdentificacao.hook';
import { AutomacaoAcionamento, AutomacaoAcionamentoRequest } from '../../../interfaces/AutomacaoAcionamento.interface';
import useAutomacaoAcionamento from '../../../hooks/AutomacaoAcionamento.hook';


interface PropsMorador {
    show: boolean;
    hide: () => void;
}

const CadastrarAutomacaoAcionamento: FC<PropsMorador> = ({ show, hide }) => {

    let condominioId = localStorage.getItem('condominio');
    const { unidadeStore, acionamentoStore, acionamentoPerfilStore, automacaoAcionamentoIdentificacaoStore, cameraStore } = useContext(StoreContext);
    const [form, setForm] = useState<AutomacaoAcionamentoRequest>({});
    const { cadastrar, salvando } = useAutomacaoAcionamento();


    const salvar = () => {
        cadastrar(form as AutomacaoAcionamento, hide);
    }

    useEffect(() =>
    {
        acionamentoStore.listar();
        if(automacaoAcionamentoIdentificacaoStore.selecionado)
        {
            setForm({ ...form, automacaoAcionamentoIdentificacaoId: automacaoAcionamentoIdentificacaoStore.selecionado.id });
        }
    }, [automacaoAcionamentoIdentificacaoStore.selecionado]);

    const renderForm = () =>
    {
      return(
        <div>
            <div className='card-form-morador'>
                <div className='mt-2'>
                    <p className='title-form'>Selecione o acionamento</p>
                    <SelectPicker
                        block
                        placeholder={'Selecione'}
                        data={acionamentoStore.acionamentos.map((a) => ({ label: a.nome, value: a.id }))}
                        onChange={(e) => {if(e) setForm({ ...form, acionamentoId: e })}} 
                        />
                    {/* <Input className='' placeholder='Identificação' value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })}  /> */}
                </div>
                <div className='mt-2'>
                    <p className='title-form'>Delay - Milisegundos </p>
                    <InputNumber placeholder='Identificação' value={form.delay || ''} onChange={(e) => setForm({ ...form, delay: Number(e) })}  />
                </div>
            </div>
        </div>
      )
    }

    return(
        <Modal open={show} onClose={hide} size={'xs'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastro programação para essa automação</p>
                    <p className='modal-styled-subtitl'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                {renderForm()}
            </Modal.Body>

            <Modal.Footer>
                <Button appearance={'primary'} color={'violet'} disabled={salvando} onClick={salvar}>
                    {
                        salvando
                        ?
                        <Loader content={'Salvando...'} />
                        :
                        'Cadastrar'
                    }
                </Button>
                <Button onClick={hide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(CadastrarAutomacaoAcionamento);