import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import BlocoAPI from "../api/BlocoAPI";
import { Bloco } from "../interfaces/Bloco.interface";

class BlocoStore {

    blocos: Bloco[] = [];
    selecionado: Bloco | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listar() {
        this.loading = true;
        try {
            let condominioId = localStorage.getItem('condominio');
            if(condominioId) {
                const { data } = await BlocoAPI.listarPorCondominioId(condominioId);
                runInAction(() => {
                    if(data) this.blocos = data;
                    else this.blocos = [];
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(blocoId: string) {
        let selecionado = this.blocos.find((blo) => blo.id === blocoId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
    }

}

export default BlocoStore;