import { Container } from "./styles/Ramais";
import Header from "../layouts/Header";
import { Col, Row } from "react-bootstrap";
import { HeaderNavigation } from "../condominios/styles/Condominios.style";
import { BiRefresh } from "react-icons/bi";
import { Button, Input, InputGroup, Loader, Modal } from "rsuite";
import { useEffect, useContext, useState } from "react";
import { StoreContext } from "../../stores";
import { observer } from "mobx-react-lite";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import { RamalGeral } from "../../interfaces/Ramal.interface";
import { FiSearch } from "react-icons/fi";
import { useRamal } from "../../hooks/Ramal.hook";

const Ramais = () => {
  const { ramalStore } = useContext(StoreContext);
  const [show, setShow] = useState(false);
  const [sipId, setSipId] = useState("");
  const [search, setSearch] = useState("");
  const [filteredRamais, setFilteredRamais] = useState<RamalGeral[]>([]);
  const { reiniciar, reiniciando } = useRamal();

  const toggleModal = (ramal: any) => {
    setSipId(ramal.dispositivoSipId);
    setShow(!show);
  };

  useEffect(() => {
    const filtered = ramalStore.ramaisGeral.filter(
      (ramal) =>
        ramal.condominioNome.toLowerCase().includes(search.toLowerCase()) ||
        ramal.ip.includes(search) ||
        ramal.ramalNumero.includes(search) ||
        ramal.nome.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredRamais(filtered);
  }, [search.length > 3]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleResetSearch = () => {
    setSearch("");
  };
  useEffect(() => {
    ramalStore.listarRamalGeral();
  }, []);

  const eventHandler = () => {
    reiniciar(sipId);
    setShow(false);
  };

  const listaRamal = () => {
    if (ramalStore.loading) {
      return <Loader center={true} size="lg" />;
    }

    return (
      <HeaderNavigation>
        <InputGroup inside>
          <Input onChange={handleSearch} placeholder="Buscar" />
          <InputGroup.Button onClick={handleResetSearch}>
            {search === "" ? <FiSearch /> : <CloseOutlineIcon />}
          </InputGroup.Button>
        </InputGroup>
        <Row className="d-flex theme justify-content-center">
          {filteredRamais.map((ramal: any) => (
            <Col
              key={ramal.id}
              xl="3"
              md="2"
              sm="4"
              className="m-2 border border-light  pt-3 bordered rounded shadow"
            >
              <Col>
                <h5 className="mb-2 text-center">
                  {ramal.condominioNome} - {ramal.ramalNumero}{" "}
                </h5>
                <p className="mt-1 text-center">{ramal.ip}</p>
                <p className="mt-1">{ramal.nome}</p>
                <p className="small mt-1">{ramal.sipModelo}</p>
                <p className="small mt-1">{ramal.sipFabricante}</p>
                <p className="small mt-1">{ramal.tipo}</p>
                <Col className="text-center">
                  <button
                    onClick={() => toggleModal(ramal)}
                    className="my-3 btn-sm  text-dark"
                  >
                    <BiRefresh size={20} style={{ marginRight: "4px" }} />
                    <small>Reiniciar Ramal</small>
                  </button>
                </Col>
              </Col>
            </Col>
          ))}
        </Row>
      </HeaderNavigation>
    );
  };

  const ModalReiniciarRamal = () => {
    return (
      <Modal open={show} onClose={toggleModal}>
        <Modal.Header closeButton>
          <div>
            <p className="modal-styled-title">Reiniciar Ramal</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>Deseja realmente reiniciar esse ramal?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={eventHandler}>Sim</Button>
          <Button onClick={toggleModal}>Não</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Container>
      <Header mode="condominio" />
      {listaRamal()}
      {ModalReiniciarRamal()}
    </Container>
  );
};

export default observer(Ramais);
