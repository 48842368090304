import { Mensagem, NovaMensagem, StatusMensagemUsuarios } from "../interfaces/Chat";
import chatApi from "../services/chat/api";

class ChatAPI {

    ObterStatusChatUsuarios = async (centralAtendimentoId: string) => await chatApi.get<StatusMensagemUsuarios[]>('/Chat/ObterStatusChatUsuarios', { params: {centralAtendimentoId} })
    ObterMensagensPorUsuario = async (condominioId: string, usuarioId: string, registroInicial: number, registroQuantidade: number) => await chatApi.get<Mensagem[]>('/Chat/ObterMensagensPorUsuario', { params: {condominioId, usuarioId, registroInicial, registroQuantidade} })
    ObterMensagensPorId = async (mensagemId: string) => await chatApi.get<Mensagem>('/Chat/ObterMensagemPorId', { params: {mensagemId} })
    ConfirmarMensagem = async (id: string) => await chatApi.put(`/Chat/MarcarComoLida?id=${id}`)
    historicoMensagens = async (centralAtendimentoId: string) => await chatApi.get(`/Chat/ObterHistoricoStatusChatUsuarios?centralAtendimentoId=${centralAtendimentoId}`)
    Enviar = async (request: NovaMensagem) => await chatApi.post<Mensagem>(`/Chat/Enviar`, request);
}

export default new ChatAPI();