import { useContext, useState } from "react";
import { toast } from "react-toastify";
import GuaritaAPI from "../api/GuaritaAPI";
import { StoreContext } from "../stores";
import { Guarita } from "../interfaces/Guarita.interface";
import { Bravas, BravasDeletarGrupoDeAcesso, BravasGrupoDeAcesso, BravasInfoacessoCadastrado } from "../interfaces/Bravas.interface";
import BravasAPI from "../api/BravasAPI";


const useBravas = () => {

    const { bravasStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [reiniciando, setReiniciando] = useState(false);
    const [resetando, setResetando] = useState(false);

    const reiniciar = async (bravas: Bravas, hide: () => void) => {
        // setReiniciando(true);
        // try {
        //     const { data, status } = await GuaritaAPI.reiniciar(guarita.id);
        //     if(status === 200) {
        //         toast.success('Guarita reiniciado com sucesso');
        //         hide();
        //         setReiniciando(false);
        //     } else {
        //         toast.error('Erro ao reiniciar guarita');
        //         setReiniciando(false);
        //     }
        // } catch(e) {
        //     setReiniciando(false);
        //     toast.error('Erro ao reiniciar guarita');
        //     //
        // }
    }

    const atualizarAcesso = async (acesso: BravasInfoacessoCadastrado) => {

        

        setSalvando(true);
        try {
            if(acesso.config.type === 'Nativo')
                acesso.config.type = 'Nativo';
            else if(acesso.config.type === 'ReceptorRf')
                acesso.config.type = 'ReceptorRf';

            acesso.config.equipamentoId = bravasStore.selecionado?.id || '';
            const { data, status } = await BravasAPI.atualizarAcessoPortaNativa(acesso);
            if(status === 200) {
                if(bravasStore.selecionado)
                {
                    bravasStore.listarAcessosCadastrado(bravasStore.selecionado.id, null);
                    bravasStore.obterInformaçãoAcesso(bravasStore.selecionado.id, acesso.config.name);
                    toast.success('Acesso atualizado com sucesso');
                    setSalvando(false);
                }
                else
                    alert('erro ao atualizar acesso');
            } else {
                toast.error('Erro ao cadastrar bravas');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const cadastrarGrupoDeAcesso = async (acesso: BravasGrupoDeAcesso) => {
        setSalvando(true);
        try {
            const { data, status } = await BravasAPI.cadastrarGrupoDeAcesso(acesso);
            if(status === 200) {
                // bravasStore.bravas.push(data);
                toast.success('Grupo cadastrado com sucesso');
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar grupo');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const cadastrar = async (bravas: Bravas, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await BravasAPI.cadastrar(bravas);
            if(status === 200) {
                bravasStore.bravas.push(data);
                toast.success('Bravas cadastrado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar bravas');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const atualizarGrupoDeAcesso = async (acesso: BravasGrupoDeAcesso) => {
        setAtualizando(true);
        try {
            const { data, status } = await BravasAPI.atualizarGrupoDeAcesso(acesso);
            if(status === 200) {
                // bravasStore.bravas.push(data);
                toast.success('Grupo atualizado com sucesso');
                setSalvando(false);
            } else {
                toast.error('Erro ao atualizar grupo');
                setAtualizando(false);
            }
        } catch(e) {
            setAtualizando(false);
            //
        }
    }

    const atualizar = async (bravas: Bravas, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await BravasAPI.atualizar(bravas);
            if(status === 200) {
                bravasStore.bravas = bravasStore.bravas.map( b => b.id === bravas.id ? bravas : b );
                toast.success('Bravas atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar bravas');
                setAtualizando(false);
            }
        } catch(e) {
            setAtualizando(false);
            //
        }
    }

    const deletar = async (bravas: Bravas) => {
        setDeletando(true);
        try {
            const { status } = await BravasAPI.deletar(bravas.id);
            if(status === 200) {
                bravasStore.bravas = bravasStore.bravas.filter( b => b.id !== bravas.id );
                toast.success('Bravas deletado com sucesso');
                setDeletando(false);
            } else {
                toast.error('Erro ao deletado bravas');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
        }
    }

    const deletarGrupoDeAcesso = async (acesso: BravasDeletarGrupoDeAcesso) => {
        setDeletando(true);
        try {
            const { status } = await BravasAPI.deletarGrupoDeAcesso(acesso);
            if(status === 200) {
                // bravasStore.bravas = bravasStore.bravas.filter( b => b.id !== bravas.id );
                toast.success('Grupo deletado com sucesso');
                setDeletando(false);
            } else {
                toast.error('Erro ao deletado grupo');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
        }
    }


    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
        reiniciar,
        reiniciando,
        atualizarAcesso,
        cadastrarGrupoDeAcesso,
        atualizarGrupoDeAcesso,
        deletarGrupoDeAcesso 
    }

}


export default useBravas;