import styled from 'styled-components';
import background from '../../../assets/img/login-bg.jpg';

export const Container = styled.div`
    .img
    {
        height: 200px;
    }
    .title
    {
        margin: 0;
        font-weight: 550;
        font-size: 16pt;        
    }
    .subtitle
    {
        margin: 0;
        font-size: 9pt;
    }
    .title-form
    {
        font-weight: 550;
        margin-top: 10px;
    }
`