import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';

interface Props {
    state?: boolean;
}

export const Container = styled.div`
    padding: 20px;
    background: ${(props) => props.theme.colors.bgColor};
    
    .box-carrosel
    {
        background: ${(props) => props.theme.colors.bgBox};
        padding: 10px;
        border-radius: 8px;
        margin-top: 5px;
        transition: 0.2s;
    }
    .box-carrosel:hover
    {
        background: ${(props) => props.theme.colors.borderColor};
        cursor: pointer;
        transition: 0.2s;
    }
    .icon
    {
        font-size: 20pt;
        margin-right: 10px;
    }
    .title
    {
        margin: 0;
        font-size: 10pt;
        font-weight: 550;
    }
    .subtitle
    {
        margin: 0;
        font-size: 9pt;
        // width: 20vw;
    }
`

export const Box = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    .title {
        margin: 0;
        font-size: 12pt;
        font-weight: 550;
        align-self: center;
    }
    .subtitle {
        margin: 0;
        font-size: 9pt;
        align-self: center;
    }
`

export const Header = styled.div`
    // padding-top: 10px;
    // padding-left: 30px;
    // padding-right: 30px;
    .title
    {
    font-size: 12pt;
    margin: 0;
    font-weight: 550;
    }
    .subtitle
    {
    font-size: 9pt;
    margin: 0;
    }

    .box
    {
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    margin-top: 5px;
    }

    .content
    {
    padding: 20px;
    margin-top: 10px;
    width: 450px;
    }
    .content-icon
    {
    align-self: center;
    font-size: 45pt;
    margin-right: 20px;
    }
    .content-title
    {
    font-size: 10pt;
    margin: 0;
    font-weight: 550;
    }
    .content-subtitle
    {
    font-size: 9pt;
    margin: 0;
    }

    .info-title
    {
    font-size: 8pt;
    width: 80%;
    }
    .info-title-filtro
    {
    font-size: 8pt;
    width: 50%;
    }
`

export const HeaderItem = styled.div`
    // padding-top: 10px;
    // padding-left: 30px;
    // padding-right: 30px;
    .title
    {
    font-size: 12pt;
    margin: 0;
    font-weight: 550;
    }
    .subtitle
    {
    font-size: 9pt;
    margin: 0;
    }

    .box
    {
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    margin-top: 5px;
    }

    .content
    {
    // padding: 20px;
    margin-top: 10px;
    width: 250px;
    }
    .content-icon
    {
    align-self: center;
    font-size: 45pt;
    margin-right: 20px;
    }
    .content-title
    {
    font-size: 10pt;
    margin: 0;
    font-weight: 550;
    }
    .content-subtitle
    {
        font-size: 9pt;
        margin: 0;
    }

    .info-title
    {
    font-size: 8pt;
    width: 80%;
    }
    .info-title-filtro
    {
    font-size: 8pt;
    width: 50%;
    }
`

export const ContainerLista = styled.div`
    border-radius: 4px;
    // margin-top: 20px;
    height: 100vh;
    .box-table
    {
        background: ${(props) => props.theme.colors.bgBox};
        padding: 10px;
        border-radius: 8px;
        margin-top: 10px;
        // height: 60vh;
    }
    .nav-route
    {
        margin-right: 30px;
        width: 20vw;
    }
    .nav-content
    {
        width: 100%;
    }
    .container-table
    {
        border: 1px solid ${(props) => props.theme.colors.borderColor};
        border-radius: 6px;
    }
    table
    {
        border-radius: 6px;
    }
    thead th {
        font-weight: 0;
        font-size: 13px;
        padding: 10px;
        border-bottom: 1px solid ${(props) => props.theme.colors.bgNav};
    } 
    tbody td {
        font-size: 12px;
        padding: 12px;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    }
    .box
    {
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
        padding: 10px;
    }
    .box-card
    {
        padding: 10px;
        border-radius: 6px;
        background: ${(props) => props.theme.colors.bgBox};
        margin: 10px;
        width: 40%;
    }
    .title-config
    {
        font-size: 11pt;
        font-weight: 550;
        align-self: center;
    }
    .icon
    {
        font-size: 12pt;
        align-self: center;
    }
    .subtitle-config
    {
        font-size: 10pt;
        width: 70%;
        line-height: 15px;
        align-self: center;
        margin-left: 20px;
    }
    .content-grupos
    {
        padding: 10px;
        border-radius: 6px;
        background: ${(props) => props.theme.colors.bgBox};
    }
`

