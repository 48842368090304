import { Alert, Avatar, FormControlLabel, LinearProgress, Snackbar, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { StoreContext } from '../../stores';
import { Morador, MoradorRequest } from '../../interfaces/Morador.interface';
import { ContainerFormMorador, ContainerFormVisitante, InforPessoal } from './styles/Moradores.style';
import { Input, List, Modal } from 'rsuite';
import Loading from '../utils/Loading';
import { Box, Unidade } from './styles/BuscaMorador.style';
import NotFound from '../utils/NotFound';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const BuscaMorador: FC<ModalProps> = ({ show, hide }) => {

    const { moradorStore, condominioStore, unidadeStore } = useContext(StoreContext);
    const [nome, setNome] = useState('');

    const buscar = (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          if(condominioStore.selecionado)
        {
            moradorStore.listarPorNomeMorador(condominioStore.selecionado.id, nome);
            
        }        
    }

    useEffect(() =>
    {   
        if(show)
        {
            moradorStore.moradoresNome = [];
            setNome('');
        }
    }, [show])

    const FormVisitante = () => {

        if(moradorStore.loading)
        {
            return <Loading message={'Buscando morador...'} />
        }

        if(moradorStore.moradoresNome.length === 0)
        {
            return <NotFound message='Nenhum morador encontrado!' />
        }

        return(
        <div>
            <div className='mt-2'>
                <List>
                    {moradorStore.moradoresNome.map((morador, index) => (
                    <Box
                        key={morador.id}
                        className="d-flex"
                        onClick={() => {
                            hide();
                            moradorStore.listarPorUnidadeId(morador.unidadeId);
                            unidadeStore.definirSelecionado(morador.unidadeId);
                        }}
                    >
                        <Unidade>
                        <span className="text-muted">Unidade</span>
                        <b>{morador?.unidadeIdentificacao}</b>
                        </Unidade>
                        <Unidade>
                        <span className="text-muted">Bloco</span>
                        <b>{morador.blocoIdentificacao?.toUpperCase()}</b>
                        </Unidade>
                        <div className="media-body pl-2 align-self-center font-weight-bold">{morador.nome}</div>
                    </Box>
                    ))}
                </List>
            </div>
        
        </div>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header >
                <div>
                    <p className='modal-styled-title'>Buscar morador do condomínio pelo nome</p>
                    <p className='modal-styled-subtitle'>
                        Preencha o campo abaixo para procurar o morador
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={buscar} className={'mb-2'}>
                    <Input value={nome} onChange={(e) => setNome(e)} placeholder={'Buscar morador pelo nome'} />
                </form>
                {FormVisitante()}
            </Modal.Body>
        </Modal>
    )
}

export default observer(BuscaMorador);