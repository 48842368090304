import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsCameraVideo } from 'react-icons/bs';
import { StoreContext } from '../../stores';
import { useCamera } from '../../hooks/Camera.hook';
import { Camera, CameraPerfilIdentificacaoRequest, CameraRequest } from '../../interfaces/Cameras.interface';
import { Layout } from '../../interfaces/Layout.interface';
import { FormCamera, SubTitle, Title } from './styles/Modal.style';
import { Button, Input, Loader, Modal, Popover, SelectPicker, Whisper } from 'rsuite';
import LayoutAPI from '../../api/LayoutAPI';

interface Props {
    show: boolean;
    hide: () => void;
}

const AtualizarCameraPerfilIdentificacao:FC<Props> = ({ show, hide }) => {

    const { cameraStore, layoutStore } = useContext(StoreContext);
    const [form, setForm] = useState<CameraPerfilIdentificacaoRequest>({});
    const [imgCam, setImgCam] = useState('');
    const [loading, setLoading] = useState(false);
    const { atualizarPerfil, atualizando, deletarPerfil, deletando } = useCamera();

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizarPerfil(form as any, hide);
    }

    useEffect(() =>
    {   
        if(cameraStore.perfilSelecionado)
        {
            setForm(cameraStore.perfilSelecionado)
        }
    }, [cameraStore.perfilSelecionado])

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header style={{border: 0}} closeButton>
                <div>
                    <Title>Cadastro de perfil de câmeras <BsCameraVideo/> </Title>
                    <SubTitle>Cadastre novo perfil de câmera para esse condomínio</SubTitle>
                </div>
            </Modal.Header>
            <Modal.Body>
                <FormCamera onSubmit={salvar}>
                    <p className='info'>Preencha todos os campos abaixo</p>
                    <div>
                        <p className='title'>Nome<span className='text-danger'>*</span></p>
                        <Input required placeholder='Nome de identifição da câmera' value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })}  />
                    </div>

                    <div className='d-flex justify-content-between mt-2'>
                        <Button type={'button'} appearance={'primary'} color={'red'} className='m-1' onClick={() => 
                        {
                            if(cameraStore.perfilSelecionado)
                            {
                                deletarPerfil(cameraStore.perfilSelecionado, hide)
                            }
                        }}>
                            {
                                deletando
                                ?
                                <Loader title={'Exlcuindo...'} />
                                :
                                'Excluir'
                            }
                        </Button>
                        <div className='d-flex'>
                            <Button disabled={atualizando} type={'submit'} appearance="primary" className='m-1'>
                                {
                                    atualizando
                                    ?
                                    <Loader title={'Salvando...'} />
                                    :
                                    'Salvar'
                                }
                            </Button>
                            <Button type={'button'} className='m-1' onClick={hide}>Fechar</Button>
                        </div>
                    </div>
                </FormCamera>

            </Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarCameraPerfilIdentificacao);
