import styled from "styled-components";
import PerfectScrollBar from "react-perfect-scrollbar";
import PerfectScroll from "react-perfect-scrollbar";

export const Container = styled(PerfectScrollBar)`
  padding: 10px;
  height: 90vh;
  // background: ${(props) => props.theme.colors.bgBox};
  border-radius: 8px;
  // margin: 20px;
  .dashboard {
    background: ${(props) => props.theme.colors.bgBox};
    padding: 10px;
    border-radius: 8px;
    box-shadow: 1px 3px 5px ${(props) => props.theme.colors.bgColor};
  }
  .dashboard-content {
    border-right: 1px solid ${(props) => props.theme.colors.borderColor};
    width: 350px;
    margin-right: 15px;
  }
  .dashboard-icon {
    font-size: 20pt;
    align-self: center;
    margin-right: 20px;
  }
  .dashboard-title {
    margin: 1px;
    font-size: 12pt;
    font-weight: 550;
  }
  .dashboard-subtitle {
    margin: 2px;
    font-size: 10pt;
  }
  .dashboard-info {
    margin: 2px;
  }
`;

export const ListAviso = styled(PerfectScroll)`
  margin-top: 5px;
  align-self: center;
  border-radius: 10px;
  height: 70vh;
  // border-radius: 12px;
  margin-top: 8px;
  thead th {
    font-weight: 0;
    // background: ${(props) => props.theme.colors.bgBox};
    font-size: 13px;
    padding: 10px;
    // border-bottom: 1px solid ${(props) => props.theme.colors.bgNav};
  }
  tbody td {
    font-size: 12px;
    border: 0;
    padding: 15px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`;

export const ImageAviso = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 80px;
`;

export const ImageDefault = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 80px;
`;

export const DescriptionText = styled.p`
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
`;

export const CreatedBy = styled.p`
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
`;

export const TextAlert = styled.p`
  color: red;
  text-align: center;
`;

export const ContainerAcoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonEdit = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;

  &:hover {
    background-color: #f0f0f0;
  }

  &:active {
    background-color: #d9d9d9;
  }
`;

export const ButtonDelete = styled.button`
  background-color: transparent;
  border-radius: 10px;

  cursor: pointer;
  transition: background-color 0.3s ease;

  &:active {
    background-color: #ff9999;
  }
`;

export const TextEdit = styled.p``;
export const TextDelete = styled.p``;

export const ContainerImageUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 25px;
`;

export const TextPendent = styled.p`
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
`;
export const TextConfirmed = styled.p`
  color: green;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
`;

export const TextCreatedIn = styled.p`
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
`;

export const TextExpired = styled.p`
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
`;

export const TextExpiredVoid = styled.p`
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
`;

export const TextRejectedReason = styled.p`
  
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
  width: 290px;
`;

export const TextRejected = styled.p`
  color: red;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
`;

export const Box = styled.div`
  padding: 15px;
  border-radius: 10px;
  height: 100%;
  .card-input {
    width: 300px;
    background: ${(props) => props.theme.colors.bgColor};
    border-radius: 7px;
  }
  .icon {
    align-self: center;
    margin-left: 20px;
    font-size: 14 pt;
  }
  .input {
    border: 0;
    background: transparent;
  }
  .button-add {
    border-radius: 7px;
    width: 150px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .tr-title {
    align-self: center;
    margin: 0;
    font-size: 9pt;
    font-weight: 550;
  }
  .tr-subtitle {
    align-self: center;
    margin: 0;
    font-size: 7pt;
  }
`;

export const Header = styled.div`
  margin-top: 5px;
  background: ${(props) => props.theme.colors.bgBox};
  padding: 10px;
  border-radius: 4px;
  .title {
    margin: 0;
    font-size: 12pt;
    font-weight: 550;
    align-self: center;
  }
  .subtitle {
    margin: 0;
    font-size: 12pt;
  }
`;

export const List = styled.div`
  border-radius: 12px;
  margin-top: 8px;
  // height: 20vh;
  background: ${(props) => props.theme.colors.bgColor};
  // padding-top: 10px;
  // padding-left: 10px;
  // padding-right: 10px;
  // border: 1px solid ${(props) => props.theme.colors.borderColor};
  thead th {
    font-weight: 0;
    // background: ${(props) => props.theme.colors.bgBox};
    font-size: 13px;
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.bgNav};
    // margin-bottom: 20px;
  }
  tbody td {
    font-size: 12px;
    padding: 12px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`;

export const CardItem = styled.div`
  background: ${(props) => props.theme.colors.bgColor};
  margin-top: 5px;
  border-radius: 6px;
  padding: 4px;
  .title-item {
    margin: 0;
  }
  .subtitle-item {
    margin: 0;
    margin-top: -13px;
  }

  .item-icon {
    font-size: 20pt;
  }
`;
