import React, { FC } from 'react'
import { MdWarning } from 'react-icons/md';
import { Button, Loader, Modal } from 'rsuite';

interface Props
{
    content: string;
    show: boolean;
    hide: () => void;
    action: () => void;
    loading?: boolean;
}

const ModalConfirmacao: FC<Props> = ({ show, hide, action, content, loading }) => {
  return (
    <Modal backdrop="static" role="alertdialog" open={show} onClose={hide} size="xs">
        <Modal.Body>
          <MdWarning style={{ color: '#ffb300', fontSize: 24 }} />
          {content}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} onClick={() => { action(); if(!loading) hide(); }} appearance="primary">
            { loading ? <Loader content={'Deletando... Aguarde!'} /> : 'Sim' }
          </Button>
          <Button disabled={loading} onClick={hide} appearance="subtle">
            Não
          </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirmacao;