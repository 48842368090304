import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import PermissoesAPI from "../api/PermissoesAPI";
import RamalAPI from "../api/RamalAPI";
import { Permissoes } from "../interfaces/Permissoes.interface";
import { Maquina, Ramal, RamalCentral } from "../interfaces/Ramal.interface";

class PermissoesStore {

    permissoes: Permissoes | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }
    
    async selecionarPorId(permissaoId: string) {
        this.permissoes = null;
        this.loading = true;
        try {
            const { data } = await PermissoesAPI.selecionarPorId(permissaoId);
            runInAction(() => {
                this.loading = false;
                this.permissoes = data;
                
            });
        } catch(e) {
            
            this.loading = false;
        }
    }
    
}

export default PermissoesStore;