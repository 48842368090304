import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { StoreContext } from "../stores";
import AutomacaoAcionamentoIdentificacaoAPI from "../api/AutomacaoAcionamentoIdentificacaoAPI";
import { AutomacaoAcionamentoIdentificacao } from "../interfaces/AutomacaoAcionamentoIdentificacao.interface";
import { AutomacaoAcionamento, NovaOrdem } from "../interfaces/AutomacaoAcionamento.interface";
import AutomacaoAcionamentoAPI from "../api/AutomacaoAcionamentoAPI";


const useAutomacaoAcionamento = () => {

    const { automacaoAcionamentoStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [deletando, setDeletando] = useState(false);

    const cadastrar = async (automacaoAcionamentoIdentificacao: AutomacaoAcionamento, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await AutomacaoAcionamentoAPI.cadastrar(automacaoAcionamentoIdentificacao);
            if(status === 200) {
                automacaoAcionamentoStore.listar(automacaoAcionamentoIdentificacao.automacaoAcionamentoIdentificacaoId);
                toast.success('Comando cadastrado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar comando');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const atualizarOrdem = async (novaOrdem: NovaOrdem, automacaoAcionamentoIdentificacaoId: string, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await AutomacaoAcionamentoAPI.atualizarOrdem(novaOrdem);
            if(status === 200) {
                automacaoAcionamentoStore.listar(automacaoAcionamentoIdentificacaoId);
                toast.success('Ordem atualizado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Ordem atualizado comando');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const deletar = async (automacaoAcionamento: AutomacaoAcionamento) => {
        setDeletando(true);
        try {
            const { status } = await AutomacaoAcionamentoAPI.deletar(automacaoAcionamento.id);
            if(status === 200) {
                // automacaoAcionamentoStore.automacaoAcionamento = automacaoAcionamentoStore.automacaoAcionamento.filter( a => a.id !== automacaoAcionamento.id);
                automacaoAcionamentoStore.listar(automacaoAcionamento.automacaoAcionamentoIdentificacaoId);
                toast.success('Comando deletado com sucesso');
                setDeletando(false);
            } else {
                toast.error('Erro ao deletado comando');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
        }
    }


    return {
        cadastrar,
        deletar,
        atualizarOrdem,
        salvando,
        deletando
    }

}


export default useAutomacaoAcionamento;