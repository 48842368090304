import api from "../services/api/api";
import { Bloco } from "../interfaces/Bloco.interface";

class BlocoAPI {

    listarPorCondominioId = async (condominioId: string) => await api.get<Bloco[]>(`/Bloco/condominio/${condominioId}`); 
    listarBlocoPorId = async (blocoId: string) => await api.get<Bloco>(`/Bloco${blocoId}`); 
    cadastrar = async (bloco: Bloco) => await api.post<Bloco>(`/Bloco`, bloco); 
    cadastrarMultiplos = async (bloco: Bloco[]) => await api.post<Bloco[]>(`/Bloco/registrarmultiplosblocos`, bloco); 
    atualizar = async (bloco: Bloco) => await api.put<Bloco>(`/Bloco`, bloco); 
    deletar = async (blocoId: string) => await api.delete<Bloco>(`/Bloco/${blocoId}`); 

}

export default new BlocoAPI();